import { Component, Input, OnInit } from '@angular/core';
import { Notification } from 'src/app/models/notification.model';
import { NotificationCardComponent } from 'src/app/shared/components/notification-card/notification-card.component';

@Component({
    selector: 'app-notifications-container',
    templateUrl: './notifications-container.component.html',
    styleUrls: ['./notifications-container.component.scss'],
})
export class NotificationsContainerComponent implements OnInit {

    
    @Input() notificationList: Notification[];
    constructor(){}

    ngOnInit() {}

}
