import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ChangeRequest, StatusChange } from '../models/change-request.model';

@Injectable({
  providedIn: 'root'
})
export class ChangeRequestService {

	static COLLECTION_NAME = 'changeRequest';

  	constructor(
  		private firestore: AngularFirestore
	) { }	

  	createChangeRequest(cRequest: ChangeRequest) {
		cRequest.createdAt = new Date();
		cRequest.updatedAt = cRequest.createdAt;
		cRequest.status = StatusChange.NEW;

		if (!cRequest.crid) {
			cRequest.crid = cRequest.createdAt.getTime() + '';
		}

		return this.firestore.collection(ChangeRequestService.COLLECTION_NAME).doc(cRequest.crid).set(JSON.parse(JSON.stringify(cRequest)));
	}

	updateChangeRequest(cRequest: ChangeRequest) {
		cRequest.updatedAt = new Date();
		return this.firestore.doc(ChangeRequestService.COLLECTION_NAME + '/' + cRequest.crid).update(JSON.parse(JSON.stringify({ ...cRequest }) ) );
	}

}
