import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from './value-accessor-base';

@Component({
  selector: 'app-common-input',
  templateUrl: './common-input.component.html',
  styleUrls: ['./common-input.component.scss'],
  providers: [
  	{provide: NG_VALUE_ACCESSOR, useExisting: CommonInputComponent, multi: true}
  ]
})
export class CommonInputComponent extends ValueAccessorBase<string> implements OnInit {

	@Input() label;
	@Input() type = "text";
	@Input() placeholder = "";
	@Input() autofocus = false;
	@Input() clearInput = false;
	@Input() color = "primary";
	@Input() disabled = false;
	@Input() separator = false;
	@Input() required:boolean = false;
	@Input() tip;
	@Input() event:boolean = false;
	@Input() value:any;
	@Input() name:string;
	@Input() add = false;
	@Input() styleLabel: any = {};

	@Input() radioItems:any[];

	@Output() keyupEvent:EventEmitter<string> = new EventEmitter();
	

	@Input() errorMessage = "";
	@Input() section;
	@ViewChild("input", {static: false}) input: IonInput;

	constructor() {
		super();
	}

	ngOnInit() {
	}

	keyup(e){
		if(this.event){
			this.keyupEvent.emit(e);
			this.value = undefined;
		}
	}

    increment() {
        if(this.type != "password"){
            this.value++;
        }
    }

	valueChange() {
	}

	public getInputElement() {
		return this.input.getInputElement();
	}

}

