import { Deserializable } from "./deserializable.model";

export class Category {
	label: string; 
	value: string; 
	selected: boolean;
	iconName?: string; 
    imageUrl?: string; 
    lightImageUrl?:string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class TagsCategory {
	tags: Tag[] = []; 
	category:string;
}

export class Tag implements Deserializable{
	tagName:string;
	score:number;
	category:string;

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	}
}