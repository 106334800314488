
export class ChangeRequest {
	crid: string;
    createdAt: Date;
    updatedAt: Date;
    oldObject: any;
    newObject: any;
    status: StatusChange;
    dateChangeExecution: Date;
    entity: TargetEntity;
    dataEntity: any;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}

export enum StatusChange{
	NEW = "new",
	IN_PROGRESS = "in_progress",
	ON_HOLD = "on_hold",
	CLOSED = "closed"
}

export enum TargetEntity{
	USER = "user",
	SALON = "salon",
	ABONNEMENT_SALON = "abonnement_salon",
}
