import { Deserializable } from './deserializable.model';
import { Achievement, SalonDetail } from './salon.model';

export class Image implements Deserializable{
    iid: string;
    filename: string;
    likes: string[]; //uid of user who likes
    createdAt:Date;
    _url: string;
    description: string;
    comments: { uid: string; createdAt: Date; message: string; replies?: { uid: string; createdAt: Date; message: string }[] }[];
    tags: string[];
    title: string;
    pid?: string; ///prestation id
    hasBeenNotified?: boolean;
    prestationName?: string;
    salon?: SalonDetail;
	storagePath?: string;
    cover =  false;
    
    constructor(url) {
    	this.url = url;
    	this.createdAt = new Date();
    }

    deserialize(input: any) {
		Object.assign(this, input);
	    return this;
	}
    
    get url() {
        if(!this._url || this._url.includes("fbcdn") || !this._url.includes('https') || (this._url.includes('https') && this._url.includes('_480x480'))){
            return this._url;
        }
        let name = this._url.split('?')[0];
        let re = new RegExp("(.+)\\.(gif|png|jpg|jpeg)", "g");
        let url =  this._url.replace(re, "$1_480x480.$2");
        if(!url.includes('_480x480')){
            url = url.split('?')[0] + '_480x480?' + url.split('?')[1];
        }
        url = url.replace(/\.png|\.gif|\.jpg/g,'.jpeg');
        return url;
    }

    set url(url: string){
        this._url = url;
    }

    get url_image_medium(){

        if(!this._url || this._url.includes("fbcdn") || !this._url.includes('https') || (this._url.includes('https') && this._url.includes('_240x240') ) ){
            return this._url;
        }
        let name = this._url.split('?')[0];
        let re = new RegExp("(.+)\\.(gif|png|jpg|jpeg)", "g");
        let url =  this._url.replace(re, "$1_240x240.$2");
        url = url.replace("_480x480", "");
        url = url.replace("_120x120", "");
        if(!url.includes('_240x240')){
            url = url.split('?')[0] + '_240x240?' + url.split('?')[1];
        }
        url = url.replace(/\.png|\.gif|\.jpg/g,'.jpeg');
        return url;
        
    }

    get url_image_small(){

        if(!this._url || this._url.includes("fbcdn") || !this._url.includes('https') || (this._url.includes('https') && this._url.includes('_120x120') ) ){
            return this._url;
        }
        let name = this._url.split('?')[0];
        let re = new RegExp("(.+)\\.(gif|png|jpg|jpeg)", "g");
        let url =  this._url.replace(re, "$1_120x120.$2");
        url = url.replace("_480x480", "");
        url = url.replace("_240x240", "");
        if(!url.includes('_120x120')){
            url = url.split('?')[0] + '_120x120?' + url.split('?')[1];
        }
        url = url.replace(/\.png|\.gif|\.jpg/g,'.jpeg');
        return url;

    }

    public static fromAchievement(achievement: Achievement): Image[]{
        const images = [];
        
        for(let i=0; i< achievement.images.length; i++){
            const tempImage = achievement.images[i];
            tempImage.tags = achievement.tags || null;
            tempImage.description = achievement.description || null;
            tempImage.title = achievement.title || null;
    
            if (achievement.prestation)
                tempImage.pid = achievement.prestation;

            images.push({...tempImage});
        }

        return images;
    }

    public static imagefactory(object:any): Image {
        const image = new Image(object.url);

        for(let key in object) {
            if (object[key])
                image[key] = object[key];
        } 

        return image;
    }

    addComment(comment:{ uid: string; createdAt: Date; message: string }) {

        if (!this.comments)
            this.comments = [];

        this.comments.unshift(comment);

        this.hasBeenNotified = true;

    }

    addCommentReply(commentIndex, reply:{ uid: string; createdAt: Date; message: string }) {

        if (!this.comments[commentIndex]) 
            return;

        if (!this.comments[commentIndex].replies)
            this.comments[commentIndex].replies = [];

        this.comments[commentIndex].replies.push(reply);

    }

}