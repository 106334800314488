import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { Category } from 'src/app/models/category.model';
import { TagService } from 'src/app/services/tag.service';
import { SalonService } from 'src/app/services/salon.service';
import { PrestationService } from 'src/app/services/prestation.service';
import { Tag } from 'src/app/models/tags.model';
import { Observable } from 'rxjs';
import { CalendarComponentOptions } from 'ion2-calendar';
import { RedirectionService } from 'src/app/services/redirection.service';
import { SearchDetailComponent } from '../search-detail/search-detail.component';
import { ModalController } from '@ionic/angular';



@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  @Output() addClick = new EventEmitter<boolean>();
  @Output() filterChange = new EventEmitter<boolean>();
  @Output() keyUp = new EventEmitter<boolean>();
  @Input() map: boolean = false;
  @Input() listePrestations: boolean = false;
  @Input() isListePrestation: boolean = false;
  @Input() modal: boolean = false;
  @Input() salon: boolean = false;
  @Input() tags: string[];
  @Input() categoriesListePrestations = [];
  @Input() isSalon: boolean;
  @Input() isGallerie: boolean;

  tag: string;
  filterSidebar: boolean = false;
  tagsResult: Observable<Tag[]>;

  showResult: boolean = false;
  showDefaultResult: boolean = false;
  //tags:string[] = [];

  showFilters: boolean = false;
  showDate: boolean = false;

  dateRange: { from; to; };
  optionsRange: CalendarComponentOptions = {
    pickMode: 'range'
  };
  constructor(
    public filterService: FilterService,
    public tagService: TagService,
    public salonService: SalonService,
    public prestationService: PrestationService,
    private redirectionService: RedirectionService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    // this.prestationService.getPrestationsDefault();
    this.salonService.getSalonsDefault();

    this.filterService.tagsAdded.subscribe(t => {
      this.filterService.nombreFiltreActifs();
    });

    if(window.location.href.toString().includes('/salons') || window.location.href.toString().includes('/galleries') || window.location.href.toString().includes('/prestations') ){
      this.showFilters = true; 
    }

  }


  searchbarChange(keyword) {
    if (keyword) {
      this.showResult = true;
      this.showDefaultResult = false;
      if (this.isSalon) {
        this.salonService.searchbarChange(keyword);
      } else if (this.isGallerie) {
        this.prestationService.searchbarChange(keyword);
      }
    } else {
      this.showResult = false;
    }
  }

  searchbarFocus() {
    this.showDefaultResult = true;
  }

  clickOutside() {
    setTimeout(() => {
      this.showResult = false;
      this.showDefaultResult = false;
      this.tag = undefined;
    }, 100);
  }

  filterToggle(e) {
    // this.menu.enable(true, 'filterMenu');
    // this.menu.open('filterMenu');
    this.showFilters = !this.showFilters;
  }

  filter(num) {

    switch (num) {
      case 0:
        if (!this.filterService.filterBy.gender) {
          this.filterService.filterBy.gender = [];
        }
        if (this.filterService.filterBy.gender.find((el) => el === "child")) {
          this.filterService.filterBy.gender = this.filterService.filterBy.gender.filter((el) => el !== "child");
          if (this.filterService.filterBy.gender.length === 0) {
            this.filterService.removeFilter("gender");
          }
        } else {
          this.filterService.filterBy.gender.push("child");
        }
        break;
      case 1:
        if (!this.filterService.filterBy.gender) {
          this.filterService.filterBy.gender = [];
        }
        if (this.filterService.filterBy.gender.find((el) => el === "women")) {
          this.filterService.filterBy.gender = this.filterService.filterBy.gender.filter((el) => el !== "women");
          if (this.filterService.filterBy.gender.length === 0) {
            this.filterService.removeFilter("gender");
          }
        } else {
          this.filterService.filterBy.gender.push("women");
        }
        break;
      case 2:
        if (!this.filterService.filterBy.gender) {
          this.filterService.filterBy.gender = [];
        }
        if (this.filterService.filterBy.gender.find((el) => el === "men")) {
          this.filterService.filterBy.gender = this.filterService.filterBy.gender.filter((el) => el !== "men");
          if (this.filterService.filterBy.gender.length === 0) {
            this.filterService.removeFilter("gender");
          }
        } else {
          this.filterService.filterBy.gender.push("men");
        }
        break;
      case 3:
        if (this.filterService.filterBy.promotion) {
          this.filterService.removeFilter('promotion');
        } else {
          this.filterService.filterBy.promotion = true;
        }
        break;
      case 4:
        if (this.filterService.filterBy.isEntreprise) {
          this.filterService.removeFilter('isEntreprise');
        } else {
          this.filterService.filterBy.isEntreprise = true;
        }
        break;
      case 5:
        if (this.filterService.filterBy.movingPrestations) {
          this.filterService.removeFilter('movingPrestations');
        } else {
          this.filterService.filterBy.movingPrestations = 'atHome';
        }
        break;
      case 6:
        /*if (this.filterService.filterBy.availability) {
          this.filterService.removeFilter('availability');
        } else {
          */
          this.filterService.filterBy.availability = this.dateRange;
        //}
        this.showDate = false;
        break;
      default:
        break;
    }
    this.filterService.tagsAdded.next(true);
  }

  genderType(type) {
    let res = false;
    if (!this.filterService.filterBy.gender) {
      return res;
    }
    if (this.filterService.filterBy.gender.find((el) => el === type)) {
      res = true;
    }
    return res;
  }

  dateFilter() {
    /*if (this.filterService.filterBy.availability && !this.showDate) {
      this.filterService.removeFilter('availability');
      this.showDate = false;
    } else {
      this.showDate = !this.showDate;
    }*/
    this.showDate = !this.showDate;
  }

  cancelFilterDate(){
    this.filterService.removeFilter('availability');
    this.filterService.tagsAdded.next(true);
    if(this.dateRange){
      if(this.dateRange.from) this.dateRange.from = null;
      if(this.dateRange.to) this.dateRange.to = null;
    }
    
    this.showDate = false;
  }

  selectCategory(c: Category, sub = false) {



    c.selected = !c.selected;



    if (!c.selected && !sub) {
      this.filterService.allCategory = false;
    } else if (sub) {

      // reset subCat filter
      this.filterService.removeFilter("promotionEndDate");
      this.filterService.removeFilter("promotion");
      this.filterService.removeFilter("movingPrestations");
      this.filterService.sort = undefined;

      //sub cat filter
      if (c.selected) {
        if (c.value == "promo") {
          // this.filterService.filterPromotion();
          this.filterService.filterBy['promotion'] = c.value;
        } else if (c.value == "nouveau") {
          this.filterService.sortLatest();
        } else if (c.value == "populaire") {
          this.filterService.sortPopular();
        } else if (c.value == "a-domicile") {
          // this.filterService.filterAtHome();
          this.filterService.filterBy["movingPrestations"] = 'atHome';
        }

        this.filterService.subCategories.forEach((cat) => {
          if (cat.value != c.value) {
            cat.selected = false
          }
        });
      }

    }
    if (!this.filterService.allCategory) {
      // this.filterService.filterCategory("categoriesPrestations", this.filterService.categories.filter((c) => c.selected));
      // this.filterService.filterCategoryPrestation("category",this.filterService.categories.filter((c) => c.selected));
    }
    let categories = [];
    this.filterService.categories.forEach(category => {
      if (category.selected) {
        categories.push(category.value.toLowerCase());
      }

    })
    this.filterService.filterBy["category"] = categories;
    this.filterService.tagsAdded.next(true);
  }

  /*  onkeyup(e){
      this.tagsResult = this.tagService.getResults(e.target.value.toLowerCase());
    }*/

  onkeyupModal(e) {
    this.tagService.searchBarInput = e.target.value.toLowerCase();
    this.keyUp.emit(true);
    this.filterService.tagsResultModal = this.tagService.getResults(e.target.value.toLowerCase());
    this.filterService.filterBy["name"] = e.target.value;
    this.filterService.tagsAdded.next(true);
  }

  addTags(tag: string) {
    this.filterService.addTags(tag);
    // this.filterService.filterTags(this.filterService.tags);
    this.tag = undefined;
    this.tagsResult = this.tagService.getResults('');
  }

  addTag() {
    if (this.tag && this.tag != "") {
      this.tags.push(this.tag);
      this.tag = undefined;
      this.filterService.setTags(this.tags);
      //this.filterService.filterTags(this.tags);
      this.filterChange.emit(true);
    }
  }

  removeTag(i: number) {
    this.tags.splice(i, 1);
    this.filterService.setTags(this.tags);
    if (this.tags.length == 0) {
      this.filterService.removeFilter("tags");
    } else {
      // this.filterService.filterTags(this.tags);
    }


    this.filterChange.emit(true);
    this.filterService.tagsAdded.next(true);

  }

  async addFilter() {
    const modal = await this.modalController.create({
      component: SearchDetailComponent,
      cssClass: 'searchModal',
      componentProps:{
        isSalon: this.isSalon
      }
    });
    return await modal.present();
  }

  onClickCategorie(cat, i) {
    if (i == 0)
      i++;
    let obj = { cat: cat, index: i };
    if (this.isSalon && !this.isGallerie) {
      if (cat.value != "esthetisme")
        this.salonService.onChangeCategorie(cat);
      else
        this.salonService.onChangeCategorie(null);
    }
    else if (this.isGallerie && !this.isSalon) {
      if (cat.value != "esthetisme") {
        this.prestationService.onChangeCategorie(obj);
        this.prestationService.onChangeTypeCard(2);
      }
      else {
        this.prestationService.onChangeCategorie(null);
        this.prestationService.onChangeTypeCard(2);
      }
    }
  }

  goToSalon(sid) {
    this.redirectionService.redirectTo(`/profil-salon/${sid}`, true);
  }

}
