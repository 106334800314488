import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-day-button',
  templateUrl: './day-button.component.html',
  styleUrls: ['./day-button.component.scss'],
})
export class DayButtonComponent implements OnInit {

	@Input() label = "";
	@Input() active = false;

	constructor() { }

	ngOnInit() {}


}
