import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../common-input/value-accessor-base';

import { IonicSelectableComponent } from 'ionic-selectable';

import { CitiesService } from '../../../services/cities.service';

@Component({
  selector: 'app-common-city-select',
  templateUrl: './common-city-select.component.html',
  styleUrls: ['./common-city-select.component.scss','../common-input/common-input.component.scss'],
  providers: [
  	{provide: NG_VALUE_ACCESSOR, useExisting: CommonCitySelectComponent, multi: true}
  ]
})
export class CommonCitySelectComponent extends ValueAccessorBase<string> implements OnInit {

	@Input() label;
	@Input() options = [];
	@Input() section;
	@Input() multiple = false;
	@Input() value;
	@Input() placeholder;
	@Input() color:string = "primary";
	@Input() padding:boolean = true;
	@Input() disabled:boolean = false;
	@Input() name:string;
	@Input() countryShortName: string = "CH";

	@Input() ngModel: string;
	@Output() ngModelChange = new EventEmitter<string>();
	@Output() change = new EventEmitter();
	@Input() errorMessage = "";

	constructor(private citiesService: CitiesService) {
		super();
	}

	ngOnInit() {
		
	}

	changeValue(event) {
		this.change.emit(event);
	}

	filterCities(cities: any[], text: string) {
		return cities.filter(city => {
		  return city.value.toLowerCase().indexOf(text) !== -1;
		});
	}

	ucfirst(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	searchCity(event: {
	    component: IonicSelectableComponent,
	    text: string
	  }) {
	    // let text = event.text.trim().toLowerCase();
	    let text = this.ucfirst(event.text.trim());
	    event.component.startSearch();


	    if (!text) {

	      event.component.items = [];
	      event.component.endSearch();
	      return;
	    }

	    this.citiesService.getCitiesByCountryAndCity(this.countryShortName,text).then(snapshot => {

	      // We get all ports and then filter them at the front-end,
	      // however, filtering can be parameterized and moved to a back-end.

	      const cities = [];

	      snapshot.forEach(document => {
	      	cities.push(document.data());
	      })

	      event.component.items = cities;
	      event.component.endSearch();
	    });
	  }

	onSelectOpen(event: { component: IonicSelectableComponent }) {
		event.component.startSearch();


	    this.citiesService.getCitiesByCountry(this.countryShortName).then(snapshot => {

	      // We get all ports and then filter them at the front-end,
	      // however, filtering can be parameterized and moved to a back-end.

	      const cities = [];

	      snapshot.forEach(document => {
	      	cities.push(document.data());
	      })

	      event.component.items = cities;
	      event.component.endSearch();
	    });
	}

}
