import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Storage } from '@ionic/storage'
import { map } from 'rxjs/operators';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { HttpClient } from '@angular/common/http';
import { City } from '../models/city.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { SalonDetail } from '../models/salon.model';
import { Address } from '../models/address.model';
import { add } from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class CitiesService implements AutoCompleteService {

	static COLLECTION_NAME = 'cities';
	static COLLECTION_NAME_ACTIVE = 'city';

	cityChange: BehaviorSubject<City> = new BehaviorSubject(null);

	labelAttribute = 'name';

	city: City;
	cityName: string;
	cities: City[] = [];
	defaultCities: City[] = [];
	myPosition: {
		lat: number,
		lng: number
	};;
	btnDisable: boolean = true;

	constructor(private firestore: AngularFirestore,
		private storage: Storage,
		private http: HttpClient
	) {
		this.getStocked('city').then(o => {
			if (o) {
				this.city = new City().deserialize(o);
				this.cityName = this.city.name;
			}

		});

		// test
		// this.setStocked('city', new City().deserialize(
		// 	{
		// 		"country": "CH",
		// 		"name": "Lausanne",
		// 		"lat": "46.516",
		// 		"lng": "6.63282"
		// 	  }
		// )).then(() =>{
		// 	this.getStocked('city').then(o=>{
		// 		this.city= new City().deserialize(o);
		// 		this.cityName = this.city.name;
		// 	});	
		// });
	}


	setStocked(settingName, value: City) {
		this.city = value;
		this.cityChange.next(value);
		return this.storage.set(`setting:${settingName}`, value);
	}

	initResult() {
		return new Promise<void>((resolve) => {
			let observable = this.getCitiesByCountries(["CH"]);

			observable.subscribe((o) => {
				this.cities = [];
				o.forEach((city) => {

					this.cities.push(city);
				});
				resolve();
			})
		})
	}

	initDefaultCities() {
		return new Promise<void>((resolve) => {
			this.getActiveCities().subscribe((c) => {
				this.defaultCities = [];
				c.forEach((city) => {
				  if (city.isActive) {
					let currentCity: City = new City().deserialize(city);
					this.defaultCities.push(currentCity);
				  }
				});
				resolve();
			});
		});
	}

	getSalonAdress(salon: SalonDetail): Address {
		let result: Address = null;
		for (let addr of salon.adresses) {
			if (addr.locality != undefined && this.city.name.toLowerCase().trim() != "autour" && addr.locality.toLowerCase().trim() == this.city.name.toLowerCase().trim()) {
				result = addr;
				break;
			} else if (this.city.name.toLowerCase().trim() == "autour" && addr.isMain) {
				result = addr;
				break;
			}
		}
		if (!result && salon.adresses.length > 0) {
			return salon.adresses[0];
		}
		return result;
	}

	getSalonDistance(salon: SalonDetail) {
		let addr: Address = this.getSalonAdress(salon);
		return this.distance(addr.latLng.lat, addr.latLng.lng);
	}

	async getStocked(settingName) {
		return await this.storage.get(`setting:${settingName}`);
	}
	async removeStocked(settingName) {
		return await this.storage.remove(`setting:${settingName}`);
	}

	async getCity() {
		return await this.storage.get(`setting:city`);
	}

	clearStocked() {
		this.storage.clear().then(() => {
		});
	}
	saveAll(cities) {
		var batch = this.firestore.firestore.batch();

		var count = 7300;

		cities.filter(city => ['DE'].includes(city.country)).forEach((city, index) => {
			if (index >= (count - 300) && index < count) {
				batch.set(this.firestore.firestore.collection(CitiesService.COLLECTION_NAME).doc(city.country + "#" + index), city);
			}
		})

		return batch.commit();
	}

	deleteMassive() {
		var batch = this.firestore.firestore.batch();

		for (let i = 355; i < 356; i++) {
			batch.delete(this.firestore.firestore.collection(CitiesService.COLLECTION_NAME).doc("CH#" + i))
		}

		return batch.commit();
	}
	getAllCities() {
		return this.firestore.collection<City>(CitiesService.COLLECTION_NAME).valueChanges();
	}

	getActiveCities() {
		return this.firestore.collection<City>(CitiesService.COLLECTION_NAME_ACTIVE).valueChanges();
	}

	getCitiesByCountry(countryShortName) {
		return this.firestore.collection(CitiesService.COLLECTION_NAME).ref.where("country", "==", countryShortName).get();
	}

	getCitiesByCountryAndCity(countryShortName, startWith) {
		return this.firestore.collection(CitiesService.COLLECTION_NAME).ref.where("country", "==", countryShortName).orderBy("name").startAt(startWith).endAt(startWith + '\uf8ff').get();
	}

	getCitiesByCountries(countriesShortName: string[]) {
		return this.firestore.collection<City>(CitiesService.COLLECTION_NAME, ref => ref.where("country", 'in', countriesShortName).orderBy("name"))
		.valueChanges({ idField: 'id' });
	}

	getCitiesByName(name) {
		return this.firestore.collection(CitiesService.COLLECTION_NAME, ref => ref.where("name", '==', name)).valueChanges();
	}
	
	getCitiesByCountryKey(cKey: string){
		return this.firestore.collection(CitiesService.COLLECTION_NAME).ref.where('country','==',cKey).get();
	}

	// for collection city
    getActiveCityByName( name : string){
        return this.firestore.collection(CitiesService.COLLECTION_NAME_ACTIVE).ref.where('name','==',name).get();
    }

	removeAccent(value) {
		return value
			.replace(/á/g, 'a')
			.replace(/à/g, 'a')
			.replace(/â/g, 'a')
			.replace(/ä/g, 'a')
			.replace(/é/g, 'e')
			.replace(/ê/g, 'e')
			.replace(/è/g, 'e')
			.replace(/ë/g, 'e')
			.replace(/ï/g, 'i')
			.replace(/ô/g, 'o')
			.replace(/ù/g, 'u')
			.replace(/û/g, 'u');
	}

	getResults(keyword: string = undefined): Observable<City[]> {
		this.btnDisable = true;
		let observable: Observable<City[]>;
		if (this.cities.length == 0) {
			observable = this.getCitiesByCountries(["CH"]);

			observable.subscribe((o) => {
				this.cities = [];
				o.forEach((city) => {
					this.cities.push(city);
				})
			})
		} else {
			observable = of(this.cities);
		}

		return observable.pipe(map(
			(result: any[]) => {
				if (keyword) {
					return result.filter(
						(item) => {
							let key = this.removeAccent(keyword);
							let cityName = this.removeAccent(item.name);
							return cityName.toLowerCase().startsWith(
								key.toLowerCase()
							);
						}
					);
				} else {
					return result;
				}

			}
		));
	}

	distance(lat2, lon2, unit = "K") {
		if (!this.city) {
			return 1000000;
		}
		let lat1 = parseFloat(this.city.lat);
		let lon1 = parseFloat(this.city.lng);
		if ((lat1 == lat2) && (lon1 == lon2)) {
			return 0;
		}
		else {
			let radlat1 = Math.PI * lat1 / 180;
			let radlat2 = Math.PI * lat2 / 180;
			let theta = lon1 - lon2;
			let radtheta = Math.PI * theta / 180;
			let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
			if (dist > 1) {
				dist = 1;
			}
			dist = Math.acos(dist);
			dist = dist * 180 / Math.PI;
			dist = dist * 60 * 1.1515;
			if (unit == "K") { dist = dist * 1.609344 }
			if (unit == "N") { dist = dist * 0.8684 }
			return dist;
		}
	}
}
