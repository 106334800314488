import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Reservation, ReservationDetail, ReservationStatus } from '../models/reservation.model';
import { User } from '../models/user.model';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

	static COLLECTION_NAME = 'reservations';
	chatReservations: ReservationDetail[] = [];
	activeUser: User;

	constructor(private firestore: AngularFirestore) { }

	getReservationByUser(uid: string, startDate: number, endDate: number) {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME).ref.where("client.uid","==", uid).orderBy("executionDateTimestamp","desc").startAt(endDate).endAt(startDate).get();
	}

	getReservationByUserAndStatus(uid: string, status: ReservationStatus) {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME).ref.where("client.uid","==", uid).where("client.status", "==", status).get();
	}

	observeAllReservationWithDiscussion(user: User) {
		
		const userReservations = this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where("client.uid", "==", user.uid).orderBy('discussion')).valueChanges();

		if (user.salonId) {
			const salonReservations = this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where("salon.sid", "==", user.salonId).orderBy('discussion')).valueChanges();
			
			return combineLatest(userReservations, salonReservations).pipe(
				map(([userSnapshot, salonSnapshot]) => [...userSnapshot, ...salonSnapshot])
			)
		} else {
			return userReservations;
		}

	}

	observeReservationByUserAndStatus(uid: string, status: ReservationStatus) {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where("client.uid","==", uid).where("client.status", "==", status)).snapshotChanges();
	}

	observeReservationBySalonAndStatus(sid: string, status: ReservationStatus) {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where("salon.sid","==", sid).where("client.status", "==", status)).snapshotChanges();
	}

	observeReservationByUser(uid: string, startDate: number, endDate: number): Observable<DocumentChangeAction<unknown>[]> {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where("client.uid","==", uid).orderBy("executionDateTimestamp","desc").startAt(endDate).endAt(startDate)).snapshotChanges();
	}

	observeAllReservationBy(param: {by: string, id: string}): Observable<DocumentChangeAction<unknown>[]> {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where(param.by, "==", param.id)).snapshotChanges();
	}

	getReservationBySalon(sid: string, startDate: number, endDate: number, status: ReservationStatus = null) {
		let ref = this.firestore.collection(ReservationService.COLLECTION_NAME).ref.where("salon.sid","==", sid);
		
		if (status) {
			ref = ref.where("salon.status" , "==", status);
		}

		return ref.orderBy("executionDateTimestamp","desc").startAt(endDate).endAt(startDate).get();
	}

	observeReservationBySalon(sid: string, startDate: number, endDate: number): Observable<DocumentChangeAction<unknown>[]> {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where("salon.sid","==", sid).orderBy("executionDateTimestamp","desc").startAt(endDate).endAt(startDate)).snapshotChanges();
	}

	createReservation(reservation: ReservationDetail){
		reservation.createdAt = new Date();
		reservation.updatedAt = reservation.createdAt;
	
	    return this.firestore.collection(ReservationService.COLLECTION_NAME).add({...reservation});
	}

	updateReservation(reservation: Reservation){
		reservation.updatedAt = new Date();
	    return this.firestore.doc(ReservationService.COLLECTION_NAME + '/' + reservation.rid).update({...reservation});
	}

	getReservation(rid: string) {
		return this.firestore.collection(ReservationService.COLLECTION_NAME).doc(rid).ref.get();
	}

	observeReservation(rid: string) {
		return this.firestore.collection(ReservationService.COLLECTION_NAME).doc(rid).snapshotChanges();
	}

	observeChatReservation(user: User) {
		return this.observeAllReservationWithDiscussion(user).subscribe(snapshot => {

			this.chatReservations = [];

			snapshot.forEach(document => {
				this.chatReservations.push(new ReservationDetail().deserialize(document));
			});

			this.activeUser = user;

		});
	}

	get activeChats(): ReservationDetail[] {
		return this.chatReservations.filter(reservation => {

			return [ReservationStatus.NOT_CONFIRMED, ReservationStatus.UP_COMING, ReservationStatus.EDITED].includes(reservation.client.status) || [ReservationStatus.NOT_CONFIRMED, ReservationStatus.UP_COMING, ReservationStatus.EDITED].includes(reservation.salon.status);

		}).sort((r1, r2) => {
			const lastMessageTimestamp1 = new Date(r1.discussion[r1.discussion.length - 1].createdAt).getTime();
			const lastMessageTimestamp2 = new Date(r2.discussion[r2.discussion.length - 1].createdAt).getTime();
			return lastMessageTimestamp1 < lastMessageTimestamp2 ? -1 : 1;
		});
	}

	get inactiveChats(): ReservationDetail[] {
		return this.chatReservations.filter(reservation => {

			return ![ReservationStatus.NOT_CONFIRMED, ReservationStatus.UP_COMING, ReservationStatus.EDITED].includes(reservation.client.status) && ![ReservationStatus.NOT_CONFIRMED, ReservationStatus.UP_COMING, ReservationStatus.EDITED].includes(reservation.salon.status);

		}).sort((r1, r2) => {
			const lastMessageTimestamp1 = new Date(r1.discussion[r1.discussion.length - 1].createdAt).getTime();
			const lastMessageTimestamp2 = new Date(r2.discussion[r2.discussion.length - 1].createdAt).getTime();
			return lastMessageTimestamp1 < lastMessageTimestamp2 ? -1 : 1;
		});
	}

	getSalonReservation(sid: string, startDate: number = null, endDate: number = null, statusClient: ReservationStatus = null, statusSalon: ReservationStatus = null) {
		
		let ref = this.firestore.collection(ReservationService.COLLECTION_NAME).ref.where("salon.sid","==", sid);
		
		if (statusClient) {
			ref = ref.where("client.status" , "==", statusClient);
		}

		if(statusSalon){
			ref = ref.where("salon.status" , "==", statusSalon);
		}

        if (startDate && endDate){
            ref = ref.where("executionDateTimestamp" , ">=", startDate).where("executionDateTimestamp" , "<=", endDate);
        }else if(startDate){
        	ref = ref.where("executionDateTimestamp" , ">=", startDate);
        }else if(endDate){
        	ref = ref.where("executionDateTimestamp" , "<=", endDate);
        }

        ref = ref.orderBy("executionDateTimestamp", "desc");

		return ref.get();

	}
	
	observeReservationBySalonByStatus(sid: string, status: ReservationStatus): Observable<DocumentChangeAction<unknown>[]> {
	    return this.firestore.collection(ReservationService.COLLECTION_NAME, ref => ref.where("salon.status","==", status).where("salon.sid","==", sid)).snapshotChanges();
	}

	getReservationByPrestation(pid:string){
		return this.firestore.collection(ReservationService.COLLECTION_NAME).ref.where("prestation.pid","==", pid).get();
	}

}
