import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TarificationType, DurationType} from 'src/app/models/abonnement.model';

@Injectable({
  providedIn: 'root'
})
export class StringHelperService {

	constructor(
		public translate: TranslateService
	) { }

	emailIsValid(email: string) {
		return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
	}

	splitNameInFirstLast(name: string, getLastName = false) { 
		const nameSplit = name.split(RegExp(' ', 'g'));
		let firstName = '', lastName = '';

		if (nameSplit.length === 3) {
			firstName = nameSplit[0] + ' ' + nameSplit[1];
			lastName = nameSplit[2];
		} else if (nameSplit.length === 2) {
			firstName = nameSplit[0];
			lastName = nameSplit[1];
		} else if (nameSplit.length === 4) {
			firstName = nameSplit[0] + ' ' + nameSplit[1];
			lastName = nameSplit[2] + ' ' + nameSplit[3];
		} else {
			firstName = nameSplit[0];
		}

		return getLastName ? lastName : firstName;

	}

	formatDate(date) {
	    var d = new Date(date),
	        month = '' + (d.getMonth() + 1),
	        day = '' + d.getDate(),
	        year = d.getFullYear();

	    if (month.length < 2) 
	        month = '0' + month;
	    if (day.length < 2) 
	        day = '0' + day;

	    return [year, month, day].join('-');
	}
	
	
	formatDateForm2(date) {
	    var d = new Date(date),
	        month = '' + (d.getMonth() + 1),
	        day = '' + d.getDate(),
	        year = d.getFullYear();

	    if (month.length < 2) 
	        month = '0' + month;
	    if (day.length < 2) 
	        day = '0' + day;

	    return [day, month, year].join('/');
	}

	formatReservationDate( executionDate  , horaire ) { // entrée yyyy-mm-dd , hh:ss
        var d = new Date(executionDate),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/') + ' - ' + horaire.replace(':' , 'h');
    }

	formatDatetoIsoString(date: Date) {
	    var tzo = -date.getTimezoneOffset(),
	        dif = tzo >= 0 ? '+' : '-',
	        pad = function(num) {
	            var norm = Math.floor(Math.abs(num));
	            return (norm < 10 ? '0' : '') + norm;
	        };
	    return date.getFullYear() +
	        '-' + pad(date.getMonth() + 1) +
	        '-' + pad(date.getDate()) +
	        'T' + pad(date.getHours()) +
	        ':' + pad(date.getMinutes()) +
	        ':' + pad(date.getSeconds()) +
	        '.000'+
	        dif + pad(tzo / 60) +
	        ':' + pad(tzo % 60);
	}

	escapeRegExp(string) {
	  	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
	}

	isNormalInteger(str) {
	    return /^\+?(0|[1-9]\d*)$/.test(str);
	}

	isNormalFloat(sNum) {
	    var pattern = /^\d+(\.\d{1,5})?$/;
	    return pattern.test(sNum);
	}

	formatToUTCTimeTimestamp(date: Date){
        return date.getTime()-(date.getTimezoneOffset()*60000); 
	}


	capitalize(str){
		if (typeof str !== 'string') return '';
		
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	periodLabel(period: TarificationType): string{

		let label = this.translate.instant("mois");

		switch (period) {
			case TarificationType.HOURLY:
				// code...
				label = this.translate.instant("heure");
				break;

			case TarificationType.DAILY:
				// code...
				label = this.translate.instant("jour");
				break;

			case TarificationType.WEEKLY:
				// code...
				label = this.translate.instant("semaine");
				break;

			case TarificationType.MONTHLY:
				// code...
				label = this.translate.instant("mois");
				break;

			case TarificationType.YEARLY:
				// code...
				label = this.translate.instant("ans");
				break;
			
			case TarificationType.EVERY_TWO_MONTH:
				// code...
				label = "2 "+this.translate.instant("mois");
				break;

			case TarificationType.EVERY_THREE_MONTH:
				// code...
				label = "3 "+this.translate.instant("mois");
				break;

			case TarificationType.EVERY_SIX_MONTH:
				// code...
				label = "6 "+this.translate.instant("mois");
				break;

			default:
				// code...
				label = this.translate.instant("mois");;
				break;
		}

		return label;
	}

	durationLabel(period: DurationType){
	    
	    let label = this.translate.instant("mois");

	    switch (period) {
	        case DurationType.HOUR:
	            // code...
	            label = this.translate.instant("heure");
	            break;

	        case DurationType.DAY:
	            // code...
	            label = this.translate.instant("jour(s)");
	            break;

	        case DurationType.WEEK:
	            // code...
	            label = this.translate.instant("semaine(s)");
	            break;

	        case DurationType.MONTH:
	            // code...
	            label = this.translate.instant("mois");
	            break;

	        case DurationType.MONTH:
	            // code...
	            label = this.translate.instant("ans");
	            break;

	        default:
	            // code...
	            label = this.translate.instant("mois");
	            break;
	    }

	    return label;
	}
	
}
