import { Injectable } from '@angular/core';

import { Plugins, PushNotification, PushNotificationActionPerformed } from '@capacitor/core';
const { PushNotifications, FCMPlugin } = Plugins;

import { FCM } from '@capacitor-community/fcm';

import { ToastController } from '@ionic/angular';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class FcmReservationService {

    fcm: FCM;
    uid: string;
    sid: string;

    constructor(public translate: TranslateService, public toastController: ToastController, private router: Router) {
    this.fcm = new FCM();
  }

  initPushNotification(uid: string, sid: string) {
    
    if (!uid && !sid)
      return;

    PushNotifications.requestPermission().then( result => {
      if (result.granted) {
        PushNotifications.register()
          .then(() => {

            this.uid = uid;
            this.sid = sid;

            if (this.uid) {
              this.fcm
                .subscribeTo({ topic: this.uid })
                .catch( (err) => {
                  // TODO console.log("subscribeTopic sid", JSON.stringify(err))
                  });
            }

            if (this.sid) {
              this.fcm
                .subscribeTo({ topic: this.sid })
                .catch((err) => {
                  // TODO console.log("subscribeTopic uid", JSON.stringify(err))
                });
            }

          })
          .catch((err) => console.log(JSON.stringify(err)));  

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotification) => {

              this.presentToastWithOptions(notification.title, notification.body, notification.data.route);
            }
          );

          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: PushNotificationActionPerformed) => {

              if (notification.notification.data.route) {
                this.router.navigate([notification.notification.data.route]);
              }

            }
          );

          this.fcm
            .getToken()
            .catch((err) => {
              // TODO console.log(err)
            });  
      } else {
        // Show some error
      }
    });
  }

  async presentToastWithOptions(title: string, body: string, action: string = null) {
    const toast = await this.toastController.create({
      header: title,
      message: body,
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'eye',
          text: this.translate.instant('Voir'),
          handler: () => {

            if (action) {
              this.router.navigate([action]);
            }

          }
        }, {
          text: this.translate.instant('Ok'),
          role: 'cancel',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  reset() {
    if (this.uid)
      this.fcm.unsubscribeFrom({topic: this.uid});
    
    if (this.sid)
      this.fcm.unsubscribeFrom({topic: this.sid});
  }

  removeListeners() {
    PushNotifications.removeAllDeliveredNotifications();
    PushNotifications.removeAllListeners();
  }

}

