import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { AppUpdate } from '../models/app-update';
import { TranslateService } from '@ngx-translate/core';
import { Market } from '@ionic-native/market/ngx';


@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    private firestore: AngularFirestore,
    private alertCtrl: AlertController,
    private appVersion: AppVersion,
    private inAppBrowser: InAppBrowser,
    private platform: Platform,
    public translate: TranslateService,
    private market: Market
  ) { }

  async checkForUpdate() {
    let platform = 'version';
    if (this.platform.is('android')) {
      platform = 'android';
    } else if (this.platform.is('ios')) {
      platform = 'ios';
    }
    this.firestore.collection('appUpdate').doc(platform).snapshotChanges().subscribe(async (info) => {
      let appUpdate: AppUpdate = new AppUpdate().deserialize(info.payload.data());
      if (!appUpdate.enabled) {
        this.presentAlert(appUpdate.msg.title, appUpdate.msg.msg);
      } else {
        const serverVersion = appUpdate.current.split('.').map((val: string) => parseInt(val));
        const versionNumber: string = await this.appVersion.getVersionNumber();
        const splittedVersion = versionNumber.split('.').map((val: string) => parseInt(val));
        if (serverVersion[0] > splittedVersion[0]) {
          this.presentAlert(appUpdate.majorMsg.title, appUpdate.majorMsg.msg, appUpdate.majorMsg.btn);
        } else if ((serverVersion[0] == splittedVersion[0]) && (serverVersion[1] > splittedVersion[1])) {
          this.presentAlert(appUpdate.minorMsg.title, appUpdate.minorMsg.msg, appUpdate.minorMsg.btn, true)
        }
      }
    });
  }

  openAppStoreEntry() {
    if (this.platform.is('android')) {
      this.market.open('mobile.app.askeed');
    } else {
      // this.market.open('com.geekworkers.askeed');
      this.inAppBrowser.create('https://apps.apple.com/us/app/askeed/id1586342917');
    }
  }

  async presentAlert(header, message, buttonText = '', allowClose = false) {
    const buttons: any = [];
    if (buttonText !== '') {
      buttons.push({
        text: this.translate.instant(buttonText),
        handler: () => {
          this.openAppStoreEntry();
          return false;
        }
      })
    }
    if (allowClose) {
      buttons.push({
        text: this.translate.instant('Close'),
        role: 'cancel'
      })
    }
    const alert = await this.alertCtrl.create({
      header: this.translate.instant(header),
      message: this.translate.instant(message),
      buttons,
      backdropDismiss: allowClose
    });
    await alert.present();
  }
}
