import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SalonService } from '../../../services/salon.service';
import { AuthService } from '../../../services/auth.service';
import { Image } from '../../../models/image.model';

import { LoadingController, ToastController } from '@ionic/angular';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { PrestationService } from 'src/app/services/prestation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss'],
})
export class CommentInputComponent implements OnInit {

	@Input() placeholder:string = 'Partager votre ressenti';
	// @Input() salon: SalonDetail;
	// @Input() gallerieIndex: number;
	@Input() isReply: boolean = false;
	@Input() commentIndex: number;

	@Input() actu: Image;

	@Output() onPublished: EventEmitter<any> = new EventEmitter();
	@Input() image: Image;
    @Input() prestation: PrestationDetail;
    message;
    user;

    constructor(public translate: TranslateService, private authService: AuthService, private salonService: SalonService, private loadingController: LoadingController, private toastController: ToastController, private prestationService: PrestationService) { }

	ngOnInit() {
		this.user = this.authService.userLoggedIn;
		this.image = Image.imagefactory(this.image);
	}

	async send() {

		if (this.message.trim() == "")
			return;

		// const image:Image = Image.imagefactory(this.salon.salonGalleries[this.gallerieIndex]);

		if (this.isReply) {
			this.image.addCommentReply( this.commentIndex, {
				uid: this.user.uid,
				createdAt: new Date(),
				message: this.message
			});
		} else {

			this.image.addComment({
				uid: this.user.uid,
				createdAt: new Date(),
				message: this.message
			});

		}

		const loading = await this.loadingController.create({
	      message: this.translate.instant('Publication de votre commentaire...'),
	      cssClass: 'askeed-spinner'
	    });

		await loading.present();
		
		const prestationGallerieImg = this.prestation.galleries.find(img => img.url == this.image.url);

		prestationGallerieImg.comments = this.image.comments;

		this.prestationService.update(this.prestation).then(() => {
			this.presentToast(this.translate.instant("Votre commetaire à été enregistré!"));
			loading.dismiss();
			this.commentPublished();
			this.message = "";
		}, error => {
			this.presentToast(this.translate.instant("Impossible de publier votre commentaire"));
			loading.dismiss();
		})


		// this.salonService.getSalon(this.salon.sid).then(documentData => {

		// 	this.salon = SalonDetail.salonDetailFactory(documentData.data());

		// 	this.salon.salonGalleries[this.gallerieIndex] = {...image} as Image;

		// 	this.salonService.updateSalon(this.salon).then(() => {
		// 		this.presentToast("Votre commetaire à été enregistré!");
		// 		loading.dismiss();
		// 		this.commentPublished();
		// 		this.message = "";
		// 	}, error => {
		// 		this.presentToast("Impossible de publier votre commentaire");
		// 		loading.dismiss();
		// 	});

		// }, error => {
		// 	this.presentToast("Impossible de publier votre commentaire");
		// 	loading.dismiss();
		// });

	}

	presentToast(msg:string) {
		this.toastController.create({
		    message: msg,
		    duration: 3000
		  }).then(toast => toast.present());
	}

	commentPublished() {
		this.onPublished.emit(this.image);
	}


}
