import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-map-filter-card',
  templateUrl: './map-filter-card.component.html',
  styleUrls: ['./map-filter-card.component.scss'],
})
export class MapFilterCardComponent implements OnInit {


  constructor(public filterService: FilterService,
              private popoverController: PopoverController) { }

  ngOnInit() {}

    async filterProfessionnal(i){
      this.filterService.mapCardFilter[i].isChecked = !this.filterService.mapCardFilter[i].isChecked
      if(this.filterService.mapCardFilter[0].isChecked == true){
        this.filterService.filterBy["isEntreprise"] = true;

      } else {
        if (this.filterService.filterBy["isEntreprise"]){
          this.filterService.removeFilter("isEntreprise");
        }
      }
      if(this.filterService.mapCardFilter[1].isChecked == true){
        this.filterService.filterBy["promotion"] = true;
      } else {
        if (this.filterService.filterBy["promotion"]){
          this.filterService.removeFilter("promotion");
        }
      }
      if(this.filterService.filterBy["promotion"] || this.filterService.filterBy["isEntreprise"]){
  	  	this.filterService.tagsAdded.next(true);
      }else
      	this.filterService.tagsAdded.next(false);
        await this.popoverController.dismiss();
  	}

}
