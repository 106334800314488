import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-row-button',
  templateUrl: './add-row-button.component.html',
  styleUrls: ['./add-row-button.component.scss'],
})
export class AddRowButtonComponent implements OnInit {

	constructor() { }

	ngOnInit() {}

}
