import { Deserializable } from "./deserializable.model";
import { Prestation } from './prestation.model';
import { Salon } from './salon.model';

export class CategoryPrestation implements Deserializable {
    cpid: string;
    fr: string;
    en?: string;
    de?: string;
    it?: string;
    createdAt: Date;
    updatedAt: Date;
    icon?: string;

    label?: string;
    value?: string;
    selected?: boolean = false;
    iconName?: string = '';
    imageUrl?: string = '';
    typesPrestation?: TypePrestation[];
    defaultPrestations?: DefaultPrestation[];
    prestations?: Prestation[];
    salons?: Salon[];
    showResults = 4;

    deserialize(input: any) {
        Object.assign(this, input);
        if (!this.label) {
            this.label = this.fr;
        }
        if (!this.value) {
            this.value = this.slugify(this.label);
        }
        return this;
    }

    slugify(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        let to = "aaaaeeeeiiiioooouuuunc------";
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }
}

export class TypePrestation implements Deserializable {
    tpid: string;
    name: string;
    fr: string;
    en?: string;
    de?: string;
    it?: string;
    cpid: string;
    createdAt: Date;
    updatedAt: Date;

    selected?: boolean = false;
    countPrestations = 0;

    isDeactivated?: boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);
        this.name = this.fr;
        return this;
    }
}

export class DefaultPrestation implements Deserializable {
    dpid: string;
    name: string;
    fr: string;
    en?: string;
    de?: string;
    it?: string;
    cpid: string;
    tpids: string[];
    createdAt: Date;
    updatedAt: Date;

    toValid?: boolean;
    isDeactivated = false;
    newLabel?: string;

    countPrestations = 0;
    selected?: boolean = false;

   

    deserialize(input: any) {
        Object.assign(this, input);
        this.name = this.fr;
        return this;
    }
}