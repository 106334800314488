import {Pipe, PipeTransform} from '@angular/core';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { SalonDetail } from 'src/app/models/salon.model';
@Pipe ({
   name : 'prestationPipe'
})
export class PrestationPipe implements PipeTransform {
   transform(value:PrestationDetail[], salon) {
      let prestations = [];
      if(value != undefined){
         salon.prestations.forEach(
            (pid) => {
               value.forEach((presta)=>{
                  if(presta.pid === pid){
                     prestations.push(presta);
                   
                  }
               });
            }
         );
      }
      prestations = prestations.sort((p1, p2) => {
         return (p1.promotion === p2.distanceFrom) ? 0 : p1.promotion? -1 : 1;
      });
      return prestations;
   }
}