import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { dataConst } from '../shared/helpers/data.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicLinkService {

  queryOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Content-Type': 'application/json'
		})
	};
  constructor(
    public httpClient: HttpClient
  ) { }

  create(link: string, imageLink: string, title: string, description: string = ""){
    let data =  {
        "dynamicLinkInfo": {
          "domainUriPrefix": "https://askeed.page.link",
          "link": link,
          "androidInfo": {
            "androidFallbackLink" : link,
            "androidPackageName": "mobile.app.askeed"
          },
          "iosInfo": {
            "iosFallbackLink" : link,
            "iosBundleId": "com.geekworkers.askeed"
          },
          "socialMetaTagInfo": {
            "socialTitle": title,
            "socialDescription": description,
            "socialImageLink": imageLink
          }
        }
    };
    return this.httpClient.post<any>(dataConst.API_ENDPOINT + "createDynamicLink", JSON.stringify({ ...data }), this.queryOptions);
  }
}
