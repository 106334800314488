import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import * as firebase from 'firebase';
import { DataService, dataKey, dataConst } from '../shared/helpers/data.service';
import { GooglePlus } from '@ionic-native/google-plus';
import { Facebook } from '@ionic-native/facebook';
import { googleConfig } from '../credentials';
import { Platform } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';

import { AccountProvider, User } from '../models/user.model';
import { FcmReservationService } from './fcm-reservation.service';
import { LocalNotificationReservationService } from './local-notification-reservation.service';
import { FacebookGraphService } from './facebook-graph.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { StringHelperService } from '../shared/helpers/string-helper.service';

import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { TranslateService } from "@ngx-translate/core"
import { Subscription } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	redirectionKey = {
        previousUrl: 'previousUrl',
        currentUrl: 'currentUrl',
        activateRedirectToPreviousUrl: 'activateRedirectToPreviousUrl',

        originUrlForTabPage: 'originUrlForTabPage',
    }
    isUserInfoCompleted : boolean = false;
    userSubscriber : Subscription = null;
    noUserInfoAfterChecked : boolean = false;

	constructor(
		private angularFireAuth: AngularFireAuth, 
		private router: Router, 
		private ngZone: NgZone, 
		private dataService: DataService, 
		public platform: Platform, 
		private firestore: AngularFirestore, 
		private fcmReservationService: FcmReservationService, 
		private localNotificationReservationService: LocalNotificationReservationService, 
		private facebookService: FacebookGraphService, 
		private httpClient: HttpClient, 
		private stringHelperService: StringHelperService,
		private signInApple: SignInWithApple,
		public translate : TranslateService
		) {

		this.angularFireAuth.authState.subscribe(user => {
			this.isUserInfoCompleted = false;
			this.noUserInfoAfterChecked = false;
			if (!this.checkWeb()) {
				this.fcmReservationService.removeListeners();
			}


			if (user && ((user.providerData && (user.providerData.map(data => data.providerId).includes(auth.FacebookAuthProvider.PROVIDER_ID) || user.providerData.map(data => data.providerId).includes('google.com'))) || user.emailVerified)) {
				this.dataService.saveAppData(dataKey.USER_LOGGED, user);

				if(this.userSubscriber) this.userSubscriber.unsubscribe();
				this.userSubscriber = this.firestore.collection('users').doc(user.uid).snapshotChanges().subscribe(documentSnapshot => {

					const appUser: User = User.userFactory(documentSnapshot.payload.data());

					if (appUser.accountProvider === AccountProvider.FACEBOOK && appUser.accessToken) {
						this.facebookService.accessToken = appUser.accessToken;
					} else {
						this.facebookService.accessToken = null;
					}

					this.dataService.saveAppData(dataKey.USER_LOGGED, appUser);
					this.isUserInfoCompleted = true;

					if (!this.checkWeb()) {
						this.fcmReservationService.initPushNotification(appUser.uid, appUser.salonId);
						this.localNotificationReservationService.listenUpcomingReservation(appUser.uid, appUser.salonId);
					}


				});

			} else {
				this.noUserInfoAfterChecked = true;
				this.dataService.removeAppData(dataKey.USER_LOGGED);
				this.dataService.removeAppData(dataKey.TEMP_USERDATA);
				if (!this.checkWeb()) {
					this.fcmReservationService.reset();
					this.localNotificationReservationService.unsubscribe();
				}

			}
		});

	}

	checkWeb() {
		if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
			return false;
		}
		return true;
	}


	signup(email: string, password: string) {
		return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
	}

	signIn(email, password) {
		return this.angularFireAuth.signInWithEmailAndPassword(email, password);
	}

	signInWithGoogle() {

		if (this.checkWeb()) {
			return this.authLogin(new auth.GoogleAuthProvider());
		} else {
			return GooglePlus.login({
				'webClientId': googleConfig.webClientId,
				'offline': true
			}).then(res => this.angularFireAuth.signInWithCredential(auth.GoogleAuthProvider.credential(res.idToken)));
		}

	}

	signInWithFacebook() {
		if (this.checkWeb()) {
			const provider = new auth.FacebookAuthProvider();
			provider.addScope('user_photos');
			return this.authLogin(provider);
		} else {
			return Facebook.login(['public_profile']).then(res => {
				return this.angularFireAuth.signInWithCredential(auth.FacebookAuthProvider.credential(res.authResponse.accessToken))
			});
		}

		/*		return this.authLogin(new auth.FacebookAuthProvider());*/
	}

	signInWithApple() {
		return new Promise((resolve, reject) => {
			this.signInApple.signin({
				requestedScopes: [
				  ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
				  ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
				]
			  }).then(async (appleResponse: AppleSignInResponse) => {
					// Create a custom OAuth provider
					const provider = new firebase.auth.OAuthProvider('apple.com');

					// Create sign in credentials with our token
					const credential = provider.credential({
						idToken: appleResponse.identityToken
					});
					// Call the sign in with our created credentials
					const userCredential = await this.angularFireAuth.signInWithCredential(credential);
					resolve({
						user: userCredential.user,
						fullName : appleResponse.fullName,
						email : appleResponse.email,
						appleUser : appleResponse.user,

					})

			}).catch(response => {
				// TODO console.log('SignInWithApple error', response);
				reject('SignInWithApple Error');
			});
		})
	}

	async signInWithOtherProvider(data) {
		const supportedPopupSignInMethods = [
			auth.GoogleAuthProvider.PROVIDER_ID,
			auth.FacebookAuthProvider.PROVIDER_ID,
			auth.GithubAuthProvider.PROVIDER_ID,
		];

		const providers = await auth().fetchSignInMethodsForEmail(data.email)
		const firstPopupProviderMethod = providers.find(p => supportedPopupSignInMethods.includes(p));

		// Test: Could this happen with email link then trying social provider?
		if (!firstPopupProviderMethod) {
			throw new Error(`Your account is linked to a provider that isn't supported.`);
		}

		const linkedProvider = this.getProvider(firstPopupProviderMethod);
		linkedProvider.setCustomParameters({ login_hint: data.email });

		await auth().signInWithRedirect(linkedProvider);
		return auth().getRedirectResult()
	}

	getProvider(providerId) {
		switch (providerId) {
			case auth.GoogleAuthProvider.PROVIDER_ID:
				return new auth.GoogleAuthProvider();
			case auth.FacebookAuthProvider.PROVIDER_ID:
				return new auth.FacebookAuthProvider();
			default:
				throw new Error(`No provider implemented for ${providerId}`);
		}
	}

	authLogin(provider) {
		return this.angularFireAuth.signInWithPopup(provider);
	}

	async sendVerificationMail() {
		const currentUser = await this.angularFireAuth.currentUser;

		// return currentUser.sendEmailVerification();

		if (currentUser) {
			localStorage.setItem('askeed_temp_uid', currentUser.uid);
			this.requestCustomToken(currentUser.uid).subscribe(response => {

				if (response.status === 200) {

					currentUser.sendEmailVerification({
						url: 'https://m-askeed.web.app/login/' + response.body.token
					});
				}

			});
		}
	}

	recoverPassword(passwordResetEmail) {
		return this.angularFireAuth.sendPasswordResetEmail(passwordResetEmail);
	}

	get isLoggedIn(): boolean {
		let user;

		try {
			user = this.dataService.getAppData(dataKey.USER_LOGGED);
		} catch (error) {
			user = null;
		}

		return (user !== null && user.uid) ? true : false;
	}

	checkIfEmailAlreadyUsed(email: string) {
		return this.angularFireAuth.fetchSignInMethodsForEmail(email);
	}

	get userLoggedIn(): User {
		let user;

		try {
			user = User.userFactory(this.dataService.getAppData(dataKey.USER_LOGGED));
		} catch (error) {
			user = null;
		}
		return user;
	}

	get userInOrder(): boolean {
		if (this.userLoggedIn) {

			if (!this.userLoggedIn.name || this.userLoggedIn.name.trim() === '') {

				return false;
			}

			if (!this.userLoggedIn.firstname || this.userLoggedIn.firstname.trim() === '') {

				return false;
			}

            // if (!this.userLoggedIn.birthDate) {
            //     return false;
            // }

			// if (!this.userLoggedIn.email || (this.userLoggedIn.email && !this.stringHelperService.emailIsValid(this.userLoggedIn.email))) {
			// 	return false;
			// }

			if (!this.userLoggedIn.phoneNumber) {

				return false;
			}
			if (!this.userLoggedIn.address || (this.userLoggedIn.address && this.userLoggedIn.address.length > 0 && (!this.userLoggedIn.address[0].formatted_address || this.userLoggedIn.address[0].formatted_address === ''))) {
				
				return false;
			}


		} else {
			return false
		}
		return true

	}

	signOut(backToLogin = false) {
		this.userSubscriber.unsubscribe();
		return this.angularFireAuth.signOut().then(() => {
			GooglePlus.logout();
			Facebook.logout();
			this.dataService.removeAppData(dataKey.USER_LOGGED);
			this.dataService.removeAppData(this.redirectionKey.originUrlForTabPage);
           
			if (backToLogin) {
				this.router.navigate(['login']);
			} else {
				this.router.navigate(['home']);
			}
		});
	}


	requestCustomToken(uid): Observable<HttpResponse<{ token: string }>> {
		return this.httpClient.post<{ token: string }>(dataConst.API_ENDPOINT + 'requestCustomToken', { uid }, {
			observe: 'response', headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
			})
		});
	}


	signInWithCustomToken(token) {
		return this.angularFireAuth.signInWithCustomToken(token);
	}
	
	getErrorSignUp(code) {
        switch (code) {
            case "auth/email-already-in-use":
                return this.translate.instant("cet e-mail est déjà utilisé par un autre compte");
            case "auth/invalid-email":
                return this.translate.instant("cet e-mail est invalide");
            case "auth/operation-not-allowed":
                return this.translate.instant("cette opération n'est pas autorisée");
            case "auth/weak-password":
                return this.translate.instant("le mot de passe n'est pas sécurisé");
            case "auth/user-not-found":
                return this.translate.instant("l'e-mail que vous avez saisi est introuvable");
            case "auth/wrong-password":
                return this.translate.instant("le mot de passe saisi ne correspond pas à l'e-mail saisi");
            default:
                // code...
                break;
        }
        return null;
    }

}
