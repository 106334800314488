import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { City } from '../models/city.model';
import { CitiesService } from './cities.service';

@Injectable({
  providedIn: 'root'
})
export class CityGuardService implements CanActivate {

	constructor(private citiesService: CitiesService, private router: Router) { }

	async canActivate() {
		let ok = false;
		await this.citiesService.getStocked('city').then(o => {
			if (o) {
				this.citiesService.city = new City().deserialize(o);
				this.citiesService.cityName = this.citiesService.city.name;
				ok = true;
			}

		});
		if (this.citiesService.city && this.citiesService.city.name) {
			return true;
		}


		this.router.navigate(['/search-city']);

		return false;
	}

}
