import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../common-input/value-accessor-base';

@Component({
  selector: 'app-common-select',
  templateUrl: './common-select.component.html',
  styleUrls: ['./common-select.component.scss','../common-input/common-input.component.scss'],
  providers: [
  	{provide: NG_VALUE_ACCESSOR, useExisting: CommonSelectComponent, multi: true}
  ]
})
export class CommonSelectComponent extends ValueAccessorBase<string> implements OnInit {

	@Input() label;
	@Input() options = [];
	@Input() optionsAsync;
	@Input() section;
	@Input() multiple = false;
	@Input() large = false;
    @Input() indexHourSelected;

	private _value: string;

	public get value() {
		return this._value;
	}
	public set value(value) {
		this._value = value;
	}
	@Input() placeholder:string;
	@Input() color:string = "primary";
	@Input() padding:boolean = true;
	@Input() disabled:boolean = false;
	@Input() required:boolean = false;
	@Input() name:string;
	@Input() isfromReservation: boolean = false;

	@Input() ngModel: any;
	@Output() ngModelChange = new EventEmitter<any>();

	@Output() change = new EventEmitter();
	@Input() errorMessage = "";
	@Input() searchable = false;
	@Input() mode = 2;

	@Input() modelType = 2;
    @Input() border: boolean = false;

	constructor() {
		super();
	}

	ngOnInit() {
		// select the unique address if there is only one address in the reservation page
		if(this.isfromReservation && this.options.length === 1){
			this.ngModel = this.options[0].value;
			this.ngModelChange.emit(this.ngModel)
		}
	}

	changeValue(event) {
		console.log("event " , event)
		this.change.emit(event);
	}

}
