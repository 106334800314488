import { Deserializable } from './deserializable.model';
import { Image } from './image.model';
import { Gender } from './user.model';
import { PromotionDay, PromotionHour, Discount } from './promotion.model';

export class Prestation implements Deserializable {
    key: string;
    pid: string;
    sid: string;
    defaultPrestationId: string;
    typesIds: string[] = [];
    categoryId: string;
    salonName: string;
    category: 'coiffure' | 'ongleries' | 'esthetic' | 'autre' = 'autre'; //enum
    name: string;
    price: number;
    priceUnit: string = 'CHF';
    duration: number;//durée de la prestation en minutes
    coverImage: Image;
    galleries: Image[] = [];
    promotion: boolean = false;
    promotionStartDateEnabled: boolean = false;
    promotionStartDate: Date;
    promotionEndDateEnabled: boolean = false;
    promotionEndDate: Date;
    // promotionValue:number=0;
    discount: [{ day: string; percentage: number; hour: [number] }];
    likes: string[] = []; // liste des uid des likers
    tags: string[] = [];
    atHome: boolean;
    createdAt: any;
    updatedAt: any;
    promotionUpdatedAt: Date;
    popularityIndex: number = 0;
    unitPrice: any;
    promotionDays: PromotionDay[];
    promotionDaysLight: any[];

    maxPromotion = 0;
    salonProfilImage: string;

    specialCommission?: { isActive: boolean, amount: number }
    intLabel?: any;
    light = false;
    viewCount: number = 0;

    get likersCount(): number {
        let arr = this.galleries.map((img) => {
            return img.likes ? img.likes.length : 0;
        });
        return Math.max(...arr);
    }

    slug: string;

    constructor() {
        this.promotionDays = [];
        let dayLabel = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];


        for (let index = 1; index <= 7; index++) {
            let d = new PromotionDay();
            d.label = dayLabel[index - 1];
            d.value = index;
            d.hours = [];

            for (let j = 8; j < 18; j++) {
                let hour: PromotionHour = new PromotionHour();
                hour.value = j;
                hour.label = j + 'h - ' + (j + 1) + 'h';
                d.hours.push(hour);
            }
            this.promotionDays.push(d);
        }
        // this.promotionValue=this.getMaxPromotionValue();
        this.specialCommission = { isActive: false, amount: 0 };

        if(!this.intLabel){
            this.intLabel = { fr : this.name }
        }

    }

    isPromotion() {
        let res = false;
        if (this.maxPromotion) {
            return true;
        }
        if (!this.light){
            this.promotionDays.forEach((pd, i) => {
                pd.hours.forEach((h, j) => {
                    if (h.promotion !== undefined) {
                        res = true;
                    }
                });
            });
        }
        return res;
    }

    pricePromotion() {

        if (!this.isPromotion()) {
            return this.price;
        } else {
            return this.price - (this.price * this.getMaxPromotionValue() / 100);
        }
    }


    isPromotionEqual(prestation: Prestation) {
        let res = true;
        this.promotionDays.forEach((pd, i) => {
            pd.hours.forEach((h, j) => {

                h = new PromotionHour().deserialize(h);
                if (prestation.promotionDays[i]
                    && prestation.promotionDays[i].hours[j]
                    && !h.isEqual(prestation.promotionDays[i].hours[j])) {
                    res = false;
                }
            });
        });
        return res;
    }

    getType(): string {
        return 'prestation';
    }

    renderName(): string {
        return this.name;
    }

    renderImage(): Image {
        return this.coverImage;
    }

    renderTags(): string[] {
        return this.tags;
    }

    renderLikes(): string[] {
        return this.likes;
    }

    getImagePath(filePath = '') {
        return 'prestations/' + this.sid + '/' + filePath;
    }

    getMaxPromotionValue() {
        let val = 0;
        if (this.promotion) {
            if (this.maxPromotion) {
                val = this.maxPromotion;
            } else {
                this.promotionDays.forEach(pd => {
                    pd = new PromotionDay().deserialize(pd);
                    let discount = pd.getPromotionMaxValue();
                    if (discount) {
                        if (discount.value > val) {
                            val = discount.value;
                        }
                    }

                });
            }
        }
        return val;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        this.galleries = this.galleries.map((img) => {
            return new Image(img.url).deserialize(img);
        });
        // if(typeof this.price == "string"){
        if (this.price) {
            this.price = parseFloat(this.price.toString());
        }
        // }
        if (this.promotionDays && this.promotionDays[0] && this.promotionDays[0].hours === undefined){
            this.light = true;
        }
        if (!this.light){
            let val = 0;
            if (this.promotion && this.promotionDays) {
                this.promotionDays.forEach(pd => {
                    let promotion = null;
                    let prom = [];
                    for (let hour of pd.hours) {
                        promotion = hour.promotion;
                        if (promotion !== undefined) {
                            prom.push(promotion);
                        }
                    }
                    let discount: any;
                    if (prom !== undefined) {
                        for (let pro of prom) {
                            if (discount === undefined || pro.value > discount.value) {
                                discount = pro;
                            }
                        }
                        promotion = discount;
                    }
                    discount = promotion;
                    if (discount) {
                        if (discount.value > val) {
                            val = discount.value;
                        }
                    }

                });
            }
            this.maxPromotion = val;
        }else{
            this.promotionDaysLight = this.promotionDays.slice();
            // this.promotionDays = [];
            this.promotionDaysLight.forEach((val) => {
                if (val.discount > this.maxPromotion){
                    this.maxPromotion = val.discount;
                }
            });
        }

        return this;
    } 

}

export enum PrestationCategory {

}

export class PrestationDetail extends Prestation {

    description: string;
    variants: PrestationVariant[] = [];
    images: Image[];
    target: "men" | "women" | "both" | "child";
    travelPrice: number;
    maxDistanceTravel: number;
    distance?: number;
    
    get priceApplied(): number {
        let price = parseFloat(this.price.toString());

        const now = new Date();

        if (this.promotion) {

            const promotionDay = this.promotionDays[(now.getDay() == 0) ? 6 : now.getDay() - 1];

            if (promotionDay && promotionDay.enabled) {
                const hourTab = promotionDay.hours.filter(h => h.value == now.getHours());

                if (hourTab.length == 1) {

                    if (hourTab[0].promotion) {

                        price -= (price * hourTab[0].promotion.value) / 100;

                    }

                }

            }

        }

        return price;
    }

    get promotionApplied(): number {

        const now = new Date();

        // if (this.promotion) {

        const promotionDay = this.promotionDays[(now.getDay() == 0) ? 6 : now.getDay() - 1];

        // if (promotionDay.enabled) {
        const hourTab = promotionDay.hours.filter(h => h.value == now.getHours());

        if (hourTab.length == 1) {

            if (hourTab[0].promotion) {

                return parseFloat(hourTab[0].promotion.value.toString());

            }

        }

        // }

        // }

        return 0;
    }

    get maxPromo(): number {
        if (!this.promotion)
            return -1;

        let maxProm = -1;

        this.promotionDays.forEach(promotionDay => {
            if (promotionDay && promotionDay.enabled && !this.promotionDateExpired()) {

                promotionDay.hours.forEach(hour => {
                    if (hour.promotion && (!maxProm || maxProm < hour.promotion.value)) {
                        maxProm = hour.promotion.value;
                    }
                });

            }
        })

        return maxProm;
    }

    get promotionPrice(): number{
        return this.price - ((this.price * this.getMaxPromotionValue()) / 100);
    }

    getMaxPromoAtDay(dayIndex): number {
        if (!this.promotion)
            return -1;

        let maxProm = -1;

        const promotionDay = this.promotionDays[dayIndex];

        if (promotionDay && promotionDay.enabled && !this.promotionDateExpired()) {

            promotionDay.hours.forEach(hour => {
                if (hour.promotion && (!maxProm || maxProm < hour.promotion.value)) {
                    maxProm = hour.promotion.value;
                }
            });

        }

        return maxProm;
    }

    promotionDateExpired(): boolean {
        let isExpired = false;

        if (this.promotionEndDateEnabled) {

            isExpired = new Date(this.promotionEndDate.toString()) < new Date();

        }

        return isExpired;
    }    
}

export class PrestationVariant {
    name: string;
    price: number;
    duration?: number;

    constructor() {
        this.name = '';
        this.price = 0;
    }
}