import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name:'distance',
    pure:false
})
export class DistancePipe implements PipeTransform {

    constructor() {}
    
    transform(value:number) {

        let result = value.toFixed(2);

        if (value>=0 && value < 1) {
            result = parseFloat(result) * 1000 + " m";
        } else if (value <0) {
            result = "-";
        } else {
            result += "Km";
        }    

        return result;
       
    }

}