import { Component, OnInit, Input } from '@angular/core';
import { PrestationDetail } from '../../../models/prestation.model';
import { Address } from '../../../models/address.model';
import { Salon } from '../../../models/salon.model';
import { Popup } from "leaflet";
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: 'app-prestation-card',
  templateUrl: './prestation-card.component.html',
  styleUrls: ['./prestation-card.component.scss'],
})
export class PrestationCardComponent implements OnInit {

  constructor(
    public translate: TranslateService
    ) { }

  @Input() prestation:PrestationDetail;
  @Input() salons: {distanceFrom: number; salonAdress: Address; salon:Salon; popup?: Popup}[];

  currentSalon: Salon;

  ngOnInit() {
    
  }

  // searchSalon(){
  // 	if(this.salons.length !=0){
  // 		this.salons.forEach(salon =>{
	// 		const index= this.salons.findIndex(
	// 		(salonEl)=>{
	// 			if(salonEl.salon.sid== this.prestation.sid)
	//   				return true;
	// 		});
	// 	this.currentSalon = this.salons[index].salon;
	// 	})
  // 	}
  // }

  get prestationLabel() {

      if(this.prestation.intLabel && this.prestation.intLabel[this.translate.currentLang] && this.prestation.intLabel[this.translate.currentLang] !== ""){
          return this.prestation.intLabel[this.translate.currentLang]
      }

      return this.prestation.name;
          
  } 

}
