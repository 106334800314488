import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DefaultPrestation, TypePrestation } from '../models/defaultPrestation.model';
import { TranslateService } from "@ngx-translate/core"

@Injectable({
  providedIn: 'root'
})
export class PrestationDefaultService implements AutoCompleteService  {

  static COLLECTION_NAME = "defaultPrestation";
  labelAttribute = 'fr';
  defaultPrestations: DefaultPrestation[] = [];
  typesIds: string[];
  constructor(
    public firestore: AngularFirestore,
	public translate : TranslateService
  ) { }

  getAll() {
		return this.firestore.collection<DefaultPrestation>(PrestationDefaultService.COLLECTION_NAME).valueChanges();
	}

  setTypesPrestation(types: string[]){
      this.typesIds = types;
  }

  add(defaultPrestation: DefaultPrestation){
		defaultPrestation.createdAt = new Date();
		defaultPrestation.updatedAt = new Date();
		return this.firestore.collection(
      PrestationDefaultService.COLLECTION_NAME)
      .add(JSON.parse(JSON.stringify(defaultPrestation)));
	}

  update(defaultPrestation: DefaultPrestation) {
		defaultPrestation.updatedAt = new Date();
		return this.firestore.collection(
      PrestationDefaultService.COLLECTION_NAME).doc(defaultPrestation.dpid)
      .update(JSON.parse(JSON.stringify(defaultPrestation)));
	}



	getResults(keyword: string): Observable<DefaultPrestation[]> {
		let observable: Observable<DefaultPrestation[]>;
		if (this.defaultPrestations.length === 0) {
			observable = this.getAll();

			observable.subscribe((o) => {
				this.defaultPrestations = [];
				o.forEach((el) => {
					if(!el.toValid && !el.isDeactivated){
					this.defaultPrestations.push(el);
				}

				});
			});
		} else {

			observable = of(this.defaultPrestations);
		}
		if (this.typesIds){
			let defaultPrestations = [];
			this.defaultPrestations.forEach((defaultPres) => {
				if(!defaultPres.toValid){
					defaultPrestations.push(defaultPres);
				}
			});
			defaultPrestations = defaultPrestations.filter((defaultPres) => {
				return defaultPres.tpids.filter(value => this.typesIds.includes(value)).length > 0;
			});
			observable = of(defaultPrestations);
		}
		return observable.pipe(map(
			(result: any[]) => {
			if (keyword) {
				let res = result.filter(
					(item) => {
						let key = this.removeAccent(keyword);
						let name = this.removeAccent(item.fr);
						return name.toLowerCase().includes(
						key.toLowerCase()
						);
					}
				);
				if (res.length === 0){
					res.push({toValid : true, newLabel: "<span style='color: var(--ion-color-primary)'> "+this.translate.instant('Créer')+" </span><span style='font-size: 12px'>\""+ keyword + "\" </span>", fr: keyword})
				}
				return res;
			} else {
			return result;
			}

			}
		));
	}

  removeAccent(value) {
    if (!value){
      return "";
    }
		  return value
			.replace(/á/g, 'a')
			.replace(/à/g, 'a')
			.replace(/â/g, 'a')
			.replace(/ä/g, 'a')
			.replace(/é/g, 'e')
			.replace(/ê/g, 'e')
			.replace(/è/g, 'e')
			.replace(/ë/g, 'e')
			.replace(/ï/g, 'i')
			.replace(/ô/g, 'o')
			.replace(/ù/g, 'u')
			.replace(/û/g, 'u');
	}
}
