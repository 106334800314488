import { Component, OnInit , Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User, AccountProvider } from '../../models/user.model';
import { StringHelperService } from '../../shared/helpers/string-helper.service';
import { DataService, dataKey } from '../../shared/helpers/data.service';
import { UserService } from '../../services/user.service';
import { Platform, ToastController } from '@ionic/angular';
import { LoadingController , ModalController} from '@ionic/angular';
import { City } from 'src/app/models/city.model';
import { CitiesService } from 'src/app/services/cities.service';
import { FilterService } from 'src/app/services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { RedirectionService } from '../../services/redirection.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

	email: string = '';
	password: string = '';
	error: string = '';
	requestVerificationLink = false;
	cities: City[] = [];
    @Input()isModal : boolean = false;

	constructor(
		public translate: TranslateService,
		private redirectionService: RedirectionService, 
		private filterService: FilterService, 
		private citiesService: CitiesService, 
		private authService: AuthService, 
		private router: Router, 
		private stringHelperService: StringHelperService, 
		private dataService: DataService, 
		private userService: UserService, 
		public loadingController: LoadingController, 
		public toastController: ToastController, 
		public route: ActivatedRoute, 
		public modalController: ModalController,
		public platform: Platform
		) {

	}

	ngOnInit() {

	}


	async ionViewWillEnter() {
		const token = this.route.snapshot.paramMap.get('token');

		if (token) {
			const loading = await this.loadingController.create({
				message: this.translate.instant('Connexion à votre compte...'),
				cssClass: 'askeed-spinner'
			});

			await loading.present();

			this.authService.signInWithCustomToken(token).then( credentials => {
				if (credentials.user.emailVerified) {

					this.userService.getUser(credentials.user.uid).then(documentData => {

						const userData = documentData.data();
						let user: User = new User().deserialize(userData);
						this.selectCity(user.mainAddress)

						if (this.dataService.dataExist(dataKey.RESERVATION_TEMP)) {
							this.router.navigate(['/reservation-confirm']);
						} else if (userData.salonId) {
							this.router.navigate(['profil/salon']);
						} else {
							this.router.navigate(['profil']);
						}

						loading.dismiss();

					}, error => {
						loading.dismiss();
						this.error = error;
					})

				} else {
					this.error = this.translate.instant('Votre email n\'a pas encore été vérifié. Veuillez consulter votre adresse email.') + this.translate.instant(' Si vous n\'avez pas reçu l\'email de validation, pensez à regarder dans vos spams ');
					this.requestVerificationLink = true;
					this.authService.signOut(true);
					loading.dismiss();
				}
			});

				

		}

	}

	async login() {

		this.error = '';

		if (this.email.trim() == '' || this.password.trim() == '') {
			this.error = this.translate.instant('Email ou mot de passe incorrect')
			return;
		}

		const loading = await this.loadingController.create({
			message: this.translate.instant('Connexion à votre compte...'),
			cssClass: 'askeed-spinner'
		});

		await loading.present();

		this.authService.signIn(this.email.trim(), this.password).then((credentials) => {

			if (credentials.user.emailVerified) {

				this.userService.getUser(credentials.user.uid).then(documentData => {

					const userData = documentData.data();
					let user: User = new User().deserialize(userData);
					this.selectCity(user.mainAddress)
                    if (this.isModal) {
                        this.dismiss()
                    }else if (this.dataService.dataExist(dataKey.RESERVATION_TEMP)) {
						this.router.navigate(['/reservation-confirm']);
					} else if (userData.salonId) {
						this.router.navigate(['profil/client-profil']);
					} else {
						this.router.navigate(['profil']);
					}

					loading.dismiss();

				}, error => {
					loading.dismiss();
					this.error = error;
				})

			} else {
				this.error = this.translate.instant('Votre email n\'a pas encore été vérifié. Veuillez consulter votre adresse email.') + this.translate.instant(' Si vous n\'avez pas reçu l\'email de validation, pensez à regarder dans vos spams ');
				this.requestVerificationLink = true;
				this.authService.signOut(true);
				loading.dismiss();
			}

		}, (error) => {
			loading.dismiss();
			this.error = error;
		});
	}

	async signInWithGoogle(relinking = false, dataLinking = null) {

		this.error = '';

		let query;

		if (relinking) {
			query = this.authService.signInWithOtherProvider(dataLinking)
		} else {
			query = this.authService.signInWithGoogle();
		}

		const loading = await this.loadingController.create({
			message: this.translate.instant('Connexion à votre compte...'),
			cssClass: 'askeed-spinner'
		});

		loading.present();

		query.then(credentials => {

			const user = new User();


			user.uid = credentials.user.uid;
			user.name = this.stringHelperService.splitNameInFirstLast(credentials.user.displayName, true);
			user.firstname = this.stringHelperService.splitNameInFirstLast(credentials.user.displayName);
			user.email = credentials.user.email;
			user.userImageUrl = credentials.user.photoURL;
			user.phoneNumber = credentials.user.phoneNumber || '';
			/*user.isVerified = credentials.user.emailVerified;*/
			user.isVerified = true;
			user.accountProvider = AccountProvider.GOOGLE;

			this.userService.getUser(user.uid).then(async documenSnaphsot => {

				if (documenSnaphsot.data()) {

					/*const loading = await this.loadingController.create({
					  message: this.translate.instant('Connexion à votre compte...'),
					  cssClass: 'askeed-spinner'
					});

					loading.present();*/

					this.userService.getUser(credentials.user.uid).then(documentData => {

						const userData = documentData.data();
                        if (this.isModal) {
                            this.dismiss()
                        }
						if (this.dataService.dataExist(dataKey.RESERVATION_TEMP)) {
							this.router.navigate(['/reservation-confirm']);
						} else if (userData.salonId) {
							this.router.navigate(['profil/salon']);
						} else {
							this.router.navigate(['profil']);
						}

						loading.dismiss();

					}, error => {
						loading.dismiss();
						this.error = error;
					})

				} else {

					/*const loading = await this.loadingController.create({
					  message: this.translate.instant('Enregistrement de vos informations...'),
					  cssClass: 'askeed-spinner'
					});

					await loading.present();*/

					loading.message = this.translate.instant('Enregistrement de vos informations...');

					this.userService.createUser(user).then(responseUser => {
                        if (this.isModal) {
                            this.dismiss()
                        }
						this.router.navigate(['/registration/edit/' + user.uid + '/1']);
						loading.dismiss();

					}).catch(error => {

						this.toastController.create({
							message: 'Erreur : ' + error.message,
							duration: 3000
						}).then(toastElt => toastElt.present());

						this.authService.signOut();
						loading.dismiss();

					});

				}

			}, error => {
				loading.dismiss();
				this.error = error;
			});

		}, (error) => {
			loading.dismiss();
			this.error = this.translate.instant('Echec de la connexion avec votre compte google');
		});
	}

	async signInWithFacebook() {

		const loading = await this.loadingController.create({
			message: this.translate.instant('Connexion à votre compte...'),
			cssClass: 'askeed-spinner'
		});

		loading.present();

		this.error = '';

		this.authService.signInWithFacebook().then(credentials => {


			const user = new User();

			user.uid = credentials.user.uid;
			user.name = this.stringHelperService.splitNameInFirstLast(credentials.user.displayName, true);
			user.firstname = this.stringHelperService.splitNameInFirstLast(credentials.user.displayName);
			user.email = credentials.user.email || null;
			user.userImageUrl = credentials.user.photoURL;
			user.phoneNumber = credentials.user.phoneNumber || '';
			user.isVerified = true;
			user.accountProvider = AccountProvider.FACEBOOK;

			this.userService.getUser(user.uid).then(async documenSnaphsot => {

				if (documenSnaphsot.data()) {

					/*const loading = await this.loadingController.create({
					  message: this.translate.instant('Connexion à votre compte...'),
					  cssClass: 'askeed-spinner'
					});

					loading.present();*/

					this.userService.getUser(credentials.user.uid).then(documentData => {

						const userData = documentData.data();
                        if (this.isModal) {
                            this.dismiss()
                        }
						else if (this.dataService.dataExist(dataKey.RESERVATION_TEMP)) {
							this.router.navigate(['/reservation-confirm']);
						} else if (userData.salonId) {
							this.router.navigate(['profil/salon']);
						} else {
							this.router.navigate(['profil']);
						}

						loading.dismiss();

					}, error => {
						this.error = error;
						loading.dismiss();
					})

				} else {

					/*const loading = await this.loadingController.create({
					  message: this.translate.instant('Enregistrement de vos informations...'),
					  cssClass: 'askeed-spinner'
					});

					await loading.present();*/

					loading.message = this.translate.instant('Enregistrement de vos informations...');

					this.userService.createUser(user).then(responseUser => {
                        if (this.isModal) {
                            this.dismiss()
                        }
						this.router.navigate(['/registration/edit/' + user.uid + '/1']);
						loading.dismiss();

					}).catch(error => {

						this.toastController.create({
							message: 'Erreur : ' + error.message,
							duration: 3000
						}).then(toastElt => toastElt.present());

						this.authService.signOut();
						loading.dismiss();

					});

				}

			}, error => {
				// TODO console.log('error', JSON.stringify(error));
				this.error = error;
				loading.dismiss();
			});

		}, (error) => {
			if (error.code === 'auth/account-exists-with-different-credential') {
				this.error = this.translate.instant('Un autre compte existe déjà avec le même adresse email associé à cette compte Facebook mais avec un autre moyen de connexion. Veuillez vous identifier avec cette dernière.')
				this.signInWithGoogle(true, error);
				loading.dismiss();
			} else {
				// TODO console.log('error', JSON.stringify(error));
				this.error = this.translate.instant('Echec de la connexion avec votre compte Facebook');
				loading.dismiss();
			}

		});
	}

	async signInWithApple() {
		const loading = await this.loadingController.create({
			message: this.translate.instant('Connexion à votre compte...'),
			cssClass: 'askeed-spinner'
		});

		loading.present();

		this.error = '';

		this.authService.signInWithApple().then((credentials: any) => {
			const user = new User();

			user.uid = credentials.user.uid;
			if(credentials.fullName){
				user.name = credentials.fullName.familyName ?? '';
				user.firstname = credentials.fullName.nickname ?? '';
			}else{
				user.name = '';
				user.firstname = '';
			}
			user.email = credentials.user.email ? credentials.user.email : null;
			user.userImageUrl = credentials.user.photoURL ? credentials.user.photoURL : null;
			user.phoneNumber = credentials.user.phoneNumber ? credentials.user.phoneNumber : '';
			user.isVerified = true;
			user.accountProvider = AccountProvider.APPLE;

			this.userService.getUser(user.uid).then(async documenSnaphsot => {

				if (documenSnaphsot.data()) {

					/*const loading = await this.loadingController.create({
					  message: this.translate.instant('Connexion à votre compte...'),
					  cssClass: 'askeed-spinner'
					});

					loading.present();*/

					this.userService.getUser(credentials.user.uid).then(documentData => {

						const userData = documentData.data();

						if (this.dataService.dataExist(dataKey.RESERVATION_TEMP)) {
							this.router.navigate(['/reservation-confirm']);
						} else if (userData.salonId) {
							this.router.navigate(['profil/salon']);
						} else {
							this.router.navigate(['profil']);
						}

						loading.dismiss();

					}, error => {
						this.error = error;
						loading.dismiss();
					})

				} else {

					loading.message = this.translate.instant('Enregistrement de vos informations...');

					this.userService.createUser(user).then(responseUser => {

						this.router.navigate(['/registration/edit/' + user.uid + '/1']);
						loading.dismiss();

					}).catch(error => {

						this.toastController.create({
							message: 'Erreur : ' + error.message,
							duration: 3000
						}).then(toastElt => toastElt.present());

						this.authService.signOut();
						loading.dismiss();

					});

				}

			}, error => {
				// TODO console.log('error', JSON.stringify(error));
				this.error = error;
				loading.dismiss();
			});
		}, (err) => {
			this.error = err;
			loading.dismiss();
		})
	}

	async sendVerificationLink() {
		await this.authService.sendVerificationMail();
		this.error = '';
		this.requestVerificationLink = false;
	}

	selectCity(address: any) {
		// if(!address) return; 
		this.citiesService.getCitiesByName(address?.locality || 'Lausanne').subscribe(c => {
			if (c[0]) {
				let city: City = new City().deserialize(c[0]);
				this.citiesService.setStocked('city', city).then(() => {
					this.citiesService.cityName = city.name;
					this.filterService.tagsAdded.next(true);
				});
			}
		});
	}


    dismiss(){        
        if(this.isModal)
            this.modalController.dismiss();


    }

}


