import { Injectable } from '@angular/core';
import { ExperienceSalon } from '../../models/salon.model';
import { AccountStatus } from '../../models/user.model';
import { SalonStatus } from '../../models/salon.model';
import { ReservationStatus } from '../../models/reservation.model';
import { TranslateService } from '@ngx-translate/core'
@Injectable({
  providedIn: 'root'
})
export class DataService {

	routeData = [];
	
	private typeFilterAccount = [
		{label:this.translate.instant("Compte normale"),value: AccountStatus.STANDARD },
		{label:this.translate.instant("Compte déjà signalé"),value: AccountStatus.REPORTED },
		{label:this.translate.instant("Compte désactivé"),value: AccountStatus.DEACTIVATED },
		{label:this.translate.instant("Compte bloqué"),value: AccountStatus.BLOCKED }
	];

	private typeFilterSalonAccount = [
        { label: this.translate.instant("Compte normale"), value: AccountStatus.STANDARD },
        { label: this.translate.instant("Compte désactivé"), value: AccountStatus.DEACTIVATED },
    ];

	private typeFilterSalon = [
		{label:this.translate.instant("Salon professionnel"),value: SalonStatus.PROFESSIONAL},
		{label:this.translate.instant("Salon normale (amateur)"),value: SalonStatus.NORMAL},
		{label:this.translate.instant("Salon en attente de vérification"),value: SalonStatus.WAITING_VERIFICATION},
		{label:this.translate.instant("Sans salon"),value: SalonStatus.NO_SALON }
	];

	private typeFilterReservationStatus = [
        { label: this.translate.instant("Accompli"), value: ReservationStatus.ACCOMPLISHED },
        { label: this.translate.instant("Annulé ou expiré"), value: ReservationStatus.EXPIRED },
        { label: this.translate.instant("Non confirmé"), value: ReservationStatus.NOT_CONFIRMED },
    ];
    
	private statistiqueTypeList = [
        { label: this.translate.instant("Utilisateurs"), key: StatKey.users },
        { label: this.translate.instant("Nouveaux inscrits"), key: StatKey.new_users },
        { label: this.translate.instant("Salons"), key: StatKey.salons },
        { label: this.translate.instant("Prestations effectuées"), key: StatKey.prestations_effectuees },
        { label: this.translate.instant("Utilisateur en attente de validation"), key: StatKey.users_not_confirmed },
        { label: this.translate.instant("Reservations encours"), key: StatKey.reservation_encours },
        { label: this.translate.instant("Reservations annulées"), key: StatKey.reservation_annulee },
        { label: this.translate.instant("Chiffre d'affaire"), key: StatKey.chiffre_affaire }        
    ];

    private translationKeys : string[ ]= [ 'fr' , 'en' , 'de']

	constructor(private translate :TranslateService) { }

	getCountries() {
		return [{label:"Suisse",value:"Suisse", key:"CH"},{label:"France",value:"France", key:"FR"},{label:"Allemagne",value:"Allemagne", key:"DE"}];
	}

	saveAppData(key:string, data:any) {
		localStorage.setItem(key,JSON.stringify(data));
	}

	getAppData(key:string, removeData = false) {
		
		let strData = localStorage.getItem(key);

		if (!strData || strData == "undefined") {
			strData = "{}";
		}

		if (removeData)
			this.removeAppData(key);

		return JSON.parse(strData);
	}

	clearAppData() {
		localStorage.clear();
	}

	removeAppData(key: string) {
		localStorage.removeItem(key);
	}

	dataExist(key: string): boolean {
		return localStorage.getItem(key) !== null;
	}

	setRouteData(id, data) {
		this.routeData[id] = data;
	}

	getRouteData(id) {
		return this.routeData[id];
	}

	getExperienceLabel(key) {
		switch (key) {
			case ExperienceSalon.BEGINNER:
				return this.translate.instant("intervalle_age" , {min : 0 , max : 2 }); //"0 à 2 ans";
			case ExperienceSalon.PROFESSIONAL:
				return this.translate.instant("intervalle_age" , {min :2 , max : 7 }); //"2 à 7 ans";
			case ExperienceSalon.EXPERT:
				return this.translate.instant("intervalle_age_no_limit" , {min : 7 }); //"7 ans et plus";
			default:
				// code...
				break;
		}
		return "N/A";
	}
    
    getLanguageChoice() {
        return [{ label: this.translate.instant("Français"), value: 'fr' }, { label: this.translate.instant("Allemand"), value: 'de' }, {label : this.translate.instant("Anglais"), value: 'en'} ]
    }
	
	getTypeFilterAccount(){
		return this.typeFilterAccount;
	}

	getTypeFilterSalonAccount() {
    return this.typeFilterSalonAccount;
  }

	getTypeFilterSalon(){
		return this.typeFilterSalon;
	}

	getTypeFilterReservationStatus(){
    return this.typeFilterReservationStatus;
 	}

    getStatistiqueTypeList() {
        return this.statistiqueTypeList;
    }
	
	getStartAndEndDateTimestamp(startDate: Date, duration: number, durationType: DurationType){
      const res = {
          startDateTimestamp: startDate.getTime(),
          endDateTimestamp: null
      }

      const endDate = startDate;

      if(duration > 0){

          switch (durationType) {
              case DurationType.HOUR:
                  // code...
                  endDate.setHours( startDate.getHours() + duration );
                  res.endDateTimestamp = endDate.getTime();
                  break;

              case DurationType.DAY:
                  endDate.setDate( startDate.getDate() + duration );
                  res.endDateTimestamp = endDate.getTime();
                  break;

              case DurationType.WEEK:
                  // code...
                  endDate.setDate( startDate.getDate() + (duration*7) );
                  res.endDateTimestamp = endDate.getTime();
                  break;

              case DurationType.MONTH:
                  // code...
                  endDate.setMonth( startDate.getMonth() + duration );
                  res.endDateTimestamp = endDate.getTime();
                  break;

              case DurationType.YEAR:
                  // code...
                  endDate.setFullYear( startDate.getFullYear() + duration );
                  res.endDateTimestamp = endDate.getTime();
                  break;

              default:
                  // code...
                  break;
          }

      }

      return res;
    }

    getDurationType() {
        return [{ label: this.translate.instant("mois"), value: DurationType.MONTH }, { label: this.translate.instant("année(s)"), value: DurationType.YEAR} ];
    }

    getTranslationKey() : string [] {
        return this.translationKeys;
    }
	
}

export enum DurationType{
    HOUR = "hour",
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    YEAR = "year"
}

export const dataKey = {
	USER_LOGGED: 'user_logged',
	TEMP_USERDATA: 'tempUserData',
	DETAIL_SALON_CLIENT: 'detail_salon_client',
	RESERVATION_TEMP: 'reservation_temp',
	IMGS_TEMP: 'imgs_temp',
	TYPE_PRESTATION_FILTER: 'type_prestation_filter',
    TEMP_LANG: 'temp_lang',
    
};

export const dataConst = {
	DEFAULT_COMMENT_COUNT : 2,
	DEFAULT_GALLERIE_COUNT : 4,
	// API_ENDPOINT: 'https://us-central1-askeed-app.cloudfunctions.net/'
	API_ENDPOINT: 'https://europe-west1-askeed-app.cloudfunctions.net/'
	// API_ENDPOINT: 'http://localhost:5001/askeed-app/us-central1/'
}

export async function imageExists(url){

	let image = new Image();

	image.src = url;

	if (!image.complete) {
	    return false;
	}
	else if (image.height === 0) {
	   	return false;
	}else{
		return true;
	}

}


export enum StatKey {
    users = "users",
    new_users = "new_users",
    salons = "salons",
    prestations_effectuees = "prestations_effectuees",
    users_not_confirmed = "users_not_confirmed",
    reservation_encours = "reservation_encours",
    reservation_annulee = "reservation_annulee",
    chiffre_affaire = "chiffre_affaire"
}

export enum ConfigDocument{
    config = "config",
    configCommercial = "configCommercial"
}