import { Salon } from './salon.model';
import { User } from './user.model';
import { Deserializable } from './deserializable.model';

/**
 * L'ensemble des resultats salons (accueil, galleries, Maps) 
 * sont retrouvés avec la Geoquery (latitude, longitude) 
 * par rapport à la position de l'utilisateur
 */

export class Notification implements Deserializable{
    nid: string;
    titre ?: string;
    intTitre?: any;
    message:string
    intMessage?: any;
    salon?: Salon;
    client?: User;
    textAction?:string;
    intTextAction: any;
    isClosed: boolean;
    status: NotificationStatus ; //enum;
    type: NotificationType; //enum;
    rid?: string;
    createdAt: Date;
    by: NotificationSender
    // specialite: PrestationCategorie;
 
    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }

}

export enum NotificationStatus {
    WARNING = "warning",
    DANGER = "danger",
    SUCCESS = "success",
}

export enum NotificationType {
    RESERVATION_ANNULEE = "reservation_annulee",
    RESERVATION_TERMINEE = "reservation_terminee",
    RESERVATION_FEEDBACK = "reservation_feedback",
    AUTRE = "autre"
}

export enum NotificationSender {
    CLIENT = "client",
    SALON = "salon",
    ASKEED = "askeed",
    AUTRE = "autre"
}