import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Image } from 'src/app/models/image.model';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { User } from 'src/app/models/user.model';
import { SalonDetail } from 'src/app/models/salon.model';
import { AuthService } from 'src/app/services/auth.service';
import { PrestationService } from 'src/app/services/prestation.service';
import { ReportService } from 'src/app/services/report.service';
import { SalonService } from 'src/app/services/salon.service';
import { UserService } from 'src/app/services/user.service';
import { dataConst } from '../../helpers/data.service';
import { ShareLinkComponent } from '../share-link/share-link.component';
import { TranslateService } from '@ngx-translate/core';
import { RedirectionService } from 'src/app/services/redirection.service';
@Component({
  selector: 'app-news-prestation',
  templateUrl: './news-prestation.component.html',
  styleUrls: ['./news-prestation.component.scss'],
})
export class NewsPrestationComponent implements OnInit {

  @Input() actu: Image;
  @Input() user: User;
  @Input() modal = false;
  @Input() sliderPrestations: PrestationDetail[] = [];
  @Input() prestation: PrestationDetail;
  prestations: PrestationDetail[];

  @Input() showComment = false;
  @Input() type = 'salon';

  commentsToShowCount = dataConst.DEFAULT_COMMENT_COUNT;
  dataConst = dataConst;
  tabComment = true;
  loading = false;
  sharingModalLoading = false;
  descriptionMinLimit= 120;
  descriptionLimit = 120;
  style: any;

  constructor(
    private prestationService: PrestationService,
    private salonService: SalonService,
    public authService: AuthService,
    private userService: UserService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    public reportService: ReportService,
    public router: Router,
    public translate: TranslateService,
    public redirectionService: RedirectionService,
  ) { }

  ngOnInit() {
    // this.loading = true;
    // let snap = await this.prestationService.getById(this.actu.pid);
    // this.prestation = new PrestationDetail().deserialize(snap.data());
    // this.loading = false;

    // if (this.prestation) this.prestations = this.prestationService.getPrestationsBySalonId(this.prestation.sid);

    
    if(this.prestation && this.prestationService.prestationsDefault){
      this.prestations = this.prestationService.prestationsDefault.filter((pres) => pres.sid === this.prestation.sid); 
    }
    
    if(this.modal){
      this.style = {}
    }else{
      this.style = {};
    }
    if(!this.user){
      this.user = User.userFactory(this.authService.userLoggedIn);
      if(!this.user.uid){
        return;
      }
      this.userService.observerUser(this.user.uid).subscribe((user) => {
        this.user = new User().deserialize(user.payload.data());
      });
    }
  }

  tab(num){
    switch (num) {
      case 1:
        this.tabComment = true;
        this.showComment = true;
        break;
      case 2:
        this.tabComment = false;
        this.showComment = false;
        break;

      default:
        break;
    }
  }

  async likeIt() {
    if(!this.user.uid){
      if(this.modal){
        this.dismissModal();
      }
      return this.router.navigate(['/login']);
    }
    let actionLike = true;

    if (this.actu.likes && this.actu.likes.includes(this.user.uid)) {
      this.actu.likes = this.actu.likes.filter(uid => uid !== this.user.uid);
      actionLike = false;
    } else {

      if (!this.actu.likes) {
        this.actu.likes = [];
      }

      this.actu.likes.push(this.user.uid);
    }



    const imgInPrestationGallerie = this.prestation.galleries.find(img => img.url === this.actu.url);

    imgInPrestationGallerie.likes = this.actu.likes;

    this.prestationService.update(this.prestation)

  }

  showAllComments() {

		if (this.actu.comments && this.commentsToShowCount < this.actu.comments.length) {
			this.commentsToShowCount += dataConst.DEFAULT_COMMENT_COUNT;
		} else {
			this.commentsToShowCount = dataConst.DEFAULT_COMMENT_COUNT;
		}

  }

  goToSalon() {
    this.redirectionService.redirectTo(`/profil-salon/${this.prestation.sid}`, true);
  }

  async shareLink(ev: any) {
    this.sharingModalLoading = true;
    let url = window.origin + '/prestation/' + this.prestation.pid;
    const popover = await this.popoverController.create({
      component: ShareLinkComponent,
      cssClass: 'share-link-popover',
      event: ev,
      translucent: true,
      componentProps: {
        title: this.translate.instant("Partager la prestation"),
        url: url,
        postTitle: this.prestationLabel + " par " +this.prestation.salonName,
        postImageLink: this.actu.url,
        postDescritpion: this.prestation.description
      }
    });
    return await popover.present().then(() => {
      this.sharingModalLoading = false;
    });
  }

  onCommentPublished(eventData) {
		this.showComment = true;
	}

  async next(iter){
    this.loading = true;
    this.descriptionLimit = 120;
    let sliderPrestations  = [];
    if ( this.type === 'result' ){
      sliderPrestations = this.prestationService.prestationsResult;
    }else{
      sliderPrestations = this.sliderPrestations;
    }

    let index = sliderPrestations.findIndex((el) => el.pid === this.prestation.pid);

    if (index || index === 0){
      let nextIndex = (index === 0 && iter === -1)
      ? sliderPrestations.length - 1
      :  (index + iter) % sliderPrestations.length ;
      // tslint:disable-next-line:no-unused-expression
      let nextPrestation = sliderPrestations[nextIndex];
      if(nextPrestation){

        let salon = this.salonService.salonsDefault.find((el) => el.sid === nextPrestation.sid);
        if(!salon){
          let salonValue = await (await this.salonService.getSalon(nextPrestation.sid)).data();
          salon = new SalonDetail().deserialize(salonValue);
        }
        let image: Image = nextPrestation.galleries[0];
        if (image){
          image.pid = nextPrestation.pid;
          image.salon = salon;
          image.prestationName = nextPrestation.name;
          this.actu = image;
          this.prestation = nextPrestation;
          this.prestations = this.prestationService.prestationsDefault.filter((pres) => pres.sid === this.prestation.sid);
        }
      }
    }
    this.loading = false;
  }

  async dismissModal() {
    const modal:HTMLIonModalElement = await this.modalController.getTop();
    if(modal) {
      await modal.dismiss();
    }
  }

    get prestationLabel() {

        if(this.prestation.intLabel && this.prestation.intLabel[this.translate.currentLang] && this.prestation.intLabel[this.translate.currentLang] !== ""){
            return this.prestation.intLabel[this.translate.currentLang]
        }

        return this.prestation.name;
            
    } 
}
