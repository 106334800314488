import { Component, Input, OnInit } from '@angular/core';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { PromotionDay } from 'src/app/models/promotion.model';
import { SalonDetail } from 'src/app/models/salon.model';
import { SalonService } from 'src/app/services/salon.service';
import { TranslateService } from "@ngx-translate/core";
import { PrestationService } from 'src/app/services/prestation.service';

@Component({
  selector: 'app-news-promotion',
  templateUrl: './news-promotion.component.html',
  styleUrls: ['./news-promotion.component.scss'],
})
export class NewsPromotionComponent implements OnInit {

  @Input() prestation: PrestationDetail;

  salon: SalonDetail;
  promotionDays: any[] = [];

  constructor(
    private salonService: SalonService,
    private prestationService: PrestationService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.salonService.getSalon(this.prestation.sid).then(salonSnap =>{
      this.salon = new SalonDetail().deserialize(salonSnap.data());
      for (let promDay of this.prestation.promotionDays) {
        let snap = promDay;
        promDay = new PromotionDay().deserialize(snap);
        if (promDay.getPromotionMaxValue() !== undefined) {
          this.promotionDays.push({
            discount: promDay.getPromotionMaxValue(),
            label: promDay.label
          });
        }

      }
    });
      
  }

  get prestationLabel() {

      if(this.prestation.intLabel && this.prestation.intLabel[this.translate.currentLang] && this.prestation.intLabel[this.translate.currentLang] !== ""){
          return this.prestation.intLabel[this.translate.currentLang]
      }

      return this.prestation.name;
          
  } 
    
  get salonName(){
      if(this.salon && this.salon.name){
          return this.salon.name;
      }
      return "-";
  }

}
