import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReservationDetail } from '../../../models/reservation.model';

import { AuthService } from '../../../services/auth.service';
import { PrestationDefaultService } from '../../../services/prestation-default.service'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agenda-prestation',
  templateUrl: './agenda-prestation.component.html',
  styleUrls: ['./agenda-prestation.component.scss'],
})
export class AgendaPrestationComponent implements OnInit {

  @Input() reservation: any;
  @Input() view: string = "client";
  @Input() readOnly = false;
  @Output() doAction: EventEmitter<{action: string, reservation: ReservationDetail}> = new EventEmitter(); 
  status: "expired" | "done" | "notConfirmed" | "upcoming" | "archived";

  expand:boolean =  false;
  colorDate =  {
    "expired" : "danger", 
    "accomplished" : "success",
    "notConfirmed" : "warning",
    "upcoming" : "tertiary",
    "archived" : "dark",
    "canceled": "primary",
    "canceled_by_client": "primary",
    "canceled_by_salon": "primary",
    "edited": "quaternary"
  };
  @Input() showInfos = false;

  
  constructor(public authService: AuthService , private prestationDefaultService : PrestationDefaultService, public translate: TranslateService) { }

  ngOnInit() {
    this.reservation.viewedBy = this.view;
  }

  expandToggle(){
    // this.expand = !this.expand;
  }

  onDoAction(action: string) {
    this.doAction.emit({action: action, reservation: this.reservation });
  }

  get dateHasPassed(): boolean {
    return new Date().getTime() > this.reservation.executionDateTimestamp;
  }

  confirmPrestation() {
    if (this.dateHasPassed)
      this.onDoAction('accomplish');
  }

    // get prestationLabel() {
    //     if (this.prestation.name && this.prestationService.defaultPrestations && this.prestationService.defaultPrestations.length > 0) {
    //         let prestationDefault = this.prestationService.defaultPrestations.find(prestation => prestation.dpid === this.prestation.defaultPrestationId)
    //         if (prestationDefault) {
    //             return prestationDefault[this.translate.currentLang] || prestationDefault.fr;
    //         }

    //     }

    //     return this.prestation.name || "-"
    // }

    get prestationLabel() {

        if (this.reservation.prestation.intLabel && this.reservation.prestation.intLabel[this.translate.currentLang]) {
            return this.reservation.prestation.intLabel[this.translate.currentLang]
        }

        return this.reservation.prestation.name

    }
}
