import { Injectable } from '@angular/core';

import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Report } from '../models/report.model';

import { Observable } from 'rxjs';
import { ChatAdmin } from '../models/chat-admin.model';

@Injectable({
  providedIn: 'root'
})
export class ChatAdminService {

	static COLLECTION_NAME = 'chatAdmin';

	constructor(private firestore: AngularFirestore) { }

	setChatAdmin(chat: ChatAdmin){
    chat.updatedAt = new Date();
    delete chat.client;
    delete chat.prestation;
    delete chat.salon;

    return this.firestore.collection(ChatAdminService.COLLECTION_NAME).doc(chat.uid).set(JSON.parse(JSON.stringify(chat)));
  }

  observeChatAdmin(uid: string) {
      return this.firestore.collection(ChatAdminService.COLLECTION_NAME).doc(uid).valueChanges();
  }


}
