import { Deserializable } from './deserializable.model';

export class City implements Deserializable{
	id: string; 
	country: string; 
	lat: string; 
	lng: string;
	name:string; 
	imageUrl?:string; 
	cityImageUrl?:string; 
	isActive?: boolean; 
	distance?: number; 

	deserialize(input: any) {
		Object.assign(this, input);
		if (this.cityImageUrl){
			this.imageUrl = this.cityImageUrl;
		}
	    return this;
	}
}