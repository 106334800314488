import { Component, OnInit } from '@angular/core';

import { MenuController } from '@ionic/angular';

import { AuthService } from '../../../services/auth.service';

import { LoadingController } from '@ionic/angular';

import { ReservationService } from '../../../services/reservation.service';

import { ReservationDetail, ReservationStatus } from "../../../models/reservation.model";

import { DataService, dataKey } from '../../../shared/helpers/data.service';

import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { LocalNotificationReservationService } from 'src/app/services/local-notification-reservation.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { ChatAdminService } from 'src/app/services/chat-admin.service';
import { ChatAdmin } from 'src/app/models/chat-admin.model';
import { LanguageChoiceComponent } from '../language-choice/language-choice.component';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

    constructor(private menu: MenuController, public authService: AuthService, private loadingController: LoadingController, private reservationService: ReservationService, private dataService: DataService, private router: Router, private userService: UserService, public localNotification: LocalNotificationReservationService, private redirectionService: RedirectionService, private chatAdminService: ChatAdminService, public popoverController: PopoverController) { }

  ngOnInit() {
    
    if (this.authService.isLoggedIn) {

      const paramUser: {by: string, id: string} = {by: "client.uid", id: this.authService.userLoggedIn.uid};

      if (!paramUser.id)
        return;

      this.reservationService.observeAllReservationBy(paramUser).subscribe(observer => {

        this.localNotification.messageNotRead = 0;

        observer.forEach(documentChange => {
          
          const reservation = new ReservationDetail().deserialize(documentChange.payload.doc.data());

          if (reservation.discussion) {
            
            reservation.discussion.forEach(disc => {

              if (disc.user.uid !== this.authService.userLoggedIn.uid && !disc.read) {
                this.localNotification.messageNotRead++;
              }

            });

          }

        });

      });

      if (this.authService.userLoggedIn.salonId) {

        const paramSalon =  {by: "salon.sid", id: this.authService.userLoggedIn.salonId};

        if (!paramSalon.id)
          return;

        this.reservationService.observeAllReservationBy(paramSalon).subscribe(observer => {
  
          this.localNotification.upcomingAgenda = 0;

          this.localNotification.salonMessage = 0;
  
          observer.forEach(documentChange => {
            
            const reservation = new ReservationDetail().deserialize(documentChange.payload.doc.data());

            if (reservation.discussion) {
            
              reservation.discussion.forEach(disc => {
  
                if (disc.user.uid !== this.authService.userLoggedIn.uid && !disc.read) {
                  this.localNotification.salonMessage++;
                }
  
              });
  
            }
  
            if ( [ReservationStatus.UP_COMING].includes(reservation.salon.status)) {
  
              this.localNotification.upcomingAgenda++;
            
            } 
  
          });
  
        });

      }

      this.chatAdminService.observeChatAdmin(this.authService.userLoggedIn.uid).subscribe(value => {

        if (value) {
            const chatAdmin = new ChatAdmin().deserialize(value);

            this.localNotification.messageFromAdminNotRead = chatAdmin.discussionNotReadCount(this.authService.userLoggedIn.uid);

        }
      });

    }

  }

  closeMenu(){
    this.menu.close('mainMenu');
  }

  async logout() {

    const loading = await this.loadingController.create({
      message: 'Déconnexion...',
      cssClass: 'askeed-spinner'
    });

    await loading.present();

    this.authService.signOut(false).then(() => {
      loading.dismiss();
      this.closeMenu();
    }, error => {
      loading.dismiss();
    });
  }

  openSalon() {
    this.dataService.saveAppData(dataKey.TEMP_USERDATA,this.authService.userLoggedIn);

		this.router.navigate(['/registration/salon']);
    this.closeMenu();
  }

  resetAlert(forClient = true) {

    const user = this.authService.userLoggedIn;

    if (forClient)
      user.alertCount = 0;
    else
      user.salonAlertCount = 0;
    
    this.userService.updateUser(user);

    this.closeMenu();

  }

  goToSalon() {
    this.redirectionService.redirectTo('/profil/salon', true);
    this.closeMenu();
  }

    async presentModal() {
        
        const popover = await this.popoverController.create({
            component: LanguageChoiceComponent,
            cssClass: 'my-custom-language',
            translucent: true
        });
        await popover.present();
        this.closeMenu();
        return true;
    }

    


}
