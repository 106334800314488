import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User, AccountStatus } from '../models/user.model';
import { DataService, dataKey } from '../shared/helpers/data.service';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { SalonService } from './salon.service';
import { Salon, SalonDetail } from '../models/salon.model';
import { Prestation, PrestationDetail } from '../models/prestation.model';
import { PrestationService } from './prestation.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

	static COLLECTION_NAME = 'users';
	whenActionChange: EventEmitter<{action: string; params?: any}> = new EventEmitter();

	constructor(private firestore: AngularFirestore, private dataService: DataService, private authService: AuthService, private angularFireAuth: AngularFireAuth) { }

	getUsers() {
	    return this.firestore.collection(UserService.COLLECTION_NAME).snapshotChanges();
	}

	createUser(user: User){
		user.createdAt = new Date();
		user.updatedAt = user.createdAt;
		user.status = AccountStatus.STANDARD;
	    return this.firestore.collection(UserService.COLLECTION_NAME).doc(user.uid).set({...user});
	}

	updateUser(user: User){
		user.updatedAt = new Date();
	
	    return this.firestore.doc(UserService.COLLECTION_NAME + '/' + user.uid).update({...user});
	}

	deleteUser(uid: string){
	    this.firestore.doc(UserService.COLLECTION_NAME + '/' + uid).delete();
	}

	getUser(uid: string) {
		return this.firestore.collection(UserService.COLLECTION_NAME).doc(uid).ref.get();
	}

	observerUser(uid: string) {
		return this.firestore.collection(UserService.COLLECTION_NAME).doc(uid).snapshotChanges();
	}

	getCurrentUser(): User {
		return this.dataService.getAppData(dataKey.USER_LOGGED);
	}

	updateUserEmail(oldEmail: string, password: string, user: User) {
		return this.angularFireAuth.signInWithEmailAndPassword(oldEmail, password).then(userCredential => userCredential.user.updateEmail(user.email));
	}

	getSubscribedSalons(user: User) {
		return this.firestore.collection<SalonDetail>
		(SalonService.COLLECTION_NAME, ref => ref.where("sid", 'in', user.salonsLiked))
		.snapshotChanges();
	}

	getSubscribedSalonsPrestations(user: User) {

		return this.firestore.collection<PrestationDetail>
		(PrestationService.COLLECTION_NAME, ref => ref.where("sid", 'in', user.salonsLiked))
		.snapshotChanges();
	}

	getPrestationsFavoris(user: User) {
		return this.firestore.collection<PrestationDetail>
		(PrestationService.COLLECTION_NAME, ref => ref.where("pid", 'in', user.prestationsFavoris))
		.snapshotChanges();
	}

}
