import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Salon, SalonDetail } from '../models/salon.model';
import { PrestationDetail } from '../models/prestation.model';
import { PrestationService } from '../services/prestation.service';
import { User } from '../models/user.model';
import { Address } from '../models/address.model';
import { dataConst, DataService, dataKey } from '../shared/helpers/data.service';
import { BehaviorSubject, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable , Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as geofire from 'geofire-common';

import { Popup } from 'leaflet';
import { CitiesService } from './cities.service';
import { FilterService } from './filter.service';
import { City } from '../models/city.model';
import { CategoryPrestation } from '../models/defaultPrestation.model';
import { StringHelperService } from '../shared/helpers/string-helper.service';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { CategoryService } from './category.service';

import algoliasearch from 'algoliasearch';

import { TranslateService } from '@ngx-translate/core';
import { VariablesGlobales } from 'src/app/shared/variables-globales';


@Injectable({
	providedIn: 'root'
})
export class SalonService {

	constructor(private firestore: AngularFirestore,
		private prestationService: PrestationService,
		private httpClient: HttpClient,
		private dataService: DataService,
		private citiesService: CitiesService,
		private categoryService: CategoryService,
		private filterService: FilterService,
		private stringHelper: StringHelperService,
		private authService: AuthService,
        public translate: TranslateService,
		private variablesGlobales: VariablesGlobales
		//    private userService: UserService,

	) {

		// this.citiesService.cityChange.subscribe((city) => {
		// 	if (city) {
		// 		this.getSalonsDefault(city).then(() => {
		// 			this.getSalonsResult(city);
		// 		});
		// 	}
		// });
		// this.filterService.tagsAdded.subscribe((t) => {
		// 	if (t) {
		// 		this.getSalonsResult();
		// 	}
		// });
		// this.filterService.cityChange.subscribe((t) => {
		// 	if (t) {
		// 		this.getSalonsDefault().then(() => {
		// 			this.getSalonsResult();
		// 		});
		// 	}
		// });
	}

	static COLLECTION_NAME = 'salons';
	static COLLECTION_SLUG_NAME = 'slugSalons';

	private initialCategorie = new BehaviorSubject(null);
	initialCategorieChange = this.initialCategorie.asObservable();
	queryOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Content-Type': 'application/json'
		})
	};
	salonInfos: {
		salon?: SalonDetail;
		mappedCategories?: string;
		mainAddress?: Address;
		otherAddresses?: Address[];
		prestations?: any[];
		isUnavailable?: boolean;
	} = null;
	lastSidObserved: string;


	salonsResult: SalonDetail[];
	salonsDefault: SalonDetail[];
	salonsHomeDefault: SalonDetail[] = [];

	salonsPromo: SalonDetail[] = [];
	salonsNear: SalonDetail[] = [];
	salonsPopular: SalonDetail[] = [];
	salonsNew: SalonDetail[] = [];
	salonsAll: SalonDetail[] = [];
	subscribedSalons: SalonDetail[] = [];

	results: Observable<Salon[]>;

	loadingResult: Boolean = false;
	loadingDefault: Boolean;
	whenRequestResetSalonInfos: EventEmitter<void> = new EventEmitter();

	salonClick: BehaviorSubject<boolean> = new BehaviorSubject(false);

	subscriptionChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

    prestationSubscription : Subscription = null;

    observeSalonInfosSubscription: Subscription = null;

	observelistCategoriesSubscription: Subscription = null;

    onChangeCategorie(data) {
		this.initialCategorie.next(data);
	}

	getSalons() {
		return this.firestore.collection(SalonService.COLLECTION_NAME).snapshotChanges();
	}

	getAll() {
		return this.firestore.collection<SalonDetail>(SalonService.COLLECTION_NAME).valueChanges();
	}

	createSalon(salon: SalonDetail) {
		salon.createdAt = new Date();
		salon.updatedAt = salon.createdAt;
		salon.isVerified = false;
		salon.isClosed = false;
		salon.isDeactivated = false;

		if (!salon.sid) {
			salon.sid = salon.createdAt.getTime() + '';
		}

		return this.firestore.collection(SalonService.COLLECTION_NAME).doc(salon.sid).set(JSON.parse(JSON.stringify(salon)));
	}
	updateSalonCloud(salon: SalonDetail) {
		salon.updatedAt = new Date();
		const queryOptions = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'updateSalonCloud', JSON.stringify({ ...salon }), queryOptions);
	}
	updateSalon(salon: SalonDetail) {
		salon.updatedAt = new Date();

		return this.firestore.doc(SalonService.COLLECTION_NAME + '/' + salon.sid).update(JSON.parse(JSON.stringify(salon)));
	}

	getBySlug(id) {
		return this.firestore.doc(SalonService.COLLECTION_SLUG_NAME + '/' + id).ref.get();
	}

	deleteSalon(sid: string) {
		this.firestore.doc(SalonService.COLLECTION_NAME + '/' + sid).delete();
	}

	getSalon(sid: string) {
		return this.firestore.collection(SalonService.COLLECTION_NAME).doc(sid).ref.get();
	}

	getSalonsByIds(ids: string[]) {
		return this.firestore.collection(SalonService.COLLECTION_NAME).ref.where('sid', 'in', ids).get();
	}

	observeSalon(sid: string) {
		return this.firestore.collection(SalonService.COLLECTION_NAME).doc(sid).snapshotChanges();
	}
	getObservableSalon(sid: string) {
		return this.firestore.collection(SalonService.COLLECTION_NAME).doc(sid).valueChanges();
	}

	getSalonAtProximity(distanceRequest: number,
		lat: number,
		lng: number
	): Observable<{ result: { salons: { distanceFrom: number; salonAdress: Address; salon: Salon; popup?: Popup }[]; length: number } }> {

		const user: User = User.userFactory(this.dataService.getAppData(dataKey.USER_LOGGED));

		let cityUserLat = user && user.mainAddress && user.mainAddress.latLng ? user.mainAddress.latLng.lat : 0, cityUserLng = user && user.mainAddress && user.mainAddress.latLng ? user.mainAddress.latLng.lng : 0;

		const queryOptions = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET'
			})
		};

		return this.httpClient.get<{ result: { salons: { distanceFrom: number; salonAdress: Address; salon: Salon; popup?: Popup }[]; length: number } }>(dataConst.API_ENDPOINT + `getSalonAtProximity?distanceRequest=${distanceRequest}&lat=${lat}&lng=${lng}&cityUserLat=${cityUserLat}&cityUserLng=${cityUserLng}&countryUser=${user && user.mainAddress ? user.mainAddress.country : 'none'}`);

	}

	getSalonsAtProximity(distanceRequest: number, lat: number, lng: number, filterBy = null): Observable<{ result: { salons: { distanceFrom: number; salonAdress: Address; salon: Salon; popup?: Popup }[]; length: number } }> {
		const user: User = User.userFactory(this.dataService.getAppData(dataKey.USER_LOGGED));

		let cityUserLat = user && user.mainAddress && user.mainAddress.latLng ? user.mainAddress.latLng.lat : 0;
		let cityUserLng = user && user.mainAddress && user.mainAddress.latLng ? user.mainAddress.latLng.lng : 0;
		let countryUser = user && user.mainAddress ? user.mainAddress.country : 'none';
		let data = {
			filterBy: filterBy,
			distanceRequest: distanceRequest,
			lat: lat,
			lng: lng,
			cityUserLat: cityUserLat,
			cityUserLng: cityUserLng,
			countryUser: countryUser
		};

		return this.httpClient.post<{ result: { salons: { distanceFrom: number; salonAdress: Address; salon: Salon; popup?: Popup }[]; length: number } }>(dataConst.API_ENDPOINT + 'getSalonAtProximity', JSON.stringify({ ...data }), this.queryOptions);

	}

	observeSalonInfos(sid: string, doOnError: (error: any) => void = (error) => { 
		// TODO console.log('error'); 
	}) {

		if (sid !== null){
            if (sid != this.lastSidObserved){
                this.observeSalonInfosSubscription = null;
                this.prestationSubscription = null;
                    }
			this.lastSidObserved = sid;
        }
        if (this.observeSalonInfosSubscription) return;
		this.salonInfos = null;
        

		this.observeSalonInfosSubscription = this.observeSalon(this.lastSidObserved).subscribe(salonObserver => {

			this.salonInfos = {};

			if (salonObserver.payload.exists) {

				this.salonInfos.salon = new SalonDetail().deserialize(salonObserver.payload.data());
				for (let key in this.salonInfos.salon.availability) {
                    if (typeof(this.salonInfos.salon.availability[key].available) === 'boolean') {
                        this.salonInfos.salon.availability[key].available = { am: this.salonInfos.salon.availability[key].available, pm: this.salonInfos.salon.availability[key].available }
                    } else {
                        break;
                    }
                }

				if (this.salonInfos.salon.categories) {
                    if (this.observelistCategoriesSubscription) this.observelistCategoriesSubscription.unsubscribe();
                    
					this.observelistCategoriesSubscription = this.prestationService.listCategories().subscribe(values => {
						const categories: CategoryPrestation[] = [];

						values.forEach(document => {
							categories.push(new CategoryPrestation().deserialize(document));

						});

						this.salonInfos.mappedCategories = this.salonInfos.salon.categories.map(cpid => categories.find(cat => cat.cpid === cpid)).filter(cat => cat !== null && cat !== undefined).map(cat => cat[this.translate.currentLang] || cat.fr).join("/");

						if (this.salonInfos.mappedCategories === "") {
							this.salonInfos.mappedCategories = this.salonInfos.salon.categories.map(cat => this.stringHelper.capitalize(cat)).join('/');
						}

					})

				}

				this.salonInfos.mainAddress = this.salonInfos.salon.adresses.filter(address => address.isMain == true)[0];

				this.salonInfos.otherAddresses = this.salonInfos.salon.adresses.filter(address => address.isMain == false);

                if(!this.prestationSubscription) {
                    this.prestationSubscription = this.prestationService.getPrestationsBySalonId(this.salonInfos.salon.sid).subscribe(prestationData => {
                    // this.prestationService.getOncePrestationsBySalonId(this.salonInfos.salon.sid).then(prestationData => {

                        this.salonInfos.prestations = [];

                        prestationData.forEach(prestationDocumentData => {
                            this.salonInfos.prestations.push(new PrestationDetail().deserialize(prestationDocumentData));
                            // this.salonInfos.prestations.push(new PrestationDetail().deserialize(prestationDocumentData.data()));

                        });

                    });
                }  
    				

			} else {
				this.salonInfos.isUnavailable = true;

			}

			// this.dataService.saveAppData(dataKey.DETAIL_SALON_CLIENT, this.salonInfos.salon);


		}, doOnError);
	}

	setSalon(salon: SalonDetail, prestations: PrestationDetail[] = []) {

		this.salonInfos = { salon, prestations };

	}

	getSalonWithTypes(salonId) {
		return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'getSalon', JSON.stringify({ id: salonId }), this.queryOptions);
	}

	getFilteredSalons(property) {
		return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'getFilteredSalons', JSON.stringify({ ...property }), this.queryOptions);
	}

	getFilteredSalonsClient(property) {
		return new Promise((resolve, reject) => {
			if (property['city'] && property['city']['lat'] && property['city']['lng']) {
				const lat = Number(property['city']['lat']);
				const lng = Number(property['city']['lng']);
				const center = [lat, lng];
				let distance = 15;
				const radiusInM = distance * 1000;

				let bounds = geofire.geohashQueryBounds(center, radiusInM);
				let promises = [];
				for (const b of bounds) {
					const q = this.firestore.collection('salons').ref
						// .orderBy('geohash')
						// .startAt(b[0])
						// .endAt(b[1]);
						.where("isVerified", "==", true)
						.where("isDeactivated", "==", false)
                        .where("isClosed", "==", false)
						.orderBy('geohash')
						.startAt(b[0])
						.endAt(b[1]);
					promises.push(q.get());
					
				}
				// Collect all the query results together into a single list
				Promise.all(promises).then((snapshots) => {
					let matchingDocs = [];
					for (const snap of snapshots) {
						for (const doc of snap.docs) {
							let salon = doc.data();
							// if (
							// 	(salon.isVerified || salon.isVerified == undefined)
							// 	&& (!salon.isDeactivated || salon.isDeactivated == undefined)
							// 	&& (!salon.isClosed || salon.isClosed == undefined)
							// ){
								let latP = doc.get('lat');
								let lngP = doc.get('lng');
								// We have to filter out a few false positives due to GeoHash
								// accuracy, but most will match
								const distanceInKm = geofire.distanceBetween([latP, lngP], center);
								const distanceInM = distanceInKm * 1000;
								if (distanceInM <= radiusInM) {
									doc.data().distance = distanceInKm;
									matchingDocs.push(doc.data());
								}
							//}
						}
					}

					return matchingDocs;
				}).then((matchingSalons) => {
					resolve(matchingSalons);
				});
			} else {
				reject('no city in getFilteredClient');
			}
		});
	}

	async getByCity(city = null){
		if (!city){
			await new Promise(resolve => setTimeout(resolve, 100));
			city = await this.citiesService.getCity();
			this.filterService.filterBy.city = city;
		}


		let params = {city};
		// params.city =  {
		// 	"country": "CH",
		// 	"name": "Lausanne",
		// 	"lat": "46.516",
		// 	"lng": "6.63282"
		// }
		return new Promise((resolve, reject) => {
			// resolve({data: []});
			if (params['city'] && params['city']['lat'] && params['city']['lng']) {
				let lat = Number(params['city']['lat']);
				let lng = Number(params['city']['lng']);
				const center = [lat, lng];
				let distance = 15;
				const radiusInM = distance * 1000;

				// Each item in 'bounds' represents a startAt/endAt pair. We have to issue
				// a separate query for each pair. There can be up to 9 pairs of bounds
				// depending on overlap, but in most cases there are 4.
				const bounds = geofire.geohashQueryBounds(center, radiusInM);
				const promises = [];
				let count = 0;
				let index = 0;
				let ref = this.firestore.collection('salons').ref;

				for (const b of bounds) {
					let q: any = ref;
					q = q
						.orderBy('geohash')
						.startAt(b[0])
						.endAt(b[1]);
					index++;
					promises.push(q.get());
				}

				// Collect all the query results together into a single list
				Promise.all(promises).then((snapshots) => {
					const matchingDocs = [];

					for (const snap of snapshots) {
						for (const doc of snap.docs) {
							lat = doc.get('lat');
							lng = doc.get('lng');
							// We have to filter out a few false positives due to GeoHash
							// accuracy, but most will match
							const distanceInKm = geofire.distanceBetween([lat, lng], center);
							const distanceInM = distanceInKm * 1000;
							if (distanceInM <= radiusInM) {
								let salon = doc.data();
								salon.distance = distanceInKm;
								if (
									(salon.isVerified || salon.isVerified == undefined)
									&& (!salon.isDeactivated || salon.isDeactivated == undefined)
									&& (!salon.isClosed || salon.isClosed == undefined)
								){
									matchingDocs.push(salon);
								}
							}
						}
					}
					let result = matchingDocs;
					return { data: result };
				}).then((result) => {
					resolve(result);
				});
			} else {
				reject('no city');
			}
		});
	}

	async getSalonsResult(city: City = null) {
		return new Promise( async (resolve) => {
			if (this.loadingResult){
				resolve([]);
			}
			if (!city) {
				city = await this.citiesService.getCity();
			}
			this.filterService.filterBy['city'] = city;
			// this.loadingResult = true;
			if (!this.filterService.SERVER_REQUEST && this.salonsDefault) {
				let salonsDefault: SalonDetail[] = [];
				this.salonsDefault.forEach((salon) => {
					salonsDefault.push(salon);
				});


				this.salonsResult = [];

				let result = [];

				// IF DEFAUTL SEARCH CITY -> PROCEED
				if (Object.keys(this.filterService.filterBy).length === 1 && this.filterService.filterBy.city) {
					let filters = this.filterService.makeFilters(this.filterService.filterBy, 'salon');
					this.salonsResult = _.filter(salonsDefault, _.conforms(filters));
				} else {
					// IF TOP FILTER PROCEED OR FILTER
					// tslint:disable-next-line:forin
					// // OR FILTER 
					// for (let i in this.filterService.filterBy) {

					// 	if (i !== "city") {
					// 		let filterBy = {};
					// 		filterBy[i] = this.filterService.filterBy[i];
					// 		let filters = this.filterService.makeFilters(filterBy, 'salon');
					// 		let res = _.filter(salonsDefault, _.conforms(filters));
					// 		res.forEach(element => {
					// 			if (!result.find((el) => el.sid === element.sid)) {
					// 				result.push(element);
					// 			}
					// 		});
					// 	}
					// }

					// END FILTER 
					let filterBy = {};
					for (let i in this.filterService.filterBy) {
						if (i !== "city") {
							filterBy[i] = this.filterService.filterBy[i];
						}
					}
					let filters = this.filterService.makeFilters(filterBy, 'salon');
					result = _.filter(salonsDefault, _.conforms(filters));
					this.salonsResult = result;
				}

				if (!this.filterService.sort) {
					this.filterService.sort = 'viewCount';
					this.filterService.sortType = 'desc';
				}

				this.salonsResult = this.salonsResult.filter(data => {

					if(data.prestations && data.prestations.length > 0){
						if(!VariablesGlobales.dev_mode){
							if(!data.dev_mode) return true;
							else return false;
						}
						return true;
					}
					return false;

                });

                if (this.filterService.sort) {
					this.salonsResult = _.orderBy(this.salonsResult, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
						return new SalonDetail().deserialize(s);
					});
				}
				this.loadingResult = false;
				resolve(this.salonsResult);
			} else if (!this.salonsDefault) {
				this.getSalonsDefault(city).then(() => {
					this.getSalonsResult(city).then((data) => {
						resolve(data);
					});
				});
			} else {
				this.getByCity().then(
					(value: {data: any []}) => {
						this.salonsResult = [];
						this.salonsResult = value.data.map((s) => {
							return new SalonDetail().deserialize(s);
						});
						if (this.filterService.sort) {
							this.salonsResult = _.orderBy(this.salonsResult, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
								return new SalonDetail().deserialize(s);
							});
						}
                    	
                    	this.salonsResult = this.salonsResult.filter(data => {

							if(data.prestations && data.prestations.length > 0){
								if(!VariablesGlobales.dev_mode){
									if(!data.dev_mode) return true;
									else return false;
								}
								return true;
							}
							return false;

						})

                        this.loadingResult = false;
						resolve(this.salonsResult);
					},
					error => {
						resolve([]);
						this.loadingResult = false;
					}
				);
			}
		});
	}

	getSalonsSubscribed() {
		// let user = null;
		// if (this.authService.isLoggedIn) {
		// 	user = User.userFactory(this.authService.userLoggedIn);
		// }
		// if (user && user.salonsLiked && user.salonsLiked.length > 0) {
		// 	this.userService.getSubscribedSalons(user).subscribe((o) => {
		// 		this.subscribedSalons = [];
		// 		o.forEach((s) => {
		// 			this.subscribedSalons.push(new SalonDetail().deserialize(s.payload.doc.data()));
		// 		});
		// 	})
		// }
	}

	getSalonsHomeDefault(){
		let city = {
			"country": "CH",
			"name": "Lausanne",
			"lat": "46.516",
			"lng": "6.63282"
		};

		this.getByCity({city}).then((value: {data: any[]}) => {

			let data = value.data.map(d => {
				let salon = new SalonDetail().deserialize(d);
				let ad = salon.mainAddress;
				if(ad.latLng){
					salon.distance = this.filterService.distance(ad.latLng.lat, ad.latLng.lng);
				}
				salon.price = salon.pricePrestation.min;
				return salon;
			});

			const salons: SalonDetail[] = data.map(d => {
				let salon = new SalonDetail().deserialize(d);
				return salon;
			});
			this.salonsHomeDefault = salons;
		});

	}

	async getSalonsDefault(city: City = null) {
		return new Promise<void>(async (resolve) => {
			this.loadingDefault = true;
			if (!city) {
				await new Promise<void>((res) => { setTimeout(() => {
						res();
					}, 100);
				});
				city = await this.citiesService.getCity();
			}
			
			this.filterService.filterBy['city'] = city;
			let property: any = {};
			property.city = city;
			if(!city){
				resolve();
			}
			this.getFilteredSalonsClient(this.filterService.filterBy).then( (value: any[]) => {

				let data = value.map(d => {
					let salon = new SalonDetail().deserialize(d);
					salon.price = salon.pricePrestation.min;
					return salon;
				});

				const salons: SalonDetail[] = data.map(d => {
					let salon = new SalonDetail().deserialize(d);
					// salon.categories = _.union(salon.categories, salon.categoriesPrestation);
					return salon;
				});
				this.salonsDefault = data.map(d => {
					let salon = new SalonDetail().deserialize(d);
					// salon.categories = _.union(salon.categories, salon.categoriesPrestation);
					return salon;
				});
				// this.prestationService.salonsDefault = salons;

				// SALON AVEC LE FILTRE CITY SEULEMENT
				this.salonsAll = salons;

				// SALON PROMO
				const date = new Date();
				this.salonsPromo = data.filter((s => {
					let res = true;
					if (!s.promotion) {
						res = false;
					} else if (s.promotion && s.promotionEndDateEnabled && s.promotionEndDate) {
						const endDate = new Date(s.promotionEndDate);
						if (endDate > date) {
							res = true;
						}
					}
					return res;
				}));

				// SALON PLUS PROCHES
				this.salonsNear = [];

				// SALON MIEUX NOTÉS
				this.salonsPopular = [];
				this.salonsPopular = data.filter((s) => s.averageNote > 0);
				this.salonsPopular.sort((a, b) => (b.averageNote - a.averageNote));

				this.loadingDefault = false;

				// NOUVEAUX SALONS
				let newSalons = data.sort(function (a, b) {
					let dateA: number;
					let dateB: number;
					if (a.createdAt['seconds']) {
						dateA = parseInt((a.createdAt['seconds']).toFixed());
					} else {
						let dateCreationA = new Date(a.createdAt).getTime();
						dateA = parseInt((dateCreationA / 1000).toFixed());
					}
					if (b.createdAt['seconds']) {
						dateB = parseInt((b.createdAt['seconds']).toFixed());
					} else {
						let dateCreationB = new Date(b.createdAt).getTime();
						dateB = parseInt((dateCreationB / 1000).toFixed());
					}

					if (dateA < dateB) { return -1; }
					if (dateA > dateB) { return 1; }
					return 0;
				});
				this.salonsNew = newSalons;
				if (this.salonsNew.length > 10) {
					this.salonsNew.splice(10, this.salonsNew.length - 10);
				}
				resolve();

			});
		});
	}

	async searchbarChange(keyword: string) {
		this.results = this.getResults(keyword);
		// const name = algoliasearch("G7N0M4HCAF","20cf2f6023dfbdaaebac33f6d6da5e4c");
        // const index = name.initIndex("name_salons");
        // let results: any[] = (await index.search(keyword)).hits
		// this.results = results.map((res) => new Salon().deserialize(res));
	}

	getResults(keyword: string): Observable<SalonDetail[]> {
		let observable: Observable<SalonDetail[]>;
		observable = of(this.salonsDefault);
		// observable = this.getAll();


		return observable.pipe(map(
			(result: any[]) => {
				if (keyword) {
					return result.filter(
						(item) => {
							keyword = this.removeAccent(keyword);
							let salonName = this.removeAccent(item.name);
							return salonName.toLowerCase().includes(
								keyword.toLowerCase()
							);
						}
					);
				} else {
					return result;
				}

			}
		));
	}

	removeAccent(value) {
		return value
			.replace(/á/g, 'a')
			.replace(/à/g, 'a')
			.replace(/â/g, 'a')
			.replace(/ä/g, 'a')
			.replace(/é/g, 'e')
			.replace(/ê/g, 'e')
			.replace(/è/g, 'e')
			.replace(/ë/g, 'e')
			.replace(/ï/g, 'i')
			.replace(/ô/g, 'o')
			.replace(/ù/g, 'u')
			.replace(/û/g, 'u');
	}

	getFilteredSalonsInArray(ids) {
		return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'getSalonsByIds', JSON.stringify({ ids }), this.queryOptions)
	}

	getSalonCAByPeriod(sid: string, startDate: number, endDate: number){
		
		const queryOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
            })
        };

        const postData = {
            salonId: sid ,
            startDate: startDate,
            endDate: endDate
        };


        const promise = new Promise((resolve, reject) => {

            var observable = this.httpClient.post<any>(dataConst.API_ENDPOINT + 'getSalonCAByPeriod', postData, queryOptions);

            observable.toPromise().then((res: any) => {
            
                let result: any[] = {...res.result};
                resolve(result)

            }, err => {
                // TODO console.log('erreur getSalonCAByPeriod : ', err);
                reject(err);
            })

        })

        return promise;
	}

	saveHistory(salonID){
	
	    let historical = {};

	    if(this.authService.isLoggedIn){

			historical = {
				salonID: salonID,
		      	dateTime: new Date(),
		      	ipAddress: "empty",
		      	userID: this.authService.userLoggedIn.uid,
		      	viewCount: 0
			};

	      	let userTesters = ["WNoMufYVlXg4FhG1OifOw8MKkUz2"];
	      	let test = false;
	      	let result = userTesters.filter(uid => {
	      		if(uid == this.authService.userLoggedIn.uid){
	      			test = true;
	      		}
	      	});
	      	
	      	if(test){
	      		return "";
	      	}

	    } else {

			historical = {
				salonID: salonID,
		      	dateTime: new Date(),
		      	ipAddress: localStorage.getItem("ipAdress"),
		      	userID: "empty",
		      	viewCount: 0
			};

	    }

		const queryOptions = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Content-Type': 'application/json'
			})
		};

		return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'salonsViewHistory', JSON.stringify({ ...historical }), queryOptions);

		//return this.salonViewFunction(historical);
	}


}
