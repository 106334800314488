import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

	constructor(private storage: AngularFireStorage) { }

	reUploadAllImages(){
		let storageRef = this.storage.ref("prestations/1611149840116");
		let promises = [];
		let paths = [];
		storageRef.listAll().subscribe( (res) => {
			res.items.forEach(async (ref) => {
				let path = ref.location.path;
				if(!path.includes("_480x480") && !path.includes("_240x240") && !path.includes("_120x120")){
					let url = await ref.getDownloadURL();
					
					let file = await fetch(url, {mode: 'no-cors'});
					this.toDataURL(url, (dataUrl) => {
					})
					// let fileRef = this.storage.ref(path+"_test");
					// fileRef.put(blob);

				}
			});
		})
	}

	toDataURL(url, callback) {
		let xhr = new XMLHttpRequest();
		xhr.onload = () => {
		  let reader = new FileReader();
		  reader.onloadend = () => {
			callback(reader.result);
		  }
		  reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
		xhr.responseType = 'blob';
		xhr.send();
	}

	uploadImage(path, imageURI){

		let pathWithoutExtension = path.split('.').slice(0, -1).join('.');

		let completePath = path;

		if(pathWithoutExtension.toString().trim() != ""){
			completePath = pathWithoutExtension+".jpeg";
		}else{
			completePath = path+".jpeg";
		}

	    return new Promise<any>((resolve, reject) => {
	    
	    //let storageRef = this.storage.ref(path);
			let storageRef = this.storage.ref(completePath);

			this.encodeImageUri(imageURI, function(image64){
			  storageRef.putString(image64, 'data_url')
			  .then(snapshot => {
				
				snapshot.ref.getDownloadURL().then(downloadURL=>{
					resolve(downloadURL);
				})

			  }, err => {
				reject(err);
			  })
			})
		})
	}

	encodeImageUri(imageUri, callback) {
	  var c = document.createElement('canvas');
	  var ctx = c.getContext("2d");
	  var img = new Image();
	  img.onload = function() {
	    var aux:any = this;
	    c.width = aux.width;
	    c.height = aux.height;
	    ctx.drawImage(img, 0, 0);
	    var dataURL = c.toDataURL("image/jpeg");
	    callback(dataURL);
	  };

	  img.src = imageUri;
	};

}
