import { Component, OnInit, Input , EventEmitter , Output } from '@angular/core';
import { Router } from '@angular/router';
import { TypePrestation } from 'src/app/models/defaultPrestation.model';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { SalonDetail } from 'src/app/models/salon.model';
import { PrestationService } from 'src/app/services/prestation.service';
import { shortDayLabel, AvailabilityState } from 'src/app/models/availability.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item-prestation',
  templateUrl: './item-prestation.component.html',
  styleUrls: ['./item-prestation.component.scss'],
})
export class ItemPrestationComponent implements OnInit {

	@Input() prestation: PrestationDetail;
	@Input() salon: SalonDetail;
    @Input() mode = "read";
	@Input() actionLink;
    @Output() whenSelectPrestation: EventEmitter<string> = new EventEmitter();
    @Output() whenDisablePromotion: EventEmitter<string> = new EventEmitter();
    @Input() loading: boolean;
    
    toggleClicked = false; 

	typesIds: TypePrestation[];
    isPromotion: boolean = false;

	constructor(
		private router: Router,
		private prestationService: PrestationService,
        public translate: TranslateService
		) { }

	ngOnInit() {

		if (!this.actionLink) {
			this.actionLink = '/reservation/' + this.prestation.sid + '/' + this.prestation.pid;
		}
		this.typesIds = [];
		if (this.prestation.typesIds) {
			this.prestation.typesIds.forEach(t => {
                this.prestationService.getTypeById(t).subscribe(result => {
                    this.typesIds.push(result.data());
                });
			});
		}

        let indexInterval = setInterval(()=>{

            if(this.salon){
                /*let dayIndexOnPromotion = new Date().getDay();

                if (dayIndexOnPromotion === 0)
                    dayIndexOnPromotion = 6;
                else
                    dayIndexOnPromotion -= 1;*/

                let arrayOfPromoAvailability = [];

                shortDayLabel.forEach((value, index) => {

                    const promotionDay: any = this.prestation.promotionDays[index];
  
                    let typeOfSalonAvailability = typeof ((this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available)

                    if ((typeOfSalonAvailability === "boolean" && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available) || (typeOfSalonAvailability !== "boolean" && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available.am && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available.pm)) {
                         arrayOfPromoAvailability.push(false);
                        //this.isPromotion = false;
                    } else {
                        const promotionDay: any = this.prestation.promotionDays[index];
                        if (promotionDay && promotionDay.enabled && !this.promotionDateExpired()) {
                            arrayOfPromoAvailability.push(this.prestation.promotion);
                            //this.isPromotion = this.prestation.promotion
                            
                        } else {
                            arrayOfPromoAvailability.push(false);
                            //this.isPromotion = false;
                        }
                    }

                });

                this.isPromotion = arrayOfPromoAvailability.includes(true);
                clearInterval(indexInterval);
                
            }
        } , 500)

            
	}


    get currentPrice(): number {
        let price = this.prestation.price;

        if (this.prestation.maxPromotion >= 0) {

            price -= (price * this.prestation.maxPromotion) / 100;

        }

        return price;
    }

	get hasPromo(): boolean {
		if (this.prestation.price != this.currentPrice)
			return true;

		return false;
	}

	seeDetail() {
		if (this.mode === 'read')
			this.router.navigate(['/prestation/'+ this.prestation.pid]);
	}

    handlePromotionToggle() {
        
        if(this.loading ||!this.toggleClicked)
            return;

        if(this.prestation.promotion){
            this.whenSelectPrestation.emit(this.prestation.pid);
        }
        else {
            this.whenSelectPrestation.emit(null);
            this.whenDisablePromotion.emit(this.prestation.pid);
        }

    }

    editPromotion() {
        if (this.prestation.promotion && !this.loading){
            this.whenSelectPrestation.emit(this.prestation.pid);
        }
    }

    onImgError(event, image){
        if(image._url){
          event.target.src = image._url;
        }else{
          event.target.src = 'assets/images/barbershop.jpg';
        }
    }

    promotionDateExpired(): boolean {

        let isExpired = false;
        let dateToCompare =  new Date()
        // dateToCompare.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())

        if (this.prestation.promotionStartDateEnabled) {

            let dateStart = new Date(this.prestation.promotionStartDate.toString());
            dateStart.setHours(0, 0, 0, 0)
            isExpired = dateToCompare < dateStart;
            if (isExpired) return isExpired;
        }

        if (this.prestation.promotionEndDateEnabled) {

            let dateFin = new Date(this.prestation.promotionEndDate.toString());
            dateFin.setHours(0, 0, 0, 0)

            isExpired = dateToCompare > dateFin;
            if (isExpired) return isExpired;
        }

        return isExpired;
    }

    get prestationLabel() {

        if(this.prestation.intLabel && this.prestation.intLabel[this.translate.currentLang] && this.prestation.intLabel[this.translate.currentLang] !== ""){
            return this.prestation.intLabel[this.translate.currentLang]
        }

        return this.prestation.name;
            
    } 

}
