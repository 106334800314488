import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Abonnement, OptionAbonnement, PromotionAbonnement } from '../models/abonnement.model';

@Injectable({
  providedIn: 'root'
})
export class AbonnementService {

	static COLLECTION_ABONNEMENT = 'abonnement';
	static COLLECTION_OPTION = 'abonnementOption';
	static COLLECTION_PROMOTION = 'abonnementPromotion';

  	constructor(private firestore: AngularFirestore) { }

  	getAllAbonnement() {
		return this.firestore.collection<Abonnement>(AbonnementService.COLLECTION_ABONNEMENT).valueChanges();
	}

	getAbonnementById(id) {
		return this.firestore.doc<Abonnement>(AbonnementService.COLLECTION_ABONNEMENT + '/' + id).valueChanges();
	}

	observeAbonnement(aid: string) {
		return this.firestore.collection(AbonnementService.COLLECTION_ABONNEMENT).doc(aid).snapshotChanges();
	}

	getAllPromotion() {
		return this.firestore.collection<PromotionAbonnement>(AbonnementService.COLLECTION_PROMOTION).valueChanges();
	}

	getPromotionById(id) {
		return this.firestore.doc<PromotionAbonnement>(AbonnementService.COLLECTION_PROMOTION + '/' + id).valueChanges();
	}

	observePromotion(promoid: string) {
		return this.firestore.collection(AbonnementService.COLLECTION_PROMOTION).doc(promoid).snapshotChanges();
	}

	getAllOption() {
		return this.firestore.collection<OptionAbonnement>(AbonnementService.COLLECTION_OPTION).valueChanges();
	}

	getOptionById(id) {
		return this.firestore.doc<OptionAbonnement>(AbonnementService.COLLECTION_OPTION + '/' + id).valueChanges();
	}

	observeOption(opid: string) {
		return this.firestore.collection(AbonnementService.COLLECTION_OPTION).doc(opid).snapshotChanges();
	}

	getOptionsByIds(opids: string[]) {
		return this.firestore.collection(AbonnementService.COLLECTION_OPTION).ref.where('opid', 'in', opids).get();
	}

	getPromotion(promoid: string){
  		return this.firestore.collection(AbonnementService.COLLECTION_PROMOTION).ref.where("promoid","==", promoid).get();
  	}

}
