import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { Category } from 'src/app/models/category.model';
import { Router } from '@angular/router';
import {TranslateService } from '@ngx-translate/core'
@Component({
  selector: 'app-categories-card',
  templateUrl: './categories-card.component.html',
  styleUrls: ['./categories-card.component.scss'],
})
export class CategoriesCardComponent implements OnInit {


  constructor(
    public filterService:FilterService, 
    private router: Router,
    public translate : TranslateService
  ) { }

  ngOnInit() {}

  selectCategory(c:Category, sub = false){
    if(!c.selected){
      c.selected = true;
    }
    if(c.selected){
      let categories=[];
      this.filterService.categories.forEach(category=>{
        if(category.value == c.value)
        {  
          category.selected = true;
          categories.push(category.value);
        }else{
          category.selected = false;
        }

      });
/*      if(this.filterService.searchType == undefined){
        if(this.filterService.tab == "salon"){
          this.filterService.setSearchType("salon");
        }else if(this.filterService.tab == "gallery"){
         this.filterService.setSearchType("prestation");
        }
      }*/
       
      this.filterService.filterBy["category"]=categories;

      if(c.value == "all"){
        this.filterService.refreshFilter();
      }
      this.filterService.loading = true;
      this.filterService.tagsAdded.next(true);
      this.router.navigate(['/home/salons']);
    }
    
 }

  viewAll(){
    this.router.navigate(['/home/salons']);
  }

}
