import { Component, Input, OnInit } from '@angular/core';
import { IChat } from 'src/app/models/chat-admin.model';
import { ReservationDetail } from 'src/app/models/reservation.model';
import { SalonDetail } from 'src/app/models/salon.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SalonService } from 'src/app/services/salon.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
})
export class ChatItemComponent implements OnInit {

  @Input() reservation: IChat;
  @Input() view = "client";
  userImage = 'assets/img/loader.gif';

  colorDate =  {
    "expired" : "danger", 
    "accomplished" : "success",
    "notConfirmed" : "warning",
    "upcoming" : "tertiary",
    "archived" : "dark",
    "canceled": "primary",
    "canceled_by_client": "primary",
    "canceled_by_salon": "primary",
    "edited": "quaternary"
  }

  constructor(private authService: AuthService, private salonService: SalonService, private userService: UserService,public translate: TranslateService) { }

  ngOnInit() {

    if (this.authService.userLoggedIn) {

      if (this.reservation.client) {
        
        if (this.authService.userLoggedIn.uid === this.reservation.client.uid) {
          this.salonService.getSalon(this.reservation.salon.sid).then(snapshot => {
            const salon = SalonDetail.salonDetailFactory(snapshot.data());
            if (salon.profilImageUrl)
              this.userImage = salon.profilImageUrl;
            else
              this.userImage = 'assets/img/profil_placeholder.png';
          });        
        } else if (this.authService.userLoggedIn.salonId  && this.authService.userLoggedIn.salonId === this.reservation.salon.sid) {
          this.userService.getUser(this.reservation.client.uid).then(snaspshot => {
            const user = User.userFactory(snaspshot.data());
            if (user.userImageUrl)
              this.userImage = user.userImageUrl;
            else
              this.userImage = 'assets/img/profil_placeholder.png';
          });
        }

      } else {
        this.userImage = 'assets/custom-ion-icons/askeed-icon.svg';
      }
      

    }

  }

  get chatLabel(): string {
    let label = "Admin Askeed";

    if (this.reservation.client) {

      if (this.authService.userLoggedIn && this.authService.userLoggedIn.uid == this.reservation.client.uid) {
          label = this.reservation.salon.name + " - " + this.prestationLabel;
      } else if (this.authService.userLoggedIn) {
          label = this.reservation.client.label + " - " + this.prestationLabel;
      }

    }

    return label;

  }

    get prestationLabel() {

        if (this.reservation.prestation.intLabel && this.reservation.prestation.intLabel[this.translate.currentLang]) {
            return this.reservation.prestation.intLabel[this.translate.currentLang]
        }

        return this.reservation.prestation.name

    }

  get lastMessage(): {user:{uid: string; label: string}, message: string, createdAt: any, read?: boolean} {
    let message = null;

    if (this.reservation) {

      message = this.reservation.discussion[this.reservation.discussion.length -1 ];

    }

    return message;
  }

  get messageNotReadCount(): number {
    let count = 0;

    if (this.authService.userLoggedIn && this.reservation) {

      this.reservation.discussion.forEach(disc => {

        if (disc.user.uid !== this.authService.userLoggedIn.uid && !disc.read) {
          count++;
        }

      });

    }
    
    return count;
  }

}
