import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {

	@Input() title = "";
	@Input() overrideBackBtn = false;
	@Output() backPressed = new EventEmitter();

	constructor(private navCtrl: NavController) { }

	ngOnInit() {}

	back() {
		if (this.overrideBackBtn) {
			this.backPressed.emit();
		} else {
			this.navCtrl.back();
		}
	}

}
