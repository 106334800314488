import { Component, OnInit, Input } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../common-input/value-accessor-base';

@Component({
  selector: 'app-common-textarea',
  templateUrl: './common-textarea.component.html',
  styleUrls: ['./common-textarea.component.scss', '../common-input/common-input.component.scss'],
  providers: [
  	{provide: NG_VALUE_ACCESSOR, useExisting: CommonTextareaComponent, multi: true}
  ]
})
export class CommonTextareaComponent extends ValueAccessorBase<string> implements OnInit {

	@Input() label;
	@Input() rows = 5;
	@Input() placeholder = "";
	@Input() autofocus = false;
	@Input() clearInput = false;
	@Input() color = "primary";
	@Input() disabled = false;
	@Input() separator = false;
	@Input() type = 1;
	@Input() name:string;
	@Input() readonly = false;
	errorMessage = "";

	constructor() {
		super();
	}

	ngOnInit() {}

}
