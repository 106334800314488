import { Input,Component, OnInit } from '@angular/core';
import { SalonDetail } from '../../../models/salon.model';
import { Address } from '../../../models/address.model';
import { IonSlides } from '@ionic/angular';
import { CitiesService } from 'src/app/services/cities.service';
import { FilterService } from 'src/app/services/filter.service';
import { CategoryService } from 'src/app/services/category.service';
import { RedirectionService } from 'src/app/services/redirection.service';

@Component({
  selector: 'app-map-salon-card',
  templateUrl: './map-salon-card.component.html',
  styleUrls: ['./map-salon-card.component.scss'],
})
export class MapSalonCardComponent implements OnInit {

	@Input() salonInfosTab:[{
	    salon: SalonDetail;
	    mappedCategories: string;
	    mainAddress: Address;
	    otherAddresses: Address[];
	    prestations: any[];
	    distanceFrom?: number;
	  }];
	
	salons: SalonDetail[];

  	sliderConfig1 = {
	    spaceBetween: 0,
	    slidesPerView:1.1,
	    onInit: (slides: IonSlides) => { 
	      this.swiper = slides; 
	    }
  	}; 
  	sliderConfig2 = {
	    spaceBetween: 0,
	    slidesPerView:1,
	    onInit: (slides: IonSlides) => { 
	      this.swiper = slides; 
	    }
  	}; 
  	swiper: IonSlides;

  constructor(
	public citiesService : CitiesService,
	public filterService : FilterService,
	public categoryService : CategoryService,
	private redirectionService: RedirectionService
  ) { 

  }

  ngOnInit() {
	  this.salons = [];
	  this.salonInfosTab.forEach((si) => {
		  this.salons.push(si.salon);
	  });
  }

  goToSalon(sid) {
    this.redirectionService.redirectTo(`/profil-salon/${sid}`, true);
  }


}
