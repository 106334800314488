import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { firebaseConfig } from './credentials';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { CalendarModule } from 'ion2-calendar';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { File } from '@ionic-native/file/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { AutoCompleteModule } from 'ionic4-auto-complete';

import { SharedModule } from './shared/shared.module';
import { IonicStorageModule } from '@ionic/storage';
// import { DpDatePickerModule } from 'ng2-date-picker';


import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFr from '@angular/common/locales/fr';

import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import * as $ from 'jquery';
// import { DpDatePickerModule } from 'ng2-date-picker';
// import { ChartsModule } from 'ng2-charts';
// import { DataTablesModule } from 'angular-datatables';
import { ImagePicker } from '@ionic-native/image-picker/ngx';

import { WebView } from '@ionic-native/ionic-webview/ngx';

import { HTTP } from '@ionic-native/http/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';

import { LoginPageModule } from 'src/app/pages/login/login.module';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Market } from '@ionic-native/market/ngx';
import { FirestoreTransLoader } from './shared/firestore-trans-loader';
import { MetafrenzyModule } from 'ngx-metafrenzy';
import { ImagesLazyloadModule }  from './shared/directives/images-lazyload/images-lazyload.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VariablesGlobales } from './shared/variables-globales';


registerLocaleData(localeFr);

// import { PdfViewerModule } from 'ng2-pdf-viewer';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function FirestoreTranslationsLoaderFactory(db: AngularFirestore) {
  return new FirestoreTransLoader(db);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    // DpDatePickerModule,
  	BrowserModule, IonicModule.forRoot(), AppRoutingModule,
  	AngularFireModule.initializeApp(firebaseConfig),
  	AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: (createTranslateLoader),
        useFactory: FirestoreTranslationsLoaderFactory,
        // deps: [HttpClient]
        deps: [AngularFirestore]
      }
    }),
    // CalendarModule,
    // LeafletModule,
    SharedModule,
    BrowserAnimationsModule,
    // AutoCompleteModule,
    IonicStorageModule.forRoot({
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    // NgxIntlTelInputModule,
    TooltipModule,
    // DpDatePickerModule,
    // ChartsModule,
    // DataTablesModule,
    LoginPageModule,
    MetafrenzyModule.forRoot(),
    ImagesLazyloadModule,
    NgbModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFireAuthGuard,
    HttpClient,
    File,
    Geolocation,
    LocalNotifications,
    BsDropdownConfig,
    ImagePicker,
    WebView,
    HTTP,
    SignInWithApple,
    AppVersion,
    InAppBrowser,
    Market,
    VariablesGlobales
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
