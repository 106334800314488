import { Address } from './address.model';
import { Notification } from './notification.model';
import { Deserializable } from './deserializable.model';

export class User implements Deserializable {
    uid: string;
    createdAt: Date;
    updatedAt: Date;
    firstname: string;
    name: string;
    phoneNumber: string;
    email: string;
    address: Address[];
    userImageUrl: string;
    isVerified: boolean;
    gender: Gender;
    birthDate: Date;
    salonId: string;
    accountProvider: AccountProvider;
    salonsLiked: string[];// sids salon
    imagesLiked: { sid: string, index: number; url: string }[];
    status: AccountStatus;
    alertCount: number;
    salonAlertCount: number;
    prestationsFavoris: string[] = [];
    notification ?: Notification[];
    
    referralId?: string;
    referralCommercialId?: string;
    accessToken?: string;
    language?: string;
    
    constructor(uid = null) {
        this.uid = uid;
        // this.gender = Gender.MALE;
        this.status = AccountStatus.STANDARD;
        this.language = "fr";
    }

    public static userFactory(object): User {
        const user = new User();

        for (let key in object) {
            if (object[key])
                user[key] = object[key];
        }

        return user;
    }

    getImagePath(filePath = '') {
        return 'users/' + this.uid + '/' + filePath;
    }

    getProfilImagePath(fileName) {
        return this.getImagePath('profil/' + fileName);
    }

    likeSalon(sid) {

        if (!this.salonsLiked)
            this.salonsLiked = [];

        if (this.salonsLiked.includes(sid)) {
            this.salonsLiked = this.salonsLiked.filter(id => id != sid);
        } else {
            this.salonsLiked.push(sid);
        }

    }

    likeImage(sid, index, url, isLike) {

        if (!this.imagesLiked)
            this.imagesLiked = [];

        if (isLike) {
            this.imagesLiked.push({ sid, index, url });
        } else {
            this.imagesLiked = this.imagesLiked.filter(obj => obj.sid != sid && obj.index != index && obj.url != url);
        }

    }

    addPrestationFavoris(prestationId, isFavoris) {
        if (isFavoris) {
            this.prestationsFavoris.push(prestationId);
        } else {
            this.prestationsFavoris = this.prestationsFavoris.filter((pid) => pid !== prestationId);
        }
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    get mainAddress(): Address {
        let result: Address = null;

        if (this.address)

            for (let addr of this.address) {
                if (addr.isMain) {
                    result = addr;
                    break;
                }
            }

        return result;
    }
	
	get genderLabel(): string{
        return this.gender == Gender.MALE ? "Homme" : "Femme";
    }

}

export enum Gender {
    MALE = 'homme',
    FEMALE = 'femme'
}

export enum AccountProvider {
    EMAIL = 'email',
    GOOGLE = 'google',
    FACEBOOK = 'facebook',
    APPLE = 'apple',
}

export enum AccountStatus {
    STANDARD = 'standard',
    REPORTED = 'reported',
    DEACTIVATED = 'deactivated',
    BLOCKED = 'blocked',
}
