import { Pipe, PipeTransform, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name:'duration',
    pure:false
})
export class DurationPipe implements PipeTransform, OnDestroy {
    private timer: number;
    constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}
    
    transform(value:number) {

        if (value < 60) {
            return value + "min";
        } else {
            const splitHour = Math.floor(value / 60);
            const restMin = value % 60;
            return splitHour + "h" + (restMin < 10 ? "0" : "") + restMin;
        }
   
    }
    
    ngOnDestroy(): void {

    }

}