import { Deserializable } from "./deserializable.model";

export class Config {
	allTax: { 
		tva : { de : number , ch : number , fr : number } ,
	}; 
	bankInfo : string ;
	footer : { de : string , en : string , fr : string };
	header: {
		subtitle : string,
		title : string
	}; 
	tax: number;
	remark: { de : string , en : string , fr : string };


    constructor() {
        this.allTax = { tva : { de : 0 , ch : 0 , fr : 0 } } ;
        this.bankInfo= "";
        this.header= {
            subtitle: "",
                title : ""
        };
        this.tax= 0;

    }

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	} 
}