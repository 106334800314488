import { Injectable, Input, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from 'src/app/services/filter.service';
import { SalonService } from 'src/app/services/salon.service';
import { PrestationService } from 'src/app/services/prestation.service';
import { ModalController, IonSlides, LoadingController } from '@ionic/angular';
import { SearchModalPage } from "src/app/pages/home/search-modal/search-modal.page";
import { Router } from '@angular/router';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-header-accueil',
  templateUrl: './header-accueil.component.html',
  styleUrls: ['./header-accueil.component.scss'],
})

@Injectable({
  providedIn: 'root'
})

export class HeaderAccueilComponent implements OnInit {

  @Input() isSalon:boolean;
  @Input() isGallerie:boolean;

	result:boolean;

  constructor(private translate:TranslateService,
  			  public filterService: FilterService,
          public salonService: SalonService,
          public prestationService: PrestationService,
          public modalController: ModalController,
          private router: Router,
          public tagService:TagService,
          ) { 
  }

  ngOnInit() {
  }

  async addTags(){
    const modal = await this.modalController.create({
      component: SearchModalPage
    });
    
    modal.onDidDismiss().then(() =>{
      this.result = true;
    })
    return await modal.present();
  }


/*  onClickRecherchePrestation(){
    if(this.isSalon && !this.isGallerie)
     this.prestationService.onChangeTypeCard(1);
    else if(this.isGallerie && ! this.isSalon)
     this.prestationService.onChangeTypeCard(2);
  }*/

  searchPrestations(){
      this.router.navigate(['/home/galleries']);
  }

  searchSalons(){
    this.router.navigate(['/home/salons']);
  }
}
