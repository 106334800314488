import { Component, OnInit , Input, Output} from '@angular/core';
import { StringHelperService } from 'src/app/shared/helpers/string-helper.service';
import { ModalController , ToastController , LoadingController, AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mail-request-modal',
  templateUrl: './mail-request-modal.component.html',
  styleUrls: ['./mail-request-modal.component.scss'],
})
export class MailRequestModalComponent implements OnInit {

  email : string;
  error : string;

  constructor(
     private stringHelperService: StringHelperService,
     private modalController: ModalController,
     public translate: TranslateService
    ) { }

  ngOnInit() {}



  save(){
    this.error = "";
    if (!this.stringHelperService.emailIsValid(this.email)) {
      this.error = "Veuillez saisir une adresse email valide";
    }else{
      this.modalController.dismiss(this.email);
    }
  }

}
