import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cgu } from '../models/cgu.model';
import { DefaultPrestation, TypePrestation } from '../models/defaultPrestation.model';
@Injectable({
  providedIn: 'root'
})
export class CguService {

  constructor(private firestore: AngularFirestore,) { }
	static COLLECTION_NAME = 'cgu';

  getTexte() {

    return this.firestore.collection<Cgu>(CguService.COLLECTION_NAME).get();

	}

  
	get(uid: string) {
		return this.firestore.collection(CguService.COLLECTION_NAME).doc(uid).ref.get();
	}

}
