
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Tag, TagsCategory } from '../models/category.model';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import  * as _ from 'lodash';
import {AutoCompleteService} from 'ionic4-auto-complete';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class TagService implements AutoCompleteService {

    tags:Tag[] = [];
    tagsCategory:TagsCategory[] = [];
    searchBarInput:string;

    labelAttribute = 'tagName';
    static COLLECTION_NAME = 'tags';
  	
    constructor(private firestore: AngularFirestore,
      private filterService:FilterService
      ) {}
    
    getCategories():Observable<any[]>{
     
      return this.getAllTags().pipe(
        map(
          (result) => {
            return result.sort((a, b) =>{
              return b.score - a.score;
            }).reduce((previousVal  , currentValue, currentIndex) =>{
              
              if(!previousVal.find((val) => val.category.toLowerCase() == currentValue.category.toLowerCase())){
                let tagsCategory:TagsCategory = new TagsCategory();
                tagsCategory.tags.push(currentValue);
                tagsCategory.category = currentValue.category;
                previousVal.push(tagsCategory);
              }else{
                let tagsCategory = previousVal.find((val) => val.category.toLowerCase() == currentValue.category.toLowerCase());
                tagsCategory.tags.push(currentValue);
              }
              
              return previousVal;
            }, new Array<TagsCategory>()).map((tagsCategory) =>{
                tagsCategory.tags.sort((a, b) => b.score - a.score);
                return tagsCategory;
            });
          }
        )
    );
      
    }

    getResults(keyword:string):Observable<Tag[]> {
      let observable:Observable<Tag[]>;

      if (this.tags.length === 0) {
        observable = this.getAllTags();
      } else {
        observable = of(this.tags);
      }
      const key="tagName";
      let pipe= observable.pipe(
          map(
            (result) => {
              let rr=result.filter(
                (item) => {
                  return item.tagName.toLowerCase().startsWith(
                      keyword.toLowerCase()
                  ) && !this.filterService.tags.includes(item.tagName.toLowerCase());
                }
              ).sort((a, b) =>{
                return b.score - a.score;
              });
             
              rr=_.uniqBy(rr, 'tagName');
              return rr;
            }
          )
      );
      return pipe;
    }

  	getAllTags(){
  		return this.firestore.collection<Tag>(TagService.COLLECTION_NAME).valueChanges();
  	}
  	getTag(categoryName:string,tagName:string){
  		return this.firestore.collection(TagService.COLLECTION_NAME).doc(categoryName+tagName).ref.get();
  	}
  	deleteTag(categoryName:string,tagName:string){
  		this.firestore.doc(TagService.COLLECTION_NAME + '/' + categoryName+tagName).delete();
  	}
  	createTag(tag:Tag){
  		return this.firestore.collection(TagService.COLLECTION_NAME).doc(tag.category+tag.tagName).set({...tag});
  	}
  	updateScores(){
  		// miantso cloud function manao update ny score ao anatiny
  	}
}

