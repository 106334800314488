import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { Category } from 'src/app/models/category.model';
import { CategoryPrestation } from 'src/app/models/defaultPrestation.model';
import { TranslateService } from "@ngx-translate/core"
@Component({
  selector: 'app-tabs-home',
  templateUrl: './tabs-home.component.html',
  styleUrls: ['./tabs-home.component.scss'],
})
export class TabsHomeComponent implements OnInit {

  constructor(public filterService:FilterService , public translate: TranslateService) { }

  ngOnInit() {
  }

  categoriesStyle(cat: CategoryPrestation){
  	let style;
    if(!this.filterService.categories.find((e) => e.selected)){
      if(cat.value === 'all'){
        cat.selected = true;
      }
    }
  	if(cat.selected){
      style = {
        'border-bottom':'solid 2px var(--ion-color-primary)',
        'color': 'var(--ion-color-primary)',
        'transition':'0.5s',
        'background-color' : 'white'
      }
    } else {
      style = {
        'border-bottom':'solid 2px #d4d0cb',
         'color': 'var(--ion-tab-bar-color)',
        'transition':'0.5s'
      }
    }
    return style;
  }

  selectCategory(c:CategoryPrestation, sub = false){
    
    if(!c.selected){

      c.selected = true;

    }
    if(c.selected){
      let categories=[];
      this.filterService.categories.forEach(category => {
        if (category.cpid == c.cpid) {
          categories.push(category.cpid);
        } else {
          category.selected = false;
        }

      });
      this.filterService.filterBy['category']=categories;
      if(c.value == 'all'){
        if(this.filterService.filterBy['category']) {
          this.filterService.removeFilter('category');
        }
      }
      this.filterService.loading = true;
      this.filterService.tagsAdded.next(true);
    }
 }

}
