import { Injectable } from '@angular/core';
import { DataService, dataKey } from '../shared/helpers/data.service';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RecapitulatifGuardService implements CanActivate {

	constructor(private dataService: DataService, private router: Router) { }

	canActivate() {

		if (this.dataService.dataExist(dataKey.RESERVATION_TEMP)) {
			return true;
		}

		this.router.navigate(['home']);

		return false;
	}

}

