import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { Image } from 'src/app/models/image.model';
import { SalonDetail } from '../../../models/salon.model';
import { User } from '../../../models/user.model';
import { AuthService } from '../../../services/auth.service';
import { SalonService } from '../../../services/salon.service';
import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-img-viewer',
  templateUrl: './img-viewer.component.html',
  styleUrls: ['./img-viewer.component.scss'],
})
export class ImgViewerComponent implements OnInit {

  @Input() src: Image;
  @Input() family: Image[];
  @Input() globalTitle: string;
  @Input() salon: SalonDetail;

  familyGroup: Image[][];
  currentImage: Image;
  showList = true;

  @ViewChild('slides', {static: false}) slides: IonSlides;

  sliderConfig = {
    spaceBetween: 0,
    slidesPerView:1
  };
  
  user: User;

  constructor(
    private modalController: ModalController, 
    private authService: AuthService,
    public salonService: SalonService, 
    private userService: UserService
  ) { }

  ngOnInit() {

    this.user = User.userFactory(this.authService.userLoggedIn);

    this.currentImage = this.src;

    this.reorder();

    this.buildFamilyGroup();

  }

  reorder() {
    if (this.family) {
      
      this.family = this.family.filter(img => img.url !== this.src.url);

      this.family = [this.src].concat(this.family);

    } else {
      this.family = [this.src];
    }
  }

  buildFamilyGroup() {
    
    if (this.family) {

      this.familyGroup = [];

      for (let i = 0; i < this.family.length; i++) {
        
        if (i%4 === 0) {
          this.familyGroup.push([]);
        }

        this.familyGroup[Math.floor(i/4)].push(this.family[i]);

      }

    }

  }

  close() {
    this.modalController.dismiss();
  }

  next(slide:IonSlides){
    slide.slideNext();
  }

  prev(slide:IonSlides){
    slide.slidePrev();
  }

  onSlideChange(event) {
		this.slides.getActiveIndex().then(index => {
      this.currentImage = this.family[index];
		});
  }
  
  slideTo(img: Image) {
    this.slides.slideTo(this.family.indexOf(img));
  }

  handlePinchZoomEvents(event) {
  }

  likeIt() {

    this.salon.likeIt(this.user.uid);

    this.salonService.updateSalon(this.salon);

    this.user.likeSalon(this.salon.sid);

    this.userService.updateUser(this.user);

  }

}
