import { Component, OnInit, Input, Output, SimpleChanges, SimpleChange, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../common-input/value-accessor-base';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: RatingComponent, multi: true}
  ]
})
export class RatingComponent extends ValueAccessorBase<number> implements OnInit {

  @Input() rate: number = 0;
  @Input() readonly: boolean = true;
  @Input() size: "small" | "large" | "very-small" = "small";
  @Input() color:string = "warning";
  @Output() rateChange: EventEmitter<number> = new EventEmitter();

  filled: number[] = [1, 2, 3, 4, 5];
  outline: number[];

  constructor() {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {

    const currentItem: SimpleChange = changes.rate;
    if (currentItem.currentValue || currentItem.currentValue == 0) {
      this.updateRate(currentItem.currentValue);
    }
  }

  selectRate(rate) {

    if (!this.readonly) {
      this.rate = rate;
      this.updateRate(rate);
      this.rateChange.emit(rate);
    }
  }

  updateRate(rate) {

    rate = Math.round(rate);

    if(rate >5){
      rate = 5;
    }
    this.filled = [];
    this.outline = [];
    for (let index = 0; index < rate; index++) {
      this.filled.push(index + 1);
    }

    for (let index = 5 - rate; index > 0; index--) {
      this.outline.push(5 - index +1);
    }

    this.value = rate;

  }

  starSize(){
    let res = {
      "font-size":"15px"
    }
    if(this.size == "large"){
      res = {
        "font-size":"28px"
      }
    }
    if(this.size == "very-small"){
      res = {
        "font-size":"10px"
      }
    }
    return res;
  }
}
