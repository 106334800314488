import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { Image } from '../../../models/image.model';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {

	@Input() comment: { uid: string; createdAt: any; message: string; replies?: { uid: string; createdAt: any; message: string }[] };
	@Input() image: Image;
	@Input() prestation: PrestationDetail;
	@Input() commentIndex = 0;
	user: User;
	currentuser: User;
	replies: { user: User; createdAt: any, message: string }[];
	@Output() onCommentReplyPublished: EventEmitter<any> = new EventEmitter();

	constructor(private userService: UserService, public authService: AuthService) { }

	ngOnInit() {
		this.userService.getUser(this.comment.uid).then(documentData => {
			this.user = documentData.data() as User;
		});

		this.getReplies();
	}

	getReplies() {

		if (this.comment.replies) {

			this.replies = this.comment.replies.map(reply => {
				return { user: new User(reply.uid), createdAt: reply.createdAt, message: reply.message };
			});
			
			this.comment.replies.forEach( (reply, index) => {

				this.userService.getUser(reply.uid).then(documentData => {
					this.replies[index].user = documentData.data() as User;
				});

			});

		}

	}

	onReplyPublished(event) {
		this.onCommentReplyPublished.emit(event);
		this.getReplies();
	}

}
