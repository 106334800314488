import { Component, Input, OnInit } from '@angular/core';
import { Notification, NotificationStatus, NotificationType, NotificationSender } from 'src/app/models/notification.model';
import { NotificationService } from 'src/app/services/notification.service';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {

    @Input() notification: Notification;
    notificationStatus = NotificationStatus; 
    user : User;
    //isSalon : boolean;
    source: string;
    notificationSender = NotificationSender;
    titreNotification:string;
    message:string;
    textAction:string;
    constructor(public translate: TranslateService, 
        public authService: AuthService, 
        public notificationService: NotificationService,
        private router: Router,
        private loadingController: LoadingController,
    ) {

        // this.notificationList = [];

    }

    ngOnInit() {
        if (this.authService.isLoggedIn) {
            this.user = User.userFactory(this.authService.userLoggedIn);
        }
        let idInt = setInterval(()=>{
            if (this.notification) {

                switch(this.user.language){
                    
                    case "fr":
                        this.titreNotification = this.notification.intTitre && this.notification.intTitre.fr ? this.notification.intTitre.fr :  this.notification.titre;
                        this.message = this.notification.intMessage && this.notification.intMessage.fr ? this.notification.intMessage.fr :  this.notification.message;
                        this.textAction = this.notification.intTextAction && this.notification.intTextAction.fr ? this.notification.intTextAction.fr :  this.notification.textAction;
                        break;
                    
                    case "en":
                        this.titreNotification = this.notification.intTitre && this.notification.intTitre.en ? this.notification.intTitre.en :  this.notification.titre;
                        this.message = this.notification.intMessage && this.notification.intMessage.en ? this.notification.intMessage.en :  this.notification.message;
                        this.textAction = this.notification.intTextAction && this.notification.intTextAction.en ? this.notification.intTextAction.en :  this.notification.textAction;
                        break;
                    
                    case "de":
                        this.titreNotification = this.notification.intTitre && this.notification.intTitre.de ? this.notification.intTitre.de :  this.notification.titre;
                        this.message = this.notification.intMessage && this.notification.intMessage.de ? this.notification.intMessage.de :  this.notification.message;
                        this.textAction = this.notification.intTextAction && this.notification.intTextAction.de ? this.notification.intTextAction.de :  this.notification.textAction;
                        break;
                    
                    default:
                        this.titreNotification = this.notification.titre;
                        this.message = this.notification.message;
                        this.textAction = this.notification.textAction;
                        break;
                        
                }
                //this.isSalon = this.notification.by && this.notification.by == NotificationSender.SALON ? true : false;
                this.source = this.notification.by;
                clearInterval(idInt);
            }
        } , 200)
    }

    closeNotification(){
        this.notification.isClosed = true;
        this.notificationService.closeNotification(this.user, this.notification);
    }

    async actionOnNotification(){

        // return; 
        let nid = this.notification.nid || null;
        this.notification.isClosed = true;
        const loading = await this.loadingController.create({
            message: this.translate.instant('Chargement...'),
            cssClass: 'askeed-spinner'
        });

        await loading.present();

        this.notificationService.closeNotification(this.user, this.notification).then(success =>{
            loading.dismiss();
            if (this.notification.type == NotificationType.RESERVATION_ANNULEE )
                this.router.navigate(['/reservation-notification-action/canceled-reservation-action', { nid: this.notification.nid }]);
            else if (this.notification.type == NotificationType.RESERVATION_TERMINEE && this.source === NotificationSender.SALON)
                this.router.navigate(['/reservation-notification-action/accomplished-reservation-action', { nid: this.notification.nid }]);
            else if (this.notification.type == NotificationType.RESERVATION_FEEDBACK && this.source === NotificationSender.ASKEED)
                this.router.navigate(['/reservation-notification-action/accomplished-reservation-action', { nid: this.notification.nid }]);
        })    
           
    }

}
