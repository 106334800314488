import { Component, OnInit, Input, ViewChild , ChangeDetectorRef, ViewRef } from '@angular/core';
import { Map, MapOptions, tileLayer, latLng, Marker, Icon } from "leaflet";

import { Platform, ToastController, NavController, IonSlides, ModalController } from '@ionic/angular';

import { SalonDetail } from '../../../models/salon.model';
import { Address } from '../../../models/address.model';
import { User } from '../../../models/user.model';

import { DataService, dataKey, dataConst } from '../../../shared/helpers/data.service';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';

// import { Deeplinks } from '@ionic-native/deeplinks/ngx';

import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth.service';
import { SalonService } from '../../../services/salon.service';
import { UserService } from '../../../services/user.service';

import { Image } from '../../../models/image.model';


import { shortDayLabel } from '../../../models/availability.model';

import { ImgViewerComponent } from '../../../shared/components/img-viewer/img-viewer.component';
import { TypePrestation } from 'src/app/models/defaultPrestation.model';
import { PrestationService } from 'src/app/services/prestation.service';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { GalleryPage } from 'src/app/pages/gallery/gallery.page';
import { RedirectionService } from 'src/app/services/redirection.service';

import { ShareLinkComponent } from 'src/app/shared/components/share-link/share-link.component';
import { PopoverController } from '@ionic/angular';
import { IonSelect } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-salon-info',
  templateUrl: './salon-info.component.html',
  styleUrls: ['./salon-info.component.scss'],
  // providers: [SocialSharing, Deeplinks]
  providers: [SocialSharing]
})

export class SalonInfoComponent implements OnInit {

  @Input() salonInfoOpen: boolean = true;
  @Input() allInfo: boolean = false;
  @Input() salonInfos: {
    salon: SalonDetail;
    mappedCategories: string;
    mainAddress: Address;
    otherAddresses: Address[];
    prestations: any[];
    distanceFrom?: number;
  };
  salonPrestationsTypes: { type: TypePrestation; occurence: number }[];
  @Input() loadingPrestations = false;

  allGallery: boolean = false;
  options: MapOptions = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 20, attribution: '...' })
    ],
    dragging: false,
    zoom: 16,
  };

  // sliderConfig = {
  //   spaceBetween: 0,
  //   slidesPerView:1,
  //   pagination: false,
  //   scrollbar: false
  // };


  user: User;
  imagesToShowCount = dataConst.DEFAULT_GALLERIE_COUNT;

  shortDayLabel = shortDayLabel;

  @Input() loading = false;

  sliderConfig = {
    spaceBetween: 0,
    slidesPerView: 1,
    onInit: (slides: IonSlides) => {
      this.swiper = slides;
    }
  };

  swiper: IonSlides;

  bio : string;
  bioSubstring : string;
  showBio : boolean = false;
  openSortSelection = false;
  bioExtend : boolean = false;

  @ViewChild('slides', { static: false }) slides: IonSlides;
  numberOfTypesToShow = 3;


  prestationSortList = [
      { id: 1, value: 1,label: this.translate.instant("Prix croissant") },
      {id : 2,  value : 2, label : this.translate.instant("Prix decroissant")},
      {id : 3 , value : 3 , label : this.translate.instant("Popularité")},
      
  ]
    sortApplied = this.prestationSortList[0];

  sortedTypeprestationApplied: string = '1';
  sortedPrestationAppliedLabel: string = this.translate.instant('Prix decroissant');

  @ViewChild(IonSelect, { static: false }) sortListSelect: IonSelect;

  shareLoading = false;

  salonSocialMediaLink: string;

  constructor(public cdrf: ChangeDetectorRef, public translate: TranslateService, private popoverController: PopoverController, public dataService: DataService, private socialSharing: SocialSharing, private platform: Platform, private toastController: ToastController, private modalController: ModalController, private router: Router, private authService: AuthService, public salonService: SalonService, private userService: UserService, private prestationService: PrestationService, private redirectionService: RedirectionService) {


  }

  ngOnInit() {
    this.user = User.userFactory(this.authService.userLoggedIn);
    this.options.center = latLng(this.salonInfos.mainAddress.latLng.lat, this.salonInfos.mainAddress.latLng.lng);
    // this.salonInfos.salon.bio = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    if (this.salonInfos.salon.bio) {
      this.bioExtend = this.salonInfos.salon.bio && this.salonInfos.salon.bio.replace(/(<[a-zA-Z\/][^<>]*>|\[([^\]]+)\])|(\s+)/ig,'').length > 150;
      this.bio = this.salonInfos.salon.bio;
      this.bioSubstring = this.salonInfos.salon.bio.length > 150 ? this.salonInfos.salon.bio.substring(0, 150) + ' ...' : this.salonInfos.salon.bio;
    } else {
      this.bio = 'N/A';
    }
    this.salonInfos.salon = new SalonDetail().deserialize(this.salonInfos.salon);

    setTimeout(() => {
      if (this.cdrf !== null && this.cdrf !== undefined &&
        !(this.cdrf as ViewRef).destroyed) {
        this.cdrf.detectChanges();
      }
    });

    const baseUrl = 'https://app.askeed.ch';
    this.salonSocialMediaLink = baseUrl + '/profil-salon/' + this.salonInfos.salon.sid;


    if (this.salonInfos.salon.salonGalleries && this.salonInfos.salon.salonGalleries.length <= dataConst.DEFAULT_GALLERIE_COUNT) {
      this.showAllGallery();
    }
    // this.salonInfos.salon.targets=[];
    // Object.getOwnPropertyNames( salonInfos.salon.targetsPrestation).forEach(t=>{
    //   if( t=="both" && salonInfos.salon.targetsPrestation[t]){
    //     salonInfos.salon.targets.push("Femme");
    //     salonInfos.salon.targets.push("Homme");
    //   }else{
    //      if( t=="women" && salonInfos.salon.targetsPrestation[t]){
    //        salonInfos.salon.targets.push("Femme");
    //      }
    //      if( t=="men" && salonInfos.salon.targetsPrestation[t]){
    //        salonInfos.salon.targets.push("Homme");
    //      }
    //   }
    // });

    this.prestationService.listTypes().subscribe(values => {

      const allTypesPrestation: TypePrestation[] = [];

      values.forEach(document => {
        allTypesPrestation.push(new TypePrestation().deserialize(document));
      })

      this.setSalonPrestationsTypes(allTypesPrestation);

    });
    
  }

  showMoreBio() {
    this.showBio = !this.showBio;
  }

  setSalonPrestationsTypes(allTypesPrestation: TypePrestation[]) {

    this.salonPrestationsTypes = [];

    if (!this.salonInfos.prestations)
      return;

    this.salonInfos.prestations.forEach(data => {

      const prestation = new PrestationDetail().deserialize(data);

      if (prestation.typesIds) {

        prestation.typesIds.forEach(tpid => {

          if (!this.salonPrestationsTypes.map(data => data.type.tpid).includes(tpid)) {
            this.salonPrestationsTypes.push({
              type: allTypesPrestation.find(type => type.tpid === tpid),
              occurence: 0
            });
          } else {
            const typeSaved = this.salonPrestationsTypes.find(data => data.type.tpid === tpid);

            typeSaved.occurence++;
          }

        });

        this.salonPrestationsTypes = this.salonPrestationsTypes.sort((t1, t2) => {
          return t1.occurence > t2.occurence ? -1 : 1;
        });

      }

    });

    this.sortPrestations(this.sortApplied.value);

  }

  onSlideProfilInit() {
    if (this.salonInfos.salon.salonPresentations)
      this.slides.slideTo(this.salonInfos.salon.salonPresentations.map(presentation => presentation.url).indexOf(this.salonInfos.salon.profilImageUrl));
  }

  showAllGallery(showMore = true) {

    if (showMore) {
      this.allGallery = true;
      this.imagesToShowCount += dataConst.DEFAULT_GALLERIE_COUNT;
    } else {
      this.allGallery = false;
      this.imagesToShowCount = dataConst.DEFAULT_GALLERIE_COUNT;
    }

  }

  gallerySyle() {
    let res = {};
    if (!this.allGallery) {
      res = {
        height: '206px',
        overflow: 'hidden'
      }
    }
    return res;
  }

  /*  closeSalonInfo(){
      this.salonInfoOpen = false;
      this.map.selectedSalon = undefined;
      this.map.selectSalon(this.map.mapInstance,e);
    }*/

  onMapReady(map: Map) {
    setTimeout(() => {
      map.invalidateSize();
      let marker: Marker = new Marker(latLng(this.salonInfos.mainAddress.latLng.lat, this.salonInfos.mainAddress.latLng.lng));
      let icon: Icon = new Icon({
        // iconSize: [ 25, 41 ],
        iconSize: [28, 40],
        iconAnchor: [20.8, 54],
        iconUrl: 'assets/custom-ion-icons/marker.png',
      });
      marker.setIcon(icon);
      marker.addTo(map);
    }, 3000);
  }

  shareProfil(socialNetwork) {

    this.platform.ready().then(() => {

      switch (socialNetwork) {
        case 1:

          this.socialSharing.canShareVia('facebook').then(response => {


            this.socialSharing.shareViaFacebook(this.salonInfos.salon.resume, this.salonInfos.salon.profilImageUrl).then(() => {
            }, error => {
              this.presentToast(this.translate.instant('Une erreur est survenue. Etes vous sur d\'avoir l\'application Facebook'));
            });

          }).catch(error => {
            this.presentToast(this.translate.instant('Une erreur est survenue. Etes vous sur d\'avoir l\'application Facebook'));
          });

          break;

        case 2:

          this.socialSharing.canShareVia('instagram').then(response => {


            this.socialSharing.shareViaInstagram(this.salonInfos.salon.resume, this.salonInfos.salon.profilImageUrl).then(() => {
            }, error => {
              this.presentToast(this.translate.instant('Une erreur est survenue. Etes vous sur d\'avoir l\'application Instagam'));
            });

          }).catch(error => {
            this.presentToast(this.translate.instant('Une erreur est survenue. Etes vous sur d\'avoir l\'application Instagam'));
          });

          break;

        case 3:

          this.socialSharing.canShareVia('twitter').then(response => {


            this.socialSharing.shareViaTwitter(this.salonInfos.salon.resume, this.salonInfos.salon.profilImageUrl).then(() => {
            }, error => {
              this.presentToast(this.translate.instant('Une erreur est survenue. Etes vous sur d\'avoir l\'application Twitter'));
            });

          }).catch(error => {
            this.presentToast(this.translate.instant('Une erreur est survenue. Etes vous sur d\'avoir l\'application Twitter'));
          });

          break;

        default:
          // code...
          break;
      }

    });

  }

  presentToast(msg: string) {
    this.toastController.create({
      message: msg,
      duration: 3000
    }).then(toast => toast.present());
  }

  seeGallerieDetails(index) {

    this.dataService.saveAppData(dataKey.DETAIL_SALON_CLIENT, this.salonInfos.salon);

    this.router.navigate(['/gallery/' + index]);

  }

  likeIt() {

    this.salonInfos.salon.likeIt(this.user.uid);

    this.salonService.updateSalon(this.salonInfos.salon);

    this.user.likeSalon(this.salonInfos.salon.sid);

    this.userService.updateUser(this.user);

  }

  get priceInterval(): { start: number; end: number; unit: string } {
    let result = null;

    let start = -1;
    let end = -1;
    let unit = 'CHF';

    if (this.salonInfos.prestations) {

      this.salonInfos.prestations.forEach(prestation => {

        unit = prestation.priceUnit;

        if (start == -1 || start > prestation.price)
          start = prestation.price;

        if (end == -1 || end < prestation.price)
          end = prestation.price;

      });

      if (start != -1 && end != -1) {

        if (start === end) {
          start = 0;
        }

        result = { start, end, unit };
      }
    }

    return result;
  }

  async previewImage(image: Image, url: string = null) {
    // this.photoViewer.show(img.url, this.prestation ? `${this.prestation.name} (${this.salon.name})` : '');

    const modal = await this.modalController.create({
      component: ImgViewerComponent,
      componentProps: {
        'src': !url ? image : new Image(url),
        'family': this.salonInfos.salon.salonPresentations,
        'globalTitle': this.salonInfos.salon.name,
        'salon': this.salonInfos.salon,

      }
    });

    return await modal.present();

  }

  toggleAllTypes() {

    if (this.numberOfTypesToShow === 3 && this.salonPrestationsTypes.length > 3) {
      this.numberOfTypesToShow = this.salonPrestationsTypes.length;
    } else {
      this.numberOfTypesToShow = 3;
    }

  }

  explorePrestations(type: TypePrestation) {
    this.dataService.saveAppData(dataKey.TYPE_PRESTATION_FILTER, type);
    this.redirectionService.redirectTo('/profil-salon/prestations/insideTab', true);
  }

  get prestationsImages() {
    let result = [];

    if (this.salonService.salonInfos && this.salonService.salonInfos.salon && this.salonService.salonInfos.prestations) {

      this.salonService.salonInfos.prestations.forEach((prestation: PrestationDetail, index) => {


        if (prestation.galleries && prestation.galleries.length > 0 && index < 2) {

          prestation.galleries = prestation.galleries.map(img => {
            img.pid = prestation.pid;
            return img;
          })

          result = result.concat(prestation.galleries);

        }

      });

    }

    return result;
  }

  async openViewer(image: Image) {

    const modal = await this.modalController.create({
      component: ImgViewerComponent,
      componentProps: {
        'src': image,
        'family': this.salonService.salonInfos.salon.salonPresentations,
        'salon': this.salonService.salonInfos.salon,
        'globalTitle': this.salonService.salonInfos.salon.name
      }
    });

    return await modal.present();

  }

  async openActuPrestation(image) {
    const modal = await this.modalController.create({
      component: GalleryPage,
      componentProps: {
        'src': image,
        'family': this.prestationsImages,
        'salon': this.salonService.salonInfos.salon,
        // 'prestation' : element.prestation
      }
    });

    return await modal.present();
  }

  goToSalon(sid) {
    this.redirectionService.redirectTo(`/profil-salon/${sid}`, true);
  }

  goToSalonPrestations(sid) {
    /*this.redirectionService.redirectTo(`/profil-salon/prestations/insideTab`, true);*/
    this.redirectionService.redirectTo(`/profil-salon/prestations/${sid}`, true);
  }

  goToSalonGalleries(sid) {
    this.redirectionService.redirectTo(`/profil-salon/gallerie/${sid}`, true);
  }

  goToSalonAvis() {
    this.redirectionService.redirectTo(`/profil-salon/salon-avis/${this.salonService.salonInfos.salon.sid}`, true);
  }

  async shareLink(ev: any) {
    this.shareLoading = true;
    const baseUrl = 'https://app.askeed.ch';
    let url = baseUrl + '/profil-salon/' + this.salonInfos.salon.sid;
    const popover = await this.popoverController.create({
      component: ShareLinkComponent,
      cssClass: 'share-link-popover',
      event: ev,
      translucent: true,
      componentProps: {
        title: this.translate.instant('Partager le salon'),
        url: url,
        postTitle: this.salonInfos.salon.name,
        postImageLink: this.salonInfos.salon.profilImageUrl,
        postDescription: this.salonInfos.salon.bio.replace(/(<[a-zA-Z\/][^<>]*>|\[([^\]]+)\])|(\s+)/ig,''),
        salon: this.salonInfos.salon
      }
    });
    return await popover.present().then(() => {
      this.shareLoading = false;
    });
  }

  follow() {
    if(!this.user.uid){
      return this.router.navigate(['/login']);
    }
    this.salonInfos.salon.likeIt(this.user.uid);
    
    this.salonService.updateSalon(this.salonInfos.salon);

    this.user.likeSalon(this.salonInfos.salon.sid);

    this.userService.updateUser(this.user).then(() => {
      this.salonService.subscriptionChange.next(true);
    });
  }

  chooseFilter() {
    this.sortListSelect.open();
  }


    // sortPrestations(){

    //     this.sortedPrestationAppliedLabel = this.prestationSortList[ this.prestationSortList.map(data=> data.id).indexOf(this.sortedTypeprestationApplied) ].label;

    //     if(this.sortedTypeprestationApplied == "2"){
    //         this.salonInfos.prestations = this.salonInfos.prestations.sort((a, b) => a.price - b.price)
    //     }else if(this.sortedTypeprestationApplied == "1"){
    //         this.salonInfos.prestations = this.salonInfos.prestations.sort((a, b) => b.price - a.price)
    //     }else if(this.sortedTypeprestationApplied == "0"){
    //         this.salonInfos.prestations = this.salonInfos.prestations.sort((a, b) => a.popularityIndex - b.popularityIndex)
    //     } 
    //     // this.salonInfos.prestations = this.salonInfos.prestations.sort()
    //     // popularityIndex price
    // }

    sortPrestations(sortMode = 1 /* 1: prix croissant - 2: prix decroissant - 3 =: popularité */) {

        this.sortApplied = this.prestationSortList.find(option => option.value === sortMode);
        this.openSortSelection = false;

        switch (sortMode) {
            case 1:
                this.salonInfos.prestations.sort((p1, p2) => p1.priceApplied > p2.priceApplied ? 1 : -1);
                break;
            case 2:
                this.salonInfos.prestations.sort((p1, p2) => p1.priceApplied < p2.priceApplied ? 1 : -1);

                break;
            case 3:
                this.salonInfos.prestations.sort((p1, p2) => p1.popularityIndex > p2.popularityIndex ? 1 : -1);
                break;
            default:
                break;
        }

        this.sortByPromotion();

        setTimeout(() => {
          if (this.cdrf !== null && this.cdrf !== undefined &&
            !(this.cdrf as ViewRef).destroyed) {
            this.cdrf.detectChanges();
          }
        });

    }
    // this.salonInfos.prestations = this.salonInfos.prestations.sort()
    // popularityIndex price

    sortByPromotion() {
        this.salonInfos.prestations.sort((a, b) => {
            if (a.promotion && a.maxPromo > 0) {
                return -1;
            }

            return 1;
        })
    }

}
