import { Deserializable } from './deserializable.model';
export class AppUpdate implements Deserializable{
    current: string;
    enabled: boolean;
    msg?: {
        title: string;
        msg: string;
        btn: string;
    };
    majorMsg?: {
        title: string;
        msg: string;
        btn: string;
    }
    minorMsg?: {
        title: string;
        msg: string;
        btn: string;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
