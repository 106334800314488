import { NgModule } from '@angular/core';
import { PreloadAllModules, PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./services/auth-guard.service";
import { RecapitulatifGuardService } from "./services/recapitulatif-guard.service";
import { AuthStateGuardService } from "./services/auth-state-guard.service";
import { Observable, of } from 'rxjs';
import { CityGuardService } from './services/city-guard.service';
import { MailGuardService } from './services/mail-guard.service';

export class SimpleLoadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    if (route.data && route.data.preload) {
      return load();
    }
    return of(null);
  }
}
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  
  { 
    path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), 
    canActivate: [MailGuardService]
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    // canActivate: [AuthStateGuardService]
  },
  {
    path: 'registration',
      loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule),
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule),
    data: {
      preload: true
    },
    canActivate: [AuthGuardService , MailGuardService],
  },
  {
    path: 'salons',
    loadChildren: () => import('./pages/home/salons/salons.module').then(m => m.SalonsPageModule), 
    canActivate: [ CityGuardService , MailGuardService]
  },
  {
    path: 'galleries',
    loadChildren: () => import('./pages/home/galleries/galleries.module').then(m => m.GalleriesPageModule), 
    canActivate: [ CityGuardService , MailGuardService]
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/home/map/map.module').then(m => m.MapPageModule), 
    canActivate: [ CityGuardService , MailGuardService]
  },
  {
    path: 'gallery',
      loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryPageModule),
      canActivate: [AuthGuardService]
  },
  {
      path: 'profil-salon',
      loadChildren: () => import('./pages/salon-client/salon-client.module').then(m => m.SalonClientPageModule), 
      canActivate: [MailGuardService]
    },
    {
        path: 'prestations',
        loadChildren: () => import('./pages/prestations/prestations.module').then(m => m.PrestationsPageModule), 
        canActivate: [AuthGuardService]
    },
    {
        path: 'prestation',
        loadChildren: () => import('./pages/prestation/prestation.module').then(m => m.PrestationPageModule), 
        canActivate: [AuthGuardService]
    },
    {
        path: 'reservation',
        loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationPageModule), 
        canActivate: [MailGuardService]
  },
  {
    path: 'reservation-confirm',
    canActivate: [RecapitulatifGuardService, MailGuardService],
    loadChildren: () => import('./pages/reservation-confirm/reservation-confirm.module').then( m => m.ReservationConfirmPageModule),
  },
  {
    path: 'availability',
      loadChildren: () => import('./pages/availability/availability.module').then(m => m.AvailabilityPageModule), 
      canActivate: [AuthGuardService]
  },
  {
    path: 'agenda',
      loadChildren: () => import('./pages/agenda/agenda.module').then(m => m.AgendaPageModule), 
      canActivate: [AuthGuardService]
  },
  {
    path: 'reservation-list',
    loadChildren: () => import('./pages/reservation-list/reservation-list.module').then( m => m.ReservationListPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'reservation-cancel',
    loadChildren: () => import('./pages/reservation-cancel/reservation-cancel.module').then( m => m.ReservationCancelPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'reservation-done',
    loadChildren: () => import('./pages/reservation-done/reservation-done.module').then( m => m.ReservationDonePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-tag-search',
      loadChildren: () => import('./pages/prestation/modal-tag-search/modal-tag-search.module').then(m => m.ModalTagSearchPageModule), 
      canActivate: [AuthGuardService]
  },
  {
    path: 'search-city',
      loadChildren: () => import('./pages/search-city/search-city.module').then(m => m.SearchCityPageModule), 
      canActivate: [MailGuardService]
  },
  {
    path: 'listePrestations',
      loadChildren: () => import('./pages/home/liste-prestations/liste-prestations.module').then(m => m.ListePrestationsPageModule), 
      canActivate: [AuthGuardService]
  },
  {
    path: 'apropos',
      loadChildren: () => import('./pages/apropos/apropos.module').then(m => m.AproposPageModule), 
      canActivate: [MailGuardService]
  },
    {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule), 
        canActivate: [MailGuardService]
    },
    {
        path: 'chat',
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule), 
        canActivate: [AuthGuardService]
  },
  {
    path: 'admin-tchat',
    loadChildren: () => import('./pages/admin-tchat/admin-tchat.module').then( m => m.AdminTchatPageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'reservation-tchat',
      loadChildren: () => import('./pages/reservation-tchat/reservation-tchat.module').then(m => m.ReservationTchatPageModule),
      canActivate: [MailGuardService]
  },
  {
    path: 'save-reservation-done',
    loadChildren: () => import('./pages/save-reservation-done/save-reservation-done.module').then( m => m.SaveReservationDonePageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'conditions-generales-utilisation',
    loadChildren: () => import('./pages/condition-general-utilisation/condition-general-utilisation.module').then( m => m.ConditionGeneralUtilisationPageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'condition-utilisation-salon',
    loadChildren: () => import('./pages/condition-utilisation-salon/condition-utilisation-salon.module').then( m => m.ConditionUtilisationSalonPageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'politique-confidentialite',
    loadChildren: () => import('./pages/politique-confidentialite/politique-confidentialite.module').then( m => m.PolitiqueConfidentialitePageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'facturation',
    loadChildren: () => import('./pages/facturation/facturation.module').then( m => m.FacturationPageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'salon-abonnement',
    loadChildren: () => import('./pages/salon-abonnement/salon-abonnement.module').then( m => m.SalonAbonnementPageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'reservation-notification-action',
    loadChildren: () => import('./pages/reservation-notification-action/reservation-notification-action.module').then(m => m.ReservationNotificationActionPageModule),
    canActivate: [MailGuardService]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule),
    canActivate: [MailGuardService]
  },

  // Redirect salon booking from mail link
  {
    path: 'profil-salon/detail/agenda-reservation',
    redirectTo: 'agenda', pathMatch: 'full'
  }
  
];

@NgModule({
  providers: [SimpleLoadingStrategy],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: SimpleLoadingStrategy })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
