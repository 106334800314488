import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-common-info-label',
  templateUrl: './common-info-label.component.html',
  styleUrls: ['./common-info-label.component.scss'],
})
export class CommonInfoLabelComponent implements OnInit {

	@Input() label;
	@Input() subLabel;
	@Input() subIcon;
	@Input() action;
	@Input() subAction;
	@Input() hasProBadge;

	constructor(public translate: TranslateService, private navCtrl: NavController) { }

	ngOnInit() {
		setTimeout(() => {
			if (this.subLabel && this.subLabel.includes('...'))
				this.subLabel = this.translate.instant("N/A");
		}, 7500);
	}

	goToLink() {
		this.navCtrl.navigateRoot([this.action.link]);
	}

}
