import { Injectable } from '@angular/core';
import { Category } from '../models/category.model';
import { SalonDetail } from '../models/salon.model';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { SalonService } from 'src/app/services/salon.service';
// import { CitiesService } from 'src/app/services/cities.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { dataConst } from '../shared/helpers/data.service';
import { Tag } from 'src/app/models/tags.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CitiesService } from './cities.service';
import * as lodash from 'lodash';
import { CategoryPrestation, DefaultPrestation, TypePrestation } from '../models/defaultPrestation.model';
import { PrestationDetail } from '../models/prestation.model';
import { City } from '../models/city.model';
import { BasicService } from './basic.service';
import { CategoryService } from './category.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core'

export class GlobalFilter {
  num: number;
  val: string;
  isSelected: boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  static CATEGORY_COLLECTION_NAME = 'categoryPrestation';

  tagsAdded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cityChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
  categoriesChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

  mapLoading: boolean = true;
  searchModalInput: string;
  tagsResultModal: Observable<Tag[]>;
  salonResultModal: SalonDetail[];
  prestationsResult: any[];
  filterBy: any = { limit: 6 };

  loading: boolean = true;
  loadingPrestations: boolean = true;
  loadingGalleries: boolean = true;
  loadingMap: boolean = true;
  loadingSalons: boolean = true;

  myPosition: {
    lat: number,
    lng: number
  };
  mapCardFilter: any[];

  tags: string[] = [];

  filters: any = {};
  filtersgalleries: any = {};
  filtersMap: any = {};

  categories: CategoryPrestation[] = [];
  categoriesCards: CategoryPrestation[];
  allCategory: boolean = true;
  subCategories: any[];
  activeFilter: number = 0;


  sort: any = 'viewCount';
  sortType: 'asc' | 'desc' = 'desc';

  queryOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Content-Type': 'application/json'
    })
  };

  tab: string;
  searchType: 'salon' | 'prestation' | 'gallery' | 'map';

  // for filterSidebar
  swipeFilterSidebar: boolean = true;
  selectedCategory: string[];

  SERVER_REQUEST: boolean = false;

  globalFilters: GlobalFilter[];

  typesFilter: TypePrestation[] = [];
  defaultPrestationsFilter: DefaultPrestation[] = [];

  activeTab: string = 'salons';
  displayMap: boolean = false;
  changeSalonOnMap: BehaviorSubject<boolean> = new BehaviorSubject(false);
  stopScrollTop: boolean = false;

  showFilters: boolean = false;

  salonSelected: SalonDetail[];
  salonSelectedPrestations: PrestationDetail[];

  salonSortItems = [
    { label: 'Les plus populaires', value: 'popular' },
    { label: 'Les mieux notés', value: 'rated' },
    { label: 'Les plus proches', value: 'proximity' },
    /*{ label: 'Les salons moins cher au plus cher', value: 'priceAsc' },
    { label: 'Les salons plus cher au moins cher', value: 'priceDesc' },*/
  ];

  prestationSortItems = [
    { label: 'Les plus proches', value: 'proximity' },
    { label: 'Les plus populaires', value: 'popular' },
    { label: 'Prix croissant', value: 'priceAsc' },
    { label: 'Prix décroissant', value: 'priceDesc' }
  ];

  salonSortSelected: Category;

  constructor(
    private httpClient: HttpClient,
    private citiesService: CitiesService,
    public basicService: BasicService,
    public categoryService: CategoryService,
    private firestore: AngularFirestore,
    private translate: TranslateService
  ) {
    this.loading = true;

    this.initCategories();

    this.subCategories = [
        { label: this.translate.instant('Tendance'), value: 'tendance', selected: false },
        { label: this.translate.instant('Nouveau'), value: 'nouveau', selected: false },
        { label: this.translate.instant('Populaire'), value: 'populaire', selected: false },
        { label: this.translate.instant('Promo'), value: 'promo', selected: false },
        { label: this.translate.instant('A domicile'), value: 'a-domicile', selected: false }
    ];

    this.globalFilters = [
      { num: 0, val: 'Prix croissant', isSelected: true },
      { num: 1, val: 'Prix decroissant', isSelected: false },
      { num: 2, val: 'Le plus proche', isSelected: false },
      { num: 3, val: 'En promotion', isSelected: false }
    ];


    this.filtersMap.salonAdress = ((val) => {
      return val.latLng !== undefined;
    });

    this.mapCardFilter = [
      { val: 'Salon professionnel', isChecked: false },
      { val: 'Promotions', isChecked: false }
    ];


    this.tagsAdded.subscribe(t => {
      if (!t) {
        this.loading = true;
        this.refreshFilter();
        this.loading = false;
      }
      this.salonSelected = undefined;

    });

  }

  initCategories() {
    this.listCategories().subscribe((o) => {
      this.categories = [];
      this.categories.push(
        new CategoryPrestation().deserialize({ label: this.translate.instant('Tous'), value: 'all', selected: false, iconName: 'cut-outline' })
      );
      o.forEach((cat) => {
        let category = new CategoryPrestation().deserialize(cat);
        localStorage.setItem(category.cpid,JSON.stringify(category.label));
        this.categories.push(category);
      });
    });
  }

  selectCategory(c: CategoryPrestation) {
    c.selected = !c.selected;

    if (c.selected) {
      let categories = [];
      this.categoriesCards.forEach((category, index) => {
        if (category.cpid === c.cpid) {
          categories.push(category.cpid);
        } else {
          this.categoriesCards[index].selected = false;
        }

      });
      this.filterBy.category = categories;
    } else {
      let selection = this.categoriesCards.find((cat) => cat.selected);
      if (!selection) {
        this.removeFilter('category');
      }
    }

    this.loading = true;
    this.tagsAdded.next(true);

  }

  setGlobalFilterActive(num) {
    this.globalFilters.map((gf) => {
      if (gf.num === num) {
        gf.isSelected = true;
      } else {
        gf.isSelected = false;
      }
      return gf;
    });
  }

  getGlobalFilterActive() {
    return this.globalFilters.find((f) => f.isSelected);
  }

  setTab(tab) {
    this.tab = tab;
  }

  setSearchType(searchType) {
    this.searchType = searchType;
  }

  listCategories() {
    return this.firestore.collection<CategoryPrestation>(FilterService.CATEGORY_COLLECTION_NAME).valueChanges();
  }

  async filterPrestations(prestations: PrestationDetail[]) {
    await new Promise(resolve => setTimeout(resolve, 500));
    return await new Promise((resolve) => {
      if (this.sort) {
        this.filterBy.sort = this.sort;
        this.filterBy.sortType = this.sortType;
      }
      let body = this.filterBy;
      let result = prestations;
      // OR FILTER
      // if (Object.keys(body).length > 3) {
      //   result = [];
      //   for (let i in body) {
      //     if (i !== 'city' && i !== 'sort' && i !== 'sortType') {
      //       let filterBy = {};
      //       filterBy[i] = body[i];
      //       let salonsResult = null;
      //       if (i === 'availability') {
      //         // salonsResult = this.salonsDefault;
      //       }
      //       let filters = this.makeFilters(filterBy, 'prestation', salonsResult);
      //       let res = lodash.filter(prestations, lodash.conforms(filters));
      //       res.forEach(element => {
      //         if (!result.find((el) => el.pid === element.pid)) {
      //           result.push(element);
      //         }
      //       });
      //     }
      //   }
      // }

      // AND FILTER
      let filterBy = {};
      if (Object.keys(body).length > 3) {
        for (let i in body) {
          if (i !== 'city' && i !== 'sort' && i !== 'sortType') {
            filterBy[i] = body[i];
          }
        }
      }
      let salonsResult = null;
      let filters = this.makeFilters(filterBy, 'prestation', salonsResult);
      result = lodash.filter(prestations, lodash.conforms(filters));
      // res.forEach(element => {
      //   if (!result.find((el) => el.pid === element.pid)) {
      //     result.push(element);
      //   }
      // });

      if (body.sort) {
        result = lodash.orderBy(result, [body.sort], [body.sortType]).map((s) => {
          return s;
        });
      }
      resolve(result);
    });
  }

  getFilteredSalons(property) {
    return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'getFilteredSalons', JSON.stringify({ ...property }), this.queryOptions);
  }

  getFilteredPrestations(property) {
    return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'getFilteredPrestations', JSON.stringify({ ...property }), this.queryOptions);
  }

  getFilteredSalonsInMap() {

  }
  refreshFilter() {
    Object.getOwnPropertyNames(this.filterBy).forEach(property => {
      if (property !== 'city') {
        this.removeFilter(property);
      }
    });
    this.categories.forEach((val, i) => {
      this.categories[i].selected = false;
    });
    // this.categories[0].selected = true;
  }

  removeFilter(property: string) {
    delete this.filterBy[property];
  }

  //
  sortLatest() {
    this.sort = (e) => e.createdAt.seconds * 1000;
    this.sortType = 'desc';
  }

  sortPopular() {
    this.sort = e => { };
    this.sortType = 'desc';
  }


  setTags(tags: string[]) {
    this.tags = tags;
  }

  addTags(tag) {
    if (tag && tag !== '') {
      this.tags.push(tag);
    }
  }

  addTypeTags(type: TypePrestation) {
    if (!this.typesFilter.find((t) => t.tpid === type.tpid)) {
      this.typesFilter.push(type);
    } else {
      this.removeTypeTags(type);
    }
    if (this.typesFilter.length > 0) {
      this.filterBy.types = this.typesFilter.map((t) => t.tpid);
    } else {
      this.removeFilter('types');
    }
    this.tagsAdded.next(true);
  }

  addDefaultPrestationTags(defaultPrestation: DefaultPrestation) {
    if (!this.defaultPrestationsFilter.find((t) => t.dpid === defaultPrestation.dpid)) {
      this.defaultPrestationsFilter.push(defaultPrestation);
    } else {
      this.removeDefaultPrestationTags(defaultPrestation);
    }
    if (this.defaultPrestationsFilter.length > 0) {
      this.filterBy.defaultPrestations = this.defaultPrestationsFilter.map((t) => t.dpid);
    } else {
      this.removeFilter('defaultPrestations');
    }
    this.tagsAdded.next(true);
  }

  removeTypeTags(type: TypePrestation) {
    this.typesFilter = this.typesFilter.filter((t) => t.tpid !== type.tpid);
    if (this.typesFilter.length > 0) {
      this.filterBy.types = this.typesFilter.map((t) => t.tpid);
    } else {
      this.removeFilter('types');
    }
    this.tagsAdded.next(true);
  }

  removeDefaultPrestationTags(defaultPrestation: DefaultPrestation) {
    this.defaultPrestationsFilter = this.defaultPrestationsFilter.filter((t) => t.dpid !== defaultPrestation.dpid);
    if (this.defaultPrestationsFilter.length > 0) {
      this.filterBy.defaultPrestations = this.defaultPrestationsFilter.map((t) => t.dpid);
    } else {
      this.removeFilter('defaultPrestations');
    }
    this.tagsAdded.next(true);
  }

  setMyPosition(lat, lng) {
    this.myPosition = { lat: 0, lng: 0 };
    this.myPosition.lat = lat;
    this.myPosition.lng = lng;
  }

  async searchArround() {
    await this.basicService.showLoading();
    return new Promise<void>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let cityObject = {
            country: '',
            lat: lat,
            lng: long,
            name: 'Autour'
          }
          let city: City = new City().deserialize(cityObject);
          this.citiesService.setStocked('city', city).then(() => {
            // this.tagsAdded.next(true);
            this.cityChange.next(true);
            this.basicService.dismissLoading();
            resolve();
          });
        }, (err) => {
          // TODO console.log('err ', err);
          this.basicService.dismissLoading();
          // console.log(err.message);
          reject();
        });
      } else {
        this.basicService.dismissLoading();
        // console.log('Geolocation is not supported by this browser.');
        reject();
      }
    })


  }

  distance(lat2, lon2, unit = 'K') {
    if (!this.citiesService.city) {
      return 1000000;
    }
    const lat1 = parseFloat(this.citiesService.city.lat);
    const lon1 = parseFloat(this.citiesService.city.lng);
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') { dist = dist * 1.609344; }
      if (unit === 'N') { dist = dist * 0.8684; }
      return dist;
    }
  }

  makeFilters(arrayFilter, takeFor, salons = null) {
    let filters: any = {};
    
    let filterSid = val => {
      let a = lodash.find(salons, { sid: val })
      if (a != undefined) {
        return true;
      } else {
        return false;
      }
    }

    Object.getOwnPropertyNames(arrayFilter).forEach(property => {
      // TYPE
      if (property === 'types') {
        if (takeFor === 'prestation') {
          filters.typesIds = ((val) => {
            const a = lodash.intersection(val, arrayFilter[property]);
            if (a.length > 0) {
              return true;
            }
            return false;
          });

          // let res = false;
          // filters.typesIds = types => {
          //   types.forEach( val => {
          //     arrayFilter[property].forEach(c => {
          //       if (val === c) {
          //         res = true;
          //       }
          //     });
          //   });
          //   return res;
          // };
        }
      }
      // DEFAULT PRESTATION
      if (property === 'defaultPrestations') {
        if (takeFor === 'prestation') {
          filters.defaultPrestationId = val => {
            let res = false;
            arrayFilter[property].forEach(c => {
              if (val === c) {
                res = true;
              }
            });
            return res;
          };
        }
      }
      // AVAILABILITY
      else if (property == 'availability') {

        const to = new Date(arrayFilter[property].to);
        const from = new Date(arrayFilter[property].from);

        let dayDifferences = to.getTime() - from.getTime();
        to.setDate(to.getDate() + 1);
        dayDifferences = (dayDifferences / (1000 * 3600 * 24)) + 1;
        const days = ['sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday'];
        let dayNeeded = [];
        if (dayDifferences >= 7) {
          dayNeeded = days;
        } else {
          for (let i = from.getDay();
            i < from.getDay() + dayDifferences;
            i++) {
            dayNeeded.push(days[i % 7]);
          }
        }

        if (takeFor == 'salon') {
            
            filters.availability = (val) => {
              let res = [];
              for (const day of dayNeeded) {
                if (val[day] != undefined) {
                  if ((typeof (val[day].available) === 'boolean' && !val[day].available) || (typeof (val[day].available) !== 'boolean' && !val[day].available.am && !val[day].available.pm)) {
                    res.push(false);
                  }else{
                    res.push(true);
                  }
                } else {
                  res.push(false);
                }

              }
              return res.includes(true);
            };

            filters.unavailabilities = (una) => {
              if (una == undefined || una == []) {
                return true;
              } else {
                for (const unav of una) {

                  if (new Date(unav.date) >= from && new Date(unav.date) <= to) {
                    return false;
                  }
                }
              }
              return true;
            };
  
        } else if (takeFor == 'prestation') {
          filters['sid'] = filterSid;
        }

        //// CATEGORY
      } else if (property == 'category') {
        if (takeFor == 'salon') {
          filters.categories = ((val) => {
            let categories = [];
            arrayFilter[property].forEach((v, i) => {
              arrayFilter[property][i] = v;
              // if(this.categoryService.categories.find((el) => el.cpid.toLowerCase() === v)){
              //   categories[i] = this.categoryService.categories.find((el) => el.cpid.toLowerCase() === v).fr.toLowerCase();
              // }
            });
            const a = lodash.intersection(val, arrayFilter[property]);
            if (a.length > 0) {
              return true;
            }
            return false;
          });

        } else if (takeFor == 'prestation') {
          filters.categoryId = val => {
            let res = false;
            arrayFilter[property].forEach(c => {
              if (val == c) {
                res = true;
              }
            });
            return res;
          };
        }
        //// CITY
      } else if (property === 'city') {
        // if (takeFor === 'salon') {
        //   filters.adresses = (val) => {
        //     let res = false;
        //     val.forEach((ad) => {
        //       // if(arrayFilter[property]['name'].toLowerCase()=="autour"){
        //       if (ad.isMain) {


        //         const distanceFrom = this.distanceKm(Number(arrayFilter[property].lat),
        //           Number(arrayFilter[property].lng),
        //           Number(ad.latLng.lat),
        //           Number(ad.latLng.lng));
        //         if (distanceFrom <= 15) {
        //           res = true;
        //         }
        //         if (ad.locality) {
        //           if (ad.locality.toLowerCase() === arrayFilter[property].name.toLowerCase()) {
        //             res = true;
        //           }
        //         }
        //       }
        //       /*}else{
        //         if(ad.locality){
        //           if( ad.locality.toLowerCase() == arrayFilter[property]['name'].toLowerCase() ){
        //               res = true;
        //           }
        //         }
        //       }*/


        //     });

        //     return res;
        //   };
        // }
        //// COUNTRY
      } else if (property === 'country') {
        if (takeFor === 'salon') {
          filters.adresses = val => {
            let res = false;
            val.forEach((ad) => {
              if (ad.country && ad.country.toLowerCase() === arrayFilter[property].toLowerCase()) {
                res = true;
              }
            });

            return res;
          };
        }



      }
      // else if (property === 'distance') {
      //   if (takeFor === 'salon') {
      //     filters.adresses = val => {
      //       let res = false;
      //       val.forEach((ad) => {
      //         const distanceFrom = this.distanceKm(Number(arrayFilter[property].lat),
      //           Number(arrayFilter[property].lng),
      //           Number(ad.latLng.lat),
      //           Number(ad.latLng.lng));
      //         if (distanceFrom <= Number(arrayFilter[property].distance)) {
      //           res = true;
      //         }
      //       });
      //       return res;
      //     };
      //   }
      // } 
      else if (property === 'gender') {
        if (takeFor === 'salon') {
          filters.targetsPrestations = val => {
            let res = false;
            arrayFilter[property].forEach(element => {
              if (val[element]) {
                res = true;
              }
            });

            // if (arrayFilter[property] === 'men' || arrayFilter[property] === 'women') {
            //   return val[arrayFilter[property]] || val.both;
            // }
            // return val[arrayFilter[property]];
            return res;
          };
        } else if (takeFor === 'prestation') {

          filters.target = val => {
            let res = false;
            arrayFilter[property].forEach((i, element) => {
              if (val === i) {
                res = true;
              } else if (val === 'both' && (i === 'men' || i === 'women')) {
                res = true;
              }
            });
            return res;
          };
        }
        //// MOVINGPRESTATION
      } else if (property === 'movingPrestations') {

        if (takeFor === 'salon') {
          filters.movingPrestations = val => {
            if (val[arrayFilter[property]]) {
              return true;
            } else {
              return false;
            }
          };
        } else if (takeFor === 'prestation') {
          filters.atHome = val => {
            if (val) {
              return true;
            } else {
              return false;
            }
          };
        }
        //// NAME
      } else if (property === 'name') {
        filters.name = val => {
          return val.toLowerCase().includes(arrayFilter[property]);
        };

        //// PRICE
      } else if (property === 'price') {
        const range = arrayFilter[property];
        if (takeFor === 'salon') {
          filters.pricePrestation = val => {
            if (val !== undefined && val.min >= arrayFilter[property].lower && val.max <= arrayFilter[property].upper) {
              return true;
            } else {
              return false;
            }
          };
        } else if (takeFor === 'prestation') {
          filters.price = val => {
            if (val > range.lower && val < range.upper) {
              return true;
            } else {
              return false;
            }
          };
        }
        //// PROMOTION
      } else if (property === 'promotion') {
        const thefunction = val => {
          let value = val;
          if (takeFor === 'map') {
            value = val.promotion;
          }

          if (value === arrayFilter[property]) {
            return true;
          } else {
            return false;
          }
        };
        if (takeFor === 'salon') {
          filters.promotion = thefunction;
          filters.promotionMaxValue = (val) => {
            return val;
          };
        } else if (takeFor === 'prestation') {
          filters.maxPromotion = val => val && val > 0;
        } else {
          filters.salon = thefunction;
        }
        /// PROFESSIONNEL
      } else if (property === 'isEntreprise') {
        const thefunction = val => {
          let value = val;
          if (takeFor === 'map') {
            value = val.isEntreprise;
          }
          if (value === arrayFilter[property]) {
            return true;
          } else {
            return false;
          }
        };
        if (takeFor === 'salon') {
          filters.isEntreprise = thefunction;
        }
        //// TAGS
      } else if (property === 'tags') {
        if (takeFor === 'salon') {
          filters.tagsPrestations = ((val) => {
            let res = false;
            for (const s of val) {
              if (arrayFilter[property].filter((r) => s.trim().toLowerCase().includes(r.trim().toLowerCase())).length > 0) {
                res = true;
              }
            }
            return res;
          });
        } else if (takeFor === 'prestation') {
          filters.tags = ((val) => {
            let res = false;
            for (const s of val) {
              if (arrayFilter[property].filter((r) => s.trim().toLowerCase().includes(r.trim().toLowerCase())).length > 0) {
                res = true;
              }
            }
            return res;
          });
        }
      }
    });




    return filters;
  }

  distanceKm(lat1, lng1, lat2, lng2) {
    if ((lat1 == lat2) && (lng1 == lng2)) {
      return 0;
    } else {
      let radlat1 = Math.PI * lat1 / 180;
      let radlat2 = Math.PI * lat2 / 180;
      let theta = lng1 - lng2;
      let radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515; // statute miles
      return dist * 1.609344;
    }
  }

  nombreFiltreActifs() {
    this.activeFilter = 0;
    if (this.filterBy.isEntreprise || this.filterBy.isEntreprise === false) {
      this.activeFilter++;
    }
    if (this.filterBy.gender && this.filterBy.gender !== 'both') {
      this.activeFilter++;
    }
    if (this.filterBy.country) {
      this.activeFilter++;
    }
    if (this.filterBy.movingPrestations && this.filterBy.movingPrestations !== 'both') {
      this.activeFilter++;
    }
    if (this.filterBy.promotion || this.filterBy.promotion === false) {
      this.activeFilter++;
    }
    if (this.filterBy.price) {
      this.activeFilter++;
    }
    if (this.filterBy.availability) {
      this.activeFilter++;
    }
    this.categories.forEach(cat => {
      if (cat.value !== 'all' && cat.selected === true) {
        this.activeFilter++;
      }
    });
  }

}
