import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker/date-picker/date-picker-config.model';
import { Subject } from 'rxjs';

import { ReservationService } from 'src/app/services/reservation.service';
import { Reservation, ReservationDetail, ReservationStatus } from 'src/app/models/reservation.model';

import { SalonService } from 'src/app/services/salon.service';
import { SalonDetail } from 'src/app/models/salon.model';

import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-salon-historique-facturation',
  templateUrl: './salon-historique-facturation.component.html',
  styleUrls: ['./salon-historique-facturation.component.scss'],
})
export class SalonHistoriqueFacturationComponent implements OnInit, OnDestroy {

	@Input() salon: SalonDetail;

	loading = {
		data: false
	}

	dateFormat = 'DD MMM YYYY';

  	startDate: number = null;
  	endDate: number = null;

	filterStartDate: string;
  	filterEndDate: string;

  	userLang = navigator.language ? navigator.language : 'fr'; 

  	filterStartDateConfig: IDatePickerConfig = {
  		format: this.dateFormat,
  		locale: this.userLang,
  	}

  	filterEndDateConfig: IDatePickerConfig = {
  		format: this.dateFormat,
  		locale: this.userLang,
  	}

  	dtOptions: DataTables.Settings = {};
  	dtTrigger: Subject<any> = new Subject<any>();

  	reservationList: any[] = []; 

  	constructor(
        public translate: TranslateService,
  		private reservationService: ReservationService, 
  		private salonService: SalonService
	) { }

  	ngOnInit() {

  		this.reservationList = [];
  		this.getDtOptions();
	    this.getListTransaction(true);

	}

	ngOnDestroy(): void {
	    // Do not forget to unsubscribe the event
	    this.dtTrigger.unsubscribe();
  	}

  	getDtOptions(){

  		this.dtOptions = {
	      	ordering: false,
	      	searching: false,
	      	pageLength: 10,
	      	responsive: true,
	      	"autoWidth": false,
      	 	order 	: [],
	      	language: {
		        emptyTable:     this.translate.instant("Aucune transaction en cours"),
			    /*info:           "_START_ à _END_ sur _TOTAL_ élément(s)",*/
			    info:           "",
			    infoEmpty:      "",
			    infoFiltered:   this.translate.instant("filtré à partir de _MAX_ transaction(s) au total"),
			    infoPostFix:    "",
			    lengthMenu:     this.translate.instant("Afficher _MENU_ élément(s)"),
			    loadingRecords: this.translate.instant("Chargement..."),
			    processing:     this.translate.instant("Traitement..."),
			    search:         this.translate.instant("Rechercher")+" :",
			    zeroRecords:    this.translate.instant("Aucun transaction correspondant trouvé"),
			    paginate: {
			        first:    this.translate.instant("Premier"),
			        last:     this.translate.instant("Dernier"),
			        next:     this.translate.instant("Suivant"),
			        previous: this.translate.instant("Précédent")
			    },
			    aria: {
			        sortAscending: ': ' + this.translate.instant('activer pour trier la colonne par ordre croissant'),
					sortDescending: ': ' + this.translate.instant('activer pour trier la colonne par ordre décroissant')
			    }

   			}

	    };
  	}

	getListTransaction(init=false,startDate=null, endDate=null){

		this.loading.data = true;

		if(!init) this.dtTrigger = new Subject<any>();

		this.reservationService.getSalonReservation(this.salon.sid, startDate, endDate, ReservationStatus.ACCOMPLISHED, ReservationStatus.ACCOMPLISHED).then((data) => {

			let allReservation = [];
			
			if(this.reservationList){
				this.reservationList.splice(0, this.reservationList.length)
			}

			data.docs.forEach(reservation => {
				allReservation.push(new ReservationDetail().deserialize(reservation.data()));
			});

			this.reservationList = allReservation.map( reservation => {
				
				let date = new DatePipe('fr-FR').transform(reservation.executionDate, "dd/MM/yyyy");
                if (!reservation.prestation.specialCommision) {
                    reservation.prestation.specialCommision = { isActive: false, amount: 0 };
                }

                let prestationLabel = (reservation.prestation.intLabel && reservation.prestation.intLabel[this.translate.currentLang] && reservation.prestation.intLabel[this.translate.currentLang]) ? reservation.prestation.intLabel[this.translate.currentLang] : reservation.prestation.name;
                let prestationVariant = "";
			    if(reservation.prestation.variant){
			    	prestationVariant += " ("+reservation.prestation.variant.name+")"
			    }

			    let priceUnit = reservation.priceResume.unit;

			    let montant = reservation.priceResume.amount;

			    let promotion = reservation.priceResume.discount+' %';

			    let commissionRate = 0;
			    let commission = 0;
			    let offre = {name:" ", commissionRate:0};

			    if(reservation.salon.abonnement){
			    	commissionRate = reservation.salon.abonnement.commissionRate;
			    	// commission = reservation.priceResume.reducedAmount * (commissionRate/100);
                    commission = reservation.prestation && reservation.prestation.specialCommision && reservation.prestation.specialCommision.isActive ?
                        reservation.prestation.specialCommision.amount :
                        (reservation.priceResume.reducedAmount) * commissionRate / 100  

			    	offre.name = reservation.salon.abonnement.name;
			    	offre.commissionRate = reservation.salon.abonnement.commissionRate;

			    	if(reservation.salon.abonnement.promotion &&
			    		 (!reservation.prestation || (reservation.prestation && (!reservation.prestation.specialCommision || (reservation.prestation.specialCommision && !reservation.prestation.specialCommision.isActive))) )
            ){
			    		
			    		if(reservation.salon.abonnement.promotion.promotionStartDate && reservation.salon.abonnement.promotion.promotionEndDate && reservation.salon.abonnement.promotion.promotionStartDate <= reservation.executionDateTimestamp && reservation.salon.abonnement.promotion.promotionEndDate >= reservation.executionDateTimestamp){
			    			
			    			if(reservation.salon.abonnement.promotion.commissionRate || reservation.salon.abonnement.promotion.commissionRate == 0){
			    				
			    				commissionRate = reservation.salon.abonnement.promotion.commissionRate;
			    				commission = reservation.priceResume.reducedAmount * (commissionRate/100);
			    				offre.commissionRate = reservation.salon.abonnement.promotion.commissionRate;

			    			}

			    		}

			    	}
			    	
			    }


			    let total = reservation.priceResume.reducedAmount;

			    let data = {
			    	date: date,
                    prestation: prestationLabel,
                    prestationInfos: reservation.prestation,
			    	variant: prestationVariant,
			    	montant: montant+" "+priceUnit,
			    	promotion: promotion,
			    	commission: commission+" "+priceUnit,
			    	offre: offre.commissionRate+"% "+offre.name,
			    	total: total+" "+priceUnit
			    }

			    return {...data};

			});


			this.dtTrigger.next();
			
			this.loading.data = false;

		});

	}



	onSelectFilterStartDate(event){
		if(event.date != "" && event.date._d.getTime()){
			this.startDate = event.date._d.getTime();
		}else{
			this.startDate = null
		}
		this.dtTrigger.unsubscribe();
		this.getListTransaction(false, this.startDate, this.endDate);
	}

	onSelectFilterEndDate(event){
		if(event.date != "" && event.date._d.getTime()){
			this.endDate = event.date._d.getTime();
		}else{
			this.endDate = null
		}
		this.dtTrigger.unsubscribe();
		this.getListTransaction(false, this.startDate, this.endDate);
	}

}
