import { IChat } from "./chat-admin.model";
import { Deserializable } from "./deserializable.model";
import { PrestationVariant } from './prestation.model';
import { Address } from "./address.model";

export class Reservation implements Deserializable {
	rid: string;
	salon: { sid: string; name: string; status: ReservationStatus; address?: Address; alwaysAvailable?: boolean, abonnement?: { aid: string, name: string, price: number, priceUnit: string, commissionRate: number, promotion?: { promoId: string, price: number, commissionRate: number, promotionStartDate?: number, promotionEndDate?: number } | null } };
	client: {uid: string; label: string; status: ReservationStatus};
	prestation: {pid: string; name: string; variant:PrestationVariant; specialCommision?: {isActive: boolean, amount: number} ; intLabel?: any; defaultPrestationId?: string };
	executionDate: string;
	executionDateTimestamp: number;
	executionHour: string;
	notes: {prestation: number; service: number; punctuality: number; cleanliness: number, comment: string};
	atHome: {enable: boolean;infos?: string;address: string};
	createdAt: Date;
	updatedAt: Date;
	updatedBy: "client" | "salon";
	priceResume: {discount: number; amount: number; reducedAmount: number, unit: string;};
	viewedBy: string = "client";

	constructor() {

	}

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	}

	get averageNote(): number {
		let result = -1;

		if(this.notes) {
			result = Math.round((this.notes.prestation + this.notes.service + this.notes.punctuality + this.notes.cleanliness)/4);
		}

		return result;
	}

	setExecutionDateHour(date: string, hour: string) {
		this.executionDate = date;
		this.executionHour = hour;
		this.executionDateTimestamp = new Date(date + " " + hour).getTime();
	}

}

export class ReservationDetail extends Reservation implements IChat {
	discussion: {user:{uid: string; label: string}, message: string, createdAt: any, read?: boolean}[];
	cancelMessage?: string;
	cancelInfo?: {date: any, message: string, originalStatus: {client: ReservationStatus; salon: ReservationStatus}};

	constructor() {
		super();
	}

	putFirstMessage(message: string) {
		this.discussion = [];

		this.discussion.push({
			user: this.client,
			message: message,
			createdAt: new Date(),
			read: false
		});

	}

	putMessage(user: {uid: string; label: string}, message: string) {
		
		if (!this.discussion)
			this.discussion = [];

		this.discussion.push({
			user: user,
			message: message,
			createdAt: new Date(),
			read: false
		});

	}

	setClient(client: {uid: string; label: string; status: ReservationStatus} , isFirst=false) {
		this.client = client;
		
		if (isFirst && this.discussion && this.discussion.length > 0 && !this.discussion[0].user) {
			this.discussion[0].user = client;
		}
	}

	setDiscussionsAsReaded(uid): boolean {
		
		let updated = false;

		if (this.discussion) {
			this.discussion.forEach(disc => {
				if (disc.user.uid !== uid && !disc.read){
					disc.read = true;
					updated = true;
				}
			});
		}

		return updated;

	}

	get statusLabel(): string {

		const status = this.viewedBy === "client" ? this.client.status : this.salon.status;

		switch (status) {
			case ReservationStatus.UP_COMING:
				return "Prochaine mission";
			case ReservationStatus.ARCHIVED:
				return "Mission archivé";
			case ReservationStatus.ACCOMPLISHED:
				return "Mission accomplie";
			case ReservationStatus.CANCELED:
				return "Annulé";
			case ReservationStatus.CANCELED_BY_CLIENT:
				return "Annulé par le client";
			case ReservationStatus.CANCELED_BY_SALON:
				return "Annulé par le salon";
			case ReservationStatus.EXPIRED:
				return "Expiré";
			case ReservationStatus.NOT_CONFIRMED:
				return "Non confirmé";
			case ReservationStatus.EDITED:
				return this.viewedBy === "client" ? "Modifié par le salon" : "Modifié";
			default:
				return "";
		}

	}

	discussionNotReadCount(uid: string): number {
		let count = 0;

		if (this.discussion) {
			this.discussion.forEach(disc => {
				if (disc.user.uid !== uid && !disc.read){
					count++;
				}
			});
		}

		return count;
	}

	discussionCount(): number {
		return this.discussion ? this.discussion.length : 0;
	}

}

export enum ReservationStatus {
	UP_COMING = "upcoming",
	ARCHIVED = "archived",
	ACCOMPLISHED = "accomplished",
	CANCELED = "canceled",
	CANCELED_BY_CLIENT = "canceled_by_client",
	CANCELED_BY_SALON = "canceled_by_salon",
	EXPIRED = "expired",
	NOT_CONFIRMED = "notConfirmed",
	EDITED = "edited"
}