import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FbAlbum, FbAlbumCover, FbAlbumPhoto, FbImage } from '../models/facebook.model';
@Injectable({
  providedIn: 'root'
})

export class FacebookGraphService {

  static API_URL="https://graph.facebook.com/v11.0/";
  public accessToken: string = "";

  constructor(private http: HttpClient) { 

  }

  buildUrl(path: string, params = null) {
    return FacebookGraphService.API_URL + `${path}?access_token=${this.accessToken}${params || ""}`;
  }

  getAlbums(): Observable<HttpResponse<{data:FbAlbum[]; paging: any;}>> {
    return this.http.get<any>(this.buildUrl("me/albums"), {observe: 'response'});
  }

  getAlbumCover(albumId): Observable<HttpResponse<FbAlbumCover>> {
    return this.http.get<any>(this.buildUrl(albumId, '&fields=picture'), {observe: 'response'});
  }

  getAlbumImages(albumId): Observable<HttpResponse<{data:FbAlbumPhoto[]; paging: any;}>> {
    return this.http.get<any>(this.buildUrl(`${albumId}/photos`), {observe: 'response'});
  }

  getImageLinks(imageId): Observable<HttpResponse<{images:FbImage[]; id: string;}>> {
    return this.http.get<any>(this.buildUrl(`${imageId}`,'&fields=images'), {observe: 'response'});
  }

  public get isAvailable(): boolean {
    return this.accessToken && this.accessToken !== "";
  }

}
