import { Injectable } from '@angular/core';
import { LoadingController , ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class BasicService {

	loader: HTMLIonLoadingElement; 
	
	constructor(
		public translate: TranslateService , 
		private loadingCtrl:LoadingController,
		private toastController:ToastController
	) {  }

  async showLoading(msg=this.translate.instant("Chargement...")){
    this.loader = await this.loadingCtrl.create({
      message: msg,
      cssClass: 'askeed-spinner'
      });  
    this.loader.present();
  }

  dismissLoading(){
    if(this.loader){
      this.loader.dismiss();
    }
  }
  
  presentToast(msg: string) {
    this.toastController.create({
      message: msg,
      duration: 3000
    }).then(toast => toast.present());
  }

}
