import { Image } from './image.model';
import { Address } from './address.model';
import { Availability, AvailabilityState } from './availability.model';
import * as moment from 'moment';
import { Deserializable } from "./deserializable.model";
import { PrestationVariant, PrestationDetail } from "./prestation.model";
import * as lodash from 'lodash';
import { TypePrestation } from './defaultPrestation.model';

/**
 * L'ensemble des resultats salons (accueil, galleries, Maps) 
 * sont retrouvés avec la Geoquery (latitude, longitude) 
 * par rapport à la position de l'utilisateur
 */

export class Salon implements Deserializable{
    sid: string;
    name: string;
    phoneNumber: string;
    email: string;
    isEntreprise: boolean;
    skills: string[] = []; //Tags libre ?
    experiences: ExperienceSalon; //Années
    adresses: Address[];
    likers: {uid: string, date: Date}[]; //Tableaux des id des utilisateur
    _profilImageUrl: string;
    tags: string[] = [];
    categories: string[]; // id Categories prestation proposées par le salon ENUM
    badge: string;
    open: boolean;
    notes: {value: number; comment: string; uid: string; date: Date; details?: any; prestation?: {pid: string; name: string; variant: PrestationVariant; intLabel?:any , defaultPrestationId?: string  }}[];
    promotion: boolean;
    promotionEndDateEnabled: boolean =  false;
    promotionEndDate: Date;
    promotionMaxValue:number;
    isVerified: boolean;
    isClosed: boolean;
    isDeactivated: boolean;
    distance: number;
    price: number;
    acceptCGA?: boolean;

    geohash : number;
    lat : number;
    lng : number;
    slug: string;
    viewCount: number = 0;
    catsList: string;

    dev_mode?: boolean;

    // specialite: PrestationCategorie;
 
    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }

    get profilImageUrl() {
        if(!this._profilImageUrl 
          || this._profilImageUrl.includes("fbcdn")
          || !this._profilImageUrl.includes('https') 
          || (this._profilImageUrl.includes('https') && this._profilImageUrl.includes('_480x480'))){
            return this._profilImageUrl;
        }
        let re = new RegExp("(.+)\\.(gif|png|jpg|jpeg)", "g");

        let url =  this._profilImageUrl.replace(re, "$1_480x480.$2");

        if(!url.includes('_480x480')){
          url = url.split('?')[0] + '_480x480?' + url.split('?')[1];
        }
        url = url.replace(/\.png|\.gif|\.jpg/g,'.jpeg');
        return url;
    }

    set profilImageUrl(url: string){
        this._profilImageUrl = url;
    }

    get profilImageUrl_medium(){

        if(!this._profilImageUrl || this._profilImageUrl.includes("fbcdn") || !this._profilImageUrl.includes('https') || (this._profilImageUrl.includes('https') && this._profilImageUrl.includes('_240x240') ) ){
            return this._profilImageUrl;
        }
        
        let re = new RegExp("(.+)\\.(gif|png|jpg|jpeg)", "g");
        let url =  this._profilImageUrl.replace(re, "$1_240x240.$2");
        url = url.replace("_480x480", "");
        url = url.replace("_120x120", "");
        if(!url.includes('_240x240')){
            url = url.split('?')[0] + '_240x240?' + url.split('?')[1];
        }
        url = url.replace(/\.png|\.gif|\.jpg/g,'.jpeg');
        return url;
        
    }

    get profilImageUrl_small(){

        if(!this._profilImageUrl || this._profilImageUrl.includes("fbcdn") || !this._profilImageUrl.includes('https') || (this._profilImageUrl.includes('https') && this._profilImageUrl.includes('_120x120') ) ){
            return this._profilImageUrl;
        }
        
        let re = new RegExp("(.+)\\.(gif|png|jpg|jpeg)", "g");
        let url =  this._profilImageUrl.replace(re, "$1_120x120.$2");
        url = url.replace("_480x480", "");
        url = url.replace("_240x240", "");
        if(!url.includes('_120x120')){
            url = url.split('?')[0] + '_120x120?' + url.split('?')[1];
        }
        url = url.replace(/\.png|\.gif|\.jpg/g,'.jpeg');
        return url;

    }

    get likersCount() : number{
      return this.likers && this.likers.length > 0 ? this.likers.length : 0;
    }

    get mainAddress() : Address {
        let result: Address = null;
        for (let addr of this.adresses) {
            if ( addr.isMain ){
                result = addr;
                break;
            }
        }

        if (!result) {
          result = this.adresses[0];
        }

        return result;
    }
    

    get randomNote(): {value: number, comment: string, uid: string, date: Date} {
      // Math.round(Math.random()*(this.notes.length-1))
      return this.notes && this.notes.length > 0 ? this.notes[this.notes.length - 1] : null;
    }

    get mainCityAndCountry(){
        let mainAddress = this.mainAddress;
        let cityAndCountry = mainAddress?.formatted_address;

        if(mainAddress.locality){
            cityAndCountry = mainAddress.locality+", "+mainAddress.country;
        }

        return cityAndCountry;
    }

    get formatted_address_2(): string{
      let address = this.adresses[0];
      return address.route +' '+address.street_number+', '+address.postal_code+' '+address.locality;
    }


}

export class SalonDetail extends Salon{
    // users: [User];
    ownerUserId: string; // uid user
    bio: string;
    salonPresentations: Image[] = [];
    salonGalleries: Image[] = [];
    isMoving: boolean; //Defini si l'utilisateur se déplace ou non a domicile
    movingPrice: number; //Defini le prix par kilimotère du déplacement a domicile
    movingMaxDistance: number//Distance maximal admi pour la prestation à domicile
    isDiscountEnabled: boolean;
    prestations: string[] = []; // pids prestations
    isAgendaEnabled: boolean;
    availability: Availability;
    unavailabilities: {date, state: AvailabilityState}[]; // Jours et heure non ouvrés
    reservations: string[]; // rid reservations
    createdAt: Date;
    updatedAt: Date;
    pricePrestation = {
      "min": 50,
      "max": 100
    };
    priceUnit: string = 'CHF';
    tagsPrestations: string[] = [];
    categoriesPrestation: string[] = [];
    typesPrestations: string[] = [];
    targetsPrestations = {
        "men":false,
        "women":false,
        "child":false
    };
    movingPrestations = {
        "atHome": false,
        "atSalon": true,
        "both": false
    };
    identity: { card: {imgUrl: string; status: IdentityStatus}; tradeRegister?: {imgUrl: string; status: IdentityStatus}, proofOfDomicile?: {imgUrl: string; status: IdentityStatus} };
    abonnement: { formule: string|null, promotion: string|null, promotionStartDate?: number, promotionEndDate?: number };
    
    referralCommercialId?: string;
    dateStartOfCommercialReferrant?: number;

    prestationsList?: PrestationDetail[] = [];

    constructor() {
        super();
        this.isEntreprise = false;
        this.open = true;
        this.unavailabilities = [];
    }

    public static salonDetailFactory(object): SalonDetail {
        const salon = new SalonDetail();

        for (let key in object) {
          if (object[key])
            salon[key] = object[key];
        } 

        return salon;
    }

    public static getMainAddress(salon): Address {
        let result: Address = null;
        for (let addr of salon.adresses) {
            if ( addr.isMain ){
                result = addr;
                break;
            }
        }
        return result;
    }


    deserialize(input: any) {
      Object.assign(this, input);
      this.categoriesPrestation =  lodash.union(this.categories, this.categoriesPrestation);
      this.salonPresentations = this.salonPresentations.map((val) => new Image(val._url).deserialize(val));
	     return this;
	  }

    getImagePath(filePath = '') {
        return 'salon/' + this.sid + '/' + filePath;
    }

    getProfilImagePath(fileName){
        return this.getImagePath('profil/' + fileName);
    }

    getIdentityImagePath(fileName){
        return this.getImagePath('identity/' + fileName);
    }

    getGallerieImagePath(fileName){
        return this.getImagePath('gallerie/' + fileName);
    }

    indexOfUnavailabilityDate(dateStr): number {

        let indexOfDate = this.unavailabilities.map(unavailability => unavailability.date).indexOf(dateStr);
        return indexOfDate;

    }

    setUnavailabilityDateState(unavailability) {

        const indexOfDate = this.indexOfUnavailabilityDate(unavailability.date);

        if (indexOfDate === -1) {
            this.unavailabilities.push(unavailability);
        } else {
            this.unavailabilities[indexOfDate] = unavailability;
        }

    }

    getNotes(){
        let res = 0;
        if (this.notes){
            let sum = 0;
            this.notes.forEach((n) => {
                res += n.value;
                sum ++;
            });
            res /= sum;
        }
        return res;
    }

    get availabilityLabel(): string {
        if (!this.availability)
          return;

        let result = "";
        let keyPosition = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        let j = 0;

        for (let i = 0; i < keyPosition.length; i++) {

            if (this.availability[keyPosition[i]].available) {
                if ( j !== 0 ) {
                    result += "-";
                }

                result += (keyPosition[i].charAt(0).toUpperCase() + keyPosition[i].slice(1)).substr(0, 3);
                
                j++;
            }
        }

        return result;

    }

    get sevenLastDaysLikers(): number {
       let result = 0;

       if (!this.likers || this.likers.length == 0)
           return result; 

       let sevenLastDays = [moment().format("YYYY-MM-DD")];

       for (let i = 1; i < 7; i++) {
           sevenLastDays.push(moment().subtract(i, 'd').format("YYYY-MM-DD"));
       }

       for (let i = 0; i < this.likers.length; i++) {

           if (sevenLastDays.includes(moment(new Date(this.likers[i].date)).format("YYYY-MM-DD"))) {
               result += 1;
           }
       }

       return result;
    }
	
	get likePercentEvolutionBetweenWeeks(): number {
      let result = 0;

      const currentWeekLikers = this.sevenLastDaysLikersPerDay.reduce((cumul, value) => cumul+value);
      const prevWeekLikers = this.sevenLastDaysLikersPerDayOfPreviousWeek.reduce((cumul, value) => cumul+value);
      
      if(currentWeekLikers === prevWeekLikers) {
        result = 0;
      }else if (prevWeekLikers > 0) {
        result = ((currentWeekLikers-prevWeekLikers)*100)/prevWeekLikers;
      } else {
        result = 100;
      }

      return result;
    
	}
	
    get sevenLastDaysLikersPerDay(): number[] {
       let result = [0, 0, 0, 0, 0, 0, 0];

       if (!this.likers || this.likers.length == 0)
           return result; 

       const currentDate = moment();

       let sevenLastDays = [currentDate.endOf("week").format("YYYY-MM-DD")];

       for (let i = 1; i < 7; i++) {
           sevenLastDays.push(moment(currentDate.clone().endOf("week")).subtract(i, 'd').format("YYYY-MM-DD"));
       }

       for (let i = 0; i < this.likers.length; i++) {

           const indexOfDate = sevenLastDays.indexOf(moment(new Date(this.likers[i].date)).format("YYYY-MM-DD"));

           if (indexOfDate !== -1) {
               result[new Date(sevenLastDays[indexOfDate]).getDay()] += 1;
           }
       }

       return result;
    }
	
	get sevenLastDaysLikersPerDayOfPreviousWeek(): number[] {
      let result = [0,0,0,0,0,0,0];

      if (!this.likers || this.likers.length == 0)
          return result; 

      const currentDate = moment().subtract(1, 'd');

      let sevenLastDays = [];

      for (let i=1; i<= 7;i++) {
          sevenLastDays.push(moment(currentDate.clone().startOf("week")).subtract(i, 'd').format("YYYY-MM-DD"));
      }

      for (let i=0; i< this.likers.length; i++) {

          const indexOfDate = sevenLastDays.indexOf(moment(new Date(this.likers[i].date)).format("YYYY-MM-DD"));

          if (indexOfDate !== -1) {
              result[new Date(sevenLastDays[indexOfDate]).getDay()] += 1;
          }
      }

      return result;
   }

    get averageNote(): number {
        let result = 0;

        if (!this.notes || this.notes.length == 0)
            return result;

        let sum = 0;

        for (let note of this.notes) {
            sum += note.value;
        }

        result = Math.round(sum / this.notes.length);

        return result;
    }

    addAchievement(achievement: Achievement) {
      if (!this.salonGalleries) {
        // this.achievements = [];
        this.salonGalleries = [];
      }

      // this.achievements.push(achievement);

      this.salonGalleries = [...this.salonGalleries, ...Image.fromAchievement(achievement)];

    }

    updateAchievement(achievement: Achievement) {
      
      this.salonGalleries[this.salonGalleries.map(image => image.url).indexOf(achievement.images[0].url)] = Image.fromAchievement(achievement)[0];

    }

    objectify() {

/*      if (this.salon) {

        for (let i = 0; i < this.achievements.length; i++) {
          this.achievements[i] = {...this.achievements[i]} as Achievement;
        }

      }*/

    }

    get resume(): string {
      return this.name + ", " + this.adresses[0].formatted_address;
    }

    isLikedByUser(uid: string) {
      if (!this.likers)
        return false;

      return this.likers.map(liker => liker.uid).includes(uid);
    }

    likeIt(uid: string) {
      
      if (this.isLikedByUser(uid)) {
        this.likers = this.likers.filter(liker => liker.uid !== uid);        
      } else {
        
        if (!this.likers)
          this.likers = [];

        this.likers.push({
          uid: uid,
          date: new Date()
        });
      }

    }

    addPresentation(image: Image) {
      
      if (!this.salonPresentations)
        this.salonPresentations = [];

      if (!this.profilImageUrl)
        this.profilImageUrl = image.url;

      if (!this.salonPresentations.map(presentation => presentation.url).includes(this.profilImageUrl))
        this.salonPresentations.unshift(new Image(this.profilImageUrl));

      this.salonPresentations.push(image);

    }

    removePresentation(image) {

      this.salonPresentations = this.salonPresentations.filter(presentation => presentation !== image);

      if (image.url == this.profilImageUrl) {
        this.profilImageUrl = this.salonPresentations.length > 0 ? this.salonPresentations[0].url : null;
      }

    }
	
	get strSkills(): string {
      if (this.skills) {
        return this.skills.join("/");
      } else {
        return "-";
      }
    }

    get strCategories(): string {
      if (this.categories) {
        return this.categories.join("/");
      } else {
        return "-";
      }
    }

}

export enum CategorySalon {
    COIFFURE = "coiffure",
    ESTHETIC = "esthetic"
}

export enum ExperienceSalon {
    BEGINNER = "beginner",
    PROFESSIONAL = "professional",
    EXPERT = "expert"
}

export class Achievement {
    title ?: string;
    clientName: string;
    prestation: string; // pid prestation
    description: string;
    tags: string[];
    dateTime: Date;
    note: number;
    advice: string;
    images: Image[];

    constructor() {

    }

    addImage(imageUrl) {

      if (!this.images) {
        this.images = [];
      }

      this.images.push(new Image(imageUrl));

    }

    bindImage(image: Image) {
      this.title = image.title;
      this.description = image.description;
      this.tags = image.tags;
      this.prestation = image.pid || null;
      this.images = [image];

      return this;
    }

}

export enum IdentityStatus {
  NOT_CONFIRMED = "not_confirmed",
  VALID = "valid",
  NOT_VALID = "not_valid"
}

export enum SalonStatus {
  PROFESSIONAL = "professional",
  NORMAL = "normal",
  WAITING_VERIFICATION = "en_attente_verification",
  NO_SALON = "pas_de_salon"
}