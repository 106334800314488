import { Component, OnInit, Input, ViewChild, ElementRef , OnDestroy} from '@angular/core';
import { FacturationService } from 'src/app/services/facturation.service';
import { FacturationSalon, FacturationStatus } from 'src/app/models/facturation-salon.model';
import { DatePipe } from '@angular/common';
// import * as jspdf from 'jspdf';
// import html2canvas from 'html2canvas';
import { IonContent, LoadingController, ToastController, AlertController, IonGrid, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from "rxjs"
import { Config } from "src/app/models/invoice-config.model"

@Component({
  selector: 'app-facture-viewer',
  templateUrl: './facture-viewer.component.html',
  styleUrls: ['./facture-viewer.component.scss'],
})
export class FactureViewerComponent implements OnInit {

	@ViewChild('factureElement') factureElement: ElementRef;

	_facture: FacturationSalon;

	@Input() 
	set facture (facture: FacturationSalon) {
	    this._facture = facture;
	    
	    if(this._facture){
	    	this.getFactureData();
	    } 
	}

  	get facture(): FacturationSalon {
	    return this._facture;
  	}

  	factureData: {number: string, date: string, abonnement: {name:string, commission: number, price: number, inPromotion: boolean}, destinataire:{name: string, coordonnee: string, salonName: string, coordonneSalon: string}, prestation: {description: string, montant: string, commission: string}[], priceResume: {subTotal: number, discount: number, reducedAmount: number, tax: {rate: number, amount: number , country?:string}, total: number}, unit: string}
      loading = {
          data: false,
        config:false
      }
    configSubscriber : Subscription;
    invoiceConfig :Config;

  	constructor(
  		public translate: TranslateService,
  		private facturationService: FacturationService,
  		private alertController: AlertController,
  		private loadingController: LoadingController, 
  		private toastController: ToastController, 
  		public modalController: ModalController
	) { }

  	ngOnInit() {
        this.getConfig();
    }

    ngOnDestroy(){
        if(this.configSubscriber)this.configSubscriber.unsubscribe();
    }    

    getConfig(){
        this.loadingController.create({
          message: this.translate.instant('Chargement de la facture...'),
          cssClass: 'askeed-spinner'
        }).then(loading =>{
        	loading.present();
	        this.loading.config = true;
	        this.configSubscriber = this.facturationService.getConfig().subscribe(config => {
	            this.invoiceConfig = new Config().deserialize(config);
	            this.loading.config = false;
	            loading.dismiss();
	        })
        });

    }	

  	getFactureData(){

  		this.loading.data = true;

  		this.factureData = {
	  		number: "",
	  		date: "",
	  		abonnement:{
	  			name: "",
	  			commission: 0,
	  			price: 0,
	  			inPromotion: false
	  		},
	  		destinataire:{
	  			name: "",
	  			coordonnee: "",
	  			salonName: "",
	  			coordonneSalon: ""
	  		},
	  		prestation: [],
	  		priceResume:{
	  			subTotal: 0,
	  			discount: 0,
	  			reducedAmount: 0,
	  			tax: {rate: 0, amount: 0},
	  			total: 0
	  		},
	  		unit: null
	  	}

	  	this.factureData.number = this.facture.facturationNumber;
	  	this.factureData.date = new DatePipe('fr-FR').transform( this.facture.facturationDate, "dd/MM/yyyy");
	  	this.factureData.unit = this.facture.priceResume.unit;

	  	if(this.facture.salon.abonnement){

  			this.factureData.abonnement.name = this.facture.salon.abonnement.name;
  			this.factureData.abonnement.commission = this.facture.salon.abonnement.commissionRate;
			this.factureData.abonnement.price = this.facture.salon.abonnement.price;

  			if(this.facture.salon.abonnementPromotion){
  				this.factureData.abonnement.price = this.facture.salon.abonnementPromotion.price;
  				if(this.facture.salon.abonnementPromotion.commissionRate || this.facture.salon.abonnementPromotion.commissionRate == 0){
  					this.factureData.abonnement.commission = this.facture.salon.abonnementPromotion.commissionRate;
  				}
  				this.factureData.abonnement.inPromotion = true;
  			}

  			if(!this.factureData.unit){
  				this.factureData.unit = this.facture.salon.abonnement.priceUnit ?  this.facture.salon.abonnement.priceUnit : 'CHF';
  			}
			
	  	}

	  	this.factureData.destinataire.name = this.facture.salonOwner.firstname+" "+this.facture.salonOwner.name;
	  	this.factureData.destinataire.coordonnee = this.facture.salonOwner.phoneNumber+" - "+this.facture.salonOwner.address[0].formatted_address;
	  	this.factureData.destinataire.salonName = this.facture.salon.name;
	  	this.factureData.destinataire.coordonneSalon = this.facture.salon.phoneNumber+" - "+this.facture.salon.adresses[0].formatted_address;

	  	this.factureData.priceResume.subTotal = this.facture.priceResume.subtotal;
	  	this.factureData.priceResume.discount = this.facture.priceResume.discount;
	  	this.factureData.priceResume.reducedAmount = this.facture.priceResume.totalWithDiscount;
	  	this.factureData.priceResume.tax = this.facture.priceResume.tax;
	  	this.factureData.priceResume.total = this.facture.priceResume.total;

	  	this.factureData.prestation = this.facture.reservations.map((reservation) => {

	  		let data = {
	  			description: "", 
	  			montant: 0+this.factureData.unit, 
	  			commission: 0+this.factureData.unit
	  		}

            let prestationName = (reservation.prestation.intLabel && reservation.prestation.intLabel[this.translate.currentLang] && reservation.prestation.intLabel[this.translate.currentLang]) ? reservation.prestation.intLabel[this.translate.currentLang] : reservation.prestation.name;

	  		if(reservation.prestation.variant){
	  			prestationName += " ("+reservation.prestation.variant.name+")";
	  		}
	  		let datePrestation =  new DatePipe('fr-FR').transform(new Date(reservation.executionDateTimestamp), "dd/MM/yyyy");

	  		data.description = "Prestation : "+prestationName+" du "+datePrestation;
	  		data.montant = reservation.priceResume.reducedAmount+" "+this.factureData.unit;

	  		// let commission = reservation.priceResume.reducedAmount * (this.factureData.abonnement.commission/100);
        let commission = reservation.prestation && reservation.prestation.specialCommision && reservation.prestation.specialCommision.isActive ?
                    reservation.prestation.specialCommision.amount :
                    (reservation.priceResume.reducedAmount) * this.factureData.abonnement.commission / 100  
              data.commission = commission+" "+this.factureData.unit;

	  		return data;

	  	});

	  	this.loading.data = false;
  	}

  	async downloadFacture(){

      const loading = await this.loadingController.create({
	      message: this.translate.instant('Téléchargement de la facture...'),
	      cssClass: 'askeed-spinner'
	    });

	    loading.present();

      let elementFacture = document.getElementById("factureElement");

      let htmlData = elementFacture.innerHTML;
      let num = this.facture.facturationNumber;

      var mediaType = 'application/pdf';
      this.facturationService.getFacturePDF(htmlData, num).subscribe( async (data) => {

        const downloadBlobAsFile = function(data, filename){
            const contentType = 'application/pdf';
            if(!data) {
            		loading.dismiss();
			        	this.toastController.create({
			            	message: this.translate.instant('Une erreur est survenue lors de la téléchargement du PDF, veuillez réessayer'),
			            	duration: 3000
			          }).then(toastElt => toastElt.present());
                return;
            }

            if(!filename) filename = 'Facture.pdf'

            var blob = new Blob([data], {type: contentType}),
            e    = document.createEvent('MouseEvents'),
            a    = document.createElement('a')

            a.download = filename
            a.href = window.URL.createObjectURL(blob)
            a.dataset.downloadurl =  [contentType, a.download, a.href].join(':')
            e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(e)

        }

        let salonName = this.facture.salon.name ? this.facture.salon.name.toString().trim().replace(" ","_") : "";
        
        const fileName = "Facture_"+num.toString().trim()+"_Salon_"+salonName+".pdf";
        await downloadBlobAsFile(data, fileName);

        loading.dismiss();

      }, error => {

        loading.dismiss();
        // TODO console.log(error);
        this.toastController.create({
        	message: 'Erreur : ' + error.message,
        	duration: 3000
        }).then(toastElt => toastElt.present());

      }); 

    }

    async oldDownloadFacture(){

  		const loading = await this.loadingController.create({
	      message: this.translate.instant('Téléchargement de la facture...'),
	      cssClass: 'askeed-spinner'
	    });

	    loading.present();

  		let elementFacture = document.getElementById("factureElement");

  		let htmlData = elementFacture.innerHTML;
  		let num = this.facture.facturationNumber;


  		this.facturationService.getFacturePDF(htmlData, num).subscribe(data => {
  			loading.dismiss();
        }, error => {

        	loading.dismiss();

        	this.toastController.create({
            	message: 'Erreur : ' + error.message,
            	duration: 3000
          }).then(toastElt => toastElt.present());

        });

  	}

	dismiss() {
        this.modalController.dismiss();
    }

    get tva(){
          if(this.factureData?.priceResume?.tax?.country && this.invoiceConfig?.allTax?.tva){
              return this.invoiceConfig.allTax.tva[ this.factureData?.priceResume?.tax?.country ] || this.invoiceConfig.allTax.tva['ch'];
          }else if(this.invoiceConfig?.allTax?.tva?.ch)
              return this.invoiceConfig.allTax.tva.ch
          return '-';
    }

}
