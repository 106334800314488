import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { CommonInputComponent } from './components/common-input/common-input.component';
import { CommonSelectComponent } from './components/common-select/common-select.component';
import { CommonCitySelectComponent } from './components/common-city-select/common-city-select.component';
import { CommonCheckboxComponent } from './components/common-checkbox/common-checkbox.component';
import { CommonInfoLabelComponent } from './components/common-info-label/common-info-label.component';
import { CommonTextareaComponent } from './components/common-textarea/common-textarea.component';
import { DayButtonComponent } from './components/day-button/day-button.component';
import { AddRowButtonComponent }  from './components/add-row-button/add-row-button.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';

import { TranslateModule } from "@ngx-translate/core";
import { CalendarModule } from "ion2-calendar";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IonicRatingModule } from 'ionic4-rating';
import { AutoCompleteModule } from 'ionic4-auto-complete';

import { HeaderComponent } from './components/header/header.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SalonCardComponent } from './components/salon-card/salon-card.component';
import { GalleryCardComponent } from './components/gallery-card/gallery-card.component';
import { FilterSidebarComponent } from './components/filter-sidebar/filter-sidebar.component';
import { HeaderAccueilComponent } from './components/header-accueil/header-accueil.component';
import { MapSalonCardComponent } from './components/map-salon-card/map-salon-card.component';
import { ItemPrestationComponent } from './components/item-prestation/item-prestation.component';
import { MenuComponent } from './components/menu/menu.component';
import { SalonInfoComponent } from './components/salon-info/salon-info.component';
import { CitiesSidebarComponent } from './components/cities-sidebar/cities-sidebar.component';
import { AvisItemComponent } from './components/avis-item/avis-item.component';
import { RatingComponent } from './components/rating/rating.component';
import { ButtonComponent } from './components/button/button.component';
import { CommentInputComponent } from './components/comment-input/comment-input.component';
import { CommentComponent } from './components/comment/comment.component';
import { SalonInfoHeaderComponent } from './components/salon-info-header/salon-info-header.component';
import { CommonInputTagComponent } from './components/common-input-tag/common-input-tag.component';
import { AgendaPrestationComponent } from './components/agenda-prestation/agenda-prestation.component';
import { CategoriesCardComponent } from './components/categories-card/categories-card.component';
import { TabsHomeComponent } from './components/tabs-home/tabs-home.component';
import { ItemSalonComponent } from './components/item-salon/item-salon.component';
import { PromotionEditComponent } from './components/promotion-edit/promotion-edit.component';
import { TimeAgoPipe } from './helpers/pipes/time-ago.pipe';
import { DistancePipe } from './helpers/pipes/distance.pipe';
import { DurationPipe } from './helpers/pipes/duration.pipe';

import { LoaderComponent } from './components/loader/loader.component';
import { PrestationCardComponent } from './components/prestation-card/prestation-card.component';
import { ConnectionFailedComponent } from './components/connection-failed/connection-failed.component';
import { ItemGalleryComponent } from './components/item-gallery/item-gallery.component';
import { MapFilterCardComponent } from './components/map-filter-card/map-filter-card.component';
import { IonicSelectableModule } from 'ionic-selectable';
 
import { ChatItemComponent } from './components/chat-item/chat-item.component';
import { NewsPrestationComponent } from './components/news-prestation/news-prestation.component';
import { NewsPromotionComponent } from './components/news-promotion/news-promotion.component';
import { TabsModalComponent } from './components/tabs-modal/tabs-modal.component';
import { CityCardComponent } from './components/city-card/city-card.component';
import { ImagesUploadComponent } from './components/images-upload/images-upload.component';
import { ShareLinkComponent } from './components/share-link/share-link.component';
import { NotificationsContainerComponent } from './components/notifications-container/notifications-container.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AbonnementItemComponent } from './components/abonnement-item/abonnement-item.component';
import { CguSalonComponent } from './components/cgu-salon/cgu-salon.component';
import { SalonCaGraphComponent } from './components/salon-ca-graph/salon-ca-graph.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ChartsModule } from 'ng2-charts';
import { SalonHistoriqueFacturationComponent } from './components/salon-historique-facturation/salon-historique-facturation.component';
import { DataTablesModule } from 'angular-datatables';
import { FactureViewerComponent } from './components/facture-viewer/facture-viewer.component';
import { SalonAbonnementEditComponent } from './components/salon-abonnement-edit/salon-abonnement-edit.component';

import { BankInfoCardComponent } from './components/bank-info-card/bank-info-card.component';

import {ImagePicker} from '@ionic-native/image-picker/ngx';
import { FacebookImagesImportComponent } from './components/facebook-images-import/facebook-images-import.component';
import { SearchDetailComponent } from './components/search-detail/search-detail.component';
import { UserReservationItemComponent } from './components/user-reservation-item/user-reservation-item.component';
import { FinalizeReservationDoneComponent } from './components/finalize-reservation-done/finalize-reservation-done.component';
import { LanguageChoiceComponent } from './components/language-choice/language-choice.component';
import { ImagesLazyloadModule }  from './directives/images-lazyload/images-lazyload.module';
import { ItemSalonPrestationComponent } from './components/item-salon-prestation/item-salon-prestation.component';
import { PrestationPipe } from './helpers/pipes/prestation-filter.pipe';
import { MailRequestModalComponent } from 'src/app/shared/components/mail-request-modal/mail-request-modal.component'


@NgModule({
    imports:[
        IonicModule,
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CalendarModule,
        LeafletModule,
        IonicRatingModule,
        IonicSelectableModule,
        AutoCompleteModule,
        DpDatePickerModule,
        ChartsModule,
        DataTablesModule,
        ImagesLazyloadModule,
        

    ],
  declarations:[ItemSalonComponent, MapFilterCardComponent,MapSalonCardComponent,HeaderAccueilComponent,HeaderComponent, CommonHeaderComponent, CommonInputComponent, CommonSelectComponent, CommonCheckboxComponent, CommonInfoLabelComponent, CommonTextareaComponent, DayButtonComponent, AddRowButtonComponent, SectionTitleComponent, SearchBarComponent, SalonCardComponent, FilterSidebarComponent, GalleryCardComponent, ItemPrestationComponent, MenuComponent, SalonInfoComponent,CitiesSidebarComponent, AvisItemComponent, RatingComponent, ButtonComponent, CommentComponent, CommentInputComponent,SalonInfoHeaderComponent, CommonInputTagComponent, AgendaPrestationComponent, TimeAgoPipe, LoaderComponent, DistancePipe, CommonCitySelectComponent, PrestationCardComponent, CategoriesCardComponent, TabsHomeComponent, ConnectionFailedComponent, ItemGalleryComponent, DurationPipe ,PromotionEditComponent, ChatItemComponent,
        NewsPrestationComponent,
        NewsPromotionComponent,
        TabsModalComponent,
        CityCardComponent,
        ImagesUploadComponent,
        ShareLinkComponent,
        NotificationsContainerComponent,
        NotificationCardComponent,
        AbonnementItemComponent,
        CguSalonComponent,
        SalonCaGraphComponent,
        SalonHistoriqueFacturationComponent,
        FactureViewerComponent,
        SalonAbonnementEditComponent,
        BankInfoCardComponent,
        FacebookImagesImportComponent,
        SearchDetailComponent,
        UserReservationItemComponent,
        FinalizeReservationDoneComponent,
      LanguageChoiceComponent,
      ItemSalonPrestationComponent,
      PrestationPipe,
      MailRequestModalComponent
  ],
  exports: [ItemSalonComponent, MapFilterCardComponent,MapSalonCardComponent,HeaderAccueilComponent,HeaderComponent, CommonHeaderComponent, CommonInputComponent, CommonSelectComponent, CommonCheckboxComponent, CommonInfoLabelComponent, CommonTextareaComponent, DayButtonComponent, AddRowButtonComponent, SectionTitleComponent, SearchBarComponent, SalonCardComponent, FilterSidebarComponent, GalleryCardComponent, ItemPrestationComponent, MenuComponent, SalonInfoComponent,CitiesSidebarComponent, AvisItemComponent, RatingComponent, ButtonComponent, CommentComponent, CommentInputComponent,SalonInfoHeaderComponent, CommonInputTagComponent, AgendaPrestationComponent, TimeAgoPipe, LoaderComponent, DistancePipe, CommonCitySelectComponent,PrestationCardComponent,CategoriesCardComponent, TabsHomeComponent, ConnectionFailedComponent, ItemGalleryComponent, DurationPipe, PromotionEditComponent, ChatItemComponent,
        NewsPrestationComponent,
        NewsPromotionComponent,
        TabsModalComponent,
        CityCardComponent,
        ImagesUploadComponent,
        ShareLinkComponent,
        NotificationsContainerComponent,
        NotificationCardComponent,
        AbonnementItemComponent,
        CguSalonComponent,
        SalonCaGraphComponent,
        SalonHistoriqueFacturationComponent,
        FactureViewerComponent,
        SalonAbonnementEditComponent,
        BankInfoCardComponent,
        FacebookImagesImportComponent,
        SearchDetailComponent,
        UserReservationItemComponent,
        FinalizeReservationDoneComponent,
      LanguageChoiceComponent,
      ItemSalonPrestationComponent,
      PrestationPipe,
      MailRequestModalComponent
  ],
    entryComponents: [ShareLinkComponent , LanguageChoiceComponent, NewsPrestationComponent, MailRequestModalComponent],
    providers: [
        SocialSharing
    ]

})
export class SharedModule { }