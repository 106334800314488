
export interface IChat {
    salon;
    client;
    prestation;
    discussion:{user:{uid: string; label: string; isAdmin?: boolean;}, 
    message: string, 
    file?:string, 
    fileName?:string,
    createdAt: Date, 
    read?: boolean, 
	isDeleted?:boolean; 
    isEdited?: boolean}[];
	executionDate?: any;
	executionHour?: any;
}

export class ChatAdmin implements IChat{
    cid: string;
    uid: string;
    discussion:{user:{uid: string; label: string; isAdmin?: boolean;}, message: string, file?:string, fileName?:string,createdAt: Date, read?: boolean, 
	isDeleted?:boolean; isEdited?: boolean}[];
    updatedAt: Date;
    salon = null;
    client = null;
    prestation = null;

    setDiscussionsAsReaded(uid): boolean {
		
		let updated = false;

		if (this.discussion) {
			this.discussion.forEach(disc => {
				if (disc.user.uid !== uid && !disc.read){
					disc.read = true;
					updated = true;
				}
			});
		}

		return updated;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    setDiscussionsAsReadedByType(uid, type:string): boolean {
    
        let updated = false;
    
        if(type=='DIVERS'){
            if (this.discussion) {
                this.discussion.forEach(disc => {
                    if (disc.user.uid === uid && !disc.read && (!(disc.message.startsWith("[RECLAMATION") || disc.message.startsWith("[BUG")))){
                        disc.read = true;
                        updated = true;
                    }
                });
            }
    
        }else{
            if (this.discussion) {
                this.discussion.forEach(disc => {
                    if (disc.user.uid === uid && !disc.read && disc.message.startsWith("["+type)){
                        disc.read = true;
                        updated = true;
                    }
                });
            }
    
        }
    
        return updated;

    }
    
    putMessage(user: {uid: string; label: string; isAdmin: boolean}, message: string) {
		
		if (!this.discussion)
			this.discussion = [];

		this.discussion.push({
			user: user,
			message: message,
			createdAt: new Date(),
			read: false
		});

	}

    putMessageWithFile(user: {uid: string; label: string; isAdmin: boolean}, message: string, file:string, fileName:string) {
        
        if (!this.discussion)
            this.discussion = [];

        const newMessage = {
            user: user,
            message: message,
            file:file,
            fileName:fileName,
            createdAt: new Date(),
            read: false
        };

        this.discussion.push(newMessage);

        return newMessage;

    }

    
    deleteDiscussion() {
        
        if (!this.discussion)
            this.discussion = [];

        this.discussion=[];

    }

	discussionNotReadCount(uid: string): number {
		let count = 0;

		if (this.discussion) {
			this.discussion.forEach(disc => {
				if (disc.user.uid !== uid && !disc.read){
					count++;
				}
			});
		}

		return count;
	}

    get lastMessage(): {user:{uid: string; label: string}, message: string, createdAt: Date, read?: boolean} {
        let message = null;
    
        
          message = this.discussion[this.discussion.length -1 ];

        return message;
    }

    get messageNotReadCount(): number {
        let count = 0;

    
        if (this.discussion) {

            this.discussion.forEach(disc => {
                if (!disc.read && disc.user.isAdmin==false){
                    count++;
                }
            });
        }
        return count;
    }


    get messageNotReadCountReclamation ():number{

            let t="[RECLAMATION";
            let count = 0;

            if (this.discussion) {

                this.discussion.forEach(disc => {
                    if (!disc.read && !disc.user.isAdmin && (disc.message.startsWith(t))){
                        count += 1;
                    }
                });
            }
            return count;
    }
     
    get messageNotReadCountBug ():number{

            let t="[BUG";
            let count = 0;

            if (this.discussion) {

                this.discussion.forEach(disc => {
                    if (!disc.read && !disc.user.isAdmin && (disc.message.startsWith(t))){
                        count += 1;
                    }
                });
            }
            return count;
    }

    get messageNotReadCountDivers ():number{

            let count = 0;

            if (this.discussion) {
                this.discussion.forEach(disc => {
                    if (!disc.read && disc.user.isAdmin==false && (!(disc.message.startsWith('[RECLAMATION') || disc.message.startsWith('[BUG')))){
                        count += 1;
                    }
                });
            }
            return count;
    }

    
    get diversLastMessage(): any{

          let lastMessage = null;
    
          if (this.discussion) {

              let allMessage = []

                this.discussion.forEach(disc => {
                    if ( !(disc.message.startsWith('[RECLAMATION') || disc.message.startsWith('[BUG') ) ){
                        allMessage.push(disc);
                    }
                });

                lastMessage = allMessage[allMessage.length -1 ]

            }

            return lastMessage;
    }

    get reclamationLastMessage(): any{

          let lastMessage = null;
    
          if (this.discussion) {

              let allMessage = []

                this.discussion.forEach(disc => {
                    if ( disc.message.startsWith('[RECLAMATION') ){
                        allMessage.push(disc);
                    }
                });

                lastMessage = allMessage[allMessage.length -1 ]

            }

            return lastMessage;
    }

    get bugLastMessage(): any{

          let lastMessage = null;
    
          if (this.discussion) {

              let allMessage = []

                this.discussion.forEach(disc => {
                    if ( disc.message.startsWith('[BUG') ){
                        allMessage.push(disc);
                    }
                });

                lastMessage = allMessage[allMessage.length -1 ]

            }

            return lastMessage;
    }

    getFilePath(filePath = '') {
        return 'chatAdmin/' + this.uid + '/' + filePath;
    }

    getPjPath(fileName){
        return this.getFilePath('pj/'+fileName);
    }


}