import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SalonDetail } from 'src/app/models/salon.model';
import { CategoryService } from 'src/app/services/category.service';
import { CitiesService } from 'src/app/services/cities.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { PrestationService } from 'src/app/services/prestation.service';
import { shortDayLabel, AvailabilityState } from 'src/app/models/availability.model';
import { IonSlides, ModalController } from '@ionic/angular';
import { Image } from '../../../models/image.model';
import { ImgViewerComponent } from '../../../shared/components/img-viewer/img-viewer.component';
import { Address } from '../../../models/address.model';
import { SalonService } from '../../../services/salon.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-salon-card',
  templateUrl: './salon-card.component.html',
  styleUrls: ['./salon-card.component.scss']/*,
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]*/
})
export class SalonCardComponent implements OnInit {


  constructor(
    public citiesService: CitiesService,
    public categoryService: CategoryService,
    private redirectionService: RedirectionService,
    public prestationService: PrestationService, private modalController: ModalController, public salonService: SalonService

  ) { }
  @Input() salon: SalonDetail;
  categories: string;

  isPromoAvailable = true;
  maxPromoAvailable = 0;

  sliderConfig = {
    spaceBetween: 0,
    slidesPerView: 1,
    onInit: (slides: IonSlides) => {
      this.swiper = slides;
    }
  };
  swiper: IonSlides;
  cats = [];
  @ViewChild('slides', { static: false }) slides: IonSlides;
  loadingPrestations = false;
  addressToShow: string = "";

  ngOnInit() {
    if(this.salon) {

      this.formatAdress(); 
      this.cats = [];
      this.salon.categories.forEach(async cat =>{
        if(JSON.parse( localStorage.getItem(cat))){
          
          this.cats.push(JSON.parse(localStorage.getItem(cat)));
        } else {
          this.cats.push(cat);
        }
        
      });
      this.salon.catsList = this.cats.join('/');

      this.categories = this.categoryService.listNameByIds(this.salon.categories).toString();

      // let prestationData = await this.prestationService.getOncePrestationsBySalonId(this.salon.sid);
    
      // this.salon.prestationsList = [];

      // prestationData.forEach(prestationDocumentData => {
      //   this.salon.prestationsList.push(new PrestationDetail().deserialize(prestationDocumentData.data()));
      // });

      // this.salon.prestationsList.sort(function(a , b){
      //   return b.maxPromo - a.maxPromo;
      // });

      // let arrayOfPromoAvailability = [];
        
      // this.salon.prestationsList.forEach((prestation) => {
      //   arrayOfPromoAvailability.push(this.getIsPromotionAvailable(prestation));
      // });

      // this.isPromoAvailable = arrayOfPromoAvailability.includes(true);

    }
  }

  

  onSlideProfilInit() {
    if (this.salon.salonPresentations)
      this.slides.slideTo(this.salon.salonPresentations.map(presentation => presentation.url).indexOf(this.salon.profilImageUrl));
  }


  goToSalon() {
    this.redirectionService.redirectTo(`/profil-salon/${this.salon.sid}`, true);
  }

  get avis() {
      if (this.salon.notes && this.salon.notes.length && this.salon.notes.length > 0 && this.salon.notes.length < 1000) {
          return this.salon.notes.length;
      } else if (this.salon.notes && this.salon.notes.length && this.salon.notes.length > 0 && this.salon.notes.length > 99) {
          return Math.floor(this.salon.notes.length / 1000) + 'K';
      }
      return 0;
  }

  onImgError(event, salon){
    if(salon._profilImageUrl){
      event.target.src = salon._profilImageUrl;
    }else{
      event.target.src = 'assets/images/barbershop.jpg';
    }
  }

  promotionDateExpired(prestation: PrestationDetail): boolean {

    let isExpired = false;
    let dateToCompare = new Date()
    // dateToCompare.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())

    if (prestation.promotionStartDateEnabled) {

      let dateStart = new Date(prestation.promotionStartDate.toString());
      dateStart.setHours(0, 0, 0, 0)
      isExpired = dateToCompare < dateStart;
      if (isExpired) return isExpired;
    }

    if (prestation.promotionEndDateEnabled) {

      let dateFin = new Date(prestation.promotionEndDate.toString());
      dateFin.setHours(0, 0, 0, 0)

      isExpired = dateToCompare > dateFin;
      if (isExpired) return isExpired;
    }

    return isExpired;
  }

  getIsPromotionAvailable(prestation: PrestationDetail):boolean{
    /*let dayIndexOnPromotion = new Date().getDay();

    if (dayIndexOnPromotion === 0)
      dayIndexOnPromotion = 6;
    else
      dayIndexOnPromotion -= 1;
*/

    let arrayOfPromoAvailability = [];

    shortDayLabel.forEach((value, index) => {

      const promotionDay: any = prestation.promotionDays[index];

      let typeOfSalonAvailability = typeof ((this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available)

      if ((typeOfSalonAvailability === "boolean" && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available) || (typeOfSalonAvailability !== "boolean" && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available.am && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available.pm)) {
        arrayOfPromoAvailability.push(false);
      } else {
        
        const promotionDay: any = prestation.promotionDays[index];
        if (promotionDay && promotionDay.enabled && !this.promotionDateExpired(prestation)) {
          arrayOfPromoAvailability.push(prestation.promotion);
        } else {
          arrayOfPromoAvailability.push(false);
        }
      }

    })


    return arrayOfPromoAvailability.includes(true);

  }

  async previewImage(image: Image, url: string = null) {
    // this.photoViewer.show(img.url, this.prestation ? `${this.prestation.name} (${this.salon.name})` : '');

    // const modal = await this.modalController.create({
    //   component: ImgViewerComponent,
    //   componentProps: {
    //     'src': !url ? image : new Image(url),
    //     'family': this.salon.salonPresentations,
    //     'globalTitle': this.salon.name,
    //     'salon': this.salon,

    //   }
    // });

    // return await modal.present();

  }

  goToSalonPrestations(sid) {
    /*this.redirectionService.redirectTo(`/profil-salon/prestations/insideTab`, true);*/
    this.redirectionService.redirectTo(`/profil-salon/prestations/${sid}`, true);
  }


  formatAdress(): void{
    if(this.salon.adresses[0].formatted_address){
      let splitted = this.salon.adresses[0].formatted_address.split(",");
      if(splitted.length > 1){
        splitted.pop();
      }
      this.addressToShow = splitted.join(",");
    }
  }

}