import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, ToastController, Platform } from '@ionic/angular';
// import { Platform, ToastController, NavController, IonSlides, ModalController } from '@ionic/angular';
import { SalonDetail } from '../../../models/salon.model';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { DynamicLinkService } from 'src/app/services/dynamic-link.service';

// import { CeiboShare } from 'ng2-social-share';
// import { SocialSharing } from '@ionic-native/social-sharing';

@Component({
    selector: 'app-share-link',
    templateUrl: './share-link.component.html',
    styleUrls: ['./share-link.component.scss'],
})
export class ShareLinkComponent implements OnInit {

    @Input() title = 'Partager';
    @Input() url;
    @Input() postTitle = '';
    @Input() postImageLink = '';
    @Input() postDescription = '';
    @Input() salon: SalonDetail;
    @Input() small = false;

    loading = false;
    constructor(
        public popoverController: PopoverController,
        public toastController: ToastController,
        public router: Router,
        private socialSharing: SocialSharing,
        private platform: Platform,
        public dynamicLinkService: DynamicLinkService
    ) { }

    ngOnInit() {
    }

    share(index) {
        this.loading = true;
        this.dynamicLinkService.create(this.url, this.postImageLink, this.postTitle, this.postDescription).subscribe((data) => {
            this.loading = false;
            if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
                let message = this.postDescription;
                let subject = this.postTitle;
                let file = null;
                this.socialSharing.share(message, subject, file, data.shortLink).then(response => {

                })
            } else {
                switch (index) {
                    case 1:
                        window.open('https://www.facebook.com/sharer.php?u=' + data.shortLink, '_blank');
                        break;
                    case 2:
                        window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + data.shortLink, '_blank');
                        break;
                    case 3:
                        window.open('https://twitter.com/share?url=' + data.shortLink + '&text=' + this.postTitle, '_blank');
                        break;
                    case 4:
                        window.open('https://web.whatsapp.com/send?text=' + data.shortLink, '_blank');
                        break;
                    case 5:
                        this.copyToClipboard(data.shortLink);
                        break;
                    default:
                        break;
                }
            }
            this.popoverController.dismiss();
        });
    }

    copyToClipboard(url) {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.presentToast('Lien copié');
    }

    shareTemp(socialNetwork/*1:facebook,2:instagram,3:twitter*/) {

        // let message = this.salon.bio;
        // let subject = "subject";
        // let file = this.salon.profilImageUrl;
        // let url = "https://www.youtube.com/watch?v=sU-JdM5h0-k";


        let message = null;
        let subject = null;
        let file = null;
        let url = 'https://www.youtube.com/watch?v=sU-JdM5h0-k';


        this.socialSharing.share(message, subject, file, url).then(response => {

        })

        // this.platform.ready().then(() => {

        //     switch (socialNetwork) {
        //         case 1:

        //             this.socialSharing.canShareVia("com.facebook.katana").then(response => {


        //                 // this.socialSharing.shareViaFacebook("", '' , '').then(() => {
        //                 this.socialSharing.shareVia("com.facebook.katana", "", this.salon.profilImageUrl , '', '').then(() => {
        //                 }, error => {
        //                     this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Facebook");
        //                     // TODO console.log("error share app" , error);
        //                 });

        //             }).catch(error => {
        //                 this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Facebook");
        //                 // TODO console.log("error can share" , error);
        //             });

        //             break;

        //         case 2:

        //             this.socialSharing.canShareVia("com.linkedin.android").then(response => {


        //                 this.socialSharing.shareVia("com.linkedin.android", this.salon.bio, '', '', '').then(() => {
        //                 }, error => {
        //                     this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Linkedin");
        //                     // TODO console.log("error share app" , error);
        //                 });

        //             }).catch(error => {
        //                 this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Linkedin");
        //                 // TODO console.log("error can share" , error);
        //             });

        //             break;

        //         case 3:

        //             this.socialSharing.canShareVia("com.twitter.android").then(response => {


        //                 this.socialSharing.shareViaTwitter(this.salon.bio, '').then(() => {
        //                 }, error => {
        //                     this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Twitter");
        //                     // TODO console.log("error share app" , error);
        //                 });

        //             }).catch(error => {
        //                 this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Twitter");
        //                 // TODO console.log("error can share" , error);
        //             });

        //             break;

        //         case 4:

        //             this.socialSharing.canShareVia("com.whatsapp").then(response => {


        //                 this.socialSharing.shareViaWhatsApp("", "https://firebasestorage.googleapis.com/v0/b/askeed-app.appspot.com/o/prestations%2F1599030735629%2Ftmp_afro-homme-coupe-de-cheveux-homme-noir-1767576040.jpg?alt=media&token=a82f586a-3ec8-4571-9551-a6e6a5e5e2ad", 'www.google.com').then(() => {
        //                 // this.socialSharing.shareVia("com.whatsapp", this.salon.bio, '', '', '').then(() => {
        //                 }, error => {
        //                     this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Twitter");
        //                     // TODO console.log("error share app" , error);
        //                 });

        //             }).catch(error => {
        //                 this.presentToast("Une erreur est survenue. Etes vous sur d'avoir l'application Twitter");
        //                 // TODO console.log("error can share" , error);
        //             });

        //             break;



        //         default:
        //             // code...
        //             break;
        //     }

        // });

    }

    async presentToast(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    dismiss() {
        this.popoverController.dismiss();
    }

}
