export class FbAlbum {
    id: string; 
    name: string;
    created_time: string;
    cover?: string;

    get createdAt() {
        return new Date(this.created_time);
    }

    deserialize(input: any) {
		Object.assign(this, input);
	    return this;
	}
}

export class FbAlbumPhoto {
    id: string; 
    created_time: string;
}

export class FbImage {
    height: number; 
    width: number; 
    source: string;
    created_time?: string;
}

export class FbAlbumCover {
    picture: {
        data: {
            is_silhouette: boolean;
            url: string;
        }
    };
    id: string;
}