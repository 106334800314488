import { Component, OnInit , OnDestroy} from '@angular/core';
import { ModalController} from '@ionic/angular';
import { FacturationService } from 'src/app/services/facturation.service';
import { Subscription } from "rxjs"
import { Config } from "src/app/models/invoice-config.model";
import {  LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bank-info-card',
  templateUrl: './bank-info-card.component.html',
  styleUrls: ['./bank-info-card.component.scss'],
})
export class BankInfoCardComponent implements OnInit, OnDestroy {
	invoiceConfig :Config;
	configSubscriber : Subscription;
	loading: boolean = false;
  	constructor(
  		public translate: TranslateService,
  		public modalController: ModalController,
  		private loadingController: LoadingController,
  		private facturationService: FacturationService,
  		
	) { }

	ngOnInit() {
		this.getConfig();
	}

	onDoAction(action: string){

		switch (action) {
			case "close":
  			this.modalController.dismiss(null);
  			break;
			default:
				this.modalController.dismiss(null);
				break;
		}

	}

	async getConfig(){
		this.loading = true;
	  const loading = await this.loadingController.create({
	    message: this.translate.instant('Chargement...'),
	    cssClass: 'askeed-spinner'
	  });

	  loading.present();
	  this.configSubscriber = this.facturationService.getConfig().subscribe(config => {
	      this.invoiceConfig = new Config().deserialize(config);
	      loading.dismiss();
	      this.loading = false;
	  })
	}

	ngOnDestroy(){
		if(this.configSubscriber)this.configSubscriber.unsubscribe();
	}

}
