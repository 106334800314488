import { Deserializable } from "./deserializable.model";

export class PromotionHour implements Deserializable{
    label: string;
    value: number; // 7 -> 7h-8h
    selected: boolean = false;
    promotion: Discount;
    hourInterval?: any[] = [
        { value: "00", selected: true },
        { value: "15", selected: false },
        { value: "30", selected: false },
        { value: "45", selected: false }
    ]; // not used here

    isEqual(promotionHour: PromotionHour) {
        let res = true;
        if (
            (this.promotion === undefined && promotionHour.promotion !== undefined)
            || (this.promotion !== undefined && promotionHour.promotion === undefined)
            )   {
            res = false;
        } else if (this.promotion && promotionHour.promotion && this.promotion.value != promotionHour.promotion.value) {
            res = false;
        }
        return res;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
 
export class PromotionDay implements Deserializable{
    label: string;
    value: number;
    valueString: string;
    hours: PromotionHour[];
    selected: boolean = false;
    enabled:boolean=false;
    getPromotionMaxValue (){
        let promotion : Discount=null;
        let prom : Discount[]=[];
        if(this.hours){
            for(let hour of this.hours){
                promotion=hour.promotion;
                if(promotion!==undefined)
                    prom.push(promotion);
           }
        }
           
       if(prom!== undefined){
           let discount:Discount;
           for(let pro of prom){
               if(discount === undefined || pro.value>discount.value){
                   discount=pro;
               }
           }
           promotion=discount;
       }

       return promotion;
    }
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class Discount {
    label: string;
    value: number;
    selected: boolean = false;
}