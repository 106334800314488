import { Injectable } from '@angular/core';

import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Report } from '../models/report.model';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

	static COLLECTION_NAME = 'reports';

	constructor(private firestore: AngularFirestore) { }

	createReport(report: Report){
		report.createdAt = new Date();

	    return this.firestore.collection(ReportService.COLLECTION_NAME).add(JSON.parse(JSON.stringify(report)));
	}

}
