import { Injectable, ɵɵresolveBody, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PrestationDetail, Prestation } from '../models/prestation.model';
import { dataConst, DataService, dataKey } from '../shared/helpers/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CitiesService } from './cities.service';
import * as _ from 'lodash';
import * as geofire from 'geofire-common';
import { FilterService } from './filter.service';
import { City } from '../models/city.model';
import { CategoryPrestation, DefaultPrestation, TypePrestation } from '../models/defaultPrestation.model';
import { SalonDetail } from '../models/salon.model';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { Image } from '../models/image.model';
import { Router } from '@angular/router';
import { ImageService } from './image.service';
import { CacheService } from './cache.service';
import { shortDayLabel, AvailabilityState } from 'src/app/models/availability.model';
import { TranslateService } from '@ngx-translate/core'
import { VariablesGlobales } from 'src/app/shared/variables-globales';


@Injectable({
	providedIn: 'root'
})
export class PrestationService implements AutoCompleteService {

	constructor(
		private firestore: AngularFirestore,
		private httpClient: HttpClient,
		private filterService: FilterService,
		private citiesService: CitiesService,
		private authService: AuthService,
		private router: Router,
		private imageService: ImageService,
		private cacheService: CacheService,
        private translate: TranslateService,
		private variablesGlobales: VariablesGlobales
	) {
		// this.citiesService.cityChange.subscribe((city) => {
		// 	if (city) {
		// 		if (!this.loadingResult) {
		// 			this.loadingResult = true;
		// 			this.getPrestationsDefault(city, true).then(() => {
		// 				this.getPrestationsResult(city);
		// 			});
		// 		}

		// 	}
		// });

		// this.filterService.tagsAdded.subscribe((t) => {
		// 	if (t) {
		// 		if (!this.loadingResult) {
		// 			this.loadingResult = true;
		// 			this.getPrestationsResult();
		// 		}
		// 	}
		// });

		// this.listCategories().subscribe((catSnap) => {
		// 	this.filterService.categoriesCards = [];
		// 	catSnap.forEach((cat) => {
		// 		this.filterService.categoriesCards.push(cat);
		// 	});
		// });


	}

	static COLLECTION_NAME = 'prestations';
	static COLLECTION_SLUG_NAME = 'slugPrestations';
	static COLLECTION_LIGHT_NAME = 'prestationsLight';
	static SALON_COLLECTION_NAME = 'salons';
	static CATEGORY_COLLECTION_NAME = 'categoryPrestation';
	static TYPE_COLLECTION_NAME = 'typePrestation';
	static DEFAULT_COLLECTION_NAME = 'defaultPrestation';
	labelAttribute = 'name';

	private typeCard = new BehaviorSubject(null);
	typeCardChange = this.typeCard.asObservable();
	private initialCategorie = new BehaviorSubject(null);
	initialCategorieChange = this.initialCategorie.asObservable();

	salonsDefault: SalonDetail[] = [];
	salonsResult: SalonDetail[];
	filteredSalons: SalonDetail[];

	prestations: PrestationDetail[];
	prestationsDefault: PrestationDetail[];
	prestationsHomeDefault: PrestationDetail[] = [];

	prestationsPromo: PrestationDetail[] = [];
	prestationsPopular: PrestationDetail[] = [];
	prestationsTrend: PrestationDetail[] = [];
	prestationsResult: PrestationDetail[];

	types: TypePrestation[];
	categories: CategoryPrestation[];
	defaultPrestations: DefaultPrestation[];

	typesPrestations: TypePrestation[];
	defaultPrestationsResult: DefaultPrestation[];

	prestationEditId: string;

	results: Observable<TypePrestation[]>;
	resultsP: Observable<DefaultPrestation[]>; // SEARCHBAR PRESTATIONS RESULT

	loadingDefault: Boolean = false;
	loadingResult: Boolean = false;
	loadingApiResult: Boolean = false;

	defaultLoaded: Boolean = false;
	resultLoaded: Boolean = false;

	queryOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Content-Type': 'application/json'
		})
	};

	galleryPage: 'result' | 'salon';

	boundsLastVisible: any[];

	loopQuery = 0;

	onChangeTypeCard(data) {
		this.typeCard.next(data);
	}

	onChangeCategorie(data) {
		this.initialCategorie.next(data);
	}

	add(prestation: PrestationDetail) {
		prestation.createdAt = new Date();
		return this.firestore.collection(PrestationService.COLLECTION_NAME).add(JSON.parse(JSON.stringify(prestation)));
	}

	async createWithImages(prestation: PrestationDetail, images: Image[]) {
		return new Promise<string>(async (res) => {
			let adding = await this.add(prestation);
			prestation.pid = adding.id;
			if (images.find((v) => !v.url.includes('https'))) {
				await new Promise<void>(async (resolve) => {
					for (let index = 0; index < images.length; index++) {
						const image = images[index];
						let img = image.url;
						if (img && !img.includes('https')) {
							const imageName = new Date().getTime().toString();
							let url = await this.uploadImage(img, prestation.getImagePath(imageName));
							images[index].url = url;
							if (index === images.length - 1) {
								resolve();
							}
						}
					}
				});
			}
			prestation.galleries = images.map((value) => new Image(value.url));
			await this.update(prestation);
			res(adding.id);
		});
	}

	async uploadImage(imageUri, path: string) {
		if (!imageUri) {
			return;
		}
		return await new Promise<string>(async (resolve, reject) => {
			const uriSplit = imageUri.split('/');
			this.imageService.uploadImage(path, imageUri).then(photoUrl => {
				return resolve(photoUrl);
			}, error => {
				reject(error);
			});
		});
	}

	update(prestation: PrestationDetail) {
		prestation.updatedAt = new Date();
		// return this.firestore.doc(PrestationService.COLLECTION_NAME + '/' + prestation.pid).update(JSON.parse( JSON.stringify(prestation)));
		const queryOptions = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Content-Type': 'application/json'
			})
		};
		return new Promise<void>((resolve) => {
			this.httpClient.
				post<any>(dataConst.API_ENDPOINT + 'updatePrestationCloud', JSON.stringify({ ...prestation }), queryOptions).subscribe((obs) => {
					resolve();
				});
		});
		// .subscribe((o) =>{
		// }, (err) => {
		// TODO	console.log("err", err);
		// });
		// return this.firestore.doc(PrestationService.COLLECTION_NAME + '/' +prestation.pid).update(JSON.parse(JSON.stringify(prestation)));
	}

	async getByCity(city?, limit?, server = false) {
		await new Promise(resolve => setTimeout(resolve, 100));
		if (!city) {
			city = await this.citiesService.getCity();
		}
		this.filterService.filterBy.city = city;
		let params = {};
		params['city'] = city;
		if (city.id === undefined) {

			let checkCity = this.citiesService.cities.find((c) => c.name === city.name);
			if (checkCity) {
				city = checkCity;
			}
		}
		if (city.id) {
			let latCenter = Number(params['city']['lat']);
			let lngCenter = Number(params['city']['lng']);
			const center = [latCenter, lngCenter];
			let prestationsSnap = await this.firestore.collection('searchResult').doc('cityPrestations').collection(city.id).get().toPromise();
			let matchingDocs = [];
			prestationsSnap.docs.forEach((value) => {
				let res = value.data();
				if (res.lat && res.lng) {
					let lat = res.lat;
					let lng = res.lng;
					let distanceInKm = geofire.distanceBetween([lat, lng], center);
					res.distance = distanceInKm;
				}
				matchingDocs.push(res);
			});
			return new Promise((resolve) => resolve({ data: matchingDocs }));
		} else {
			// params.city =  {
			// 	"country": "CH",
			// 	"name": "Lausanne",
			// 	"lat": "46.516",
			// 	"lng": "6.63282"
			// }
			return new Promise((resolve, reject) => {
				// resolve({data: []});
				if (params['city'] && params['city']['lat'] && params['city']['lng']) {

					// GET CACHE DATA IF EXISTS
					// const cacheData = this.cacheService.getItem(city.name);
					// if (cacheData) {
					// 	resolve({ data: cacheData });
					// }

					let lat = Number(params['city']['lat']);
					let lng = Number(params['city']['lng']);
					const center = [lat, lng];
					let distance = 15;
					const radiusInM = distance * 1000;

					// Each item in 'bounds' represents a startAt/endAt pair. We have to issue
					// a separate query for each pair. There can be up to 9 pairs of bounds
					// depending on overlap, but in most cases there are 4.
					const bounds = geofire.geohashQueryBounds(center, radiusInM);
					const promises = [];
					let count = 0;
					let index = 0;
					let ref = this.firestore.collection('prestations').ref;

					for (const b of bounds) {
						let q: any = ref;
						q = q
							.orderBy('geohash')
							.startAt(b[0])
							.endAt(b[1]);
						if (limit) {
							q = q.limit(limit);
						}
						index++;
						promises.push(q.get());
					}

					// Collect all the query results together into a single list
					Promise.all(promises).then(async (snapshots) => {
						const matchingDocs = [];

						for (const snap of snapshots) {
							for (const doc of snap.docs) {
								lat = doc.get('lat');
								lng = doc.get('lng');
								// We have to filter out a few false positives due to GeoHash
								// accuracy, but most will match
								const distanceInKm = geofire.distanceBetween([lat, lng], center);
								const distanceInM = distanceInKm * 1000;
								if (distanceInM <= radiusInM) {
									let prestation = doc.data();
									prestation.distance = distanceInKm;
									matchingDocs.push(prestation);
								}
							}
						}
						// if (!server && matchingDocs.length === 0){
						// 	this.getByCity(city, limit, true).then((value: any) => {
						// 		return { data: value.data };
						// 	});
						// }else{
						let result = matchingDocs;
						// this.cacheService.setItem(city.name, matchingDocs);
						return { data: result };
						// }
					}).then((result) => {
						resolve(result);
					});
				} else {
					reject([]);
				}
			});
		}
	}

	async paginate(reload = false, limit = 6, loopquery = 10) {
		await new Promise(resolve => setTimeout(resolve, 100));
		let city = await this.citiesService.getCity();
		this.filterService.filterBy.city = city;


		let params = this.filterService.filterBy;
		// params.city =  {
		// 	"country": "CH",
		// 	"name": "Lausanne",
		// 	"lat": "46.516",
		// 	"lng": "6.63282"
		// }
		if (reload) {
			this.boundsLastVisible = undefined;
			// limit = 6;
		}
		return new Promise((resolve, reject) => {
			// resolve({data: []});
			if (params['city'] && params['city']['lat'] && params['city']['lng']) {
				let lat = Number(params['city']['lat']);
				let lng = Number(params['city']['lng']);
				const center = [lat, lng];
				let distance = 15;
				const radiusInM = distance * 1000;

				// Each item in 'bounds' represents a startAt/endAt pair. We have to issue
				// a separate query for each pair. There can be up to 9 pairs of bounds
				// depending on overlap, but in most cases there are 4.
				const bounds = geofire.geohashQueryBounds(center, radiusInM);
				const promises = [];
				let count = 0;
				let index = 0;
				let ref = this.firestore.collection('prestations').ref;

				for (const b of bounds) {
					let q: any = ref;
					// Category Filter
					if (params['category']) {
						q = q.where('categoryId', 'in', params['category']);
					}
					q = q
						.orderBy('geohash')
						.startAt(b[0])
						.endAt(b[1]);
					if (this.boundsLastVisible && this.boundsLastVisible[index] !== undefined) {
						q = q
							.startAfter(this.boundsLastVisible[index]);
					} else {
					}

					q = q.limit(limit);
					index++;
					promises.push(q.get());
				}

				// Collect all the query results together into a single list
				Promise.all(promises).then((snapshots) => {
					const matchingDocs = [];
					let addMore = false;
					index = 0;
					if (!this.boundsLastVisible) {
						this.boundsLastVisible = [];
					}
					for (const snap of snapshots) {
						this.boundsLastVisible[index] = snap.docs[snap.docs.length - 1];
						index++;
						for (const doc of snap.docs) {
							lat = doc.get('lat');
							lng = doc.get('lng');
							// We have to filter out a few false positives due to GeoHash
							// accuracy, but most will match
							const distanceInKm = geofire.distanceBetween([lat, lng], center);
							const distanceInM = distanceInKm * 1000;
							if (distanceInM <= radiusInM) {
								let prestation = doc.data();
								prestation.distance = distanceInKm;
								matchingDocs.push(prestation);
							}
						}
					}
					let result = matchingDocs;
					// IF DEFAUTL SEARCH CITY -> PROCEED
					// if (Object.keys(this.filterService.filterBy).length > 1) {
					// 	result = [];
					// 	for (let i in this.filterService.filterBy) {
					// 		if (i !== "city") {
					// 			let filterBy = {};
					// 			filterBy[i] = this.filterService.filterBy[i];
					// 			let salonsResult = null;
					// 			if (i === "availability") {
					// 				salonsResult = this.salonsDefault;
					// 			}
					// 			let filters = this.filterService.makeFilters(filterBy, 'prestation', salonsResult);
					// 			let res = _.filter(matchingDocs, _.conforms(filters));
					// 			res.forEach(element => {
					// 				if (!result.find((el) => el.pid === element.pid)) {
					// 					result.push(element);
					// 				}
					// 			});
					// 		}
					// 	}
					// }
					// if (this.filterService.sort) {
					// 	result = _.orderBy(result, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
					// 		return new PrestationDetail().deserialize(s);
					// 	});
					// }
					addMore = (matchingDocs.length > 0 && result.length === 0);
					if (result.length < 6) {
						loopquery--;
						addMore = true;
					}
					if (loopquery === 0) {
						resolve({ data: result, addMore: false });
					}
					return { data: result, addMore, loopquery };
				}).then((result) => {
					resolve(result);
				});
			} else {
				reject('no city in paginate');
			}
		});
	}

	getById(id) {
		return this.firestore.doc(PrestationService.COLLECTION_NAME + '/' + id).ref.get();
	}
	
	getBySlug(id) {
		return this.firestore.doc(PrestationService.COLLECTION_SLUG_NAME + '/' + id).ref.get();
	}

	getPrestations() {
		return this.firestore.collection(PrestationService.COLLECTION_NAME).snapshotChanges();
	}

	getAll() {
		return this.firestore.collection<PrestationDetail>(PrestationService.COLLECTION_NAME).valueChanges();
	}

	getList() {
		return this.firestore.collection<PrestationDetail>(PrestationService.COLLECTION_NAME).ref.get();
	}

	listCategories() {
		return this.firestore.collection<CategoryPrestation>(PrestationService.CATEGORY_COLLECTION_NAME).valueChanges();
	}

	getListCategories() {
		return this.firestore.collection<CategoryPrestation>(PrestationService.CATEGORY_COLLECTION_NAME).ref.get();
	}

	listTypes() {
		return this.firestore.collection<TypePrestation>(PrestationService.TYPE_COLLECTION_NAME).valueChanges();
	}

	getlistTypes() {
		return this.firestore.collection<TypePrestation>(PrestationService.TYPE_COLLECTION_NAME).ref.get();
	}

	listTypesIn(typesIds: string[]): Promise<TypePrestation[]> {
		// return this.firestore.collection<TypePrestation>
		// 	(PrestationService.TYPE_COLLECTION_NAME, ref => ref.where('tpid', 'in', typesIds)).valueChanges();
		return new Promise<TypePrestation[]>(res => {
			let promises = [];
			while (typesIds.length) {
				const partIds = typesIds.splice(0, 10);
				promises.push(
					new Promise(response => {
						this.firestore.collection<TypePrestation>
							(PrestationService.TYPE_COLLECTION_NAME, ref => ref.where('tpid', 'in', [...partIds])).get().subscribe(observer => {
								response(observer.docs.map(el => new TypePrestation().deserialize(el.data())));
							});
					})
				);
			}
			Promise.all(promises).then(content => {
				res(content.flat());
			});
		});
	}

	listTypesByCategory(id) {
		return this.firestore.collection<TypePrestation>
			(PrestationService.TYPE_COLLECTION_NAME, ref => ref.where('cpid', '==', id)).valueChanges();
	}

	getTypeById(id) {
		return this.firestore.doc<TypePrestation>(PrestationService.TYPE_COLLECTION_NAME + '/' + id).get();
	}

	listDefaultPrestations() {
		return this.firestore.collection<DefaultPrestation>(PrestationService.DEFAULT_COLLECTION_NAME).valueChanges();
	}

	getListDefaultPrestations() {
		return this.firestore.collection<DefaultPrestation>(PrestationService.DEFAULT_COLLECTION_NAME).ref.get();
	}

	getDefaultPrestation(id: string) {
		return this.firestore.doc<DefaultPrestation>(PrestationService.DEFAULT_COLLECTION_NAME + '/' + id).valueChanges();
	}

	getPrestationByPid(pid: string) {
		return this.firestore.collection(PrestationService.COLLECTION_NAME).doc(pid).ref.get();
	}

	deletePrestationByPid(pid: string) {
		return this.firestore.collection(PrestationService.COLLECTION_NAME).doc(pid).ref.delete();
	}
	getPrestationsByIds(ids: string[]) {
		return this.firestore.collection(PrestationService.COLLECTION_NAME).ref.where('pid', 'in', ids).orderBy('createdAt', 'desc').limit(3).get();
	}

	getPrestationsBySalonId(sid: string) {
		return this.firestore.collection<PrestationDetail>(PrestationService.COLLECTION_NAME, ref => ref.where('sid', '==', sid)).valueChanges();
	}

	getOncePrestationsBySalonId(sid: string, limit: number = -1) {
		// let ref = this.firestore.collection<PrestationDetail>(PrestationService.COLLECTION_LIGHT_NAME).ref.where('sid', '==', sid);
		let ref = this.firestore.collection<PrestationDetail>(PrestationService.COLLECTION_NAME).ref.where('sid', '==', sid);
		
		if (limit > 0) {
			ref = ref.limit(limit);
		}

		return ref.get();
	}

	getCategorysSalon(cpid){
		let ref = this.firestore.collection<CategoryPrestation>(PrestationService.CATEGORY_COLLECTION_NAME).ref.where("cpid", "==", cpid);
		return ref.get();
	}

	observePrestationsByIds(ids: string[]) {
		return this.firestore.collection<PrestationDetail>(PrestationService.COLLECTION_NAME, ref => ref.where('pid', 'in', ids).orderBy('createdAt', 'desc').limit(3)).valueChanges();
	}

	getFilteredPrestations(property: any) {
		// if(this.filterService.sort){
		// 	this.filterService.filterBy.sort = this.filterService.sort;
		// 	this.filterService.filterBy.sortType = this.filterService.sortType;
		// }
		// return this.firestore.collection<PrestationDetail>("searchResult").doc("cityPrestations")
		// 	.collection(property.city.id).ref
		// 	.limit(24)
		// 	.get();
		return this.httpClient.post<any>
			(dataConst.API_ENDPOINT + 'getFilteredPrestations', JSON.stringify({ ...property , dev_mode: VariablesGlobales.dev_mode }), this.queryOptions);

	}
	
	getFilteredPrestationsClient(property: any) {
		// CITY NAME REQUEST
		// return new Promise(async (resolve, reject) => {
		// 	if (property['city']  && property['city']['lat'] && property['city']['lng'] && property['city']['name']){
		// 		const lat = Number(property['city']['lat']);
		// 		const lng = Number(property['city']['lng']);
		// 		const center = [lat, lng];
		// 		let cityName: string = property['city']['name'];
		// 		if(cityName.toLowerCase() === 'autour'){
		// 			if(this.citiesService.cities.length === 0){
		// 				await this.citiesService.initResult();
		// 			}
		// 			// get closest city
		// 			let closestCity: City;
		// 			let minDistance = 15;
		// 			while(!closestCity){
		// 				this.citiesService.cities.forEach((city) => {
		// 					city.distance = geofire.distanceBetween([Number(city.lat), Number(city.lng)], center);
		// 					if(city.distance <= minDistance
		// 						&& (!closestCity || (closestCity && closestCity.distance > city.distance))
		// 					){
		// 						closestCity = city;
		// 					}

		// 				});
		// 				minDistance +=50;
		// 			}
		// 			if(closestCity){
		// 				cityName = closestCity.name;
		// 			}
		// 		}
		// 		let snapshots = await this.firestore.collection('prestations').ref
		// 		.where('cityName', '==', cityName)
		// 		// .limit(3)
		// 		.get();
		// 		let result = [];
		// 		snapshots.forEach((snap) => {
		// 			let pres = snap.data();
		// 			result.push(pres);
		// 		});
		// 					result = result.map((pres) => {
		// 						if(pres.lat && pres.lng){
		// 							const latP = pres.lat;
		// 							const lngP = pres.lng;
		// 							const distanceInKm = geofire.distanceBetween([latP, lngP], center);
		// 							pres.distance = distanceInKm;
		// 						}
		// 						let val = 0;
		// 						return pres;
		// 					});
		// 				return resolve(result);
		// 	}
		// });
		// GEOQUERY
		return new Promise((resolve, reject) => {
			if (property['city'] && property['city']['lat'] && property['city']['lng']) {
				const lat = Number(property['city']['lat']);
				const lng = Number(property['city']['lng']);
				const center = [lat, lng];
				let distance = 15;
				const radiusInM = distance * 1000;

				let bounds = geofire.geohashQueryBounds(center, radiusInM);
				let promises = [];
				for (const b of bounds) {
					const q = this.firestore.collection('salons').ref
						.orderBy('geohash')
						.startAt(b[0])
						.endAt(b[1]);

					promises.push(q.get());
				}
				// Collect all the query results together into a single list
				Promise.all(promises).then((snapshots) => {
					let matchingDocs = [];
					for (const snap of snapshots) {
						for (const doc of snap.docs) {
							let salon = doc.data();
							if (
								(salon.isVerified || salon.isVerified == undefined)
								&& (!salon.isDeactivated || salon.isDeactivated == undefined)
								&& (!salon.isClosed || salon.isClosed == undefined)
							){
								const latS = doc.get('lat');
								const lngS = doc.get('lng');
								// We have to filter out a few false positives due to GeoHash
								// accuracy, but most will match
								const distanceInKm = geofire.distanceBetween([latS, lngS], center);
								const distanceInM = distanceInKm * 1000;
								if (distanceInM <= radiusInM) {
									doc.data().distance = distanceInKm;
									matchingDocs.push(doc.data());
								}
							}
						}
					}

					return matchingDocs;
				}).then((matchingSalons) => {
					let presPromises = [];
					matchingSalons.forEach((salon) => {
						salon.prestations.forEach((pid) => {
							if(pid){
								let ref =  this.firestore
								.collection('prestationsLight')
								.doc(pid).ref;
								presPromises.push(ref.get());
							}
						})
					});

					Promise.all(presPromises).then((snapshots) => {
						let result = [];
						for (const snap of snapshots) {
								let pres = snap.data();
								result.push(pres);
						}
						let counter = 0;
						let time = 0;
							result = result.map((pres) => {
								if(pres.lat && pres.lng){
									const latP = pres.lat;
									const lngP = pres.lng;
									const distanceInKm = geofire.distanceBetween([latP, lngP], center);
									pres.distance = distanceInKm;
								}
								let val = 0;
								return pres;
							});
						return result;
					}).then((matchingDocs) => {
						resolve(matchingDocs);
					});
				});
			}
		});

	}

	getSalons() {
		return this.firestore.collection<SalonDetail>('salons').ref.get();
	}

	getFilteredSalons(body) {
		return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'getFilteredSalons', JSON.stringify({ ...body }), this.queryOptions);
	}

	getFilteredSalonsClient(property) {
		return new Promise<any>((resolve, reject) => {
			if (property['city'] && property['city']['lat'] && property['city']['lng']) {
				const lat = Number(property['city']['lat']);
				const lng = Number(property['city']['lng']);
				const center = [lat, lng];
				let distance = 15;
				const radiusInM = distance * 1000;

				let bounds = geofire.geohashQueryBounds(center, radiusInM);
				let promises = [];
				for (const b of bounds) {
					const q = this.firestore.collection('salons').ref
						// .orderBy('geohash')
						// .startAt(b[0])
						// .endAt(b[1]);
						.where("isVerified", "==", true)
						.where("isDeactivated", "==", false)
                        .where("isClosed", "==", false)
						.orderBy('geohash')
						.startAt(b[0])
						.endAt(b[1]);
					promises.push(q.get());
					
				}
				// Collect all the query results together into a single list
				Promise.all(promises).then((snapshots) => {
					let matchingDocs = [];
					for (const snap of snapshots) {
						for (const doc of snap.docs) {
							let salon = doc.data();
							// if (
							// 	(salon.isVerified || salon.isVerified == undefined)
							// 	&& (!salon.isDeactivated || salon.isDeactivated == undefined)
							// 	&& (!salon.isClosed || salon.isClosed == undefined)
							// ){
								let latP = doc.get('lat');
								let lngP = doc.get('lng');
								// We have to filter out a few false positives due to GeoHash
								// accuracy, but most will match
								const distanceInKm = geofire.distanceBetween([latP, lngP], center);
								const distanceInM = distanceInKm * 1000;
								if (distanceInM <= radiusInM) {
									doc.data().distance = distanceInKm;
									matchingDocs.push(doc.data());
								}
							//}
						}
					}

					return matchingDocs;
				}).then((matchingSalons) => {
					resolve(matchingSalons);
				});
			} else {
				reject('no city in getFilteredClient');
			}
		});
	}


	async getPrestationsResult(city: City = null, cityChange = false) {
		if (!this.loadingResult) {
			return;
		}

		return new Promise<void>(async (resolve) => {
			this.loadingResult = true;
			if (!this.filterService.sort) {
				//this.filterService.sort = 'price';
				this.filterService.sort = 'viewCount';
				this.filterService.sortType = 'desc';
			}
			if (!city) {
				city = await this.citiesService.getCity();
			}
			this.filterService.filterBy.city = city;
			
			if (this.prestationsDefault) {

				//////// Get salon default //////////////////////

				if (!this.salonsDefault || this.salonsDefault.length === 0 || !this.filteredSalons) {
					// let salonsData = await this.getFilteredSalons({ city }).toPromise();
					let salonsData: any[] = await this.getFilteredSalonsClient({ city });
					this.salonsDefault = [];
					this.salonsDefault = salonsData.map((s) => {
						return new SalonDetail().deserialize(s);
					});
				}

				let salonsDefault: SalonDetail[] = [];
				
				this.salonsDefault.forEach((salon) => {
					salonsDefault.push(salon);
				});

				this.filteredSalons = [];

				let resultSalons = [];

				// IF DEFAUTL SEARCH CITY -> PROCEED
				let filters = this.filterService.makeFilters(this.filterService.filterBy, 'salon');
				this.filteredSalons = _.filter(salonsDefault, _.conforms(filters));
                
                this.filteredSalons = this.filteredSalons.filter(data => {

					if(data.prestations && data.prestations.length > 0){
						if(!VariablesGlobales.dev_mode){
							if(!data.dev_mode) return true;
							else return false;
						}
						return true;
					}
					return false;
				});

				// End Get salon default //////////////////////

				let prestationsDefault: PrestationDetail[] = [];
				this.prestationsDefault.forEach((pres) => {
					prestationsDefault.push(new PrestationDetail().deserialize(pres));
				});


				// let filters = this.filterService.makeFilters(this.filterService.filterBy, 'prestation');
				this.prestationsResult = [];
				let result = [];
				// this.prestationsResult = _.filter(this.prestationsDefault, _.conforms(filters));

				if (Object.keys(this.filterService.filterBy).length === 1 && this.filterService.filterBy.city) {
					let filters = this.filterService.makeFilters(this.filterService.filterBy, 'prestation', this.filteredSalons);
					this.prestationsResult = _.filter(prestationsDefault, _.conforms(filters));

				} else {
					let filterBy = {};
					if (Object.keys(this.filterService.filterBy).length > 1) {
						for (let i in this.filterService.filterBy) {
							if (i !== 'city' && i !== 'sort' && i !== 'sortType') {
								filterBy[i] = this.filterService.filterBy[i];
							}
						}
					}
					let salonsResult = null;
					//let filters = this.filterService.makeFilters(filterBy, 'prestation', salonsResult);
					let filters = this.filterService.makeFilters(filterBy, 'prestation', this.filteredSalons);
					result = _.filter(prestationsDefault, _.conforms(filters));
					
					this.prestationsResult = result;

				}
				if (this.filterService.sort) {

					this.prestationsResult = _.orderBy(this.prestationsResult, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
						return new PrestationDetail().deserialize(s);
					});
				}

				// 3 prestations per salon
				let salonPrestationsCounter = {};
				let count = 0;
				let prestationsPerSalon: PrestationDetail[] = [];
				for (let index = 0; index < this.prestationsResult.length; index++) {
					const pres = this.prestationsResult[index];
					if (pres.sid) {
						if (!salonPrestationsCounter[pres.sid]) {
							salonPrestationsCounter[pres.sid] = 1;
						} else {
							salonPrestationsCounter[pres.sid]++;
						}
						if (salonPrestationsCounter[pres.sid] <= 3) {
							prestationsPerSalon.push(pres);
							count++;
						}
					}
				}

				if (prestationsPerSalon.length > 20) {
					this.prestationsResult = prestationsPerSalon;
				}

				this.loadingResult = false;
				resolve();
			} else if (!this.prestationsDefault) {
				this.getPrestationsDefault(city).then(() => {
					this.getPrestationsResult(city).then(() => {
						resolve();
					});
				});
			} else {
				// this.filterService.getFilteredPrestations(this.filterService.filterBy).subscribe(
				this.getFilteredPrestationsClient(this.filterService.filterBy).then(
					(data: any[]) => {
						this.prestationsResult = [];
						this.prestationsResult = data.map((s) => {
							return new PrestationDetail().deserialize(s);
						});
						if (this.filterService.sort) {
							this.prestationsResult = _.orderBy(this.prestationsResult, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
								return new PrestationDetail().deserialize(s);
							});
						}
						this.loadingResult = false;
						this.resultLoaded = true;
						resolve();
					},
					error => {
						resolve();
						this.loadingResult = false;
					}
				);
			}
		});

	}

	async getPrestationsHomeDefault() {
		let city = {
			country: 'CH',
			name: 'Lausanne',
			lat: '46.516',
			lng: '6.63282'
		};
		this.getByCity(city, 6).then((value: { data: any[] }) => {
			let prestations: PrestationDetail[] = [];
			let typesIds = [];
			value.data.forEach((p) => {
				let s: PrestationDetail = new PrestationDetail().deserialize(p);
				prestations.push(s);
				s.typesIds.forEach((typeId) => {
					if (!typesIds.find((e) => e === typeId)) {
						typesIds.push(typeId);
					}
				});
			});
			this.prestationsHomeDefault = prestations;
		});
	}

	async getPrestationsDefault(city: City = null, reload = false) {
		return new Promise(async (resolve) => {
			await new Promise(res => setTimeout(res, 100));
			this.loadingDefault = true;
			if (this.citiesService.cities && this.citiesService.cities.length == 0) {
				await this.citiesService.initResult();
			}
			if (city && !city.id) {
				city = this.citiesService.cities.find((c) => c.name === city.name);
			}
			if (!city) {
				city = await this.citiesService.getCity();
			}
			this.filterService.filterBy.city = city;

			if (!this.categories) {

				// CACHE categories
				let categories: any = this.cacheService.getItem('categories');
                // categories = null;
				if (!categories || (categories && categories.length < 4)) {
					this.categories = [];
					this.filterService.categories = [];
					this.filterService.categoriesCards = [];
					this.filterService.categories.push(
						new CategoryPrestation().deserialize({ label: this.translate.instant('Tous'), value: 'all', selected: false, iconName: 'cut-outline' })
					);
					this.categories.push(
						new CategoryPrestation().deserialize({ label: this.translate.instant('Tous'), value: 'all', selected: false, iconName: 'cut-outline' })
					);
					let categoriesSnap = await this.getListCategories();
					categoriesSnap.forEach((val) => {
						this.categories.push(new CategoryPrestation().deserialize(val.data()));
						this.filterService.categories.push(new CategoryPrestation().deserialize(val.data()));
						this.filterService.categoriesCards.push(new CategoryPrestation().deserialize(val.data()));
					});

				} else {

					this.categories = categories.map((v) => {
						return new CategoryPrestation().deserialize(v);
					});
					this.filterService.categories = categories.map((v) => {
						return new CategoryPrestation().deserialize(v);
					});
					this.filterService.categoriesCards = this.categories.filter((cat) => cat.value !== 'all');
				}
				this.filterService.categoriesChange.next(true);
			}
			if (!city) {
				if (this.authService.isLoggedIn) {
					let user: User = User.userFactory(this.authService.userLoggedIn);
					city = new City().deserialize({
						country: user.mainAddress.country,
						lat: user.mainAddress.latLng.lat,
						lng: user.mainAddress.latLng.lng,
						name: user.mainAddress.locality,
					});
				} else {
					this.router.navigate(['home']);
					resolve(null);
					return;
				}

			}
			if (!this.salonsDefault || reload) {
				// let salonsData = await this.getFilteredSalons({ city }).toPromise();
				let salonsData: any[] = await this.getFilteredSalonsClient({ city });
				this.salonsDefault = [];
				this.salonsDefault = salonsData.map((s) => {
					return new SalonDetail().deserialize(s);
				});
				this.filteredSalons = this.salonsDefault;
			}
			if (!this.types) {
				// CACHE TYPES
				let types: any = this.cacheService.getItem('types');
				if (types) {
					this.types = [];
					types.forEach((t) => {
						this.types.push(new TypePrestation().deserialize(t));
					});
				}
				if (!this.types || (this.types && this.types.length === 0)) {
					let typeSnap = await this.getlistTypes();
					this.types = [];
					typeSnap.forEach((val) => {
						this.types.push(new TypePrestation().deserialize(val.data()));
					});
					this.cacheService.setItem('types', this.types);
				}
			}

			if (!this.defaultPrestations) {
				// CACHE default prestation
				let defaultPrestations: any = this.cacheService.getItem('defaultPrestations');
				if (defaultPrestations && defaultPrestations.length > 0) {
					this.defaultPrestations = [];
					defaultPrestations.forEach((t) => {
						this.defaultPrestations.push(new DefaultPrestation().deserialize(t));
					});
				} else {
					let defaultSnap = await this.getListDefaultPrestations();
					this.defaultPrestations = [];
					defaultSnap.forEach((val) => {
						this.defaultPrestations.push(new DefaultPrestation().deserialize(val.data()));
					});
					this.cacheService.setItem('defaultPrestations', this.defaultPrestations);
				}
			}

			let timer = 0;
			setInterval(() => {
				timer++;
			}, 1000);
			await new Promise<void>((resolve2) => {
				// this.getByCity(undefined, undefined).then((value: {data: any []}) => {
				// this.getFilteredPrestations(this.filterService.filterBy).subscribe((value: any[]) => {
			
				this.getFilteredPrestationsClient(this.filterService.filterBy).then ((value: any[]) => {
					this.loadingResult = false;
					this.prestationsDefault = value.map((p) => {
						return new PrestationDetail().deserialize(p);
					});
					this.prestationsResult = _.orderBy(this.prestationsDefault, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
						return new PrestationDetail().deserialize(s);
					});

					resolve(this.prestationsResult);
					resolve2();
				});
			});



			// let prestations: PrestationDetail[] = [];
			// if (!this.prestations || reload) {
			// 	let prestationData = await this.getFilteredPrestations({ city }).toPromise();
			// 	prestations = prestationData.map((p) => {
			// 		return new PrestationDetail().deserialize(p);
			// 	});
			// 	this.prestations = prestations;
			// } else {
			// 	prestations = this.prestations;
			// }
			// let filters = this.filterService.makeFilters({ city }, 'prestation', this.salonsDefault);
			// this.prestationsDefault = _.filter(prestations, _.conforms(filters));

			let typesIds = [];
			this.prestationsDefault.forEach((p) => {
				p.typesIds.forEach((typeId) => {
					if (!typesIds.find((e) => e === typeId)) {
						typesIds.push(typeId);
					}
				});
			});

			let defaultPrestationsIds = [];
			this.prestationsDefault.forEach((p) => {
				if (!defaultPrestationsIds.includes(p.defaultPrestationId)) {
					defaultPrestationsIds.push(p.defaultPrestationId);
				}
			});

			if (typesIds.length) {
				let types = this.types.filter((value) => typesIds.includes(value.tpid));
				types.forEach((type, index) => {
					types[index].countPrestations = this.prestationsDefault.filter((p) => p.typesIds.find((t) => t === type.tpid)).length;
				});
				this.typesPrestations = types;

				let defaultPrestations = this.defaultPrestations.filter((value) => defaultPrestationsIds.includes(value.dpid));
				defaultPrestations.forEach((defaultP, index) => {
					defaultPrestations[index].countPrestations = this.prestationsDefault.filter((p) => p.defaultPrestationId === defaultP.dpid).length;
				});
				this.defaultPrestationsResult = _.orderBy(defaultPrestations, 'countPrestations', 'desc').map((s) => {
					return new DefaultPrestation().deserialize(s);
				});

				this.filterService.categories = this.filterService.categories.map((cat) => {
					let category = new CategoryPrestation().deserialize(cat);
					if (category.value !== 'all') {
						category.typesPrestation = types.filter((t) => t.cpid === category.cpid);
						category.defaultPrestations = defaultPrestations.filter((d) => d.cpid === category.cpid);
					}
					return category;
				});
				this.categories = this.categories.map((cat) => {
					let category = new CategoryPrestation().deserialize(cat);
					if (category.value !== 'all') {
						category.typesPrestation = types.filter((t) => t.cpid === category.cpid);
						category.defaultPrestations = defaultPrestations.filter((d) => d.cpid === category.cpid);
					}
					return category;
				});
				this.cacheService.setItem('categories', this.categories);
			}

			this.prestationsPromo = [];
			let cityFilter = this.filterService.filterBy.city;

			let filters = this.filterService.makeFilters({city: cityFilter, promotion: true}, 'prestation', this.filteredSalons);
			this.prestationsPromo = _.filter(this.prestationsDefault, _.conforms(filters));


			// let promoPrestationPromises = [];

			// this.prestationsDefault.forEach((s) => {

			// 	promoPrestationPromises.push(

			// 		new Promise<any>(async (resolve3) => {

			// 			let res = true;

			// 			if (!s.promotion) {
			// 				res = false;
			// 			} else if (s.promotion && s.promotionEndDateEnabled && s.promotionEndDate) {
			// 				let endDate = new Date(s.promotionEndDate);
			// 				if (endDate > date) {
			// 					res = true;
			// 				}
			// 			}

			// 			//Verify if prestation promo is in available day of oprening salon
			// 			if(res){

			// 				let targetSalon: SalonDetail;

			// 				if(this.filteredSalons && this.filteredSalons.length > 0){

			// 					targetSalon = this.salonsDefault.find((value) => value.sid === s.sid);
							
			// 					if(!targetSalon || targetSalon == undefined || (targetSalon && !targetSalon.sid)){
			// 						let salonSnap = await this.getSalon(s.sid);
			// 						targetSalon = new SalonDetail().deserialize(salonSnap.data());
			// 					}

			// 				}else{
			// 					let salonSnap = await this.getSalon(s.sid);
			// 					targetSalon = new SalonDetail().deserialize(salonSnap.data());
			// 				}

			// 				res = this.getIsPromotionAvailable(targetSalon, s);

			// 			}

			// 			resolve3({prestation: s, availablePromo: res })

			// 		})

			// 	)

			// });

			// Promise.all(promoPrestationPromises).then(async (prestationsState) => {

				
			// 	this.prestationsPromo = [];

			// 	prestationsState.forEach(prestaState => {
			// 		if(prestaState.availablePromo){
			// 			this.prestationsPromo.push(prestaState.prestation)
			// 		}
			// 	});

			// });

			// this.prestationsPromo = _.orderBy(this.prestationsPromo, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
			// 	return new PrestationDetail().deserialize(s);
			// });

			this.prestationsPopular = [];
			const iterates = obj => -obj.likes.length;
			this.prestationsPopular = _.sortBy(this.prestationsDefault, iterates);
			this.prestationsPopular.slice(0, 9);

			this.prestationsTrend = [];
			const iteratesTrend = obj => -obj.createdAt;
			this.prestationsTrend = _.sortBy(this.prestationsDefault, iteratesTrend);
			this.prestationsTrend.slice(0, 9);

			this.loadingDefault = false;
			this.defaultLoaded = true;
			// resolve();

		});
	}

	searchbarChange(keyword: string) {
		this.results = this.getResults(keyword);
		this.resultsP = this.getResultsP(keyword);
	}

	getResults(keyword: string): Observable<TypePrestation[]> {
		let observable: Observable<TypePrestation[]>;
		observable = of(this.typesPrestations);


		return observable.pipe(map(
			(result: any[]) => {
				if (keyword) {
					return result.filter(
						(item) => {
							keyword = this.removeAccent(keyword);
							let typeName = this.removeAccent(item.name);
							return typeName.toLowerCase().includes(
								keyword.toLowerCase()
							);
						}
					);
				} else {
					return result;
				}

			}
		));
	}

	getResultsP(keyword: string): Observable<DefaultPrestation[]> {
		let observable: Observable<DefaultPrestation[]>;
		observable = of(this.defaultPrestationsResult);


		return observable.pipe(map(
			(result: any[]) => {
				if (keyword) {
					return result.filter(
						(item) => {
							keyword = this.removeAccent(keyword);
							if (item.name) {
								let typeName = this.removeAccent(item.name);
								return typeName.toLowerCase().includes(
									keyword.toLowerCase()
								);
							}
							return false;
						}
					);
				} else {
					return result;
				}

			}
		));
	}

	getTypesBySalon(salonId: string): string[] {
		let salonTypes: string[] = [];
		if (!this.prestationsDefault || !this.typesPrestations) {
			return [];
		}
		this.prestationsDefault.forEach((p) => {
			if (p.sid === salonId) {
				p.typesIds.forEach((t) => {
					if (!salonTypes.includes((t))) {
						salonTypes.push(t);
					}
				});
			}
		});
		return salonTypes.map((typeId) => this.typesPrestations.find((t) => t.tpid === typeId
		).name);
	}

	async getSalonWithTypes(sid: string) {
		let salon: SalonDetail;
		if (this.salonsDefault) {
			salon = this.salonsDefault.find((value) => value.sid === sid);
		}
		if (!salon) {
			let salonSnap = await this.getSalon(sid);
			salon = new SalonDetail().deserialize(salonSnap.data());
		}

		if (!this.prestationsDefault) this.prestationsDefault = [];

		let prestations: PrestationDetail[] = this.prestationsDefault.filter((value) => value.sid === sid);
		salon.categories = salon.categories.map((cat) => this.categories.find((c) => c.cpid === cat)).filter((el) => el).map((el) => el.fr);
		salon.typesPrestations = _.uniq(
			prestations.map((p) => p.typesIds)
				.filter((el) => el)
				.reduce((prev, current) => {
					current.forEach((c) => prev.push(c));
					return prev;
				}, [])
		).map((el) => {
			let type = this.types.find((t) => t.tpid === el);
			return type ? type.fr : undefined;
		});
		return salon;

	}

	getSalon(sid: string) {
		return this.firestore.collection(PrestationService.SALON_COLLECTION_NAME).doc(sid).ref.get();
	}

	getPriceUnit(salonId): string {
		if (!this.prestationsDefault) {
			return 'CHF';
		}
		let priceUnit = this.prestationsDefault.find((p) => p.sid === salonId).priceUnit;
		return priceUnit ? priceUnit : 'CHF';
	}

	removeAccent(value) {
		return value
			.replace(/á/g, 'a')
			.replace(/à/g, 'a')
			.replace(/â/g, 'a')
			.replace(/ä/g, 'a')
			.replace(/é/g, 'e')
			.replace(/ê/g, 'e')
			.replace(/è/g, 'e')
			.replace(/ë/g, 'e')
			.replace(/ï/g, 'i')
			.replace(/ô/g, 'o')
			.replace(/ù/g, 'u')
			.replace(/û/g, 'u');
	}

	getPrestationsOnDiscount(sid: string) {
		return this.firestore.collection<PrestationDetail>(PrestationService.COLLECTION_NAME).ref.where('sid', '==', sid).where('promotion', '==', true).get();
	}

	promotionDateExpired(prestation: PrestationDetail): boolean {

	    let isExpired = false;
	    let dateToCompare = new Date()
	    // dateToCompare.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())

	    if (prestation.promotionStartDateEnabled) {

	      let dateStart = new Date(prestation.promotionStartDate.toString());
	      dateStart.setHours(0, 0, 0, 0)
	      isExpired = dateToCompare < dateStart;
	      if (isExpired) return isExpired;
	    }

	    if (prestation.promotionEndDateEnabled) {

	      let dateFin = new Date(prestation.promotionEndDate.toString());
	      dateFin.setHours(0, 0, 0, 0)

	      isExpired = dateToCompare > dateFin;
	      if (isExpired) return isExpired;
	    }

	    return isExpired;
  	}

  	getIsPromotionAvailable(salon: SalonDetail, prestation: PrestationDetail):boolean{
	    /*let dayIndexOnPromotion = new Date().getDay();

	    if (dayIndexOnPromotion === 0)
	      dayIndexOnPromotion = 6;
	    else
	      dayIndexOnPromotion -= 1;

	   */


	    let arrayOfPromoAvailability = [];

	    shortDayLabel.forEach((value, index) => {

	      const promotionDay: any = prestation.promotionDays[index];

	      let typeOfSalonAvailability = typeof ((salon.availability[shortDayLabel[index].key] as AvailabilityState).available)

	      if ((typeOfSalonAvailability === 'boolean' && !(salon.availability[shortDayLabel[index].key] as AvailabilityState).available) || (typeOfSalonAvailability !== 'boolean' && !(salon.availability[shortDayLabel[index].key] as AvailabilityState).available.am && !(salon.availability[shortDayLabel[index].key] as AvailabilityState).available.pm)) {
	        arrayOfPromoAvailability.push(false);
	      } else {
	        
	        const promotionDay: any = prestation.promotionDays[index];
	        if (promotionDay && promotionDay.enabled && !this.promotionDateExpired(prestation)) {
	          arrayOfPromoAvailability.push(prestation.promotion);
	        } else {
	          arrayOfPromoAvailability.push(false);
	        }
	      }

	    })

	    return arrayOfPromoAvailability.includes(true);

  	}

	saveHistory(prestation){
	
	    let historical = {};


	    if(this.authService.isLoggedIn){
	     	
	     	historical = {
		        prestationID: prestation.pid,
		        salonID: prestation.sid,
		        ipAddress: "empty",
		        userID: this.authService.userLoggedIn.uid
	      	};
	    	
	      	let userTesters = ["WNoMufYVlXg4FhG1OifOw8MKkUz2"];
	      	let test = false;
	      	let result = userTesters.filter(uid => {
	      		if(uid == this.authService.userLoggedIn.uid){
	      			test = true;
	      		}
	      	});
	      	
	      	if(test){
	      		return "";
	      	}

	    } else {
	      historical = {
	        prestationID: prestation.pid,
	        salonID: prestation.sid,
	        ipAddress: localStorage.getItem("ipAdress"),
	        userID: "empty"
	      };
	    }

		const queryOptions = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Content-Type': 'application/json'
			})
		};

		return this.httpClient.post<any>(dataConst.API_ENDPOINT + 'prestationsViewHistory', JSON.stringify({ ...historical }), queryOptions);

	}


}
