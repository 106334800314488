import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { NavController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

	redirectionKey = {
		previousUrl: "previousUrl",
		currentUrl: "currentUrl",
		activateRedirectToPreviousUrl: "activateRedirectToPreviousUrl",

        originUrlForTabPage: "originUrlForTabPage",
	}

    typeTab = {
        salon_client : "salon_client",
        profil : "profil",
        message : "message"

    }

    constructor(private router: Router, private navCtrl: NavController, private location: Location) { }

	getOriginUrlForTabPage(): any [] {
		return JSON.parse( localStorage.getItem(this.redirectionKey.originUrlForTabPage) );
	}

	setOriginUrlForTabPage(urls : any []): void {
        return localStorage.setItem(this.redirectionKey.originUrlForTabPage , JSON.stringify(urls) );
    }

    getPreviousUrl(): string | null{
        return localStorage.getItem(this.redirectionKey.previousUrl);
    }

    setPreviousUrl(previousUrl: string | null): void{
        localStorage.setItem(this.redirectionKey.previousUrl, previousUrl);
    }

    getCurrentUrl(): string | null{
		return localStorage.getItem(this.redirectionKey.currentUrl);
	} 

	setCurrentUrl(currentUrl: string | null): void{
		localStorage.setItem(this.redirectionKey.currentUrl, currentUrl);
	}

	getActivateRedirectToPrevious(): boolean | null{
		return localStorage.getItem(this.redirectionKey.activateRedirectToPreviousUrl) === 'true';
	} 

	setActivateRedirectToPrevious(isActive: boolean | null): void{
		localStorage.setItem(this.redirectionKey.activateRedirectToPreviousUrl, isActive.toString());
	}

  getUrlState(): {previousUrl: string|null, currentUrl: string|null, activateRedirectToPreviousUrl: boolean|null}{
    return {previousUrl: this.getPreviousUrl(), currentUrl: this.getCurrentUrl(), activateRedirectToPreviousUrl: this.getActivateRedirectToPrevious()};
  }

	setUrlState(previousUrl: string | null, currentUrl: string | null, hasException = true): void{


        let currentUrlOld = this.getCurrentUrl();

        let arrayUrl = this.getOriginUrlForTabPage() || [];

        if (hasException && currentUrlOld !== currentUrl && this.testInsertUrl(arrayUrl , currentUrl)) {

            let previousUrll = this.getCurrentUrl();

			// this.setPreviousUrl(previousUrl);
			this.setCurrentUrl(currentUrl);
            let isTab = currentUrl.includes("") || currentUrl.includes("/chat")|| currentUrl.includes("/profil") ? true : false;
            arrayUrl.push({ url : currentUrl  , isTab : isTab , type : this.getType(currentUrl)});

            this.setOriginUrlForTabPage(arrayUrl);
		}

	}

    getType(url){

        if (url.includes("")) {
            return this.typeTab.salon_client;
        } else if (url.includes("/profil")) {
            return this.typeTab.profil;
        } else if (url.includes("/chat")) {
            return this.typeTab.message;
        }
   
        return null;

    }

    testInsertUrl(arrayUrlContent, url) {

        let arrayUrl = arrayUrlContent.map(data => data.url);
        let indexOfLast = arrayUrl.length-1;

        return true;

        if(indexOfLast>=0){
            let urlLast = arrayUrl[indexOfLast];
            if ((urlLast.includes("") && url.includes("")) || (url.includes("/profil/") && urlLast.includes("/profil/")) || (urlLast.includes("/chat") && url.includes("/chat")))
            return false;
        }

        return true;


    }

    redirectTo(url: string, activatePreviousRedirect: boolean){
        this.setActivateRedirectToPrevious(activatePreviousRedirect);
        this.router.navigateByUrl(url);
    }

    redirect(){

        let currentUrl = this.getCurrentUrl();
        let urls = this.getOriginUrlForTabPage();

        let urlsContent = urls.map(data => data.url);


        let indexOfCurrent = urlsContent.lastIndexOf(currentUrl);
        let indexOfPrevious = indexOfCurrent - 1;
       
        if (indexOfPrevious >= 0) {

            let backUrlContent = urls[indexOfPrevious];
            let currentUrlContent = urls[indexOfCurrent];

            if(!currentUrlContent.isTab){
                urls.pop();
                this.setCurrentUrl(backUrlContent.url);
                this.setOriginUrlForTabPage(urls);
                // this.router.navigateByUrl(backUrlContent.url);
                this.navCtrl.navigateBack(backUrlContent.url);
            }else{
                let newBackUrlObject = this.getRealBackUrlIsTabWithIndex(urls);

                if (newBackUrlObject.index != -1){
                    urls.splice(newBackUrlObject.index)
                    this.setOriginUrlForTabPage(urls);
                }

                this.navCtrl.navigateBack(newBackUrlObject.url);
               
            }
        }
        return;

        if(this.getActivateRedirectToPrevious()){
            this.router.navigateByUrl(this.getPreviousUrl());
        }else{
            
            this.router.navigateByUrl(this.getCurrentUrl());
        }
        this.setActivateRedirectToPrevious(false);
    }

    getRealBackUrlIsTabWithIndex(urls) : any | null{


        for (var i = 0 ; i >= urls.length - 1; i++) {

            if(urls[i].type == urls[i-1].type && i-1 >=0){
                continue;
            }else if(i-1 >=0) {
                return { url:  urls[i - 1].url , index : (i-1) };
            }
        }

        return { url: "" , index: -1 };

    }


  clearDataRedirection(){
    localStorage.removeItem(this.redirectionKey.previousUrl);
    localStorage.removeItem(this.redirectionKey.currentUrl);
    localStorage.removeItem(this.redirectionKey.activateRedirectToPreviousUrl);
    localStorage.removeItem(this.redirectionKey.originUrlForTabPage);
    
  }

}
