import { Component, OnInit , Input} from '@angular/core';

import { ReservationDetail, ReservationStatus } from '../../../models/reservation.model';
import { UserService } from '../../../services/user.service';

import { User } from '../../../models/user.model';

@Component({
  selector: 'app-user-reservation-item',
  templateUrl: './user-reservation-item.component.html',
  styleUrls: ['./user-reservation-item.component.scss'],
})
export class UserReservationItemComponent implements OnInit {

	@Input() reservation;
	@Input() showPrice = true;
	client: User;

  	constructor(private userService: UserService) { }

  	ngOnInit() {
  		this.getClient();
  	}

  	getClient(){
  		this.userService.getUser(this.reservation.client.uid).then(snapshot => {
			this.client = User.userFactory(snapshot.data());
		});
  	}

}
