import { Component, OnInit, Input } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker/date-picker/date-picker-config.model';

import { SalonService } from 'src/app/services/salon.service';
import { SalonDetail } from 'src/app/models/salon.model';

import { ReservationService } from 'src/app/services/reservation.service';
import { Reservation, ReservationDetail, ReservationStatus } from 'src/app/models/reservation.model';

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-salon-ca-graph',
  templateUrl: './salon-ca-graph.component.html',
  styleUrls: ['./salon-ca-graph.component.scss'],
})
export class SalonCaGraphComponent implements OnInit {

    @Input() salon: SalonDetail;

  	loading =  {
  		graphData: false,
      globalCA: false
  	}

	  barChartOptions = {
	    scaleShowVerticalLines: false,
	    responsive: true
  	};
  	
  	lineChartLabels: string[] = [];
  	
  	lineChartType = 'line';
	
    lineChartLegend: boolean;
	
	  lineChartData: any[] = [];

	  dateFormat = 'DD MMM YYYY';

  	startDate: number;
  	endDate: number;

	  filterStartDate: string;
  	filterEndDate: string;

  	userLang = navigator.language ? navigator.language : 'fr'; 

  	filterStartDateConfig: IDatePickerConfig = {
  		format: this.dateFormat,
  		locale: this.userLang,
  	}

  	filterEndDateConfig: IDatePickerConfig = {
  		format: this.dateFormat,
  		locale: this.userLang,
  	}

    caByPeriode = 0;
    caGlobal = 0;

  	constructor(
      private salonService: SalonService,
      private reservationService: ReservationService
    ) { }

  	ngOnInit() {
      this.startDate = null;
      this.endDate = null;
      this.getSalonCADataByPeriod();
      this.getSalonGlobalCA();
  	}

    getSalonCADataByPeriod(){

      this.loading.graphData = true;

      this.salonService.getSalonCAByPeriod(this.salon.sid, this.startDate, this.endDate).then((data: {periode:any[], dataCA: number[]}) => {

        let tabPeriode = [...data.periode];

        tabPeriode = tabPeriode.map((periode, index) => {

          let periodeLabel = new DatePipe('fr-FR').transform(periode.startDate, "MMMM yyyy");

          /*if(index == 0){
            periodeLabel = new DatePipe('fr-FR').transform(periode.startDate, " dd-MMMM-yyyy");
          }else if(index == tabPeriode.length-1){
            periodeLabel = new DatePipe('fr-FR').transform(periode.endDate, " dd-MMMM-yyyy");
          }*/

          return periodeLabel

        });

        this.lineChartLabels = [...tabPeriode];

        this.lineChartData = [
          {data: [...data.dataCA], label: 'Montant CA'},
        ]

        this.caByPeriode = [...data.dataCA].reduce(function (accumVariable, curValue) {
          return accumVariable + curValue;
        }, 0);

        this.loading.graphData = false;

      }).catch(error => {
            this.loading.graphData = false;
            // TODO console.log("error", error);
      });

    }

    getSalonGlobalCA(){

      this.loading.globalCA = true;

      this.reservationService.getSalonReservation(this.salon.sid, null, null, ReservationStatus.ACCOMPLISHED, ReservationStatus.ACCOMPLISHED).then((data) => {
        
        data.docs.forEach(reservations => {
          let reservation = new ReservationDetail().deserialize(reservations.data());
          this.caGlobal += reservation.priceResume.reducedAmount;
        });
        
        this.loading.globalCA = false;

      });
    }


  	onSelectFilterStartDate(event){
   		if(event.date != "" && event.date._d.getTime()){
        this.startDate = event.date._d.getTime();
      }else{
        this.startDate = null
      }
   		this.getSalonCADataByPeriod();
   	}

 	  onSelectFilterEndDate(event){
   		if(event.date != "" && event.date._d.getTime()){
        this.endDate = event.date._d.getTime();
      }else{
        this.endDate = null
      }
      this.getSalonCADataByPeriod();
   	}

}
