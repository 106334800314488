import { Image  as AskeedImage} from 'src/app/models/image.model';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Platform } from '@ionic/angular';
// import { Camera, CameraResultType } from '@capacitor/camera';
import { ActionSheetController } from '@ionic/angular';

import { Plugins, CameraResultType } from '@capacitor/core';

const {  Camera, BackgroundTask, CameraOptions } = Plugins;
import { TranslateService } from '@ngx-translate/core';
import { IonContent, LoadingController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-images-upload',
  templateUrl: './images-upload.component.html',
  styleUrls: ['./images-upload.component.scss'],
})
export class ImagesUploadComponent implements OnInit {

  @ViewChild('inputFile', {static: false}) inputFile: ElementRef<HTMLElement>;

  @Input() label: string;
  @Input() accept = "image/*";
  @Input() icon = "add-photo";
  @Input() multiple = false;
  @Input() imgPdf : string;
  @Input() pdfLabel : string;
  @Input() fileType: string = "image";
  @Input() imagesPreview: AskeedImage[] = [];
  @Input() fromProfil = false;
  @Input() typeIcon = false;
  @Input() fileNo_Url = false;
  @Output() imagesChange = new EventEmitter<AskeedImage[]>();

  croppedImagepath: string = "";
  isLoading = false;

  imagePickerOptions = {
    maximumImagesCount: 1,
    quality: 50
  };

  value: any;
 
  constructor(  
    public translate: TranslateService,
    public platform: Platform,
    public imagePicker: ImagePicker,
    public webView: WebView,
    public zone: NgZone,
    public actionSheetController: ActionSheetController,
    private toastController: ToastController
    ) { }

  ngOnInit() {

    //traitement uniquement pour les fichiers d'inscription  
    if (!this.imagesPreview) {
      this.imagesPreview = [];
    } else if (this.imagesPreview && this.fileNo_Url) {
        if (this.imagesPreview.filter(data => data).length > 0 )
            this.imagesPreview = this.imagesPreview.filter(data => data).map(data => (new AskeedImage(data)));
        else
            this.imagesPreview = []; 
    }
  }
 
  setImages() {

    this.imagesPreview = [];
    if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
      this.openMobileImageOption();
    } else {
      this.inputFile.nativeElement.click();      
    }
   }

   openImagePicker(){
     this.imagePicker.hasReadPermission().then(
        (result) => {
          if (result === false) {
            // no callbacks required as this opens a popup which returns async
            this.imagePicker.requestReadPermission();
          } else if (result === true) {
            this.imagePicker.getPictures({
              maximumImagesCount: this.multiple ? 20 : 1
            }).then(
              (results) => {

                results.forEach((r) => {
                  this.zone.run(() => {
                    let imageUri = this.webView.convertFileSrc(r);
                    let image: AskeedImage = new AskeedImage(imageUri);
                    const uriSplit = imageUri.split('/');
                      const imageName = uriSplit[uriSplit.length - 1];
                    image.filename = imageName;
                    this.imagesPreview.push(image);
                    this.imagesChange.emit(this.imagesPreview);
                  });
                });
              }
            ).catch(error => {
              console.log('Error:' + error.message);
            });
          }
        },
        (err) => {
          console.log('Error:' + err);
        }
      );
   }

  removeImages(index) {
    this.imagesPreview.splice(index, 1);
    this.imagesChange.emit(this.imagesPreview);
  }

  onFileChanged(e) {
    const files = e.target.files;
    for(let i = 0; i < files.length; i++){
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              const height = img.naturalHeight;
              const width = img.naturalWidth;
              if(width >= 800 ){

                let image = new AskeedImage(reader.result);
                this.imagesPreview.push(image);
                this.imagesChange.emit(this.imagesPreview);
              }else{
                this.presentToast(this.translate.instant("L'image est de faible résolution"));
              }
            };
      };

    }
  }
  
  //   this.imagePicker.hasReadPermission().then(
  //     (result) => {
  //       if (result === false) {
  //         // no callbacks required as this opens a popup which returns async
  //         this.imagePicker.requestReadPermission();
  //       } else if (result === true) {
  //         this.imagePicker.getPictures({
  //           maximumImagesCount: 1
  //         }).then(
  //           (results) => {

  //             results.forEach((r) => {
  //               this.zone.run(() => {
  //                 this.mainImage = [];
  //                 this.mainImage.push(this.webview.convertFileSrc(r));
  //                 this.imagesPreview[0] = this.webview.convertFileSrc(r);
  //               });
  //             });
  //           }, (err) => {
                // TODO console.log(err)
              // }
  //         ).catch(error => {
  //           this.presentToast('Error:' + error.message);
  //         });
  //       }
  //     },
  //     (err) => {
  //       this.presentToast('Error:' + err);
  //     }
  //   );


    checkTypeOfDoucmentByUrl(url) {

        if (url.includes('.pdf')) {
            return 'pdf';
        }
        return 'image';

    }
  //   this.imagePicker.hasReadPermission().then(
  //     (result) => {
  //       if (result === false) {
  //         // no callbacks required as this opens a popup which returns async
  //         this.imagePicker.requestReadPermission();
  //       } else if (result === true) {
  //         this.imagePicker.getPictures({
  //           maximumImagesCount: 1
  //         }).then(
  //           (results) => {

  //             results.forEach((r) => {
  //               this.zone.run(() => {
  //                 this.mainImage = [];
  //                 this.mainImage.push(this.webview.convertFileSrc(r));
  //                 this.imagesPreview[0] = this.webview.convertFileSrc(r);
  //               });
  //             });
  //           }, (err) => (err) => {
                // TODO console.log(err)
              // }
  //         ).catch(error => {
  //           this.presentToast('Error:' + error.message);
  //         });
  //       }
  //     },
  //     (err) => {
  //       this.presentToast('Error:' + err);
  //     }
  //   );

  onImgError(event, image){
    if(image._url){
      event.target.src = image._url;
    }else{
      event.target.src = 'assets/image/barbershop.jpg';
    }
  }


  // pickImage(sourceType) {
  //   const options: CameraOptions = {
  //     quality: 100,
  //     sourceType: sourceType,
  //     destinationType: this.camera.DestinationType.DATA_URL,
  //     encodingType: this.camera.EncodingType.JPEG,
  //     mediaType: this.camera.MediaType.PICTURE
  //   }
  //   this.camera.getPicture(options).then((imageData) => {
  //     // imageData is either a base64 encoded string or a file URI
  //     this.croppedImagepath = 'data:image/jpeg;base64,' + imageData;
  //   }, (err) => {
  //     // Handle error
  //   });
  // }

  async openMobileImageOption() {

    this.takePicture();
    return;
    const actionSheet = await this.actionSheetController.create({
      header: "Select Image source",
      buttons: [{
        text: 'Load from Library',
        handler: () => {
          this.openImagePicker()
        }
      },
      {
        text: 'Use Camera',
        handler: () => {
          this.takePicture();
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
      ]
    });
    await actionSheet.present();
  }

  async takePicture(){
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true, 
      resultType: CameraResultType.Uri
    });

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath;
    // Can be set to the src of an image now
    this.croppedImagepath  = imageUrl;
    this.imagesPreview = [];
    let imageTaken: AskeedImage = new AskeedImage(this.croppedImagepath);
    const uriSplit = this.croppedImagepath.split('/');
    const imageName = uriSplit[uriSplit.length - 1];
    imageTaken.filename = imageName;
    this.imagesPreview.push(imageTaken);
    this.imagesChange.emit(this.imagesPreview);
  }

  presentToast(msg:string) {
    this.toastController.create({
        message: msg,
        duration: 3000,
        position: 'middle'
      }).then(toast => toast.present());
  }

}
