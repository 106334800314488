import { Component, OnInit, Input , Output , ChangeDetectorRef , EventEmitter} from '@angular/core';
import { PrestationDetail, PrestationVariant } from '../../../models/prestation.model';
import { PromotionHour, PromotionDay, Discount } from '../../../models/promotion.model';
import  * as _ from 'lodash';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-promotion-edit',
  templateUrl: './promotion-edit.component.html',
  styleUrls: ['./promotion-edit.component.scss'],
})
export class PromotionEditComponent implements OnInit {

  @Output() whenPromotionChange: EventEmitter<any> = new EventEmitter();;
  @Input() prestation;
  @Input() noPromotionToggle : boolean = false;
  @Input() loading: boolean = false;

  discountValue: number[];
  reductions: Discount[] = [
    {label: '15%', selected: false, value: 15},
    {label: '30%', selected: false, value: 30},
    {label: '50%', selected: false, value: 50}
  ];

  hourSelected = 0;
  constructor(
      public translate: TranslateService,
      private toastController: ToastController,
      private cdrf: ChangeDetectorRef
      ) {

    

  }

  ngOnInit() {

  }

  selectDay(d: PromotionDay) {
    d.selected = true;
    // this.selectedDay = d;
    this.prestation.promotionDays.forEach((wd, i) => {
      if (wd.value !== d.value) {
        this.prestation.promotionDays[i].selected = false;
      } else {
        this.prestation.promotionDays[i].selected = true;
      }
      this.prestation.promotionDays[i].hours.forEach((pH, j) => {
        this.prestation.promotionDays[i].hours[j].selected = false;
      });
    });
    this.deselectHours();
  }

  selectHour(h: PromotionHour) {
    h.selected = !h.selected;
    this.hourSelected = 0;
    this.discountValue = [];
    this.prestation.promotionDays.forEach((pD, i) => {
      if (pD.selected) {
        this.prestation.promotionDays[i].hours.forEach((pH, j) => {
          if (pH.value === h.value && h.selected) {
            this.prestation.promotionDays[i].hours[j].selected = true;
          }
          if (!h.selected) {
            this.prestation.promotionDays[i].hours[j].selected = false;
          }
        });
        this.hourSelected = this.prestation.promotionDays[i].hours.filter(x => x.selected).length;
      } else {
        this.prestation.promotionDays[i].hours.forEach((pH, j) => {
          this.prestation.promotionDays[i].hours[j].selected = false;
        });
      }


      this.prestation.promotionDays[i].hours.forEach((pH, j) => {
        if (pH.promotion && pH.selected) {
          this.discountValue.push(pH.promotion.value);
        }
        if (!pH.promotion && pH.selected) {
          this.discountValue.push(-1);
        }
      });
      this.discountValue = _.sortedUniq(this.discountValue);
    });
    this.reductions.forEach( (r, i) => {
      if (this.discountValue.length === 1) {
        if (this.discountValue[0] === r.value) {
          this.reductions[i].selected = true;
        } else {
          this.reductions[i].selected = false;
        }
      }
    });
    // this.hourSelected+=1;
  }
  deselectHours() {

    this.prestation.promotionDays.forEach((pD, i) => {
      pD.hours.forEach((pH, j) => {
        this.prestation.promotionDays[i].hours[j].selected = false;
      });
    });
    this.hourSelected = 0;
    this.discountValue = [];
  }

  selectReduction(r: Discount) {
    r.selected = !r.selected;

    this.prestation.promotionDays.forEach((pD, i) => {
      if (pD.selected) {
        this.prestation.promotionDays[i].hours.forEach((pH, j) => {
          if (pH.selected) {
            if (r.selected) {
              this.prestation.promotionDays[i].enabled = true;
              this.prestation.promotionDays[i].hours[j].promotion = r;
            } else {
              delete this.prestation.promotionDays[i].hours[j].promotion;
              let enabled = false;
              this.prestation.promotionDays[i].hours.forEach(h => {
                if (h.promotion) {
                  enabled = true;
                }
                this.prestation.promotionDays[i].enabled = enabled;
              });
            }
          }
        });
      }
    });
    this.deselectHours();
    // this.updatePrestation(p);
    this.whenPromotionChange.emit(true);

  }

    detectChange(type, startEnd = null) {
        if (this.loading) return;

        let now: Date = new Date();
        now.setHours(0, 0, 0, 0)
        if (type == "1") {
            if (startEnd === "start" && this.prestation.promotionStartDateEnabled) this.prestation.promotionStartDate = this.prestation.promotionStartDate || null;
            else if (startEnd === "end" && this.prestation.promotionStartDateEnabled) this.prestation.promotionEndDate = this.prestation.promotionEndDate || null;
            this.whenPromotionChange.emit(true);
        } else if (type === "2" && startEnd === "start") {


            if (new Date(this.prestation.promotionStartDate) < now || (this.prestation.promotionEndDateEnabled && this.prestation.promotionEndDate && new Date(this.prestation.promotionEndDate).setHours(0, 0, 0, 0) < new Date(this.prestation.promotionStartDate).setHours(0, 0, 0, 0))) {
                setTimeout(() => {
                    this.prestation.promotionStartDate = null;
                }, 100);
                if (new Date(this.prestation.promotionStartDate) < now)
                    this.presentToast(this.translate.instant('Veuillez choisir une date de début ultérieur'))
                else
                    this.presentToast(this.translate.instant('Veuillez choisir une date de début antérieur'))
            }else{
                this.whenPromotionChange.emit(true);
            }
        } else if (type === "2" && startEnd === "end") {
            if (new Date(this.prestation.promotionEndDate) < now || (this.prestation.promotionStartDateEnabled && this.prestation.promotionStartDate && new Date(this.prestation.promotionEndDate).setHours(0, 0, 0, 0) < new Date(this.prestation.promotionStartDate).setHours(0, 0, 0, 0))) {
                setTimeout(() => {
                    this.prestation.promotionEndDate = null;
                }, 100);
                   
                this.presentToast(this.translate.instant('Veuillez choisir une date de fin ultérieur'))
            }else{
              this.whenPromotionChange.emit(true);
          }
        }
        this.cdrf.detectChanges();
    } 

    presentToast(msg: string) {
        this.toastController.create({
            message: msg,
            duration: 3000
        }).then(toast => toast.present());
    }

}
