import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-connection-failed',
  templateUrl: './connection-failed.component.html',
  styleUrls: ['./connection-failed.component.scss'],
})
export class ConnectionFailedComponent implements OnInit {

	@Input() show: boolean;
	@Input() fullHeight: boolean = true;
	@Input() fullWidth: boolean = true;
    @Output() resetAction: EventEmitter<void> = new EventEmitter();

	constructor() { }

	ngOnInit() {}

    reset(){
        this.resetAction.emit();
    }

}
