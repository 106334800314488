import { Component, OnInit, Input, EventEmitter, Output, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from 'src/app/services/filter.service';
import { TagService } from 'src/app/services/tag.service';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { SalonService } from 'src/app/services/salon.service';
import { SalonDetail } from 'src/app/models/salon.model';
import { CitiesService } from 'src/app/services/cities.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CategoryPrestation, TypePrestation } from 'src/app/models/defaultPrestation.model';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { PrestationService } from 'src/app/services/prestation.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.page.html',
  styleUrls: ['./search-modal.page.scss'],
})

export class SearchModalPage implements OnInit {


  @Output() filterChange = new EventEmitter<boolean>();
  @Input() overrideBackBtn = false;
  @Input() isSalon = false;
  @Output() backPressed = new EventEmitter();

  tagsCategory: Observable<any[]>;
  defaultView: boolean = true;
  salons: SalonDetail[] = [];
  searchBarInput: string;

  prestations: PrestationDetail;

  constructor(
    public modalController: ModalController,
    public filterService: FilterService,
    public translate: TranslateService,
    public tagService: TagService,
    private navCtrl: NavController,
    private salonService: SalonService,
    private prestationService: PrestationService,
    private citiesService: CitiesService,
    private router: Router
  ) { }

  ionViewWillEnter() {

  }

  ngOnInit() {
    this.updateResults();
    // this.filterService.tagsAdded.subscribe(t => {
    //   if (t) {
    //     this.salons = null;
    //     this.filterService.loading = true;
    //     this.citiesService.getCity().then(o => {
    //       this.filterService.filterBy['city'] = o;
    //       if (this.filterService.filterBy['country'] != undefined) {
    //         this.filterService.removeFilter('city');
    //       }

    //       this.filterService.getFilteredSalons(this.filterService.filterBy).subscribe(
    //         data => {
    //           this.salons = [];
    //           this.salons = data;
    //           if (this.filterService.sort) {
    //             this.salons = _.orderBy(this.salons, [this.filterService.sort], [this.filterService.sortType]).map((s) => {
    //               return new SalonDetail().deserialize(s);
    //             });
    //           }
    //           this.filterService.loading = false;
    //         },
    //         error => {
    //           this.filterService.loading = false;
    //         }
    //       );
    //     });


    //   }
    //   if (!t) {
    //     this.salons = null;
    //   }
    // });
  }

  tagStyle(type: TypePrestation) {
    let res = {};
    if (this.filterService.typesFilter.find((t) => t.tpid === type.tpid)) {
      res = {
        'background-color': 'var(--ion-color-primary)',
        color: 'var(--ion-color-secondary)'
      };
    }
    return res;
  }

  selectType(e, typeId) {
    this.updateResults();
  }

  isTypesSelected() {
    let res = false;
    this.filterService.categories.forEach((cat) => {
      if(cat.typesPrestation) {
        cat.typesPrestation.forEach((type) => {
          if (type.selected) {
            res = true;
          }
        });
      }
    });
    return res;
  }

  updateResults() {
    let typeSelected = this.isTypesSelected();
    this.filterService.categories.map((cat) => {
      let filterBy: any = {};
      filterBy.category = [cat.value.toLowerCase()];
      if (typeSelected && cat.typesPrestation) {
      filterBy.types = cat.typesPrestation.filter((type) => type.selected).map((type) => type.tpid);
      }
      let filters = this.filterService.makeFilters(filterBy, 'prestation');
      cat.prestations = _.filter(this.prestationService.prestationsDefault, _.conforms(filters));

      let filtersSalons = this.filterService.makeFilters(filterBy, 'salon');
      cat.salons = _.filter(this.salonService.salonsDefault, _.conforms(filtersSalons));
      return cat;
    });
  }

  isPrestationsResult() {
    let res = false;
    this.filterService.categories.forEach((cat) => {
      if (this.categoryIsShown(cat) && cat.prestations.length > 0) {
        res = true;
      }
    });
    return res;
  }

  isSalonsResult() {
    let res = false;
    this.filterService.categories.forEach((cat) => {
      if (this.categoryIsShown(cat) && cat.salons.length > 0) {
        res = true;
      } else if (cat.selected) {

      }
    });
    return res;
  }

  categoryIsShown(cat: CategoryPrestation) {
    let res = true;
    if (cat.value === 'all') {
      res = false;
    }
    let selected: CategoryPrestation = this.filterService.categories.find((c) => c.selected);
    if (selected.value !== 'all' && cat.cpid !== selected.cpid) {
      res = false;
    }

    if (selected.value === 'all' && 
    (!cat.typesPrestation || (cat.typesPrestation && cat.typesPrestation.length === 0) )
    ) {
      res = false;
    }
    return res;
  }

  search() {
    this.dismissModal();
    /*this.filterService.removeFilter("name");*/
    this.filterService.tagsAdded.next(true);
  }

  dismissModal() {
    // this.filterService.removeFilter("name");
    this.modalController.dismiss({
      'dismissed': true,
    });
    this.filterService.tagsAdded.next(true);
  }

  back() {
    this.modalController.dismiss({
      'dismissed': true,
    });
  }

  addTags(tag: string) {
    this.filterService.addTags(tag);
    this.filterService.filterBy['tags'] = this.filterService.tags;
  }

  showTag(tag) {
    let style = {}
    let res = this.filterService.tags.find((t) => t == tag);
    if (res) {
      style = {
        "display": "none"
      }
    }
    return style;
  }

  addTag(tag: string) {
    this.filterService.addTags(tag);
    this.filterService.searchModalInput = undefined;
    this.filterService.tagsResultModal = this.tagService.getResults('');

    this.filterService.filterBy['tags'] = this.filterService.tags;
  }

  async test() {
    let salonsSnap = await this.salonService.getAll();
    salonsSnap.forEach((observer) => {
      this.salons = [];
      observer.forEach((child) => {
        let s: SalonDetail = new SalonDetail().deserialize(child);
        this.salons.push(s);
      });
    });
  }

  onKeyUp() {
    this.searchBarInput = this.tagService.searchBarInput;
    if (this.searchBarInput.length > 0) {
      this.defaultView = false;
    }
    else {
      this.searchBarInput = undefined;
      this.defaultView = true;
    }
  }
}
