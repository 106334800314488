import { Component, OnInit, Input, EventEmitter, Output, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from 'src/app/services/filter.service';
import { TagService } from 'src/app/services/tag.service';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { SalonService } from 'src/app/services/salon.service';
import { SalonDetail } from 'src/app/models/salon.model';
import { CitiesService } from 'src/app/services/cities.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CategoryPrestation, DefaultPrestation, TypePrestation } from 'src/app/models/defaultPrestation.model';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { PrestationService } from 'src/app/services/prestation.service';
import { Category } from 'src/app/models/category.model';

@Component({
  selector: 'app-search-detail',
  templateUrl: './search-detail.component.html',
  styleUrls: ['./search-detail.component.scss'],
})
export class SearchDetailComponent implements OnInit {


  @Output() filterChange = new EventEmitter<boolean>();
  @Input() overrideBackBtn = false;
  @Input() isSalon = false;
  @Output() backPressed = new EventEmitter();

  tagsCategory: Observable<any[]>;
  defaultView: boolean = true;
  salons: SalonDetail[] = [];
  searchBarInput: string;

  prestations: PrestationDetail;
  tag: string;

  seeMores: number[];
  limitPrestations: number[];
  keyword: string;

  constructor(
    public modalController: ModalController,
    public filterService: FilterService,
    public translate: TranslateService,
    public tagService: TagService,
    private navCtrl: NavController,
    public salonService: SalonService,
    private prestationService: PrestationService,
    private citiesService: CitiesService,
    private router: Router
  ) { }

  ionViewWillEnter() {
  }

  ngOnInit() {
    this.updateResults();
    this.seeMores = [];
    this.limitPrestations = [];
    this.filterService.categories.forEach((cat, index) =>{
      this.seeMores[index] = 2;
      this.limitPrestations[index] = 5;
    });

  }

  seeMore(index){
    this.seeMores[index] += 4;
  }

  seeMorePrestations(index){
    this.limitPrestations[index] += 5;
  }

  searchbarChange(keyword) {
    if (keyword && keyword !== ''){
      this.keyword = keyword;
    }else{
      this.keyword = undefined;
    }
  }

  checkSalonCat(salons: SalonDetail[], cat: CategoryPrestation){
    return salons.filter((salon) => {
      if(this.keyword){
        return salon.categories.find((el) => {
          return el.toLowerCase() === cat.cpid.toLowerCase();
        }) && salon.name.toLowerCase().includes(this.keyword.toLowerCase());
      }
      return salon.categories.find((el) => {
        return el.toLowerCase() === cat.cpid.toLowerCase();
      });
    });
  }

  prestationsFilter(typesPrestation: TypePrestation[]){
    return typesPrestation ? typesPrestation.filter((type) => {
      if (this.keyword){
        return type.name.toLowerCase().includes(this.keyword.toLowerCase());
      }
      return true;
    }) : [];
  }

  defaultPrestationsFilter(defaultPrestation: DefaultPrestation[]){
    return defaultPrestation ? defaultPrestation.filter((type) => {
      if (this.keyword){
        return type.name.toLowerCase().includes(this.keyword.toLowerCase());
      }
      return true;
    }).sort((a, b ) => b.countPrestations - a.countPrestations) : [];
  }

  

  selectCategory(c: Category, sub = false) {

    c.selected = !c.selected;

    if (c.selected) {
      let categories = [];
      this.filterService.categories.forEach((category, index) => {
        if (category.value === c.value) {
          categories.push(category.value.toLowerCase());
        } else {
          this.filterService.categories[index].selected = false;
        }

      });
      this.filterService.filterBy.category = categories;
    } else {
      let selection = this.filterService.categories.find((c) => c.selected);
      if (!selection) {
        this.filterService.removeFilter("category");
      }
    }

    this.filterService.loading = true;
    // this.filterService.tagsAdded.next(true);

  }

  categoryStyle(cat: Category) {
    let style = {};
    if (cat.selected) {
      style = {
        "background-color": "var(--ion-color-primary)",
        "color": "white"
      }
      if (cat.value === 'all') {
        style = {
          'display': 'none'
        }
      }
    }

    return style;
  }

  
  tagStyle(type: TypePrestation) {
    let res = {};
    if (this.filterService.typesFilter.find((t) => t.tpid === type.tpid)) {
      res = {
        'background-color': 'var(--ion-color-primary)',
        color: 'var(--ion-color-secondary)'
      };
    }
    return res;
  }

  tagPrestationStyle(pres: DefaultPrestation) {
    let res = {};
    if (this.filterService.defaultPrestationsFilter.find((t) => t.dpid === pres.dpid)) {
      res = {
        'background-color': 'var(--ion-color-primary)',
        color: 'var(--ion-color-secondary)'
      };
    }
    return res;
  }

  selectType(e, typeId) {
    this.updateResults();
  }

  isTypesSelected() {
    let res = false;
    this.filterService.categories.forEach((cat) => {
      if (cat.typesPrestation) {
        cat.typesPrestation.forEach((type) => {
          if (type.selected) {
            res = true;
          }
        });
      }
    });
    return res;
  }

  updateResults() {
    let typeSelected = this.isTypesSelected();
    this.filterService.categories.map((cat) => {
      let filterBy: any = {};
      filterBy.category = [cat.value.toLowerCase()];
      if (typeSelected && cat.typesPrestation) {
        filterBy.types = cat.typesPrestation.filter((type) => type.selected).map((type) => type.tpid);
      }
      let filters = this.filterService.makeFilters(filterBy, 'prestation');
      cat.prestations = _.filter(this.prestationService.prestationsDefault, _.conforms(filters));

      let filtersSalons = this.filterService.makeFilters(filterBy, 'salon');
      cat.salons = _.filter(this.salonService.salonsDefault, _.conforms(filtersSalons));
      return cat;
    });
  }

  isPrestationsResult() {
    let res = false;
    this.filterService.categories.forEach((cat) => {
      if (this.categoryIsShown(cat) && cat.prestations.length > 0) {
        res = true;
      }
    });
    return res;
  }

  isSalonsResult() {
    let res = false;
    this.filterService.categories.forEach((cat) => {
      if (this.categoryIsShown(cat) && cat.salons.length > 0) {
        res = true;
      } else if (cat.selected) {

      }
    });
    return res;
  }

  categoryIsShown(cat: CategoryPrestation) {
    let res = true;
    if (cat.value === 'all') {
      res = false;
    }
    let selected: CategoryPrestation = this.filterService.categories.find((c) => c.selected);
    if (selected && selected.value !== 'all' && cat.cpid !== selected.cpid) {
      res = false;
    }else if (selected && selected.value === 'all' &&
      (!cat.typesPrestation || (cat.typesPrestation && cat.typesPrestation.length === 0))
    ) {
      res = false;
    }
    return res;
  }

  search() {
    this.dismissModal();
    /*this.filterService.removeFilter("name");*/
    // this.filterService.tagsAdded.next(true);
  }

  dismissModal() {
    // this.filterService.removeFilter("name");
    this.modalController.dismiss({
      'dismissed': true,
    });
    // this.filterService.tagsAdded.next(true);
  }

  back() {
    this.modalController.dismiss({
      'dismissed': true,
    });
  }

  addTags(tag: string) {
    this.filterService.addTags(tag);
    this.filterService.filterBy['tags'] = this.filterService.tags;
  }

  showTag(tag) {
    let style = {}
    let res = this.filterService.tags.find((t) => t == tag);
    if (res) {
      style = {
        "display": "none"
      }
    }
    return style;
  }

  addTag(tag: string) {
    this.filterService.addTags(tag);
    this.filterService.searchModalInput = undefined;
    this.filterService.tagsResultModal = this.tagService.getResults('');

    this.filterService.filterBy['tags'] = this.filterService.tags;
  }

  async test() {
    let salonsSnap = await this.salonService.getAll();
    salonsSnap.forEach((observer) => {
      this.salons = [];
      observer.forEach((child) => {
        let s: SalonDetail = new SalonDetail().deserialize(child);
        this.salons.push(s);
      });
    });
  }

  onKeyUp() {
    this.searchBarInput = this.tagService.searchBarInput;
    if (this.searchBarInput.length > 0) {
      this.defaultView = false;
    }
    else {
      this.searchBarInput = undefined;
      this.defaultView = true;
    }
  }
}
