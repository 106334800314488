import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { PrestationService } from 'src/app/services/prestation.service';
import { Router } from '@angular/router';
import { SalonDetail } from 'src/app/models/salon.model';
import { SalonService } from '../../../services/salon.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent implements OnInit {

	@Input() label = "";
	@Input() type = "secondary";
	@Input() icon:string;
	@Input() iconRight:string;
	@Input() options: any[];
	// @Input() fromProfil = false;
    @Input() selectedOption: any;
	@Input() modify: string;
	@Input() id: any;
	@Input() salon: SalonDetail;

	@Output() selectOption: EventEmitter<void> = new EventEmitter();

	modifyBtn: boolean = false;

    showOption = false;

    selectedOptionLabel: string;

	constructor(public translate: TranslateService, public alertController: AlertController,
				public prestationService: PrestationService,
				public salonService: SalonService,
				public router: Router) { }

	ngOnInit() {

		if(this.modify == 'true'){
			this.modifyBtn = true;
		}
		else if(this.modify == 'false'){
			this.modifyBtn = false;
		}

        // if (this.fromProfil) {
        //     let indexSelected = this.options.map(data => data.value).indexOf(this.selectedOption);
        //     this.selectedOptionLabel = (indexSelected != -1 ) ? this.options[indexSelected].label : '-';

        // }
	}

	optionChange() {
        // if (this.fromProfil) {
        //     let indexSelected = this.options.map(data => data.value).indexOf(this.selectedOption);
        //     this.selectedOptionLabel = (indexSelected != -1) ? this.options[indexSelected].label : '-';

        // }
		this.selectOption.emit(this.selectedOption);
	}

	
	async presentAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('Vraiment supprimer ?'),
      buttons: [
        {
          text: this.translate.instant('Non'),
          handler: () => {
          }
        }, {
          text: this.translate.instant('Oui'),
          handler: () => {
            this.deletePrestation();
            this.router.navigate(['/profil/salon/prestations/insideTab']);
          }
        }
      ]
    });

    await alert.present();
  }

  deletePrestation(){
  	this.prestationService.deletePrestationByPid(this.id);
  	this.salon.prestations.forEach((p,index) =>{
  		if(p == this.id){
  			this.salon.prestations.splice(index);
  		}
  	})
  	this.salonService.updateSalon(this.salon);
  }

}
