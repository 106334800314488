import { Component, OnInit, Input } from '@angular/core';
import { Salon } from '../../../models/salon.model';

import { SalonDetail } from '../../../models/salon.model';
import { Address } from '../../../models/address.model';

import { SalonService } from '../../../services/salon.service';
import { PrestationService } from 'src/app/services/prestation.service';
import { CategoryPrestation } from 'src/app/models/defaultPrestation.model';

import { ReservationDetail } from 'src/app/models/reservation.model';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-salon-info-header',
  templateUrl: './salon-info-header.component.html',
  styleUrls: ['./salon-info-header.component.scss'],
})
export class SalonInfoHeaderComponent implements OnInit {

	@Input() type:number = 1;
	@Input() distance:boolean = false;
	@Input() minimize: boolean = false;
	@Input() salon: Salon;
	@Input() by: string = 'client';
	@Input() label: string;
	@Input() clientImage: string;
    @Input() reservation: ReservationDetail;
    @Input() showPrestationPrice: boolean = false;
    @Input() showReservationDetail: boolean = false;
    categories: CategoryPrestation[] = [];
    datePrestation: string = "-";
    constructor(private prestationService: PrestationService) { }

    ngOnInit() {

        this.datePrestation = this.reservation ? new DatePipe('fr-FR').transform(this.reservation.executionDate, "dd/MM/yyyy") : "-"
		this.prestationService.listCategories().subscribe(values => {

			this.categories = [];

			values.forEach(document => {
				this.categories.push(new CategoryPrestation().deserialize(document));
			});

		})
	}

	get mappedCategories(): string {
		
		if (this.salon && this.salon.categories) {
			 return this.salon.categories.map(cpid => this.categories.find(cat => cat.cpid === cpid)).filter(cat => cat !== null && cat !== undefined).map(cat => cat.fr).join("/");
		}
		
		return "";
	}

}
