import { Component, OnInit, Input } from '@angular/core';
import { ReservationDetail, ReservationStatus } from 'src/app/models/reservation.model';
import { ReservationService } from 'src/app/services/reservation.service';
import { ToastController, LoadingController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-finalize-reservation-done',
  templateUrl: './finalize-reservation-done.component.html',
  styleUrls: ['./finalize-reservation-done.component.scss'],
})
export class FinalizeReservationDoneComponent implements OnInit {

    @Input() reservation: ReservationDetail;
    @Input() readOnly = false;
    @Input() edit = false;
    notes: { prestation: number; service: number; punctuality: number; cleanliness: number, comment: string };

    constructor(
        private toastController: ToastController,
        private loadingController: LoadingController,
        private reservationService: ReservationService,
        private authService: AuthService,
        private _location: Location,
        public translate: TranslateService
    ) { }

    ngOnInit() {

        let idInt = setInterval( () => {
            if (this.reservation) {

                if( (this.readOnly || this.edit) && this.reservation.notes) {
                    this.notes = this.reservation.notes;
                }else{
                    this.notes = {
                        prestation: 2,
                        service: 2,
                        punctuality: 2,
                        cleanliness: 2,
                        comment: ""
                    };
                }
                clearInterval(idInt);
            }
        }, 500 );
            

    }

    async dismiss(isCancel = true) {

        const loading = await this.loadingController.create({
            message: this.translate.instant("Enregistrement de vos avis"),
            cssClass: 'askeed-spinner'
        });

        await loading.present();

        this.reservation.notes = this.notes;

        if(this.reservation.client) this.reservation.client.status = ReservationStatus.ACCOMPLISHED;
        
        if(this.reservation.salon) this.reservation.salon.status = ReservationStatus.ACCOMPLISHED;

        this.reservation.updatedBy = this.authService.userLoggedIn.uid === this.reservation.client.uid ? "client" : "salon";

        if(this.reservation.rid){
                this.reservationService.updateReservation(this.reservation).then(() => {
                loading.dismiss();
                this._location.back();
            }, error => {
                this.presentToast(this.translate.instant("Une erreur est survenue Veuillez réessayer"));
                loading.dismiss();
            });
        }else{
            loading.dismiss();
            this._location.back();
        }
        
    }

    presentToast(msg: string) {
        this.toastController.create({
            message: msg,
            duration: 3000
        }).then(toast => toast.present());
    }

}
