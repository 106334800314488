import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CategoryPrestation } from '../models/defaultPrestation.model';
import { TranslateService } from "@ngx-translate/core"
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  static COLLECTION_NAME = 'categoryPrestation';

  categories: CategoryPrestation[];
  
  constructor(
    private firestore: AngularFirestore,
    private translate : TranslateService
  ) {
    // this.initCategories();
   }

  initCategories(){
    return this.list().subscribe((catSnap) => {
      this.categories = [];
      catSnap.forEach((cat) => {
        this.categories.push(new CategoryPrestation().deserialize(cat));
      });
    });
  }
  
  listAllCategories(){
    return this.list().subscribe((catSnap) => {
      this.categories = [];
      catSnap.forEach((cat) => {
        this.categories.push(new CategoryPrestation().deserialize(cat));
      });
    });
  }

  list() {
		return this.firestore.collection<CategoryPrestation>(CategoryService.COLLECTION_NAME).valueChanges();
  }

  listNameByIds(ids: string[]){
    let result: string[] = [];
    if (this.categories) {
      ids.forEach((id) => {
        let cat: CategoryPrestation = this.categories.find((c) => c.cpid == id);
        if(cat){
          result.push(cat[this.translate.currentLang] || cat.fr );
        }
      });
    }
    return result;
  }
}
