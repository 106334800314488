import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { ModalController, LoadingController, ToastController} from '@ionic/angular';
import { SalonDetail } from 'src/app/models/salon.model';
import { SalonService} from 'src/app/services/salon.service'; 
import { Abonnement, OptionAbonnement, PromotionAbonnement, TarificationType, DurationType, periodLabel } from 'src/app/models/abonnement.model';
import { AbonnementService } from 'src/app/services/abonnement.service';
import { Subscription } from 'rxjs'; 
import { DatePipe } from '@angular/common';
import { ChangeRequestService } from 'src/app/services/change-request.service';
import { ChangeRequest, TargetEntity } from 'src/app/models/change-request.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-salon-abonnement-edit',
  templateUrl: './salon-abonnement-edit.component.html',
  styleUrls: ['./salon-abonnement-edit.component.scss'],
})
export class SalonAbonnementEditComponent implements OnInit, OnDestroy {

	loading = {
		currentAbo: true,
		newAbo: true
	}

	@Input() salon: SalonDetail;
	@Input() newAbonnement: Abonnement;

	currentAbonnement: Abonnement;
	currentPromotion: PromotionAbonnement;
	newPromotion: PromotionAbonnement;

	labelOriginalPrice = "";
	labelPromoPrice="";
	labelPromoDuration="";

	newlabelOriginalPrice = "";
	newlabelPromoPrice="";
	newlabelPromoDuration="";

	subscription: Subscription[] = [];
 
	dateNewAbonnement: string;

  	constructor(
  		private abonnementService: AbonnementService,
  		public modalController: ModalController,
  		private loadingController: LoadingController,
  		private toastController: ToastController,
  		private changeRequestService: ChangeRequestService,
  		private router: Router
	) { }

  	ngOnInit() {}

  	ionViewWillEnter() {

  		
  		this.loading.newAbo = true;

  		this.initCurrentAbo();
  		this.initNewAbo();

  		let date = new Date();
		let firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
		this.dateNewAbonnement = new DatePipe('fr-FR').transform(firstDayOfNextMonth, "dd/MM/yyyy")

	}

	initCurrentAbo(){

		this.loading.currentAbo = true;

  		if(this.salon && this.salon.abonnement && this.salon.abonnement.formule){

  			this.subscription.push(

  				this.abonnementService.getAbonnementById(this.salon.abonnement.formule).subscribe(abo => {
  					this.currentAbonnement = new Abonnement().deserialize(abo);

  					this.labelOriginalPrice = this.currentAbonnement.price && this.currentAbonnement.price > 0 ? this.currentAbonnement.price+' '+this.currentAbonnement.priceUnit : 'Aucun frais mensuel';

  					this.getCurrentAbonnementPromotion();
  				})
			)

  		}else{
  			this.loading.currentAbo = false;
  		}

	}

	initNewAbo(){

		this.loading.newAbo = true;
		this.newlabelOriginalPrice = this.newAbonnement.price && this.newAbonnement.price > 0 ? this.newAbonnement.price+' '+this.newAbonnement.priceUnit : 'Aucun frais mensuel';
  		this.getNewAbonnementPromotion();
	}

	ngOnDestroy(){

		if(this.subscription.length > 0){
			for(let i=0; i<this.subscription.length; i++){
				this.subscription[i].unsubscribe();
			}
		}
	}

	getCurrentAbonnementPromotion(){

		if(this.salon && this.salon.abonnement && this.salon.abonnement.promotion){
  			
  			this.abonnementService.getPromotionById(this.salon.abonnement.promotion).subscribe(promo => {
  				this.currentPromotion = new PromotionAbonnement().deserialize(promo);
  				this.labelPromoPrice = this.currentPromotion.price+' '+this.currentAbonnement.priceUnit;
  				this.labelPromoDuration = this.currentPromotion.labelPromotionDuration;
  				this.loading.currentAbo = false;

  			});

  		}else{
  			this.labelPromoPrice="";
			this.labelPromoDuration="";
			this.loading.currentAbo = false;
  		}

	}

	getNewAbonnementPromotion(){

		if(this.newAbonnement.promotion && this.newAbonnement.promotion.promoId && this.newAbonnement.promotion.active){
  			
  			this.abonnementService.getPromotionById(this.newAbonnement.promotion.promoId).subscribe(promo => {
  				this.newPromotion = new PromotionAbonnement().deserialize(promo);

  				this.newlabelPromoPrice = this.newPromotion.price+' '+this.newAbonnement.priceUnit;
  				this.newlabelPromoDuration = this.newPromotion.labelPromotionDuration;
  				this.loading.newAbo = false;
  			});

  		}else{
  			this.newlabelPromoPrice="";
			this.newlabelPromoDuration="";
			this.loading.newAbo = false;
  		}

	}	

  	onDoAction(action: string){

		switch (action) {
			case "close":
				this.cancel();
				break;
			default:
				this.modalController.dismiss(null);
				break;
		}

	}

  	getPeriodLabel(period: TarificationType){
  		return periodLabel(period);
  	}

  	cancel(){
		this.modalController.dismiss(null);
	}


	async sendChangeAbonnementRequest(){

		const loading = await this.loadingController.create({
	      message: 'Enregistrement de votre demande...',
	      cssClass: 'askeed-spinner'
	    });

      	await loading.present();

      	let request = new ChangeRequest();

      	
      	request.oldObject = {abonnement: {formule: this.salon.abonnement.formule, promotion: this.salon.abonnement.promotion, promotionStartDate: this.salon.abonnement.promotionStartDate, promotionEndDate: this.salon.abonnement.promotionEndDate } }
	    request.newObject = {abonnement: {formule: this.newAbonnement.aid , promotion: this.newAbonnement.promotion && this.newAbonnement.promotion.promoId && this.newAbonnement.promotion.active ? this.newAbonnement.promotion.promoId : null }}
	    
	    let date = new Date();
		let firstDayOfNextMonth = new Date( Date.UTC(date.getFullYear(), date.getMonth() + 1, 1, 1, 0, 0) );

	    request.dateChangeExecution = firstDayOfNextMonth;
	    request.entity = TargetEntity.ABONNEMENT_SALON;

	    request.dataEntity = {
	    	salonId: this.salon.sid,
	    	userId: this.salon.ownerUserId,
	    }

      	this.changeRequestService.createChangeRequest(request).then(async () => {

      		await this.toastController.create({
	            message: "Votre nouvelle abonnement a été enregistré et s'appliquera à partir du "+this.dateNewAbonnement,
	            duration: 3500,
       			position: 'middle'
          	}).then(toastElt => toastElt.present());

          	loading.dismiss();
          	this.modalController.dismiss(null);
          	
      	}).catch(error => {

	        loading.dismiss();

	        this.toastController.create({
	            message: 'Erreur : ' + error.message,
	            duration: 3500,
	            position: 'middle'
          	}).then(toastElt => toastElt.present());

      	});

	}

}
