import { Component, OnInit, Input } from '@angular/core';
import { SalonDetail } from 'src/app/models/salon.model';

@Component({
  selector: 'app-item-salon',
  templateUrl: './item-salon.component.html',
  styleUrls: ['./item-salon.component.scss'],
})
export class ItemSalonComponent implements OnInit {

  @Input() salon:SalonDetail;
  mainAddress : any;
  constructor() { }

  ngOnInit() {
  	this.mainAddress = SalonDetail.getMainAddress(this.salon);
  }

}
