import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-input-tag',
  templateUrl: './common-input-tag.component.html',
  styleUrls: ['./common-input-tag.component.scss'],
})
export class CommonInputTagComponent implements OnInit {

  @Input() tags:string[] = [];
  @Output() tagsChange:EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() addClick = new EventEmitter<boolean>();
  @Input() label;
  @Input() color:string;
  @Input() placeholder:string;
  @Input() required:boolean = false;
  @Input() forPreview:boolean = false;
  @Input() inlineEdit:boolean = false;
  @Input() type = 0;
  tag:string
  constructor() { }

  ngOnInit() {}

  addTag(e:string=undefined){
    let ok = false
    if(e && e.includes(",")){
      e = e.replace(",", "");
      this.tag = e;
      ok = true;
    }
    if(e == undefined && this.tag && this.tag != ""){
        ok = true;
    }
    if(ok){
      this.tags.push(this.tag);
      this.tagsChange.emit(this.tags);
      this.tag = undefined;
    }
  }

  removeTag(i){
    this.tags.splice(i, 1);
    this.tagsChange.emit(this.tags);
  }
  emitterShowModal(){
    this.addClick.emit(true);
  }
}
