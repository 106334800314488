import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { CitiesSidebarComponent } from "../../components/cities-sidebar/cities-sidebar.component";
import { City } from 'src/app/models/city.model';
import { ReservationDetail } from 'src/app/models/reservation.model'; 
import { CitiesService } from "../../../services/cities.service";
import { RedirectionService } from "../../../services/redirection.service";
import { Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { LocalNotificationReservationService } from 'src/app/services/local-notification-reservation.service';
import { AuthService } from 'src/app/services/auth.service';
import { PrestationService } from 'src/app/services/prestation.service';
import { VariablesGlobales } from 'src/app/shared/variables-globales';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

  @Input() type:number = 0;
  @Input() title = '';
  @Input() view = '';
  @Input() imageUser = 'assets/img/loader.gif';
  @Input() withBackBtn = false;
  @Input() withTab = false;
  @Input() overrideBackBtn = false;
  @Output() backPressed = new EventEmitter();
  @Output() checkPressed = new EventEmitter();
  @Output() placeClick:EventEmitter<boolean> = new EventEmitter();
  @Input() noMenuBtn = false;
  @Input() emptyRightAction = false;
  @Input() isTchat = false;
  @Input() reservationFromChat: ReservationDetail;
  @Input() isSalon: boolean;
  @Input() isGallerie: boolean;
  @Input() showHeader = true;
  @Input() showTabsHome = true;
  @Input() tabStyle: any;
  @Input() isModal: boolean = false;
  @Input() noCheckBtn: boolean = false;
  city:City;

    colorDate = {
        "expired": "danger",
        "accomplished": "success",
        "notConfirmed": "warning",
        "upcoming": "tertiary",
        "archived": "dark",
        "canceled": "primary",
        "canceled_by_client": "primary",
        "canceled_by_salon": "primary",
        "edited": "quaternary"
    }

  static readonly dev_mode =  VariablesGlobales.dev_mode;
  public classReference = HeaderComponent;

  constructor(private menu: MenuController, 
              private navCtrl: NavController,
              public citiesService: CitiesService,
              public filterService: FilterService,
              public prestationService: PrestationService,
              private router:Router,
              private localNotification: LocalNotificationReservationService,
              public auhthService: AuthService,
              private changeDetectorRef: ChangeDetectorRef,
              private redirectionService: RedirectionService,
              private modalController: ModalController,
              public variablesGlobales: VariablesGlobales
    ) { }

  ngOnInit() {

      // this.changeDetectorRef.detectChanges();
/*    this.filterService.tagsAdded.next(true);
    this.filterService.tagsAdded.subscribe((c) =>{
      this.citiesService.getStocked('city').then(o=>{
        this.city= new City().deserialize(o);
      });
    })
    */

    // if(this.citiesService.getStocked("city")){
    //   this.city = new City().deserialize(this.citiesService.getStocked("city"));
    // }

    // this.citiesService.getCity()
  }

  dismissModal() { 
    if (this.emptyRightAction)
      this.modalController.dismiss();     
  }
  
  openMenu() {
    this.menu.open('mainMenu');
  }
  goToSearchCity(){
     this.router.navigate(['/search-city']);
  }

  closeMenu(){
    this.menu.close('mainMenu');
  }

  changePlace(){
    // this.placeClick.emit(true);
    this.menu.enable(true, 'placeMenu');
    this.menu.open('placeMenu');
    // this.citiesService.openEvent.next(true);

  }

  closeSidebar(){
    this.menu.close("placeMenu");
  }

    get reservation(): ReservationDetail{

        return this.reservationFromChat;
    }

  back() {
     

    if(this.overrideBackBtn){
      this.backPressed.emit(true);
    }else{
        this.redirectionService.redirect();
        // this.navCtrl.back();
    }
  }

  onCheckClick() {
    this.checkPressed.emit();
  }

  redirect(){
    this.router.navigate(['/home/salons']);
  }

    redirectChat() {
        this.router.navigate(['/chat/active']);
    }

  get notificationCount(): number {
    let count = 0;

    if (this.auhthService.userLoggedIn && this.auhthService.isLoggedIn) {

      // count = this.localNotification.messageNotRead + this.localNotification.upcomingAgenda + this.localNotification.salonMessage + this.localNotification.messageFromAdminNotRead;

      count = this.localNotification.upcomingAgenda;

      if (this.auhthService.userLoggedIn.alertCount)
        count += this.auhthService.userLoggedIn.alertCount;

      if (this.auhthService.userLoggedIn.salonAlertCount)
        count += this.auhthService.userLoggedIn.salonAlertCount;

    }

    return count;
  }

    get unreadMsgCount(): number {
        let count = 0;

        if (this.auhthService.userLoggedIn) {

            count = this.localNotification.messageNotRead + this.localNotification.salonMessage + this.localNotification.messageFromAdminNotRead;

        }

        return count;
    }

}
