import { Component, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RedirectionService } from './services/redirection.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService , dataKey} from 'src/app/shared/helpers/data.service';
import { UpdateService } from './services/update.service';
import { UserService } from './services/user.service';
declare var gtag: Function; 
import { MetafrenzyService } from 'ngx-metafrenzy';
import { HttpClient } from '@angular/common/http';
import { PrestationService } from './services/prestation.service';
import { CitiesService } from './services/cities.service';
import { SalonService } from './services/salon.service';
import { FilterService } from './services/filter.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {

  navigationSubscription: Subscription;
  previousUrl = '';
  currentUrl = '';
  loading = false;
  userSubscription: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private router: Router,
    private redirectionService: RedirectionService,
    public authService: AuthService,
    public dataService: DataService,
    public updateService: UpdateService,
    private userService: UserService,
    private readonly metafrenzyService: MetafrenzyService,
    private httpClient: HttpClient,
    public filterService:FilterService,
    public salonService:SalonService,
    private citiesService:CitiesService,
    public prestationService:PrestationService,

  ) {  

      if (this.authService.userLoggedIn && this.authService.userLoggedIn.uid && this.authService.userLoggedIn.language) {

          translate.setDefaultLang(this.authService.userLoggedIn.language);
          translate.currentLang = this.authService.userLoggedIn.language;

          this.userSubscription = this.userService.observerUser(this.authService.userLoggedIn.uid).subscribe( data => {
            let user:any = data.payload.data();
              translate.use(user.language);
              translate.setDefaultLang(user.language);
              translate.currentLang = user.language;
          })
      } else {
          let tempLang = JSON.parse( localStorage.getItem(dataKey.TEMP_LANG ) );
          if(tempLang){
              translate.setDefaultLang(tempLang);
              translate.currentLang = tempLang;
          }else{
              translate.setDefaultLang('fr');
              translate.currentLang = 'fr';
          }
      }

      this.metafrenzyService.setTags({
          title: "", // setting title and og:title
          description: this.translate.instant("askeed_description"), // setting meta description and og:description
          url: "https://app.askeed.ch/home",  // setting canonical and og:url
          // robots: '', // setting robots
          // image: '' // setting og:image:url
      });

    this.initializeApp();

      this.navigationSubscription = this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd)
      ).subscribe((e: any) => {
          if (e instanceof NavigationEnd) {

              if (e.url.includes('home/salons')) {
                  this.redirectionService.clearDataRedirection();
              }

              this.previousUrl = this.currentUrl;
              this.currentUrl = e.url;

              this.redirectionService.setUrlState(this.previousUrl, this.currentUrl);

          }
      });
      this.router.events.subscribe((e : RouterEvent) => {
        this.navigationInterceptor(e);
        if (e instanceof NavigationEnd) {
            gtag('set', 'page', e.urlAfterRedirects);
            gtag('send', 'pageview');
        }
      })

      this.citiesService.cityChange.subscribe(async (obs) => {
        if (obs) {
          if(this.filterService.searchType == "gallery"){
            this.filterService.refreshFilter();
            await this.salonService.getSalonsResult();
            this.prestationService.loadingResult = true;
          } else if(this.filterService.searchType == "salon"){
            this.salonService.getSalonsDefault().then(() => {
              this.salonService.getSalonsResult();
            });
          }
          this.prestationService.getPrestationsDefault();
        }
      });

      this.getIP();
  }

  ngOnInit() {
    this.prestationService.getPrestationsDefault();
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription)
      this.navigationSubscription.unsubscribe();
    if (this.userSubscription)
      this.userSubscription.unsubscribe();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
        this.updateService.checkForUpdate();
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }

  getIPAddress()
  {
    return this.httpClient.get("http://api.ipify.org/?format=json");
  }

  getIP()
  {
    let ip = localStorage.getItem("ipAdress");
    if (!ip || ip == "undefined" || ip == undefined) {
      return localStorage.getItem("ipAdress");
    } else {
      this.getIPAddress().subscribe((res:any)=>{
        localStorage.setItem("ipAdress", res.ip);
      });
    }
  }

  
}
