import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FacturationSalon, FacturationStatus } from '../models/facturation-salon.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { dataConst, DataService, dataKey, ConfigDocument } from '../shared/helpers/data.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class FacturationService {

	static COLLECTION_NAME = 'invoices';
	// static COLLECTION_NAME = 'invoicesSalonTest';
    static COLLECTION_NAME_CONFIG = 'invoiceConfig';

    
  	constructor(
  		private firestore: AngularFirestore,
  		private httpClient: HttpClient,
		private dataService: DataService
	) { }

  	getSalonFacturation(sid: string, status: FacturationStatus = null){

  		let ref = this.firestore.collection(FacturationService.COLLECTION_NAME).ref.where("salon.sid","==", sid);
		
		if (status) {
			ref = ref.where("status" , "==", status);
		}

        ref = ref.orderBy("facturationDateTimestamp", "desc");

		return ref.get();

  	}

  	getSalonFacturationEncours(sid: string){

        let ref = this.firestore.collection(FacturationService.COLLECTION_NAME).ref.where("salon.sid","==", sid);

            ref = ref.where("status", 'in', [FacturationStatus.IN_PROGRESS, FacturationStatus.LATE, FacturationStatus.PAID, FacturationStatus.PARTIALLY_PAID]);

        ref = ref.orderBy("facturationDateTimestamp", "desc");

        return ref.get();

    }

      observeFacturation(fsid: string) {
		return this.firestore.collection(FacturationService.COLLECTION_NAME).doc(fsid).snapshotChanges();
	}

	getFacturePDF(htmlData: any, factureNumber: any){
        
        const queryOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Content-Type': 'application/json'
            }),
            responseType: 'blob' as 'blob'
        };

        const postData = {
            htmlData: htmlData,
            num: factureNumber
        };

        return this.httpClient.post(dataConst.API_ENDPOINT + 'invoicePdf', JSON.stringify({...postData}), queryOptions);

    }

    getConfig(){
        return this.firestore.collection(FacturationService.COLLECTION_NAME_CONFIG).doc(ConfigDocument.config).valueChanges();
    }
	
}
