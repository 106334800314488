import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PrestationDetail } from "src/app/models/prestation.model";
import { SalonDetail } from "src/app/models/salon.model";
import { GalleryPage } from 'src/app/pages/gallery/gallery.page';
import { SalonService } from "src/app/services/salon.service";
import { Image } from 'src/app/models/image.model';
import { RedirectionService } from 'src/app/services/redirection.service';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core"
import { NewsPrestationComponent } from '../news-prestation/news-prestation.component';
import { PrestationService } from 'src/app/services/prestation.service';
@Component({
  selector: 'app-gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss'],
})
export class GalleryCardComponent implements OnInit {

  @Input() prestation: PrestationDetail;
  @Input() list: boolean = false;
  @Input() prestationLink: string = '';
  salon: SalonDetail;
  loading = true;
  modalLoading = false;
  constructor(
              private salonService: SalonService,
              private prestationService: PrestationService,
              private modalController: ModalController,
              private redirectionService: RedirectionService,
              private router: Router,
              public translate: TranslateService,

  ) { }

  ngOnInit() {
    this.prestation = new PrestationDetail().deserialize(this.prestation);
    if (this.prestationService.salonsDefault){
      this.salon = this.prestationService.salonsDefault.find((s) => s.sid === this.prestation.sid);
    }
    if (!this.prestation.salonName && this.prestation.sid) {
      this.salonService.getSalon(this.prestation.sid).then(s => {
        this.salon = new SalonDetail().deserialize(s.data());
        this.loading = false;
        this.prestation.salonName = this.salon.name;
      });
    }

    // this.prestation=new PrestationDetail().deserialize(this.prestation);
    // this.salonService.getSalon(this.prestation.sid).then(s=>{
    // 	this.salon=new SalonDetail().deserialize(s.data());
    // 	this.loading=false;
    // });
  }

  get currentPrice(): number {
    let price = this.prestation.price;

    const now = new Date();

    if (this.prestation.promotion) {

      const promotionDay = this.prestation.promotionDays[(now.getDay() == 0) ? 6 : now.getDay() - 1];

      if (promotionDay.enabled) {
        const hourTab = promotionDay.hours.filter(h => h.value == now.getHours());

        if (hourTab.length == 1) {

          if (hourTab[0].promotion) {

            price -= (price * hourTab[0].promotion.value) / 100;

          }

        }

      }

    }

    return price;
  }

  get hasPromo(): boolean {

    if (this.prestation.price != this.currentPrice)
      return true;

    return false;
  }

  async openActuPrestation(image: Image) {

      if (this.prestationLink)
          this.router.navigate([this.prestationLink]);
      return;

    const modal = await this.modalController.create({
      component: GalleryPage,
      componentProps: {
        'src': image,
        'prestation': this.prestation,
        'showComment': false,
        'salon': this.salon
      }
    });

    return await modal.present();
  }

  async openImagePopup(image: Image) {
    let data: any = {};
    data.image = image;
    data.prestation = this.prestation;
    data.salon = this.salon;
    // this.imageClick.emit(data);
    image.salon = this.salon;
    image.pid = this.prestation.pid;
    image.prestationName = this.prestationLabel;
    // this.modalController.dismiss();
    this.modalLoading = true;
    const modal = await this.modalController.create({
      component: NewsPrestationComponent,
      componentProps: {
        actu: image,
        modal: true,
        type: 'result',
        prestation: this.prestation
      },
      cssClass: 'galleryModal'
    });
    return await modal.present().then(() => {
      this.modalLoading = false;
    }
    );
  }
  
  goToSalon() {
    this.redirectionService.redirectTo(`/profil-salon/${this.prestation.sid}`, true);
  }


  navigate(link){
      this.redirectionService.redirectTo(link, true);
  }

  onImgError(event, entity, element='prestation'){

    if(element == 'profil'){

      if(entity._profilImageUrl){
        event.target.src = entity._profilImageUrl;
      }else{
        event.target.src = 'assets/img/profil_placeholder.png';
      }

    }else{

      if(entity._url){
        event.target.src = entity._url;
      }else{
        event.target.src = 'assets/images/barbershop.jpg';
      }

    }
    
  }

  get prestationLabel() {

      if(this.prestation.intLabel && this.prestation.intLabel[this.translate.currentLang] && this.prestation.intLabel[this.translate.currentLang] !== ""){
          return this.prestation.intLabel[this.translate.currentLang]
      }

      return this.prestation.name;
          
  }  
  
}
