import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot , Router } from '@angular/router';
import { ModalController , ToastController , LoadingController, AlertController } from '@ionic/angular'
import { LoginPage } from 'src/app/pages/login/login.page'
import { MailRequestModalComponent } from 'src/app/shared/components/mail-request-modal/mail-request-modal.component'
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	constructor(
		public toastController: ToastController,
		private authService: AuthService, 
		private router: Router, 
		private modalController: ModalController,
        private loadingController: LoadingController,
        private alertController: AlertController,
        public translate: TranslateService,
        private userService: UserService,
       
		) { }

	 async canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        let check = await setTimeout( async () =>{
            } , 300)

        if(next.data && next.data.isHome){
            
            // if(this.authService.isLoggedIn && this.authService.userInOrder) {
            if(this.authService.isLoggedIn) {
              this.router.navigate(['/home/salons']);
                return false;
            } 
            /*else if (this.authService.isLoggedIn && !this.authService.userInOrder) {
                this.router.navigate([`registration/edit/${this.authService.userLoggedIn.uid}/2`]);

                this.presentToast(this.translate.instant("Veuillez remplir toutes les informations obligatoires sur votre profil afin de pouvoir naviguer dans l'application"));
                return false;
            }*/else{
                return true;
            }
            
        }

        if (this.authService.isLoggedIn) {

            return true;

            /*if (this.authService.userLoggedIn && this.authService.userInOrder) {
                return true;
            }else{
                this.router.navigate([`registration/edit/${this.authService.userLoggedIn.uid}/2`]);
                this.presentToast(this.translate.instant("Veuillez remplir toutes les informations obligatoires sur votre profil afin de pouvoir naviguer dans l'application"));
                    
            }*/
            
        }else{
            await this.modalController.create({
                component: LoginPage,
                componentProps: {
                    isModal: true
                }
            }).then(modal => {
                modal.present()
            });
            // this.router.navigate(['login']);
            return false;
        }
            
	}
	
	presentToast(msg: string) {
        this.toastController.create({
            message: msg,
            duration: 3000
        }).then(toast => toast.present());
    }

 

}
