import { Injectable } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { ReservationService } from './reservation.service';
import { Subscription } from 'rxjs';
import { ReservationDetail, ReservationStatus } from '../models/reservation.model';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LocalNotificationReservationService {

  clientReservationSubscription: Subscription;
  salonReservationSubscription: Subscription;
  messageNotRead = 0;
    upcomingAgenda = 0;
    salonMessage = 0;
  messageFromAdminNotRead = 0;

    constructor(public translate: TranslateService, private localNotifications: LocalNotifications, private reservationService: ReservationService) { }

  async listenUpcomingReservation(uid: string, sid: string) {
    
    await this.localNotifications.cancelAll();

    if (uid) {
      
      this.clientReservationSubscription = this.reservationService.observeReservationByUserAndStatus(uid, ReservationStatus.UP_COMING).subscribe(snapshot => {

        snapshot.forEach(document => {

          const reservation = new ReservationDetail().deserialize(document.payload.doc.data());

          const oneHourBeforeExecution = moment(new Date(reservation.executionDateTimestamp)).subtract({ hours: 1 }).toDate();

          this.localNotifications.schedule({
              text: this.translate.instant("local_notification_info_salon", { name: (reservation.prestation.intLabel && reservation.prestation.intLabel[this.translate.currentLang] && reservation.prestation.intLabel[this.translate.currentLang]) ? reservation.prestation.intLabel[this.translate.currentLang] : reservation.prestation.name, salon: reservation.salon.name }),
            trigger: {at: oneHourBeforeExecution},
            led: 'FF0000',
            vibrate: true
          });

        });

      });
    
    }

    if (sid) {
      
      this.salonReservationSubscription = this.reservationService.observeReservationBySalonAndStatus(sid, ReservationStatus.UP_COMING).subscribe(snapshot => {

        snapshot.forEach(document => {

          const reservation = new ReservationDetail().deserialize(document.payload.doc.data());

          const oneHourBeforeExecution = moment(new Date(reservation.executionDateTimestamp)).subtract({ hours: 1 }).toDate();

          this.localNotifications.schedule({
              text: this.translate.instant("local_notification_info_client", { name: (reservation.prestation.intLabel && reservation.prestation.intLabel[this.translate.currentLang] && reservation.prestation.intLabel[this.translate.currentLang]) ? reservation.prestation.intLabel[this.translate.currentLang] : reservation.prestation.name, client: reservation.client.label }),
            trigger: {at: oneHourBeforeExecution},
            led: 'FF0000',
            vibrate: true
          });

        });

      });
    
    }

  }

  unsubscribe() {
    
    if (this.clientReservationSubscription)
      this.clientReservationSubscription.unsubscribe();

    if (this.salonReservationSubscription)
      this.salonReservationSubscription.unsubscribe();

    this.localNotifications.cancelAll();

  }


}
