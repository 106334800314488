import { Component, OnInit, Input, SimpleChange, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { CitiesService } from 'src/app/services/cities.service';
import { FilterService } from 'src/app/services/filter.service';
import { City } from 'src/app/models/city.model';
import { Observable } from 'rxjs';
import { Plugins, GeolocationPosition  } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
// const { Geolocation } = Plugins;
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { TranslateService } from '@ngx-translate/core';
// import  *  as  cities  from  '../../cities.json';

@Component({
  selector: 'app-cities-sidebar',
  templateUrl: './cities-sidebar.component.html',
  styleUrls: ['./cities-sidebar.component.scss'],
  providers: [LocationAccuracy, Geolocation]
})
export class CitiesSidebarComponent implements OnInit {
  @Input() open:boolean;
  @Output() isOpen:EventEmitter<boolean> = new EventEmitter();

  allCities:any[];
  cities: Observable<City[]>;
  myPosition: any;

  showResult = false;
  showDefaultResult = true;
  cityName: string;

  constructor(public translate: TranslateService, private menu: MenuController,
    public citiesService:CitiesService,
    private filterService:FilterService,
    private toastController: ToastController,
    private locationAccuracy: LocationAccuracy,
    public platform: Platform,
    private geolocation: Geolocation
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.citiesService.getResults();
    this.citiesService.initDefaultCities();
  }

  filterCities(e){
    this.cities = this.citiesService.getResults(e.srcElement.value);
  }

  selectCity(cityObject:any){
    this.closeSidebar();
    let city:City = new City().deserialize(cityObject);
    this.citiesService.setStocked("city", city).then(() =>{
      this.filterService.cityChange.next(true);
    });
  }

  openSidebar() {
    this.menu.enable(true, "placeMenu");
    this.menu.open('placeMenu');
  }

  closeSidebar(){
    this.menu.close("placeMenu");
    this.isOpen.emit(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.open;
    if (currentItem.currentValue) {
      this.openSidebar();
    }
  }

  getCurrentPosition(){
    this.closeSidebar();
    if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
      if(this.platform.is('android')){
        // this.locationAccuracy.canRequest().then((canRequest:boolean) => {
        //   if (canRequest) {
        //     this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
        //       () => {

                this.geolocation.getCurrentPosition().then(position => {
                  let lat = position.coords.latitude;
                  let long = position.coords.longitude;
                  this.citiesService.myPosition = {
                    lat: lat,
                    lng: long
                  };
                  // this.citiesService.cityName = "Autour";
                  this.selectCity({
                    country : "",
                    lat: lat,
                    lng: long,
                    name: "Autour"
                  })
                  // this.filterService.filterBy["distance"] = this.citiesService.myPosition;
                  // if(this.filterService.filterBy["city"])
                    // this.filterService.removeFilter("city");
                  this.filterService.tagsAdded.next(true);

                }).catch(e => {
                  this.presentToast(this.translate.instant("Inmpossible de récupérer votre position actuelle."));
                });



              // },
            //   error => {
            //     this.presentToast(this.translate.instant("Inmpossible de récupérer votre position actuelle."));

            //   }
            // );
          // }

        // });
      }else if(this.platform.is('ios')){


                this.geolocation.getCurrentPosition().then(position => {
                  let lat = position.coords.latitude;
                  // let lat = 48.856614;
                  let long = position.coords.longitude;
                  // let long = 2.352222;
                  this.citiesService.myPosition = {
                    lat: lat,
                    lng: long
                  };
                  // this.citiesService.cityName = "Autour";
                  this.selectCity({
                    country : "",
                    lat: lat,
                    lng: long,
                    name: "Autour"
                  })
                  // this.filterService.filterBy["distance"] = this.citiesService.myPosition;
                  // if(this.filterService.filterBy["city"])
                    // this.filterService.removeFilter("city");
                  this.filterService.tagsAdded.next(true);

                }).catch(e => {
                  this.presentToast(this.translate.instant("Inmpossible de récupérer votre position actuelle."));
                });



      }
    }else{
      this.filterService.searchArround();
    }
  }

  presentToast(msg:string) {
    this.toastController.create({
        message: msg,
        duration: 3000
    }).then(toast => toast.present());
  }

  cityChange(keyword: string){
    if (keyword) {
      this.showResult = true;
      this.showDefaultResult = false;
      this.cities  = this.citiesService.getResults(keyword);
    } else {
      this.showResult = false;
      this.showDefaultResult = true;
    }
  }

  searchbarFocus() {
    this.showDefaultResult = true;
  }

  clickOutside() {
    setTimeout(() => {
      this.showResult = false;
      this.showDefaultResult = true;
    }, 1000);
  }

}
