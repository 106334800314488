import { Deserializable } from "./deserializable.model";
import { ReservationDetail } from "./reservation.model";
import { Address } from './address.model';
import { Abonnement, PromotionAbonnement } from './abonnement.model';

export class FacturationSalon implements Deserializable {

	fsid?: string;
	facturationDate: string;
	facturationDateTimestamp: number;
	facturationNumber: string;
	salon: { sid: string, name: string, email: string, phoneNumber: string, adresses: Address[], abonnement?: Abonnement, abonnementPromotion: PromotionAbonnement, referralCommercialId?: string };
	salonOwner: {uid: string, name: string, firstname: string, email: string, phoneNumber: string, gender: string, address: Address[] };
	reservations: ReservationDetail[];
	priceResume: {
		totalCommission: number, 
		subtotal: number, 
		discount: number, 
		totalWithDiscount: number, 
		tax: {rate: number, amount: number}, 
		total: number, 
		unit: string,
		partiallyPayment?: {amount: number, date: number}[] 
	};
	status: FacturationStatus;
	paymentDate?: Date;
	createdAt: Date;
	updatedAt: Date;

	deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
	
	get statusLabel(): string{

    	let label = this.status.toString();

    	switch(this.status){
    		case FacturationStatus.IN_PROGRESS:
    			label = 'En suspend';
    			break;

			case FacturationStatus.LATE:
				label = 'En retard';
				break;

			case FacturationStatus.PAID:
				label = 'Payé';
				break;

			case FacturationStatus.PARTIALLY_PAID:
				label = 'Partiel';
				break;

			case FacturationStatus.CANCELED:
				label = 'Annuler';
				break;

			default:
				break;
    	}

    	return label;

    }

}

export enum FacturationStatus {
	IN_PROGRESS = "in_progress",
	LATE = "late",
	PAID = "paid",
	PARTIALLY_PAID = "partially_paid",
    CANCELED = "canceled"
}
