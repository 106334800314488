import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  @Input() name: string;
  @Input() gradient: string;
  @Input() disabled = false;
  @Input() outline = false;
  @Input() size = 'normal';
  @Input() color = 'primary';

  @Input() round = false;
  @Input() uppercase = false;
  
  @Input()
  set value(val) {
    this.name = val;
  }

  get value(): string {
    return this.name;
  }

  constructor() { }

  ngOnInit() {
  }


  buttonStyle() {
    let style: any = {};
    if (this.outline) {
      style['color'] = 'var(--ion-color-primary)';
    }
    if (this.size === 'small') {
      style['font-size'] = '12px';
      style.height = '32px';
    }
    if (this.size === 'full-width') {
      style.width = '100%';
    }

    if (this.size === 'medium') {
      style.width = '100%';
      style['min-width'] = 'unset';
      
    }

    if (this.size === 'uppercase') {
      style['text-transform'] = 'uppercase';
      
    }

    if (!this.round) {
        style['border-radius'] = '11px!important';

    }

    if (this.gradient) {
      style['--background'] = "linear-gradient(272.4deg, #EE5959 3.81%, #FE8989 65.3%)";
    }
    return style;
  }

}
