import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Abonnement, OptionAbonnement, PromotionAbonnement, TarificationType, DurationType, periodLabel, durationLabel } from 'src/app/models/abonnement.model';
import { AbonnementService } from 'src/app/services/abonnement.service';
import { TranslateService } from '@ngx-translate/core';
import { StringHelperService } from 'src/app/shared/helpers/string-helper.service';

@Component({
  selector: 'app-abonnement-item',
  templateUrl: './abonnement-item.component.html',
  styleUrls: ['./abonnement-item.component.scss'],
})
export class AbonnementItemComponent implements OnInit, OnChanges {

	_abonnement: Abonnement;
	@Input()
	set abonnement(abonnement: Abonnement){
		this._abonnement = abonnement;
	} 

	get abonnement(): Abonnement{
		return this._abonnement;
	}

	_selected: boolean;
	@Input()
	set selected(selected: boolean){
		this._selected = selected;
	} 

	get selected(): boolean{
		return this._selected;
	}

	promotion: PromotionAbonnement;

	labelOriginalPrice = "";
	labelPromoPrice="";
	labelPromoDuration="";
	stickerPromoDuration="";

	options:{ option: OptionAbonnement, active: boolean, intLabel: string }[];

	@Output() whenSelected: EventEmitter<any> = new EventEmitter();
	
  	constructor(
  		private abonnementService: AbonnementService,
  		public translate: TranslateService,
  		public stringHelperService: StringHelperService
	) {
  		
  	}

  	ngOnInit() {
  		this.init();
  	}

  	init(){
  			
		this.labelOriginalPrice = this.abonnement.price && this.abonnement.price > 0 ? this.abonnement.price+' '+this.abonnement.priceUnit : this.translate.instant('Aucun frais mensuel');

		if(this.abonnement && this.abonnement.promotion && this.abonnement.promotion.promoId){
			this.getPromotion();
		}

		this.getOptions();

		this.ngOnChanges();

  	}

  	ngOnChanges(){

  		if(this.selected){

  			let labelOriginalPrice = this.labelOriginalPrice;
  			let labelPromoPrice = this.labelPromoPrice;
  			let labelPromoDuration = this.labelPromoDuration;

  			if(this.promotion){
  				labelPromoPrice = labelPromoPrice+" / "+this.getPeriodLabel(this.promotion.tarificationType);
  			}

			this.whenSelected.emit({ labelOriginalPrice: labelOriginalPrice, labelPromoPrice: labelPromoPrice, labelPromoDuration: labelPromoDuration });

		}

  	}

  	getPromotion(){
  		if(this.abonnement.promotion && this.abonnement.promotion.promoId && this.abonnement.promotion.active){
  			
  			this.abonnementService.getPromotionById(this.abonnement.promotion.promoId).subscribe(promo => {
  				this.promotion = new PromotionAbonnement().deserialize(promo);

  				this.labelPromoPrice = this.promotion.price+' '+this.abonnement.priceUnit;

	        if(this.promotion.duration > 0){
	           this.labelPromoDuration =  this.promotion.duration + ' ' + this.stringHelperService.durationLabel(this.promotion.durationType)+' '+this.translate.instant('offert(s)');
	           this.stickerPromoDuration = this.promotion.duration + ' ' + this.stringHelperService.durationLabel(this.promotion.durationType);
	        }else{
	        	 this.labelPromoDuration = "";
	        	 this.stickerPromoDuration = "";
	        }

  				this.ngOnChanges();

  			});

  		}else{
  			this.labelPromoPrice="";
				this.labelPromoDuration="";

				this.ngOnChanges();
  		}
  		
  	}

  	getPeriodLabel(period: TarificationType){
  		return this.stringHelperService.periodLabel(period);
  	}

  	getOptions(){

  		this.options = [];

  		if(this.abonnement && this.abonnement.option){

  			let optIds = [];

  			this.abonnement.option.forEach(opt => {
  				optIds.push(opt.optionId);
  			});

  			if(optIds.length > 0){

  				this.abonnementService.getOptionsByIds(optIds).then( option => {

	  				let allOptions = [];

	  				option.docs.forEach(opt => {
	  					let targetOpt = new OptionAbonnement().deserialize(opt.data());
	  					allOptions.push(targetOpt);
	  				});
	  				
						this.abonnement.option.forEach(opt => {

							allOptions.forEach(targetOpt => {

								if(targetOpt.active){

									if(opt.optionId === targetOpt.opid){

										let intLabel = targetOpt[this.translate.currentLang] ? targetOpt[this.translate.currentLang] : targetOpt['fr'] ? targetOpt['fr'] : targetOpt.label;

										this.options.push( { option: targetOpt, active: opt.active, intLabel: intLabel } );
									}

								}

							});
			  				
		  			});
	  						
	  			});

  			}

  		}
  		
  	}

}
