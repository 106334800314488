import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { PrestationDetail } from 'src/app/models/prestation.model';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-avis-item',
  templateUrl: './avis-item.component.html',
  styleUrls: ['./avis-item.component.scss'],
})
export class AvisItemComponent implements OnInit {

    @Input() note: { value: number; comment: string; uid: string; date: any; prestation?: any };
    user: User;

    constructor(public translate: TranslateService, private userService: UserService) { }

	ngOnInit() {
		this.userService.getUser(this.note.uid).then(documentData => {
			this.user = documentData.data() as User;

			if (!this.user) {
				this.user = new User();
				this.user.firstname = this.translate.instant("Utilisateur supprimé");
			}
		});
	}

    get prestationLabel() {

        if (this.note.prestation.intLabel && this.note.prestation.intLabel[this.translate.currentLang]) {
            return this.note.prestation.intLabel[this.translate.currentLang]
        }

        return this.note.prestation.name;

    }

}
