import { Component, OnInit, Input, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { CalendarComponentOptions } from 'ion2-calendar';
import { FilterService } from 'src/app/services/filter.service';
import { CitiesService } from 'src/app/services/cities.service';

@Component({
  selector: 'app-filter-sidebar',
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss'],
})
export class FilterSidebarComponent implements OnInit {

  @Input() open: boolean;
  @Output() isOpen: EventEmitter<boolean> = new EventEmitter();

  gender: string;
  movingPrestation: string;
  categories: string[];
  categoriesTemp: string[];
  promotion: string;
  professional: string;

  dateRange: { from; to; };
  priceRange: any = { lower: 0, upper: 200 };
  type: 'string'; // 'string' | 'js-date' | 'moment' | 'time' | 'object'
  optionsRange: CalendarComponentOptions = {
    pickMode: 'range'
  };


  constructor(
    private menu: MenuController,
    public filterService: FilterService,
    public citiesService: CitiesService
  ) { }

  ngOnInit() { }

  openSidebar() {
    this.menu.enable(true, "opening")
    this.menu.open('filterMenu');
  }

  closeSidebar() {
    this.filterService.mapLoading = true;
    this.menu.close("filterMenu");
    this.isOpen.emit(false);
  }
  refreshFilter() {
    this.priceRange = { lower: 0, upper: 200 };
    this.filterService.categories.forEach((category, i) => {
      category.selected = false;
    });
    this.filterService.categories[0].selected = true;
    this.removeFilters();
    this.initialisationParametres();
    /*    this.closeSidebar();*/
    this.filterService.tagsAdded.next(true);
  }

  removeFilters() {
    if (this.filterService.filterBy["gender"])
      this.filterService.removeFilter("gender");
    if (this.filterService.filterBy["country"])
      this.filterService.removeFilter("country");
    if (this.filterService.filterBy["movingPrestation"])
      this.filterService.removeFilter("movingPrestation");
    if (this.filterService.filterBy["priceRange"])
      this.filterService.removeFilter("priceRange");
    if (this.filterService.filterBy["dateRange"])
      this.filterService.removeFilter("dateRange");
    if (this.filterService.filterBy["promotion"])
      this.filterService.removeFilter("promotion");
    if (this.filterService.filterBy["category"])
      this.filterService.removeFilter("category");
    if (this.filterService.filterBy["isEntreprise"])
      this.filterService.removeFilter("isEntreprise");
  }
  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.open;
    if (currentItem.currentValue) {
      this.openSidebar();
    }
  }


  // TAGS NO MBOLA TSY VITA

  // AVAILABILITY 
  onDateChange(e) {
    this.filterService.filterBy["availability"] = this.dateRange;
    this.closeSidebar();
    this.filterService.tagsAdded.next(true);
  }

  // CATEGORY 
  onCategoriesChange() {
    this.categoriesTemp = [];
    this.filterService.categories.forEach((category, i) => {
      category.selected = false;
      this.categories.forEach(c => {
        if (category.value.toLowerCase() == c.toLowerCase()) {
          category.selected = true;
        }
      })
      this.filterService.categories[i].selected = category.selected;
    })

    this.categories.forEach((c, index) => {
      if (c != "all") {
        this.categoriesTemp.push(c);
      }
    })
    if (this.categoriesTemp[0]) {
      this.filterService.filterBy["category"] = this.categoriesTemp;
    }
    else {
      if (this.filterService.filterBy["category"])
        this.filterService.removeFilter("category");
    }
    this.closeSidebar();
    this.filterService.tagsAdded.next(true);
  }

  // GENDER
  onGenderChange(e) {
    if (this.gender == "men" || this.gender == "women" || this.gender == "child") {
      this.filterService.filterBy["gender"] = this.gender;
    }
    else {
      this.filterService.removeFilter("gender");
    }
    this.closeSidebar();
    this.filterService.tagsAdded.next(true);
  }


  // MOVING
  onMovingChange(e) {
    this.filterService.filterBy["movingPrestations"] = this.movingPrestation;
    this.closeSidebar();
    this.filterService.tagsAdded.next(true);
  }

  // PRICE
  onPriceChange(e) {
    this.filterService.filterBy["price"] = this.priceRange;
    this.closeSidebar();
    this.filterService.tagsAdded.next(true);
  }


  // PROMOTION 
  onPromotionChange() {
    if (this.promotion == "promo") {
      this.filterService.filterBy["promotion"] = true;
    } else if (this.promotion == "standard") {
      this.filterService.filterBy["promotion"] = false;
    } else {
      this.filterService.removeFilter("promotion");
    }
    this.closeSidebar();
    this.filterService.tagsAdded.next(true);
  }

  onProfessionnalChange() {
    if (this.professional == "pro") {
      this.filterService.filterBy["isEntreprise"] = true;
    } else if (this.professional == "prive") {
      this.filterService.filterBy["isEntreprise"] = false;
    } else {
      this.filterService.removeFilter("isEntreprise");
    }
    this.closeSidebar();
    this.filterService.tagsAdded.next(true);
  }

  menuOpen() {
    this.initialisationParametres();
  }

  initialisationParametres() {
    this.filterService.mapLoading = false;

    // categories
    this.categories = this.filterService.categories.filter((c) => c.selected).map((c) => c.value);

    // Promotion
    if (this.filterService.filterBy["promotion"] == true) {
      this.promotion = "promo";
    } else if (this.filterService.filterBy["promotion"] == false) {
      this.promotion = "standard";
    }
    else {
      this.promotion = "both";
    }

    // Professionnel
    if (this.filterService.filterBy["isEntreprise"] == true) {
      this.professional = "pro";
    } else if (this.filterService.filterBy["isEntreprise"] == false) {
      this.professional = "prive";
    }
    else {
      this.professional = "both";
    }

    // gender
    if (this.filterService.filterBy["gender"]) {
      this.gender = this.filterService.filterBy["gender"];
    } else {
      this.gender = "all";
    }

    // Mobilite
    if (this.filterService.filterBy["movingPrestations"]) {
      this.movingPrestation = this.filterService.filterBy["movingPrestations"];
    } else {
      this.movingPrestation = "both";
    }

  }

}
