import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { FbAlbum, FbImage } from 'src/app/models/facebook.model';
import { FacebookGraphService } from 'src/app/services/facebook-graph.service';

@Component({
  selector: 'app-facebook-images-import',
  templateUrl: './facebook-images-import.component.html',
  styleUrls: ['./facebook-images-import.component.scss'],
})
export class FacebookImagesImportComponent implements OnInit {

  albums: FbAlbum[] = [];
  step = 0;
  selectedAlbum: FbAlbum;
  images: FbImage[] = [];
  selectedImages: FbImage[] = [];
  loading = false;

  constructor(private facebookService: FacebookGraphService, private toastCtrl: ToastController, private modalCtrl: ModalController) { }

  ngOnInit() {

    this.getAlbums();

  }

  getAlbums() {

    this.loading = true;

    this.facebookService.getAlbums().subscribe(response => {
      
      if (response.status === 200) {
        this.albums = response.body.data.map(alb => {
          const album = new FbAlbum().deserialize(alb);
          album.cover = 'assets/images/barbershop.jpg';
          return album;
        });  

        this.loadAlbumsCover();

      } else {
        this.toastCtrl.create({
          message: "Erreur lors de la récupération de vos albums facebook",
          position: "middle",
          duration: 3000
        }).then(dialog => dialog.present());
      }

      this.loading = false;

    }, () => {
      this.toastCtrl.create({
        message: "Erreur lors de la récupération de vos albums facebook",
        position: "middle",
        duration: 3000
      }).then(dialog => dialog.present());

      this.loading = false;
    });

  }

  loadAlbumsCover() {

    this.albums.forEach(album => {

      this.facebookService.getAlbumCover(album.id).subscribe(response => {

        if (response.status === 200) {
          album.cover = response.body.picture.data.url;
        } 

      });

    });

  }

  get stepTitle() {
    
    switch(this.step) {
      case 1:
        return this.selectedAlbum.name;
      default:
        return "Album:";
    }

  }

  exploreAlbum(album: FbAlbum) {
    this.selectedAlbum = album;
    this.step = 1;

    this.loading = true;

    this.facebookService.getAlbumImages(album.id).subscribe(response => {

      if (response.status === 200) {
        this.images = [];
        
        response.body.data.forEach(photo => {

          this.facebookService.getImageLinks(photo.id).subscribe(imgResponse => {

            if (imgResponse.status === 200) {
              this.images.push(imgResponse.body.images[Math.floor(imgResponse.body.images.length / 2)]);
            }
            
            this.loading = false;

          });

        });

        if (response.body.data.length === 0) {
          this.loading = false;
        }

      }else {
        this.toastCtrl.create({
          message: "Erreur lors de la récupération des photos de votre album facebook",
          position: "middle",
          duration: 3000
        }).then(dialog => dialog.present());

        this.loading = false;
      }

    }, () => {
      this.toastCtrl.create({
        message: "Erreur lors de la récupération des photos de votre album facebook",
        position: "middle",
        duration: 3000
      }).then(dialog => dialog.present());

      this.loading = false;
    });

  }

  selectImage(image: FbImage) {

    if (!this.selectedImages.includes(image)) {
      this.selectedImages.push(image);
    } else {
      this.selectedImages = this.selectedImages.filter(img => img.source !== image.source);
    }

  }

  imageIsSelected(image: FbImage) {
    return this.selectedImages.includes(image);
  }

  importImages() {
    this.modalCtrl.dismiss({images: this.selectedImages});
  }

}
