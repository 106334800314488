import { Deserializable } from "./deserializable.model";
import { TranslateService } from '@ngx-translate/core';
import { StringHelperService } from 'src/app/shared/helpers/string-helper.service';

export class Abonnement implements Deserializable {

    aid: string;
    name: string;
    price: number;
    priceUnit: string = 'CHF';
    tarificationType: TarificationType; 
   	commissionRate: number;
   	promotion: { promoId: string | null, active: boolean };
    nbReservation?: number;
    nbPrestation?: number;
    option: { optionId: string, active: boolean }[];
	createdAt: Date;
    updatedAt: Date;
    order: number;
    isCustom?: boolean;
    active?: boolean;

    constructor() {
        this.price = 0;
        this.tarificationType = TarificationType.MONTHLY;
        this.commissionRate = 0;
        this.nbReservation = -1;
        this.nbPrestation = -1;
        this.promotion = { promoId: null, active: false};
        this.isCustom = false;
        this.active = true;
        this.option = [];
    }
    
	deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    get optionFraisAbonnement(): string{

        let label = "";

        if(this.price > 0){
            label = this.price+' '+this.priceUnit+' par '+periodLabel(this.tarificationType);
        }else{
            label = "Pas de frais mensuels";
        }

        return label;
    }

    get optionNombreReservation(): string{

        let label = "";

        if(this.nbReservation > 0){
            label = this.nbReservation+' réservation par '+periodLabel(this.tarificationType);
        }else{
            label = "Nombre de réservation illimitées";
        }

        return label;

    }

    get optionNombrePrestation(): string{

        let label = "";

        if(this.nbPrestation > 0){
            label = this.nbPrestation+' prestation par '+periodLabel(this.tarificationType);
        }else{
            label = "Nombre de prestation illimitées";
        }

        return label;

    }

    getOptionFraisAbonnement(translate: TranslateService, stringHelper: StringHelperService){

        let label = "";

        if(this.price > 0){
            label = this.price+' '+this.priceUnit+' '+translate.instant('par')+' '+ stringHelper.periodLabel(this.tarificationType);
        }else{
            label = translate.instant("Pas de frais mensuels");
        }

        return label;
    }

    getOptionNombreReservation(translate: TranslateService, stringHelper: StringHelperService): string{

        let label = "";

        if(this.nbReservation > 0){
            label = this.nbReservation+' '+translate.instant('réservation par')+' '+stringHelper.periodLabel(this.tarificationType);
        }else{
            label = translate.instant("Nombre de réservation illimitées");
        }

        return label;

    }

    getOptionNombrePrestation(translate: TranslateService, stringHelper: StringHelperService){

        let label = "";

        if(this.nbPrestation > 0){
            label = this.nbPrestation+' '+translate.instant('prestation par')+' '+stringHelper.periodLabel(this.tarificationType);
        }else{
            label = translate.instant("Nombre de prestation illimitées");
        }

        return label;

    }

}

export function periodLabel(period: TarificationType): string{

    let label = "mois";

    switch (period) {
        case TarificationType.HOURLY:
            // code...
            label = "heure";
            break;

        case TarificationType.DAILY:
            // code...
            label = "jour";
            break;

        case TarificationType.WEEKLY:
            // code...
            label = "semaine";
            break;

        case TarificationType.MONTHLY:
            // code...
            label = "mois";
            break;

        case TarificationType.YEARLY:
            // code...
            label = "ans";
            break;
        
        case TarificationType.EVERY_TWO_MONTH:
            // code...
            label = "2 mois";
            break;

        case TarificationType.EVERY_THREE_MONTH:
            // code...
            label = "3 mois";
            break;

        case TarificationType.EVERY_SIX_MONTH:
            // code...
            label = "6 mois";
            break;

        default:
            // code...
            label = "mois";
            break;
    }

    return label;
}

export function durationLabel(period: DurationType){
    let label = "mois";

    switch (period) {
        case DurationType.HOUR:
            // code...
            label = "heure";
            break;

        case DurationType.DAY:
            // code...
            label = "jour(s)";
            break;

        case DurationType.WEEK:
            // code...
            label = "semaine(s)";
            break;

        case DurationType.MONTH:
            // code...
            label = "mois";
            break;

        case DurationType.MONTH:
            // code...
            label = "ans";
            break;

        default:
            // code...
            label = "mois";
            break;
    }

    return label;
}

export class OptionAbonnement implements Deserializable {
    opid: string;
    label: string;
    fr?: string;
    en?: string;
    de?: string;
    it?: string;
    active: boolean = true;
    createdAt: Date;
    updatedAt: Date;

    deserialize(input: any) {
        Object.assign(this, input);
        if (!this.fr) {
            this.fr = this.label;
        }
        return this;
    }

}

export class PromotionAbonnement implements Deserializable{
    promoid: string;
    name: string;
    price: number = 0;
    tarificationType: TarificationType;
    duration: number; 
    durationType: DurationType; 
    commissionRate: number;
    nbReservation?: number;
    nbPrestation?: number;
    createdAt: Date;
    updatedAt: Date;
    salonsToEdit?: string[];
    
    constructor() {
        this.price = 0;
        this.tarificationType = TarificationType.MONTHLY;
        this.duration = 1;
        this.durationType = DurationType.MONTH;
        this.nbReservation = -1;
        this.nbPrestation = -1;
    }
    
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    get labelPromotionDuration(): string{

        if(this.duration > 0){
            return this.duration + ' ' + durationLabel(this.durationType) + ' offert(s)';
        }

        return "";
    }

    get promotionDurationText(): string{

        if(this.duration > 0){
            return this.duration + ' ' + durationLabel(this.durationType);
        }


        return "";
    }
    
    getPromoStartAndEndDateTimestamp(startDate: Date, duration: number, durationType: DurationType){
        const res = {
            startDateTimestamp: startDate.getTime(),
            endDateTimestamp: null
        }

        const endDate = startDate;

        if(duration > 0){

            switch (durationType) {
                case DurationType.HOUR:
                    // code...
                    endDate.setHours( startDate.getHours() + duration );
                    res.endDateTimestamp = endDate.getTime();
                    break;

                case DurationType.DAY:
                    endDate.setDate( startDate.getDate() + duration );
                    res.endDateTimestamp = endDate.getTime();
                    break;

                case DurationType.WEEK:
                    // code...
                    endDate.setDate( startDate.getDate() + (duration*7) );
                    res.endDateTimestamp = endDate.getTime();
                    break;

                case DurationType.MONTH:
                    // code...
                    endDate.setMonth( startDate.getMonth() + duration );
                    res.endDateTimestamp = endDate.getTime();
                    break;

                case DurationType.YEAR:
                    // code...
                    endDate.setFullYear( startDate.getFullYear() + duration );
                    res.endDateTimestamp = endDate.getTime();
                    break;

                default:
                    // code...
                    break;
            }

        }

        return res;
    }


}   
    
export enum TarificationType{
    HOURLY = "hourly",
	DAILY = "daily",
    WEEKLY = "weekly",
    MONTHLY = "monthly",
    YEARLY = "yearly",
    EVERY_HOUR = "every_day",
    EVERY_DAY = "every_day",
    EVERY_WEEK = "every_week",
    EVERY_MONTH = "every_month",
    EVERY_TWO_MONTH = "every_two_month",
    EVERY_THREE_MONTH = "every_three_month",
    EVERY_SIX_MONTH = "every_six_month"
}

export enum DurationType{
    HOUR = "hour",
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    YEAR = "year"
}
