import { Component, OnInit , ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController , PopoverController} from '@ionic/angular';
import { DataService , dataKey} from 'src/app/shared/helpers/data.service';

@Component({
  selector: 'app-language-choice',
  templateUrl: './language-choice.component.html',
  styleUrls: ['./language-choice.component.scss'],
})
export class LanguageChoiceComponent implements OnInit {

    languages: any[] = [];
    constructor(
        public translate: TranslateService, 
        public authService: AuthService, 
        private loadingController: LoadingController, 
        private userService: UserService, 
        public popoverController: PopoverController,
        public dataService: DataService,
        public cdrf: ChangeDetectorRef

        ) { }

    ngOnInit() {
        this.languages = this.dataService.getLanguageChoice();
    }

    async switch(type){
        const loading = await this.loadingController.create({
            message: this.translate.instant('Mise à jour de vos informations'),
            cssClass: 'askeed-spinner'
        });

        await loading.present();
        this.dataService.saveAppData(dataKey.TEMP_LANG , type)
        this.translate.setDefaultLang(type);
        this.translate.use(type);
        
        this.cdrf.detectChanges();

        loading.dismiss();
        this.popoverController.dismiss();
            
    }
}
