import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingController, ToastController, IonSlides, ModalController, PopoverController } from '@ionic/angular';

import { SalonDetail } from '../../models/salon.model';
import { User } from '../../models/user.model';
import { Prestation, PrestationDetail } from '../../models/prestation.model';
import { Image } from '../../models/image.model';

import { SalonService } from '../../services/salon.service';
import { PrestationService } from '../../services/prestation.service';

import { DataService, dataKey, dataConst } from '../../shared/helpers/data.service';

import { IonContent } from '@ionic/angular';

import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

import { ImgViewerComponent } from '../../shared/components/img-viewer/img-viewer.component';
import { observable } from 'rxjs';
import { ShareLinkComponent } from 'src/app/shared/components/share-link/share-link.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.page.html',
  styleUrls: ['./gallery.page.scss']
})
export class GalleryPage implements OnInit {

	@Input() src: Image;
	@Input() family: Image[] = [];
	@Input() salon: SalonDetail;


	prestation: PrestationDetail;
	gallerieIndex: number;
	activeImage: Image;
	user: User;
	commentsToShowCount = dataConst.DEFAULT_COMMENT_COUNT;
	imagesToShowCount = dataConst.DEFAULT_GALLERIE_COUNT;
	dataConst = dataConst;

	sliderConfig = {
		spaceBetween: 0,
		slidesPerView:1,
		onInit: (slides: IonSlides) => { this.swiper = slides; }
	};

	swiper: IonSlides;

	imageFamily: Image[] = [];

	slideActiveIndex = 0;

	@Input() showComment = true;

	@ViewChild('slides', {static: false}) slides: IonSlides;

	@ViewChild(IonContent, { static: false }) content: IonContent;

	constructor(
		public translate: TranslateService, private salonService: SalonService, 
		private router: Router, 
		private route: ActivatedRoute, 
		private loadingController: LoadingController, 
		private toastController: ToastController, 
		private dataService: DataService, 
		public authService: AuthService, 
		private prestationService: PrestationService, 
		private userService: UserService, 
		private modalController: ModalController,
		public popoverController: PopoverController
		) {

	}

	ngOnInit() {
		if(this.authService.userLoggedIn){
			if(this.src && !this.src.comments){
				this.src.comments = []
			}

			this.user = User.userFactory(this.authService.userLoggedIn);
		}
	}

	async ionViewWillEnter() {
	    // if (this.route.snapshot.paramMap.get('index')) {

		// 	this.salon = SalonDetail.salonDetailFactory(this.dataService.getAppData(dataKey.DETAIL_SALON_CLIENT));

		// 	this.viewImage(parseInt(this.route.snapshot.paramMap.get('index')));

		// }
		this.checkPrestation();
	}

	checkPrestation(){
		if(this.src){
			this.prestationService.getById(this.src.pid).then(documentData => {
				this.prestation = new PrestationDetail().deserialize(documentData.data());
			});
		}
	}

	presentToast(msg:string) {
		this.toastController.create({
		    message: msg,
		    duration: 3000
		  }).then(toast => toast.present());
	}

	onCommentPublished(eventData) {
		this.src = eventData;
		const imgInFamily = this.family.find(img => img.pid === this.src.pid && img.url === this.src.url);

		if (imgInFamily) {
			imgInFamily.comments = this.src.comments;
		}
	}

	viewImage(image: Image) {

		this.src = Image.imagefactory(image);

		this.prestation = null;

		this.checkPrestation();

		this.content.scrollToTop(500);
	}

	async likeIt() {
		if(!this.user.uid){
			return this.router.navigate(['/login']);
		}
		let actionLike = true;

		if (this.src.likes && this.src.likes.includes(this.user.uid)) {
			this.src.likes = this.src.likes.filter(uid => uid !== this.user.uid);
			actionLike = false;
		} else {

			if (!this.src.likes) {
				this.src.likes = [];
			}

			this.src.likes.push(this.user.uid);
		}

		const imgInPrestationGallerie = this.prestation.galleries.find(img => img.url === this.src.url);

		imgInPrestationGallerie.likes = this.src.likes;

		this.prestationService.update(this.prestation);

	}

	showAllComments() {

		if (this.src.comments && this.commentsToShowCount < this.src.comments.length) {
			this.commentsToShowCount += dataConst.DEFAULT_COMMENT_COUNT;
		} else {
			this.commentsToShowCount = dataConst.DEFAULT_COMMENT_COUNT;
		}

	}

	showAllGalleries() {

		if (this.imagesToShowCount < this.familyWithoutSrc.length) {
			this.imagesToShowCount += dataConst.DEFAULT_GALLERIE_COUNT;
		} else {
			this.imagesToShowCount = dataConst.DEFAULT_GALLERIE_COUNT;
		}

	}

	async previewImage(img: Image) {
		// this.photoViewer.show(img.url, this.prestation ? `${this.prestation.name} (${this.salon.name})` : '');

		const modal = await this.modalController.create({
		  component: ImgViewerComponent,
		  componentProps: {
			src: img,
			family: this.imageFamily,
			globalTitle: this.salon.name
		  }
		});

		return await modal.present();

	}

	async shareLink(ev: any) {
		let url = window.origin + '/prestation/' + this.prestation.pid;
		const popover = await this.popoverController.create({
		  component: ShareLinkComponent,
		  cssClass: 'share-link-popover',
		  event: ev,
		  translucent: true,
		  componentProps: {
			title: this.translate.instant('Partager la prestation'),
			url,
			postTitle: this.prestationLabel + ' '+this.translate.instant('par')+' ' +this.salon.name,
			postImageLink: this.src.url,
			postDescritpion: this.prestation.description
		  }
		});
		return await popover.present();
	  }

	dismiss() {
		this.modalController.dismiss();
	}

	get familyWithoutSrc(): Image[] {
		return this.family.filter(img => img.url !== this.src.url);
	}

    get prestationLabel() {

        if(this.prestation.intLabel && this.prestation.intLabel[this.translate.currentLang] && this.prestation.intLabel[this.translate.currentLang] !== ""){
            return this.prestation.intLabel[this.translate.currentLang]
        }

        return this.prestation.name;
            
    } 

}
