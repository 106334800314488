import { Component, OnInit, Input } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../common-input/value-accessor-base';

@Component({
  selector: 'app-common-checkbox',
  templateUrl: './common-checkbox.component.html',
  styleUrls: ['./common-checkbox.component.scss','../common-input/common-input.component.scss'],
  providers: [
  	{provide: NG_VALUE_ACCESSOR, useExisting: CommonCheckboxComponent, multi: true}
  ]
})
export class CommonCheckboxComponent extends ValueAccessorBase<boolean> implements OnInit {

	@Input() label = "Label";
	@Input() color = "dark";
	@Input() subLink;
	@Input() value:boolean;
	errorMessage = "";

	constructor() {
		super();
	}

	ngOnInit() {}

}
