import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../models/user.model';
import { Notification } from '../models/notification.model';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    static COLLECTION_NAME = 'users';
    whenActionChange: EventEmitter<string> = new EventEmitter();
    
    constructor(
        private firestore: AngularFirestore,  
        private authService: AuthService, 
        private angularFireAuth: AngularFireAuth
    ) { }

    pushNotification(user: User, notification: Notification) {
        
        user.updatedAt = user.createdAt;
        if (user.notification){
             user.notification.push(notification );
        }
        else {
            user.notification = [notification]; 
        }
        return this.firestore.collection(UserService.COLLECTION_NAME).doc(user.uid).set(JSON.parse(JSON.stringify({...user})));
    }

    closeNotification(user: User, notification: Notification){

        user.updatedAt = user.createdAt;
        let indexNotification = user.notification.map(data => data.nid).indexOf(notification.nid);
        if (indexNotification != -1) user.notification[indexNotification].isClosed = true;
        return this.firestore.collection(UserService.COLLECTION_NAME).doc(user.uid).set({ ...user });  
    }
}
