import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PrestationDetail } from '../../../models/prestation.model';
import { SalonDetail } from '../../../models/salon.model';
import { shortDayLabel, AvailabilityState } from 'src/app/models/availability.model';

import { PrestationService } from '../../../services/prestation.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { CitiesService } from 'src/app/services/cities.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item-gallery',
  templateUrl: './item-gallery.component.html',
  styleUrls: ['./item-gallery.component.scss'],
})
export class ItemGalleryComponent implements OnInit {

	@Input() image;
	@Input() notified = false;
	@Input() noDetail = false;
    @Input() fromProfil = false;
    @Output() action = new EventEmitter();
    prestation: PrestationDetail;
    @Input() loading = false;
    @Input() size = 6;
    @Input() editable = false;
    @Input() salon : SalonDetail;
    distance:number = 0;
    isPromotion: boolean = false;

    constructor(public translate: TranslateService , private prestationService: PrestationService, private citiesService: CitiesService, private redirectionService: RedirectionService) { }

	ngOnInit() {

        if(this.salon){
            this.distance = this.citiesService.getSalonDistance(this.salon) 
        }

		if (this.image.pid) {

			this.prestationService.getPrestationByPid(this.image.pid).then( snap =>{
                this.prestation = new PrestationDetail().deserialize(snap.data());
                
                /*let dayIndexOnPromotion = new Date().getDay();

                if (dayIndexOnPromotion === 0)
                    dayIndexOnPromotion = 6;
                else
                    dayIndexOnPromotion -= 1;*/

                let arrayOfPromoAvailability = [];

                    shortDayLabel.forEach((value, index) => {
                    const promotionDay: any = this.prestation.promotionDays[index];

                    let typeOfSalonAvailability = typeof ((this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available)

                    if ((typeOfSalonAvailability === "boolean" && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available) || (typeOfSalonAvailability !== "boolean" && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available.am && !(this.salon.availability[shortDayLabel[index].key] as AvailabilityState).available.pm)) {
                        arrayOfPromoAvailability.push(false);
                        //this.isPromotion = false;
                    } else {
                        const promotionDay: any = this.prestation.promotionDays[index];
                        if (promotionDay && promotionDay.enabled && !this.promotionDateExpired()) {
                            arrayOfPromoAvailability.push(this.prestation.promotion);
                            //this.isPromotion = this.prestation.promotion
                        } else {
                            arrayOfPromoAvailability.push(false);
                            //this.isPromotion = false;
                        }
                    }

                });

                this.isPromotion = arrayOfPromoAvailability.includes(true);
            });
    		
		}
	}

    get currentPrice(): number {

        if (!this.prestation) return 0;

        let price = this.prestation.price;

        const now = new Date();

        if (this.prestation.promotion) {

            const promotionDay = this.prestation.promotionDays[(now.getDay() == 0) ? 6 : now.getDay() - 1];

            if (promotionDay.enabled) {
                const hourTab = promotionDay.hours.filter(h => h.value == now.getHours());

                if (hourTab.length == 1) {

                    if (hourTab[0].promotion) {

                        price -= (price * hourTab[0].promotion.value) / 100;

                    }

                }

            }

        }

        return price;
    }

    navigate(link) {
        this.redirectionService.redirectTo(link, true);
    }

    checkInfo(){
        if (this.fromProfil) this.action.emit();
    }

    goToSalon(){
        this.navigate(`/profil-salon/${this.salon.sid}`);
    }

    onImgError(event, entity, element='prestation'){

        if(element == 'profil'){

          if(entity._profilImageUrl){
            event.target.src = entity._profilImageUrl;
          }else{
            event.target.src = 'assets/img/profil_placeholder.png';
          }

        }else{

          if(entity._url){
            event.target.src = entity._url;
          }else{
            event.target.src = 'assets/images/barbershop.jpg';
          }

        }
        
    }

    promotionDateExpired(): boolean {

        let isExpired = false;
        let dateToCompare = new Date()
        // dateToCompare.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())

        if (this.prestation.promotionStartDateEnabled) {

            let dateStart = new Date(this.prestation.promotionStartDate.toString());
            dateStart.setHours(0, 0, 0, 0)
            isExpired = dateToCompare < dateStart;
            if (isExpired) return isExpired;
        }

        if (this.prestation.promotionEndDateEnabled) {

            let dateFin = new Date(this.prestation.promotionEndDate.toString());
            dateFin.setHours(0, 0, 0, 0)

            isExpired = dateToCompare > dateFin;
            if (isExpired) return isExpired;
        }

        return isExpired;
    }

    get prestationLabel() {

        if(this.prestation.intLabel && this.prestation.intLabel[this.translate.currentLang]){
            return this.prestation.intLabel[this.translate.currentLang]
        }

        if (!this.prestation.name && this.prestationService.defaultPrestations && this.prestationService.defaultPrestations.length > 0) {
            let prestationDefault = this.prestationService.defaultPrestations.find(prestation => prestation.dpid === this.prestation.defaultPrestationId)
            if (prestationDefault) {
                return prestationDefault[this.translate.currentLang] || prestationDefault.fr;
            }

        }

        if(this.prestation.intLabel && this.prestation.intLabel.fr){
            return this.prestation.intLabel.fr;
        }else{
            return this.prestation.name;
        }

    }

}
