import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { ModalController,  ToastController , LoadingController } from '@ionic/angular';
import { MailRequestModalComponent } from 'src/app/shared/components/mail-request-modal/mail-request-modal.component'
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { DataService, dataKey } from '../shared/helpers/data.service';

@Injectable({
  providedIn: 'root'
})
export class MailGuardService implements CanActivate {

	constructor(
        public toastController: ToastController,
		private authService: AuthService, 
		private router: Router,
		public modalController: ModalController,
        public loadingController: LoadingController,
        private dataService: DataService, 
        public translate: TranslateService,
        private userService: UserService
		) { }

	async canActivate( next: ActivatedRouteSnapshot ) {
        let route: any = { ...next }
        let url = route._routerState;
        
        while(!this.checkUserInfoCompleted()){
            //wait until we get the real user state
            await this.sleep(200);
        }

        if(this.isEmailUserInvalid()){
            this.requestMail(url);
            this.presentToast(this.translate.instant("Veuillez remplir toutes les informations obligatoires sur votre profil afin de pouvoir naviguer dans l'application"));
            return false;
        }else{
            return true;
        }
       
	}

    presentToast(msg: string) {
        this.toastController.create({
            message: msg,
            duration: 3000
        }).then(toast => toast.present());
    }

    async requestMail(url) {

        await this.modalController.create({
            component: MailRequestModalComponent,
            cssClass: 'my-custom-modal-request-modal',
            backdropDismiss: false,
        }).then(modal => {
            modal.onDidDismiss().then( async overlayEventDetail => {
                if (overlayEventDetail.data) {
                    const email = overlayEventDetail.data;

                    const loading = await this.loadingController.create({
                        message: this.translate.instant('Enregistrement...'),
                        cssClass: 'askeed-spinner'
                    });

                    await loading.present();

                    const userUpdated = new User().deserialize( {...this.authService.userLoggedIn, email: email});
                    // const userUpdated = new User().deserialize( {...this.authService.userLoggedIn});

                    // await this.userService.updateUserEmail(this.authService.userLoggedIn.email, eventData.password, this.authService.userLoggedIn);          
                    this.userService.updateUser( userUpdated ).then(response =>{
                        this.dataService.saveAppData(dataKey.USER_LOGGED, userUpdated);
                        loading.dismiss();
                        this.router.navigateByUrl(url)
                    }).catch(error =>{
                        loading.dismiss();
                        this.presentToast(this.translate.instant("Une erreur est survenue. Veuillez réessayer."));
                    });
                }
            });
            modal.present()
        });
    }

    isEmailUserInvalid():boolean{
        
        if (!this.authService.isLoggedIn || this.authService?.userLoggedIn?.email) return false;
        return true;
    }

    checkUserInfoCompleted() {

        if(this.authService?.isUserInfoCompleted || this.authService?.noUserInfoAfterChecked){            
            return true;
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

   

}
