export class Cgu {
    texte: string;
    texteInt?:any;
    resume?: string;
    resumeMobileInt?:any;
    texteEn:string;
    texteDe:string;
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
