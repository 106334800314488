import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { Category } from 'src/app/models/category.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-tabs-modal',
  templateUrl: './tabs-modal.component.html',
  styleUrls: ['./tabs-modal.component.scss'],
})
export class TabsModalComponent implements OnInit {

  constructor(public filterService:FilterService, public translate :TranslateService) { }

  ngOnInit() {}

  categoriesStyle(cat){
  	let style;
  	if(cat.selected){
      style = {
        "border-bottom":"solid 2px var(--ion-color-primary)",
        "color": "var(--ion-color-primary)",
        "transition":"0.5s"
      }
    } else {
      style = {
        "border-bottom":"solid 2px #d4d0cb",
         "color": "var(--ion-tab-bar-color)",
        "transition":"0.5s"
      }
    }
    return style;
  }

  selectCategory(c:Category, sub = false){

    if(!c.selected){

      c.selected = true;

    }
    if(c.selected){
      let categories=[];
      this.filterService.categories.forEach(category => {
        if (category.value == c.value) {
          categories.push(category.cpid);
        } else {
          category.selected = false;
        }

      });
      this.filterService.filterBy["category"] = categories;
      if(c.value == "all"){
        if(this.filterService.filterBy["category"]) {
          this.filterService.removeFilter("category");
        }
      }
      this.filterService.loading = true;
      this.filterService.tagsAdded.next(true);
    }
 }

}
