export const firebaseConfig = {
	// PROD 
	apiKey: "AIzaSyA7bQZKtvMxX5WkSIZAk3G7W1kSI6iyR6c",
	authDomain: "askeed-app.firebaseapp.com",
	databaseURL: "https://askeed-app.firebaseio.com",
	projectId: "askeed-app",
	storageBucket: "askeed-app.appspot.com",
	messagingSenderId: "98100155911",
	appId: "1:98100155911:web:3af9420703b6c74918bca2",
	measurementId: "G-G3SD1L8PEZ"

	// STAGING
	// apiKey: "AIzaSyCh9dvpq-deDKeVyYABAYVxX9uIug31pxg",
	// authDomain: "askeed-staging.firebaseapp.com",
	// projectId: "askeed-staging",
	// storageBucket: "askeed-staging.appspot.com",
	// messagingSenderId: "604128750633",
	// appId: "1:604128750633:web:0489729bcbee3e4d6dc038",
	// measurementId: "G-9E1X5E96L5"
  };

export const googleConfig = {
	webClientId: "98100155911-bvls0cqle7lme6hl15dqorjvqbe7aucv.apps.googleusercontent.com"
}