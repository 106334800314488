
export class Availability {
	monday: AvailabilityState;
	tuesday: AvailabilityState;
	wednesday: AvailabilityState;
	thursday: AvailabilityState;
	friday: AvailabilityState;
	saturday: AvailabilityState;
	sunday: AvailabilityState;

	constructor() {
		this.monday = {...new AvailabilityState(true)};
		this.tuesday = {...new AvailabilityState(true)};
		this.wednesday = {...new AvailabilityState(true)};
		this.thursday = {...new AvailabilityState(true)};
		this.friday = {...new AvailabilityState(true)};
		this.saturday = {...new AvailabilityState(false)};
		this.sunday = {...new AvailabilityState(false)};
	}

}

export class AvailabilityState {
	available:{ am: boolean , pm : boolean};
	am: {begin: string, end: string};
	pm: {begin: string, end: string};

	constructor(available) {
		this.available = { am: available, pm: available };
		this.am = {
			begin: "08:00",
			end: "12:00"
		};
		this.pm = {
			begin: "14:00",
			end: "19:00"
		};
	}

}

export const shortDayLabel = [{label:"Lu", key: "monday"}, {label:"Ma", key: "tuesday"}, {label:"Mer", key: "wednesday"}, {label:"Jeu", key: "thursday"}, {label:"Ven", key: "friday"}, {label:"Sam", key: "saturday"}, {label:"Dim", key: "sunday"}];