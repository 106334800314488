import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CguService } from 'src/app/services/cgu.service';
import { Cgu } from 'src/app/models/cgu.model';
import { dataKey } from 'src/app/shared/helpers/data.service';

@Component({
  selector: 'app-cgu-salon',
  templateUrl: './cgu-salon.component.html',
  styleUrls: ['./cgu-salon.component.scss'],
})
export class CguSalonComponent implements OnInit {

	@Input() show = 'resume';
	currentCgu:Cgu;
	fullCG = "";
	resumeCG = "";

  	constructor(  public authService: AuthService,
		public translate: TranslateService,
		public cguService:CguService) { }

  	ngOnInit() {

		this.cguService.get("cgu-salon").then(values => {

			this.currentCgu= new Cgu().deserialize(values.data());
			this.fullCG=this.currentCgu.texte;
			if (this.authService.userLoggedIn && this.authService.userLoggedIn.uid && this.authService.userLoggedIn.language) {
			  this.translate.setDefaultLang(this.authService.userLoggedIn.language);
			  this.translate.currentLang = this.authService.userLoggedIn.language;
		  } else {
			  let tempLang = JSON.parse(localStorage.getItem(dataKey.TEMP_LANG));
			  if (tempLang) {
				this.translate.setDefaultLang(tempLang);
				this.translate.currentLang = tempLang;
			  } else {
				this.translate.currentLang = tempLang;
				this.translate.currentLang = 'fr';
			  }
	  
		  }
			switch(this.translate.currentLang){
			  case "fr":
				this.resumeCG=this.currentCgu.resumeMobileInt.fr;
				this.fullCG=this.currentCgu.texte;
				break;
			  case "en":
				this.resumeCG=this.currentCgu.resumeMobileInt.en;
				this.fullCG=this.currentCgu.texteInt.en;
				break;
			  case "de":
				  this.resumeCG=this.currentCgu.resumeMobileInt.de;
				this.fullCG=this.currentCgu.texteInt.de;
				break
			  default:
				this.resumeCG=this.currentCgu.resumeMobileInt.fr;
				this.fullCG=this.currentCgu.texte;
				break;
			}
			
			});
  	}
	get cgu(){
		if(this.currentCgu){
			return this.currentCgu.texteInt[this.translate.currentLang];
		}
		
	}

	get resumeCgu(){
		if(this.currentCgu){
			return this.currentCgu.resumeMobileInt[this.translate.currentLang];
		}
		
	}

  


	
	

}
